<script>
  import anime from "animejs";
  import { ifSafari, isIe, getRandomString } from "../utils";

  import { onMount } from "svelte";
  import Wave from "./svg/Wave.svelte";

  export let id;
  let cls;
  export { cls as class };
  export let style;

  export let waveColor = "#f9f9f9";
  export let waveColorTop = "";
  export let waveColorBottom = "";

  const clipPathId = getRandomString(15);
  const imgId = getRandomString(15);

  let ww = window.innerWidth;
  window.addEventListener("resize", () => {
    ww = window.innerWidth;
    window.requestAnimationFrame(() => {
      setAnimation();
    });
  });

  export let imgSrc = "";
  export let imgAlt = "";

  const LARGE_ANIMATION_PATH =
    "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z";
  const SMALL_ANIMATION_PATH =
    "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77C1005.43,799.35,1195.72,900.48,1200,900z";

  let node;

  function createLargeAnimation() {
    return anime({
      targets: node,
      duration: 40000,
      easing: "linear",
      autoplay: false,
      d: [
        {
          value:
            "M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
        },
        {
          value:
            "M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
        },

        {
          value:
            "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
        }
      ],
      loop: true,
      update: () => {
        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0
        ) {
          const fimp = document.getElementById(imgId);
          if (!fimp) {
            return;
          }
          fimp.style.display = "none";
          fimp.offsetWidth;
          fimp.style.display = "block";
        }
      }
    });
  }

  function createSmallAnimation() {
    return anime({
      targets: node,
      easing: "linear",
      duration: 20000,
      autoplay: false,
      d: [
        {
          value:
            "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23C1101.71,821.22,1195.72,900.48,1200,900z"
        },
        {
          value:
            "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
        }
      ],
      loop: true
    });
  }

  let largeAnimation, smallAnimation;

  function setAnimation() {
    if (ww > 1400) {
      if (largeAnimation) {
        return;
      }

      smallAnimation && smallAnimation.pause();
      smallAnimation = null;
      node.setAttribute("d", LARGE_ANIMATION_PATH);
      largeAnimation = createLargeAnimation();
      largeAnimation.play();
    } else if (ww > 960) {
      if (smallAnimation) {
        return;
      }

      largeAnimation && largeAnimation.pause();
      largeAnimation = null;
      if (!ifSafari()) {
        node.setAttribute("d", SMALL_ANIMATION_PATH);
        smallAnimation = createSmallAnimation();
        smallAnimation.play();
      }
    } else {
      largeAnimation && largeAnimation.pause();
      largeAnimation = null;
      smallAnimation && smallAnimation.pause();
      smallAnimation = null;
    }
  }

  onMount(() => {
    node = document.querySelector(`#${clipPathId} .bblob`);
    setTimeout(() => {
      setAnimation();
    }, 1000);
  });
</script>

<style>
  .LavaImage_img {
    width: 100%;
    height: calc(100% - 2px);

    position: relative;
    top: 1px;

    object-fit: cover;
  }

  .LavaImage_imageWrapper {
    display: block;
    position: relative;

    width: 60vw;
    height: 100%;

    overflow: hidden;
    padding: 0;
  }

  @media (min-width: 961px) {
    .LavaImage_imageWrapper {
      margin: 0 -10vw 0 -2vw;
    }
  }

  @media (max-width: 960px) {
    .LavaImage_img {
      width: 100%;
      min-height: calc(100% - 2px);
      height: calc(100% - 2px);
    }

    .LavaImage_imageWrapper {
      width: 100vw;
      height: 100vh;
    }
  }
</style>

{#if isIe()}
  <svg
    class={'LavaImage_imageWrapper' + (cls ? ' ' + cls : '')}
    style={style ? style : undefined}
    id={id ? id : undefined}>
    <image
      x="0"
      y="0"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      xlink:href={imgSrc}
      clip-path={'url(#' + clipPathId + ')'} />
  </svg>
{:else}
  <div
    class={'LavaImage_imageWrapper' + (cls ? ' ' + cls : '')}
    style={style ? style : undefined}
    id={id ? id : undefined}>
    <Wave
      class=""
      flip="true"
      style="position: absolute; top: 0; z-index: 1;"
      color={waveColorTop || waveColor} />
    <img
      class="LavaImage_img"
      id={imgId}
      style={ww >= 1024 ? `clip-path: url('#${clipPathId}'); -webkit-clip-path: url('#${clipPathId}');` : ''}
      alt={imgAlt}
      src={imgSrc} />
    <Wave
      class=""
      style="position: absolute; bottom: 0; z-index: 1;"
      color={waveColorBottom || waveColor} />
  </div>
{/if}

<slot />

<svg
  viewBox={ww > 1400 ? '0 0 398.6 435.4' : '0 0 1200 900'}
  width="0"
  height="0">
  <defs>
    <clipPath
      id={clipPathId}
      clipPathUnits="objectBoundingBox"
      transform={ww > 1400 ? 'scale(0.0027, 0.0037)' : 'scale(0.0008, 0.002)'}>
      <path
        style={ww > 1400 ? 'transform: translate(200px, 125px);' : undefined}
        transform={ww > 1400 ? 'translate(200, 125)' : 'translate(-180, 0)'}
        class="bblob"
        d={ww > 1400 ? LARGE_ANIMATION_PATH : SMALL_ANIMATION_PATH} />
    </clipPath>
  </defs>
</svg>

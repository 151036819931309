<script>
  export let title;
  export let content;
  export let li;
  export let link;

  if (link === "interne-kommunikation" || link === "pr") {
    link = "kommunikationsagentur";
  }

  let tg = false;

  function toggleActive(li) {
    var els = document.querySelectorAll(".accordion-item .active");

    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove("active");
    }

    tg = !tg;
    if (tg) {
      setTimeout(() => {
        document.querySelector("." + li + " button").className = "active";
        document.querySelector("." + li + " .content").className =
          "content active";
      }, 100);
    }
  }
</script>

<div class="accordion-item {li}">
  <button on:click={toggleActive(li)}>
    <span style="width: 85%">{title}</span>
  </button>
  <div class="content">
    <p>
      {@html content}
      <a href={link} target="_self">Mehr</a>
    </p>

  </div>
</div>

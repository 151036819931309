<script>
  import { onMount } from "svelte";
  import ScrollMagic from "scrollmagic";
  import anime from "animejs";
  import Footer from "../components/Footer.svelte";
  import Opener from "../components/opener.svelte";

  import Prodhost from "../components/host.js";
  import LavaImage from "../components/LavaImage.svelte";

  import ColletRotationC from "../components/ColletRotationC.svelte";
  import AboutVideo from "../components/AboutVideo.svelte";
  import NewsSlider from "../components/NewsSlider.svelte";
  import BlobSlider from "../components/BlobSlider.svelte";

  var home = [];
  var projekte = [];
  var awards = [];
  var news = [];
  var about = [];
  var leistungenImages = [];
  var projekteImages = [];
  let aboutImages = [];

  let leistungen = [];
  let leistungenBeschreibung = [];

  let undJetzt = [];
  let undJetztBeschreibung = [];

  let blobColor = {
    f1: "#e61974",
    f2: "#FF9298"
  };

  let scr1 = 0;
  let scr2 = 0;
  let scr22 = 0;
  let scr3 = 0;
  let scr4 = 0;
  let scr5 = 0;
  let scr6 = 0;

  let openertimeout = 0;

  const MAX_MOBILE_SIZE = 480;

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  var mobileSize = Math.min(MAX_MOBILE_SIZE, ww) / 100;

  if (getCookie("opener") !== "1") {
    openertimeout = 12000;
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  var controller;

  function registerBubble(cls) {
    requestAnimationFrame(() => {
      new ScrollMagic.Scene({
        triggerElement: "." + cls,
        duration: ww,
        triggerHook: 0.7
      })
        .on("enter", function() {
          document.querySelector("." + cls).style.opacity = "1";
        })
        .addTo(controller);
    });
  }

  function registerAndNowStep(cls) {
    requestAnimationFrame(() => {
      new ScrollMagic.Scene({
        triggerElement: "." + cls,
        duration: ww,
        triggerHook: 0.7
      })
        .on("enter", function() {
          document.querySelector("." + cls).style.opacity = "1";
        })
        .addTo(controller);
    });
  }

  async function hashchange() {
    await fetch(Prodhost + "/colletadmin/api/singletons/get/home")
      .then(response => response.json())
      .then(json => {
        home = json;
        leistungenImages = home.LeistungenBlobImages;
        projekteImages = home.ProekteBlobImages;
        aboutImages = home.AboutBlobImages;
        leistungen = home.Leistungen;
        leistungenBeschreibung = home.LeistungenBeschreibung;
        undJetzt = home.UndJetzt;
        undJetztBeschreibung = home.UndJetztBeschreibung;
      });

    // await fetch(Prodhost + "/colletadmin/api/singletons/get/home")
    //   .then(response => response.json())
    //   .then(json => {
    //     home = json;
    //     leistungenImages = home.LeistungenBlobImages;
    //     projekteImages = home.ProekteBlobImages;
    //     aboutImages = home.AboutBlobImages;
    //     leistungen = home.Leistungen;
    //     leistungenBeschreibung = home.LeistungenBeschreibung;
    //     undJetzt = home.UndJetzt;
    //     undJetztBeschreibung = home.UndJetztBeschreibung;
    //   });

    await fetch(Prodhost + "/colletadmin/api/collections/get/projekte", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true },
        limit: 3,
        skip: 0,
        sort: { Datum: -1 }
      })
    })
      .then(response => response.json())
      .then(json => {
        projekte = json.entries;
      });

    await fetch(Prodhost + "/colletadmin/api/collections/get/awards", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { publish: true },
        sort: { _o: 1 }
      })
    })
      .then(response => response.json())
      .then(json => {
        awards = json.entries;
      });

    await fetch(Prodhost + "/colletadmin/api/collections/get/News", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        news = json.entries;
        news.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });
        news.length = 15;
        news.reverse();
      });

    await fetch(Prodhost + "/colletadmin/api/collections/get/tour", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        limit: 3
      })
    })
      .then(response => response.json())
      .then(json => {
        about = json.entries;
      });
  }

  var localopener = 0;

  onMount(() => {
    if (getCookie("opener") !== "1") {
      // if (typeof Storage !== "undefined") {
      //   if (!localStorage.opener) {
      //     localStorage.opener = Date.now();
      //     localopener = 1;
      //   } else {
      //     const hour = 1000 * 60 * 60 * 7;
      //     if (Date.now() - localStorage.opener >= hour) {
      //       localopener = 1;
      //     }
      //   }
      // } else {

      //   localopener = 1;

      // }

      localopener = 1;
    }

    if (localopener === 0) {
      openertimeout = 0;
    }

    window.addEventListener("resize", () => {
      ww = window.innerWidth;
      wh = window.innerHeight;

      mobileSize = Math.min(MAX_MOBILE_SIZE, ww) / 100;
      setScrollActive();
      setLavaAnimation();
    });
    hashchange();

    controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        reverse: true
      },
      vertical: false
    });

    if (ww < 1024) {
      controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          reverse: true
        },
        vertical: true
      });
    } else {
      controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          reverse: true
        },
        vertical: false
      });
    }

    var scene = new ScrollMagic.Scene({
      triggerElement: "#w1Projekt",
      duration: ww,
      // offset: ww / 4,
      triggerHook: 0.8
    })
      .on("enter", function() {
        if (ww >= 961 && scr1 === 0) {
          setTimeout(() => {
            wIn("#w1Projekt .wProjekt", "bottom", 1);
          }, 1000);
        }
        anime({
          targets: blobColor,
          f1: "#e61974",
          f2: "#FF9298",
          duration: 1000,
          easing: "linear",
          loop: false
        });
      })

      .addTo(controller);

    var scene2 = new ScrollMagic.Scene({
      triggerElement: "#w2Projekt .tb",
      duration: ww,
      triggerHook: 0.7
    })
      .on("enter", function() {
        if (ww >= 961 && scr2 === 0) {
          wIn("#w2Projekt .wProjekt", "top", 2, 0.5, 1);
        }
        anime({
          targets: blobColor,
          f1: "#156f7b",
          f2: "#36b8c9",
          easing: "linear",
          duration: 1000,
          loop: false
        });
      })

      .addTo(controller);

    var scene22 = new ScrollMagic.Scene({
      triggerElement: "#awards",
      duration: ww,
      triggerHook: 0.8
    })
      .on("enter", function() {
        if (ww >= 961 && scr22 === 0) {
          wIn("#awards .wProjekt", "bottom", 22, 0.5, 1);
        }
      })

      .addTo(controller);

    var scene3 = new ScrollMagic.Scene({
      triggerElement: "#w3Projekt",
      duration: ww,
      triggerHook: 0.8
    })
      .on("enter", function() {
        if (ww >= 961 && scr3 === 0) {
          wIn("#w3Projekt .wProjekt", "bottom", 3, 0.5, 1);
        }
        anime({
          targets: blobColor,
          f2: "#e61974",
          f1: "#FF9298",
          duration: 1000,
          easing: "linear",
          loop: false
        });
      })

      .addTo(controller);

    var scene4 = new ScrollMagic.Scene({
      triggerElement: "#w4Projekt",
      duration: ww,
      triggerHook: 0.99
    })
      .on("enter", function() {
        if (ww >= 961 && scr4 === 0) {
          wIn("#w4Projekt .wProjekt", "top", 4);
        }
        anime({
          targets: blobColor,
          f2: "#156f7b",
          f1: "#36b8c9",
          easing: "linear",
          duration: 1000,
          loop: false
        });
      })

      .addTo(controller);

    var scene5 = new ScrollMagic.Scene({
      triggerElement: "#w5Projekt",
      duration: ww,
      triggerHook: 0.99
    })
      .on("enter", function() {
        if (ww >= 961 && scr5 === 0) {
          wIn("#w5Projekt .wProjekt", "top", 5);
        }
        anime({
          targets: blobColor,
          f1: "#e61974",
          f2: "#FF9298",
          easing: "linear",
          duration: 1000,
          loop: false
        });
      })

      .addTo(controller);

    if (ww >= 961) {
      var scene6 = new ScrollMagic.Scene({
        offset: -100,
        triggerElement: "#w6Projekt .tb",
        duration: ww,
        triggerHook: 0.7
      })
        .on("enter", function() {
          if (ww >= 961 && scr6 === 0) {
            wIn("#w6Projekt .wProjekt", "top", 6);
          }
          anime({
            targets: blobColor,
            f1: "#e61974",
            f2: "#FF9298",
            easing: "linear",
            duration: 1000,
            loop: false
          });
        })

        .addTo(controller);
    }

    // const svgPath = document.querySelectorAll(".path");

    function wIn(target, pos, src, startScale, endScale) {
      if (ww >= 961) {
        let tbtX = "-50px";
        let tbtY = "-100px";
        let wtX = "-100px";

        if (pos === "top") {
          tbtX = "50px";
          tbtY = "100px";
          wtX = "100px";
        }

        anime({
          targets: target + " .tb",
          loop: false,
          duration: 500,
          translateX: tbtX,
          translateY: tbtY,
          easing: "easeInOutSine"
        });
        anime({
          targets: target + " .w",
          loop: false,
          duration: 500,
          easing: "easeInOutSine",
          translateX: wtX,
          translateY: 0,
          scale: 2
        });
        anime({
          targets: target,
          loop: false,
          duration: 500,
          opacity: [0, 1],
          scale: [startScale || 0, endScale || 1],
          easing: "easeInOutSine",
          begin: function() {
            setStyle(target, "display", "block");
          }
        });

        if (src === 1) {
          scr1 = 1;
        }
        if (src === 2) {
          scr2 = 1;
        }
        if (src === 22) {
          scr22 = 1;
        }
        if (src === 3) {
          scr3 = 1;
        }
        if (src === 4) {
          scr4 = 1;
        }
        if (src === 5) {
          scr5 = 1;
        }
        if (src === 6) {
          scr6 = 1;
        }
      }
    }

    var lavaAnimation = (function() {
      var t,
        i = {
          screen: {
            elem: null,
            callback: null,
            ctx: null,
            width: 0,
            height: 0,
            left: 0,
            top: 0,
            init: function(t, i, s) {
              return (
                (this.elem = document.getElementById(t)),
                (this.callback = i || null),
                "CANVAS" == this.elem.tagName &&
                  (this.ctx = this.elem.getContext("2d")),
                window.addEventListener(
                  "resize",
                  function() {
                    this.resize();
                  }.bind(this),
                  !1
                ),
                (this.elem.onselectstart = function() {
                  return !1;
                }),
                (this.elem.ondrag = function() {
                  return !1;
                }),
                s && this.resize(),
                this
              );
            },
            resize: function() {
              var t = this.elem;
              for (
                this.width = t.offsetWidth,
                  this.height = t.offsetHeight,
                  this.left = 0,
                  this.top = 0;
                null != t;
                t = t.offsetParent
              ) {
                (this.left += t.offsetLeft), (this.top += t.offsetTop);
              }
              this.ctx &&
                ((this.elem.width = this.width),
                (this.elem.height = this.height)),
                this.callback && this.callback();
            }
          }
        },
        s = function(t, i) {
          (this.x = t),
            (this.y = i),
            (this.magnitude = t * t + i * i),
            (this.computed = 0),
            (this.force = 0);
        };
      s.prototype.add = function(t) {
        return new s(this.x + t.x, this.y + t.y);
      };
      var h = function(t) {
        var i = 0.1,
          h = 1.5;
        (this.vel = new s(
          (Math.random() > 0.5 ? 1 : -1) * (0.2 + 0.25 * Math.random()),
          (Math.random() > 0.5 ? 1 : -1) * (0.2 + Math.random())
        )),
          (this.pos = new s(
            0.1 * t.width + Math.random() * t.width * 0.3,
            0.1 * t.height + Math.random() * t.height * 0.3
          )),
          (this.size = t.wh / 15 + (Math.random() * (h - i) + i) * (t.wh / 15)),
          (this.width = t.width),
          (this.height = t.height);
      };
      h.prototype.move = function() {
        this.pos.x >= this.width - this.size
          ? (this.vel.x > 0 && (this.vel.x = -this.vel.x),
            (this.pos.x = this.width - this.size))
          : this.pos.x <= this.size &&
            (this.vel.x < 0 && (this.vel.x = -this.vel.x),
            (this.pos.x = this.size)),
          this.pos.y >= this.height - this.size
            ? (this.vel.y > 0 && (this.vel.y = -this.vel.y),
              (this.pos.y = this.height - this.size))
            : this.pos.y <= this.size &&
              (this.vel.y < 0 && (this.vel.y = -this.vel.y),
              (this.pos.y = this.size)),
          (this.pos = this.pos.add(this.vel));
      };
      var e = function(t, i, e) {
        (this.step = 5),
          (this.width = t),
          (this.height = i),
          (this.wh = Math.min(t, i)),
          (this.sx = Math.floor(this.width / this.step)),
          (this.sy = Math.floor(this.height / this.step)),
          (this.paint = !1),
          (this.metaFill = r(t, i, t)),
          (this.plx = [0, 0, 1, 0, 1, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0]),
          (this.ply = [0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 1, 0, 1]),
          (this.mscases = [0, 3, 0, 3, 1, 3, 0, 3, 2, 2, 0, 2, 1, 1, 0]),
          (this.ix = [
            1,
            0,
            -1,
            0,
            0,
            1,
            0,
            -1,
            -1,
            0,
            1,
            0,
            0,
            1,
            1,
            0,
            0,
            0,
            1,
            1
          ]),
          (this.grid = []),
          (this.balls = []),
          (this.iter = 0),
          (this.sign = 1);
        for (var o = 0; o < (this.sx + 2) * (this.sy + 2); o++) {
          this.grid[o] = new s(
            (o % (this.sx + 2)) * this.step,
            Math.floor(o / (this.sx + 2)) * this.step
          );
        }
        for (var l = 0; e > l; l++) {
          this.balls[l] = new h(this);
        }
      };
      (e.prototype.computeForce = function(t, i, s) {
        var h,
          e = s || t + i * (this.sx + 2);
        if (0 === t || 0 === i || t === this.sx || i === this.sy) {
          h = 0.6 * this.sign;
        } else {
          h = 0;
          for (var r, n = this.grid[e], a = 0; (r = this.balls[a++]); ) {
            h +=
              (r.size * r.size) /
              (-2 * n.x * r.pos.x -
                2 * n.y * r.pos.y +
                r.pos.magnitude +
                n.magnitude);
          }
          h *= this.sign;
        }
        return (this.grid[e].force = h), h;
      }),
        (e.prototype.marchingSquares = function(t) {
          var i = t[0],
            s = t[1],
            h = t[2],
            e = i + s * (this.sx + 2);
          if (this.grid[e].computed === this.iter) {
            return !1;
          }
          for (var r, n = 0, a = 0; 4 > a; a++) {
            var l = i + this.ix[a + 12] + (s + this.ix[a + 16]) * (this.sx + 2),
              d = this.grid[l].force;
            ((d > 0 && this.sign < 0) || (0 > d && this.sign > 0) || !d) &&
              (d = this.computeForce(
                i + this.ix[a + 12],
                s + this.ix[a + 16],
                l
              )),
              Math.abs(d) > 1 && (n += Math.pow(2, a));
          }
          if (15 === n) {
            return [i, s - 1, !1];
          }
          5 === n
            ? (r = 2 === h ? 3 : 1)
            : 10 === n
            ? (r = 3 === h ? 0 : 2)
            : ((r = this.mscases[n]), (this.grid[e].computed = this.iter));
          var p =
            this.step /
            (Math.abs(
              Math.abs(
                this.grid[
                  i +
                    this.plx[4 * r + 2] +
                    (s + this.ply[4 * r + 2]) * (this.sx + 2)
                ].force
              ) - 1
            ) /
              Math.abs(
                Math.abs(
                  this.grid[
                    i +
                      this.plx[4 * r + 3] +
                      (s + this.ply[4 * r + 3]) * (this.sx + 2)
                  ].force
                ) - 1
              ) +
              1);
          return (
            o.lineTo(
              this.grid[
                i + this.plx[4 * r] + (s + this.ply[4 * r]) * (this.sx + 2)
              ].x +
                this.ix[r] * p,
              this.grid[
                i +
                  this.plx[4 * r + 1] +
                  (s + this.ply[4 * r + 1]) * (this.sx + 2)
              ].y +
                this.ix[r + 4] * p
            ),
            (this.paint = !0),
            [i + this.ix[r + 4], s + this.ix[r + 8], r]
          );
        }),
        (e.prototype.renderMetaballs = function() {
          this.metaFill = o.createLinearGradient(
            window.innerWidth / 3,
            200,
            window.innerWidth / 2,
            0
          );
          for (var t, i = 0; (t = this.balls[i++]); ) {
            t.move();
          }
          for (
            this.iter++,
              this.sign = -this.sign,
              this.paint = !1,
              this.metaFill.addColorStop(0, blobColor.f2),
              this.metaFill.addColorStop(1, blobColor.f1),
              o.fillStyle = this.metaFill,
              o.beginPath(),
              i = 0;
            (t = this.balls[i++]);

          ) {
            var s = [
              Math.round(t.pos.x / this.step),
              Math.round(t.pos.y / this.step),
              !1
            ];
            do {
              s = this.marchingSquares(s);
            } while (s);
            this.paint &&
              (o.fill(), o.closePath(), o.beginPath(), (this.paint = !1));
          }
        });
      var r = function(t, i, s) {
        var r = o.createRadialGradient(t / 1, i / 1, 0, t / 1, i / 1, s);
        var r = o.createLinearGradient(0, 0, t, 0);

        return r;
      };

      if (document.querySelector(".lamp-anim")) {
        var n = function() {
            if (!lavaRunning) {
              return;
            }
            requestAnimationFrame(n);
            o.clearRect(0, 0, a.width, a.height), t.renderMetaballs();
          },
          a = i.screen.init("lamp-anim", null, !0),
          o = a.ctx;

        a.resize(), (t = new e(a.width * 2, a.height * 2, 6));
      }
      return { run: n };
    })();

    var lavaRunning = false;
    function setLavaAnimation() {
      if ((ww > 960 && lavaRunning) || (ww <= 960 && !lavaRunning)) {
        return;
      }

      if (ww > 960) {
        lavaRunning = true;
        document.querySelector(".lamp-anim").style.display = "";
        lavaAnimation.run();
      } else {
        document.querySelector(".lamp-anim").style.display = "none";
        lavaRunning = false;
      }
    }

    if (ww > 960) {
      setTimeout(() => {
        setLavaAnimation();
        lavaAnimation.run();
      }, 100);
    }

    let timeoutElapsed = false;
    let scrollActive = false;

    function setScrollActive() {
      scrollActive = timeoutElapsed && ww > 960;
    }

    setTimeout(() => {
      let style = document.createElement("style");
      style.innerHTML =
        "@media (min-width: 961px) {#openerbox, #opener {display: none !important;}}";
      document.head.appendChild(style);

      timeoutElapsed = true;
      setScrollActive();

      if (
        getCookie("ch") === "1" ||
        getCookie("ch") === "2" ||
        getCookie("ch") === "3" ||
        getCookie("ch") === "4" ||
        getCookie("ch") === "5"
      ) {
        setCookie("opener", 1, 7);
      }
    }, openertimeout - 2000);

    const el = document.querySelector("#slider");
    window.addEventListener("wheel", function(e) {
      if (!scrollActive) {
        return;
      }

      let sp = e.deltaY + e.deltaX;

      if (e.deltaY >= 100) {
        sp = sp / 2;
      }

      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        if (e.deltaY < 5) {
          sp = sp * 20;
        } else {
          sp = sp * 20;
        }
      }

      if (
        navigator.userAgent.search("Safari") >= 0 &&
        navigator.userAgent.search("Chrome") < 0 &&
        navigator.platform.indexOf("Mac") > -1
      ) {
        if (sp < 0) {
          sp = -80;
        } else {
          sp = 80;
        }
      }

      if (e.deltaY > 0 || e.deltaX > 0) {
        el.scrollLeft += sp;
      } else {
        el.scrollLeft -= sp * -1;
      }
    });

    function setStyle(selector, prop, value) {
      let element = document.querySelector(selector);
      if (!element) {
        return;
      }

      element.style[prop] = value;
    }

    el.onscroll = function() {
      if (!scrollActive) {
        return;
      }

      var elmnt = document.getElementById("slider");
      var x = elmnt.scrollLeft;
      if (ww >= 961) {
        setStyle("main", "backgroundPosition", "-" + (x - x * 0.99) + "px 0px");

        setStyle(
          "#w2Projekt .tb2",
          "transform",
          "translateX(" + (x - x * 1.2) + "px)"
        );

        setStyle(
          "#mainIntroText",
          "transform",
          "translateX(" + (x - x * 1.1) + "px)"
        );

        setStyle(
          "#w1Projekt .tb1",
          "transform",
          "translateX(" + (x - x * 1.1) + "px)"
        );

        setStyle(
          "#w3Projekt .tb1",
          "transform",
          "translateX(" + (x - x * 1.12) + "px)"
        );

        setStyle(
          "#w4Projekt .tb2",
          "transform",
          "translateX(" + (x - x * 1.2) + "px)"
        );

        setStyle(
          "#w5Projekt .tb2",
          "transform",
          "translateX(" + (x - x * 1.3) + "px)"
        );

        setStyle(
          "#w6Projekt .tb2",
          "transform",
          "translateX(" + (x - x * 1.15) + "px)"
        );

        setStyle(
          "#w1Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, -50px)"
        );

        setStyle(
          "#awards .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, -50px)"
        );

        setStyle(
          "#w2Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, 0px) scale(1)"
        );

        setStyle(
          "#w3Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, -50px)  scale(1)"
        );

        setStyle(
          "#awards .wProjekt ",
          "transform",
          "translate(" + (x - x * 1.1) + "px, -50px)  scale(1)"
        );

        setStyle(
          "#w4Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, 50px) scale(1)"
        );

        setStyle(
          "#w5Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.1) + "px, 50px) scale(1)"
        );

        setStyle(
          "#w6Projekt .wProjekt",
          "transform",
          "translate(" + (x - x * 1.15) + "px, 50px) scale(1)"
        );

        setStyle(
          "#lamp-anim",
          "transform",
          "translateX(-" + (x - x * 0.9) + "px) scale(1)"
        );

        setStyle(
          "#blobhg0",
          "transform",
          "translateX(" + (x - x * 0.5) + "px) scale(3)"
        );

        setStyle(
          "#blobhg1",
          "transform",
          "translateX(" + (x - x * 0.5) + "px) scale(3)"
        );

        setStyle(
          "#blobhg2",
          "transform",
          "translateX(" + (x - x * 0.9) + "px) scale(3)"
        );

        setStyle(
          "#blobhg3",
          "transform",
          "translateX(" + (x - x * 0.9) + "px) scale(2.5)"
        );

        setStyle(
          "#blobhg4",
          "transform",
          "translateX(" + (x - x * 1) + "px) scale(2)"
        );

        setStyle(
          "#blobhg5",
          "transform",
          "translateX(" + (x - x * 1) + "px) scale(3)"
        );

        setStyle(
          "#blobhg6",
          "transform",
          "translateX(" + (x - x * 1.15) + "px) scale(6,3)"
        );
      }
    };
  });
</script>

<div id="main">

  {#if ww >= 961}
    <canvas id="lamp-anim" class="lamp-anim" />
  {/if}
  <div
    class=" scrollcontentbox {ww < 1024 ? 'contentmain' : 'slider'}"
    id="slider"
  >

    {#if ww >= 961 && getCookie('opener') !== '1'}
      <section id="opener" class={ww < 1024 ? 'section' : ''}>
        {#if home}
          <div class="sbox" id="openerBox">
            <div class="tb tb1">
              <Opener openertext={home.openertext} />
            </div>
          </div>
        {/if}
      </section>
    {/if}

    <section
      id="index_mainImage"
      class="section mainslider is-vertical-center"
      style="min-height: 800px;"
    >
      {#if ww >= 961}
        <div id="blobhg0" class="morph" />
      {/if}

      {#if home.Bild}
        <LavaImage
          id=""
          style=""
          imgSrc="{Prodhost}/colletadmin/{ww < 961 ? home.Bild.styles[2].path : home.Bild.styles[0].path}"
          class="index_mainImage"
          waveColorTop="#fff"
        >
          {#if ww < 961}
            <div class="index_introTextContainer" id="mainIntroText">

              <BlobSlider showBlob3={false} />
              <div class="index_introTextContainerInner">
                <ColletRotationC
                  id=""
                  class=""
                  text="OME IN!"
                  animation="true"
                  delay="1000"
                  dotColor="#156f7b"
                  style="margin-bottom: 0.2em; font-size: 0.75em; font-weight:
                  800; color: #ffffff;"
                />
                <h1 class="index_introText">
                  {@html home.openertext}
                </h1>
              </div>
            </div>
          {/if}
        </LavaImage>
      {/if}
    </section>

    {#if home}
      <section id="projekte_block" class={ww < 1024 ? 'section' : ''}>

        {#if ww > 960}
          <div id="blobhg1" class="morph" />
        {/if}
        <div class="sbox" id="w1Projekt">
          <div class="tb tb1" style="bottom: auto; left: 5%; top: 10vh;">
            <h2>Projekte</h2>
            <p>
              {@html home.ProjekteBlobText}
            </p>
            {#if ww > 960}
              <a
                href="/projekte/"
                target="_self"
                class="index_moreButton button"
                style="margin-top: 3em;"
              >
                Cool, ich will mehr Projekte!
              </a>
            {/if}
          </div>
          <div class="wProjekt wbottom index_projects">
            <div class="columns">
              {#each projekte as p, i}
                <a href="/projekte/{p.slug}/" target="_self" class="columnLink">
                  <div class="column homeimg{i}">
                    <figure
                      class="image is-rounded {ww <= 960 ? 'is-square' : ''}"
                    >
                      <div class="imgblend is-rounded" />
                      <img
                        alt={p.Image.description !== '' ? p.Image.description : p.Title}
                        class="is-rounded"
                        src="{Prodhost}/colletadmin/{p.Image.styles[0].path}"
                      />
                    </figure>
                    <p style={ww < 961 ? 'font-weight: bold;' : ''}>
                      {@html p.Title}
                    </p>
                  </div>
                </a>
              {/each}
            </div>
          </div>
          {#if ww <= 960}
            <a
              href="/projekte/"
              target="_self"
              class="index_moreButton button"
              style="margin-top: 3em;"
            >
              Cool, ich will mehr Projekte!
            </a>
          {/if}
        </div>

      </section>
    {/if}

    <section class={ww < 1024 ? 'section' : ''}>

      <style>
        #awards .awardsImage {
          max-width: 120px;
          margin: 10px 5px 0 5px;
        }

        #awards.sbox {
          min-width: 700px !important;
          left: -200px;
          text-align: left;
        }

        #awards.sbox .wProjekt {
          width: 100%;
          top: 10vh;
        }
        #awards.sbox .wProjekt > div {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          max-width: 400px;
        }

        @media screen and (max-width: 1024px) {
          #awards.sbox {
            left: 0px;
            text-align: center;
          }

          #awards.sbox .wProjekt > div {
            margin: 100px auto 0 auto;
            justify-content: center;
          }

          body #index #awards.sbox .wProjekt a {
            margin-top: 0px !important;
          }
        }
      </style>
      <div class="sbox" id="awards">

        <div class="wProjekt">

          <h2>AWARDS</h2>
          <p>{@html home.awardsText}</p>
          <div>
            {#each awards as aw}
              {#if aw.link !== ''}
                <a href={aw.link} target="_self">

                  <img
                    class="awardsImage"
                    alt={aw.image.description !== '' ? aw.image.description : aw.title}
                    src="{Prodhost}/colletadmin/storage/uploads/{aw.image.path}"
                  />
                </a>
              {:else}
                <div>
                  <img
                    class="awardsImage"
                    alt={aw.image.description !== '' ? aw.image.description : aw.title}
                    src="{Prodhost}/colletadmin/storage/uploads/{aw.image.path}"
                  />
                </div>
              {/if}
            {/each}
          </div>
        </div>
      </div>
    </section>
    <section class={ww < 1024 ? 'section' : ''}>
      {#if home}
        {#if ww >= 961}
          <div id="blobhg3" class="morph" />
        {/if}

        <div class="sbox" id="w3Projekt">
          <div class="tb tb1" style="bottom: 30%;">
            <h2>Leistungen</h2>
            <p>
              {@html home.LeistungenBlobText}
            </p>
            {#if ww >= 961}
              <a
                href="/leistungen/"
                target="_self"
                class="index_moreButton button"
                style="margin-top: 3em;"
              >
                So viel? Schau ich mir genauer an!
              </a>
            {/if}
          </div>
          <div class="wProjekt">
            <div class="columns {ww >= 961 ? 'service_columns' : ''}">
              {#each leistungen as item, i}
                <div
                  class="service_bubble service_bubble_{i}"
                  style="background-image: url('{Prodhost}/images/index_fluid_bubble_{i + 1}{ww >= 961 || i % 2 === 0 ? '' : '_green'}.svg');
                  "
                >
                  <div class="service_bubble_content">
                    <h3>{item.value}</h3>
                    <p>{leistungenBeschreibung[i].value}</p>
                  </div>
                </div>
                {registerBubble('service_bubble_' + i) || ''}
              {/each}
            </div>
          </div>
          {#if ww < 961}
            <a
              href="/leistungen/"
              target="_self"
              class="index_moreButton button"
              style="margin-top: 3em;"
            >
              So viel? Schau ich mir genauer an!
            </a>
          {/if}
        </div>
      {/if}
    </section>

    <section class={ww < 1024 ? 'section' : ''}>
      {#if home}
        {#if ww >= 961}
          <div id="blobhg4" class="morph" />
        {/if}
        <div class="sbox" id="w4Projekt">
          <div class="tb tb2 index_about_us_text">
            <h2 class="index_about_us_title">Über uns</h2>
            <p class="index_about_us_intro">
              {@html home.AboutBlobText}
            </p>
            <p class="index_about_us_description">
              {@html home.AboutBlobTextLong}
            </p>
            <AboutVideo
              class="index_about_us_video"
              videoId={home.video}
              altText="About video preview"
              shadowSize="2vw"
              style=""
            />
            <div class="index_about_us_button">
              <a
                href="/ueberuns/"
                target="_self"
                class="button index_moreButton"
                style={ww >= 961 ? 'position: relative; top: -1em; left: -3em;' : ''}
              >
                Aha, ich will alle Details!
              </a>
              <a
                href="/perfectmatch/"
                target="_blank"
                rel="nofollow"
                class="button2 spiel"
                style="margin-top: 0.5em !important; max-width: 100%;
                white-space: normal; height: auto; padding: 0.5em 3em;"
              >
                <p
                  style="font-size: 1rem; line-height: 1.2rem;margin:0;
                  text-align:center"
                >
                  Ob wir wirklich zusammen passen?
                  <br />
                  Finde es hier heraus!
                </p>
              </a>
            </div>
          </div>
        </div>
      {/if}
    </section>

    <section class={ww < 1024 ? 'section' : ''}>
      {#if home}
        {#if ww >= 961}
          <div id="blobhg5" class="morph" />
        {/if}
        <div class="sbox" id="w5Projekt">
          <div class="tb tb2" style="top: auto; bottom: 10%;">
            <h2>Vorteile</h2>
            <p>
              {@html home.VorteileText}
            </p>
            {#if ww >= 961}
              <ul class="index_advantagesList">
                {#each home.Vorteile || [] as vorteil, i}
                  <li class="index_advantagesListEntry">
                    <ColletRotationC size="12" id="" class="" style="" />
                    <p>
                      <b>{vorteil.value}:</b>
                      {home.VorteileBeschreibung[i].value}
                    </p>
                  </li>
                {/each}
              </ul>
              <a
                href="/kontakt/"
                target="_self"
                class="button index_moreButton"
                style="margin-top: 1em;"
              >
                Toll, die muss ich ansprechen!
              </a>
            {/if}
          </div>
          {#if ww < 961}
            <div class="wProjekt wtop" style="position:relative; float: right;">
              <ul class="index_advantagesList" style="margin-bottom: 3em;">
                {#each home.Vorteile || [] as vorteil, i}
                  <li class="index_advantagesListEntry">
                    <ColletRotationC
                      size="12"
                      style="margin-top: -1em;"
                      id=""
                      class=""
                    />
                    <p>
                      <b>{vorteil.value}:</b>
                      {home.VorteileBeschreibung[i].value}
                    </p>
                  </li>
                {/each}
              </ul>
            </div>
            <a href="/kontakt/" target="_self" class="button index_moreButton">
              Toll, die muss ich ansprechen!
            </a>
          {/if}
        </div>
      {/if}
    </section>

    <section class={ww < 1024 ? 'section' : ''}>
      {#if home}
        {#if ww >= 961}
          <div id="blobhg2" class="morph" />
        {/if}
        <div class="sbox" id="w2Projekt">
          <div class="tb tb2">
            <h2>News & Blog</h2>
            <p>
              {@html home.BlogBlobText}
            </p>
            {#if ww >= 961}
              <a
                href="/blog/"
                target="_self"
                class="button index_moreButton"
                style="margin-top: 3em;"
              >
                Echt guter (Lese)stoff, ich brauch' mehr!
              </a>
            {/if}
          </div>
          <div class="wProjekt wtop">
            <NewsSlider {news} />
          </div>
          {#if ww < 961}
            <a
              href="/blog/"
              target="_self"
              class="button index_moreButton"
              style="margin-top: 3em;"
            >
              Echt guter (Lese)stoff, Ich brauch' mehr!
            </a>
          {/if}
        </div>
      {/if}
    </section>

    <section class={ww < 1024 ? 'section' : ''}>
      {#if home}
        {#if ww >= 961}
          <div id="blobhg6" class="morph" />
        {/if}
        <div class="sbox" id="w6Projekt">
          <div class="tb tb2" style="top: 10%; left: -10%;">
            <h2>Und jetzt?</h2>
            <p>
              {@html home.UndJetztBlobText}
            </p>
          </div>
          <div class="wProjekt">
            <div class="andNowWrapper">
              <svg
                style="position: absolute; {ww >= 961 ? `display: none;` : `width: ${120 * mobileSize}px`}"
                preserveAspectRatio="xMidYMin"
                width="900"
                height="2500"
                viewBox="0 0 567 1679"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <g>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 126.881 16.475 C 65.464 46.411 26.574 76.141 18.788
                      174.163 C -12.384 566.581 440.567 406.946 525.18 589.186 C
                      695.889 956.863 99.456 883.634 49.613 1095.641 C -13.603
                      1364.53 386.555 1400.406 446.243 1409.669 C 590.537
                      1432.063 543.042 1605.841 462.717 1628.364"
                      stroke-width="20"
                      stroke-dashoffset="100"
                      stroke-dasharray="3830.27"
                      fill="none"
                      style="stroke-linecap: round; stroke: rgb(236, 58, 130);"
                    />
                    <g
                      transform="matrix(0.987689, 0.156435, -0.156435, 0.987689,
                      381.07489, 139.973373)"
                    >
                      <line
                        style="stroke-width: 15px; stroke: rgb(236, 58, 130);"
                        x1="275.432"
                        y1="1449.877"
                        x2="325.432"
                        y2="1449.877"
                        transform="matrix(0.325568, -0.945519, 0.945519,
                        0.325568, -1168.264743, 1261.907734)"
                      />
                      <line
                        style="stroke-miterlimit: 1; stroke-width: 15px; stroke:
                        rgb(236, 58, 130);"
                        x1="286.454"
                        y1="1472.191"
                        x2="336.454"
                        y2="1472.191"
                        transform="matrix(0.945519, 0.325568, -0.325568,
                        0.945519, 496.266468, -21.192506)"
                      />
                    </g>
                  </g>
                  <circle
                    style="paint-order: fill; fill: rgb(236, 58, 130);"
                    cx="123.9"
                    cy="19.583"
                    r="20"
                  />
                  <circle
                    style="paint-order: fill; fill: rgb(236, 58, 130);"
                    cx="416.762"
                    cy="510.843"
                    r="20"
                    transform="matrix(0, -1, 1, 0, -90.61142, 925.196289)"
                  />
                  <circle
                    style="paint-order: fill; fill: rgb(236, 58, 130);"
                    cx="211.009"
                    cy="959.6"
                    r="20"
                  />
                </g>
              </svg>

              <div
                class="andNowPart andNowBottom andNowFirst andNowPart_1"
                style={ww < 961 ? `max-width: ${75 * mobileSize}px; margin-top: ${14 * mobileSize}px; height: ${104 * mobileSize}px;` : ''}
              >
                <div class="andNowNumber">1</div>
                <div class="andNowStep">
                  {undJetzt.length > 0 && undJetzt[0].value}
                </div>
                <div class="andNowText">
                  <p>
                    {undJetztBeschreibung.length > 0 && undJetztBeschreibung[0].value}
                  </p>
                  <a
                    href="/kontakt/"
                    target="_self"
                    class="button index_moreButton"
                    style="margin-top: 0.5em !important; max-width: 100%;
                    white-space: normal; height: auto; padding: 0.5em 3em;"
                  >
                    Reden hilft!
                  </a>
                </div>
                <div class="andNowBullet" />
                <div class="andNowLine andNowLineRight" />
              </div>
              {registerAndNowStep('andNowPart_1') || ''}
              <div
                class="andNowPart andNowTop andNowPart_2"
                style={ww < 961 ? `max-width: ${75 * mobileSize}px; height: ${98 * mobileSize}px;` : ''}
              >
                <div
                  class="andNowNumber"
                  style={ww < 961 ? 'right: 27%; left: auto;' : ''}
                >
                  2
                </div>
                <div
                  class="andNowStep"
                  style={ww < 961 ? 'text-align: right; padding-right: 23%;' : ''}
                >
                  {undJetzt.length > 0 && undJetzt[1].value}
                </div>
                <div
                  class="andNowText"
                  style={ww >= 961 ? 'display: flex; flex-direction: column; align-items: center;' : ''}
                >
                  <a
                    href="/perfectmatch/"
                    target="_blank"
                    rel="nofollow"
                    class="button2 index_moreButton spiel"
                    style="margin-top: 0.5em !important; max-width: 100%;
                    margin-bottom: 20px; white-space: normal; height: auto;
                    padding: 0.5em 3em;"
                  >
                    <p style="font-size: 1rem; line-height: 1.2rem;">
                      Ob wir matchen?
                      <br />
                      Die Antwort steht hier
                      <br />
                      in den Sternen ☆
                    </p>
                  </a>
                  <p>
                    {undJetztBeschreibung.length > 0 && undJetztBeschreibung[1].value}
                  </p>
                </div>
                <div class="andNowLine andNowLineLeft" />
                <div class="andNowLine andNowLineRight" />
                <div class="andNowBullet" />
              </div>
              {registerAndNowStep('andNowPart_2') || ''}
              <div
                class="andNowPart andNowBottom andNowPart_3"
                style={ww < 961 ? `max-width: ${75 * mobileSize}px; height: ${104 * mobileSize}px; margin-left: ${15 * mobileSize}px;` : ''}
              >
                <div class="andNowNumber">3</div>
                <div class="andNowStep">
                  {undJetzt.length > 0 && undJetzt[2].value}
                </div>
                <div class="andNowText">
                  {undJetztBeschreibung.length > 0 && undJetztBeschreibung[2].value}
                </div>
                <div class="andNowLine andNowLineLeft" />
                <div class="andNowLine andNowLineRight" />
                <div class="andNowBullet" />
              </div>
              {registerAndNowStep('andNowPart_3') || ''}
              <div
                class="andNowPart andNowTop andNowLast andNowPart_4"
                style={ww < 961 ? `max-width: ${75 * mobileSize}px; margin-right: ${6 * mobileSize}px;` : ''}
              >
                <div
                  class="andNowNumber"
                  style={ww < 961 ? 'right: 33%; left: auto;' : ''}
                >
                  4
                </div>
                <div
                  class="andNowStep"
                  style={ww < 961 ? 'text-align: right; padding-right: 30%;' : ''}
                >
                  {undJetzt.length > 0 && undJetzt[3].value}
                </div>
                <div class="andNowText">
                  {undJetztBeschreibung.length > 0 && undJetztBeschreibung[3].value}
                </div>
                <div class="andNowLine andNowLineLeft" />
                <div class="andNowLine andNowLineRight andNowLineArrow" />
                <div class="andNowBullet" />
              </div>
              {registerAndNowStep('andNowPart_4') || ''}
              <a
                href="/kontakt/"
                target="_self"
                class="button index_moreButton"
                style="{ww < 961 ? 'margin-top: 3em !important;' : 'flex: none; margin-left: 4em;'}
                max-width: 100%; white-space: normal; height: auto; padding:
                0.5em 3em;"
              >
                Ab hier kommt nichts mehr,
                <br />
                also ruf lieber an!
              </a>
            </div>
          </div>
        </div>
      {/if}
    </section>

    <section class="section">
      <Footer />
    </section>
  </div>
</div>

<style>
  #main {
    background-color: #f9f9f9;
  }

  #main section {
    opacity: 1;
  }

  #opener {
    width: 100vw;
    background: #f9f9f9;
    z-index: 1;
  }

  @media (max-width: 959px) {
    #opener {
      margin-bottom: 200px;
    }
  }

  #slider {
    z-index: 1000000;
  }

  .wProjekt a.button:hover,
  .wProjekt a.button:focus,
  .index_moreButton:hover,
  .index_moreButton:focus,
  .index_moreButton.colored,
  .wProjekt .index_moreButton.colored {
    background-color: #e61974;
    color: #fff;
    border-color: #e61974;
  }

  .wProjekt a.button,
  .index_moreButton {
    background-color: #4a4a4a;
    color: #ffffff;
    border-color: #4a4a4a;
    border-radius: 50px;
  }

  a.button2:hover,
  a.button2:hover * {
    background-color: #4a4a4a !important;
    color: #ffffff !important;
    border-color: #4a4a4a !important;
    border-radius: 50px !important;
  }

  a.button2,
  a.button2 * {
    background-color: #e61974 !important;
    color: #fff !important;
    border-color: #e61974 !important;
    border-radius: 50px;
  }

  .columnLink {
    cursor: pointer;
  }

  .lamp-anim {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 145%;
    height: 100%;
    pointer-events: none;
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
    margin-right: -20vw;
  }

  .sbox {
    z-index: 10;
    width: auto !important;
    /* min-width: 90vw !important; */
    min-width: 1600px !important;
  }

  /* @media only screen and (min-width: 960px) and (max-width: 1400px) and (orientation: landscape) {
    .sbox {
      min-width: 120vw !important;
    }
  } */

  .tb h2,
  .tb p {
    color: #4a4a4a;
  }

  .tb p {
    font-size: 18px;
  }

  .tb h2 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 0;
  }

  #blobhg0 {
    position: absolute;
    left: 35%;
    top: 20%;
    fill: #ec3a82;
    fill: url(#MyGradient);
    transform: scale(3);
    opacity: 0.2;
  }

  @media (min-width: 961px) {
    #blobhg0 {
      opacity: 0.5;
    }

    .columnLink .image .imgblend {
      background-color: transparent;
      width: 100%;
      height: 100%;
      border-radius: 700px;
      z-index: 10;
      position: absolute;
      opacity: 0;
      transition-duration: 1s;
    }

    .columnLink .image:hover .imgblend {
      background-color: #e61974b3;
      opacity: 1;
      transition-duration: 1s;
    }
  }

  #blobhg1 {
    position: absolute;
    left: -20%;
    top: 20%;
    fill: #ec3a82;
    fill: url(#MyGradient);
    transform: scale(3);
    opacity: 0.2;
  }

  #blobhg2 {
    position: absolute;
    left: -90%;
    top: 0%;
    fill: #ec3a82;
    fill: url(#MyGradient2);
    transform: scale(3);
    opacity: 0.2;
  }

  #blobhg3 {
    position: absolute;
    left: -30%;
    bottom: -20%;
    fill: #ec3a82;
    fill: url(#MyGradient3);
    transform: scale(2.5);
    opacity: 0.2;
  }

  #blobhg4 {
    position: absolute;
    left: -30%;
    top: 0%;
    fill: #ec3a82;
    fill: url(#MyGradient4);
    transform: scale(2);
    opacity: 0.2;
  }

  #blobhg5 {
    position: absolute;
    left: -130%;
    top: 0%;
    fill: #ec3a82;
    fill: url(#MyGradient5);
    transform: scale(3);
    opacity: 0.5;
  }

  #blobhg6 {
    position: absolute;
    left: 50%;
    top: 0%;
    fill: #ec3a82;
    fill: url(#MyGradient5);
    transform: scale(6, 3);
    opacity: 0.5;
  }

  .tb {
    position: absolute;
    bottom: 40%;
    left: 0%;
    width: 370px;
    text-align: left;
    height: auto;
    transition-timing-function: ease;
    transition: 1s;
  }

  .morph {
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -1;
    display: block;
    background-color: #ffffff;
    animation: homeblobmorph 8s ease-in-out infinite both alternate;
    border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    background-size: cover;
  }

  @media (max-width: 960px) {
    .morph {
      animation: none;
      display: none;
    }
  }

  @keyframes homeblobmorph {
    0% {
      border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    }
    50% {
      border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
    }
    100% {
      border-radius: 28% 72% 49% 51% / 25% 51% 49% 75%;
    }
  }

  .index_introTextContainer {
    position: absolute;
    right: 5vw;
    top: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 36px;

    transition-timing-function: ease;
    transition: 1s;
  }

  .index_introTextContainerInner {
    display: flex;
    flex-direction: column;
    /*align-items: flex-end;*/
  }

  .index_introText {
    width: 18em;
    font-size: 0.75em;
    text-align: left;
  }

  @media (min-width: 961px) {
    #index_mainImage :global(.Wave_svg) {
      display: none;
    }

    .index_introTextContainerInner {
      align-items: flex-end;
    }
  }

  @media (max-width: 960px) {
    body
      #page
      div.contentmain.scrollcontentbox
      section.section.mainslider#index_mainImage {
      margin: 0 !important;
      min-height: 110vh;
    }

    #main section,
    .contentmain > section {
      z-index: 0;
    }
  }

  @media (min-width: 961px) and (max-width: 1023px) {
    .index_introTextContainerInner {
      background: rgba(249, 249, 249, 0.5);
      padding: 2vw;
    }
  }

  @media (max-width: 960px) {
    .index_introTextContainerInner {
      width: 270px;
      font-weight: bold;

      padding: 0 0 2em 0;
      color: #fff;
      border-radius: 50px;
      z-index: 1;
    }

    .index_introTextContainer :global(svg.blob2) {
      left: -60px;
      right: 0;
      margin: auto;
    }

    .index_introTextContainer {
      max-width: 100%;
      width: auto;
      top: 30vh;
      right: 0;
      left: 0;
      bottom: auto;
      margin: auto;
      font-size: 24px;

      align-items: center;
    }

    .index_introText {
      width: 100%;
      /* color: rgb(55, 53, 53);
       */
      color: #fff;
    }

    .tb {
      text-align: center;
    }
  }

  .hoblob2,
  .hoblob3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;
    z-index: 0;

    display: block;
    border-radius: 26% 74% 56% 44% / 63% 45% 55% 37%;
  }

  .hoblob2 {
    opacity: 0.2;
    transform: scale(1.1) rotate(20deg);
    background: #156f7b;
  }

  .hoblob3 {
    transform: rotate(-15deg) scaleX(-1) scaleY(-1);
    background: #ec3a82;
  }

  @media (min-width: 961px) {
    .service_columns {
      height: 100%;
      flex-wrap: wrap;
      width: 40em;
      align-items: center;
      align-content: center;
    }

    #w1Projekt {
      min-width: 1600px !important;
    }
    #w3Projekt {
      min-width: 1800px !important;
    }
  }

  .service_bubble {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    color: #fff;
    font-size: 16px;

    display: flex;
    align-items: center;
    padding: 2em 3em;
    flex: none;

    width: 20em;
    height: 17em;

    position: relative;
  }

  @media (min-width: 961px) {
    .service_bubble_0 {
      bottom: -8vh;
    }

    .service_bubble_1 {
      top: -1vh;
      right: -3em;
    }

    .service_bubble_2 {
      bottom: -13vh;
    }

    .service_bubble_3 {
      top: 6vh;
      right: -3em;
    }

    #w3Projekt .wProjekt {
      left: 20%;
      height: 100%;
    }
  }

  @media (max-width: 960px) {
    .service_bubble {
      font-size: 16px;
      margin-top: 3em;
      opacity: 0;
      transition: opacity 1s ease;
    }

    .service_bubble_0,
    .service_bubble_2 {
      left: -20vw;
    }

    .service_bubble_1,
    .service_bubble_3 {
      right: -20vw;
    }
  }

  @media (max-width: 420px) {
    .service_bubble_0,
    .service_bubble_2 {
      padding-left: calc(3em + 5vw);
    }

    .service_bubble_1,
    .service_bubble_3 {
      padding-right: calc(3em + 5vw);
    }
  }

  .service_bubble_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service_bubble_content h3 {
    color: inherit;
    font-size: 1.2em;
    font-weight: 600;
  }

  @media (min-width: 961px) {
    .index_about_us_text {
      bottom: auto !important;
      top: 5% !important;
      width: 50%;
    }

    .index_about_us_text :global(p) {
      margin-top: 1em;
    }
  }

  .index_about_us_text :global(.index_about_us_video) {
    width: 30vw !important;
    height: 16vw !important;
  }

  .index_about_us_button {
    display: inline-flex;
    flex-direction: column;
  }

  @media (min-width: 961px) {
    .index_about_us_text {
      min-width: 41em;
    }

    .index_about_us_text :global(.index_about_us_video) {
      margin-top: 2em;
    }

    .index_about_us_button {
      margin-top: 2em;
      float: right;
    }
  }

  @media (max-width: 960px) {
    .index_about_us_text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .index_about_us_title {
      order: 1;
    }

    .index_about_us_intro {
      order: 2;
    }

    .index_about_us_description {
      order: 4;
    }

    .index_about_us_text :global(.index_about_us_video) {
      order: 3;
      width: 70vw !important;
      height: 37vw !important;
      margin: 5vw 0 7vw 0;
    }

    .index_about_us_button {
      margin-top: 2em;
      order: 5;
    }
  }

  #w2Projekt .wProjekt {
    left: -10%;
    transform-origin: center;
  }

  #w2Projekt .tb2 {
    top: 10%;
    bottom: auto;
  }

  #w5Projekt {
    /* width: 50vw !important;
    min-width: 0 !important; */
    width: 900px !important;
    min-width: 0 !important;
  }

  #w5Projekt .tb2 {
    width: 50vw;
    bottom: auto;
    top: 10%;
  }

  #w6Projekt .wProjekt {
    left: 5%;
    height: 100%;
  }

  @media (min-width: 961px) {
    #w6Projekt {
      min-width: 54em !important;
    }
  }

  .andNowWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .andNowPart {
    position: relative;
    width: 20em;
    flex: none;
  }

  .andNowText,
  .andNowStep,
  .andNowNumber {
    position: absolute;
    left: 0;
    right: 0;
  }

  .andNowPart.andNowBottom .andNowText,
  .andNowPart.andNowTop .andNowStep {
    top: 2.5em;
  }

  .andNowPart.andNowTop .andNowText,
  .andNowPart.andNowBottom .andNowStep {
    bottom: 2.5em;
  }

  .andNowPart.andNowTop .andNowNumber {
    top: 0.15em;
  }

  .andNowPart.andNowBottom .andNowNumber {
    bottom: 0.15em;
  }

  .andNowStep {
    font-weight: 600;
  }

  .andNowBullet {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #ec3a82;

    z-index: 1;
  }

  .andNowLine {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 7px;
    background: #ec3a82;
    width: 50%;

    box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, 0.5);
  }

  .andNowLineRight {
    right: 0;
  }

  .andNowLineLeft {
    left: 0;
  }

  .andNowLineArrow:before {
    transform: rotate(-45deg);
    top: 19px;
    z-index: 1;
  }

  .andNowLineArrow:after {
    transform: rotate(45deg);
    bottom: 19px;
  }

  .andNowLineArrow:before,
  .andNowLineArrow:after {
    position: absolute;
    right: -15px;
    content: "";
    display: block;
    height: 7px;
    width: 60px;
    background: #ec3a82;
    transform-origin: center;

    box-shadow: -1px 4px 6px 0 rgba(0, 0, 0, 0.5);
  }

  .andNowNumber {
    z-index: -1;
    font-size: 5rem;
    font-weight: 900;

    color: #156f7b;
    opacity: 0.25;
  }

  .index_advantagesListEntry {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  .index_advantagesList {
    margin-top: 1em;
  }

  .index_advantagesListEntry p {
    margin-left: 1em;
    flex: 1 1 0.00001px;
  }

  @media (min-width: 1400px) and (min-height: 1200px) {
    #w1Projekt .tb.tb1 {
      top: 20vh;
    }
  }

  @media (max-width: 960px) {
    #w5Projekt {
      width: 100% !important;
    }

    .index_advantagesListEntry {
      align-items: flex-start;
    }

    .index_advantagesListEntry p {
      text-align: left;
    }

    .andNowWrapper {
      flex-direction: column;
    }

    .andNowBullet {
      display: none;
    }

    .andNowLine {
      display: none;
    }

    .andNowLineArrow {
      display: none;
    }

    .andNowText {
      position: static;
    }

    .andNowStep {
      position: static;
    }

    .andNowPart {
      text-align: left;
      max-width: 75vw;
      opacity: 0;
      transition: opacity 1s ease;
    }

    .andNowPart.andNowTop .andNowNumber {
      top: -0.6em;
      bottom: auto;
      left: 0.5em;
    }

    .andNowPart.andNowBottom .andNowNumber {
      top: -0.6em;
      bottom: auto;
      left: 0.5em;
    }

    #main section {
      overflow: hidden;
    }
  }

  @media (min-width: 1024px) {
    #index .wProjekt .columnLink {
      min-width: 420px;
    }
  }

  @media (min-width: 961px) {
    #index .wProjekt .columnLink {
      max-width: 100%;
    }
    .index_projects {
      left: 0;
      width: 90%;
      height: 90%;
      top: calc(5% + 50px);
      position: relative;
      z-index: 10;
      transform-origin: center center;
    }

    .index_projects .columns {
      flex-direction: row;
      height: 100%;
      padding-top: 37vh;
      align-items: center;
    }

    #index .slider .sbox .tb {
      z-index: 100;
    }

    .index_projects .columns .column {
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .index_projects .columns .column p {
      margin-top: 1.5em;
      font-weight: bold;
      max-width: 80%;
      margin: 1.5em auto 0 auto;
    }

    .index_projects .columns figure {
      height: 27vh;
      width: 27vh;
      min-height: 27vh;
      min-width: 27vh;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
    }

    .index_projects .columns figure img {
      height: 27vh;
      width: 27vh;
      min-height: 27vh;
      min-width: 27vh;
      margin-bottom: 0;
      top: 0;
    }
  }
</style>

<script>
  import Prodhost from "../components/host.js";
  import { onMount } from "svelte";
  import Load from "../components/Load.svelte";
  import anime from "animejs";
  import isMobile from "../components/isMobile.js";
  import Kontaktform from "../components/Kontaktform.svelte";

  function isIe() {
    if (navigator.userAgent.indexOf("Trident") > -1) {
      return true;
    }
    return false;
  }

  let stripeReady = false;
  let kontakt = [];
  var ww = window.innerWidth;
  var wh = window.innerHeight;

  async function hashchange() {
    await fetch(Prodhost + "/colletadmin/api/singletons/get/contact")
      .then(response => response.json())
      .then(json => {
        kontakt = json;
      });

    if (ww >= 1024) {
      anime({
        targets: "#adresse",
        easing: "linear",
        opacity: [0, 1],
        duration: 2000,
        delay: 1000,
        loop: false
      });
    }
  }

  onMount(() => {
    hashchange();

    setTimeout(() => {
      if (ww >= 1024) {
        anime({
          targets: "#sliderblob .bblob",
          duration: 20000,
          easing: "linear",
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }
    }, 1000);
  });
</script>

<style>
  section.section {
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    margin: 0px;
  }

  section.section::-webkit-scrollbar {
    display: none;
  }

  section.section {
    -ms-overflow-style: none;
  }

  #adresse {
    opacity: 0;
    background: transparent;
    color: #fff;
    z-index: 100000;
    padding: 50px;
    font-size: 16px;
    width: 50vw;
  }

  #adresse p {
    position: relative;
    z-index: 100;
  }

  #adresse svg path {
    fill: #156f7b;
    opacity: 0.5;
    transform: translate(300px, 120px) scaleY(1.3) scaleX(1.5) rotate(45deg);
    position: relative;
    z-index: 0;
  }

  .markercontainer {
    width: 120px;
    height: 108px;
    position: absolute;
    right: 31%;
    top: 42%;
  }

  .marker {
    z-index: 1;
    position: absolute;
    top: 10%;
    left: 25%;
    margin-left: 0px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: #e61974;
    animation: hover 1s infinite ease-in;
  }
  .marker:after {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 25%;
    margin-left: 7.5px;
    margin-top: -7.5px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #fff;
  }
  .marker:before {
    z-index: 0;
    content: "";
    position: absolute;
    top: 80%;
    left: 11%;
    margin-left: 0px;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-top: 40px solid #e61974;
  }
  .pulse {
    position: absolute;
    top: 30%;
    left: 50%;

    display: block;
    width: 60px;
    height: 17px;
    background: skyblue;
    border-radius: 50%;
    transform: translate(-50%, 400%);
  }
  .pulse:before,
  .pulse:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: skyblue;
    border-radius: 50%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
    animation: ripple 1s infinite ease-in;
  }
  @keyframes ripple {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }
  @keyframes hover {
    0% {
      margin-top: 0px;
    }
    50% {
      margin-top: 10px;
    }
  }

  #mainbild {
    z-index: 10;
    position: absolute;
  }

  .newmap {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .section .adressebox {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 100vh;
  }

  @media (max-width: 960px) {
    .section .adressebox {
      display: block !important;
      height: auto;
    }

    .section .adressebox #adresse {
      position: static;
      width: 100vw;
      padding: 40px 20px 40px 20px !important;
      background-color: rgb(21, 111, 123, 0.8);
      opacity: 1;
    }

    section.section {
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
    }

    .markercontainer {
      transform: scale(0.7);
      display: none;
      right: 19%;
      top: 42%;
    }

    .newmap {
      opacity: 0.5;
    }
  }
</style>

<svelte:head>

  {#if isMobile() || isIe()}
    <style>
      #map {
        background-image: url(/images/kontakt.jpg);
        background-position: center;
        background-size: cover;
      }
    </style>
  {/if}
  <style>
    @media (min-width: 960px) {
      .collet-logo svg path {
        fill: #ffffff !important;
      }
      .navbar-burger {
        color: #ffffff !important;
      }

      #kontaktForm {
        width: 40vw;
        margin: 0 auto;
      }
    }

    @media (max-width: 960px) {
      body .is-fluid.contentmain.scrollcontentbox {
        background: rgb(21, 111, 123, 1) !important;
      }

      body .contentmain,
      body .slider {
        height: auto;
      }

      body #page .scrollcontentbox #adresse {
        opacity: 1 !important;
      }
    }
  </style>
</svelte:head>

<section class="section">

  {#if ww >= 1024}
    <div id="mainbild">
      <style>
        #msvg {
          clip-path: url("#sliderblob");
          -webkit-clip-path: url("#sliderblob");
          background-color: rgb(21, 111, 123, 0.8);
        }
      </style>
      <div id="msvg" />
    </div>
  {/if}

  <div class="adressebox">
    <div id="adresse" style="">
      <div>
        <p>
          COLLET Concepts Communication GmbH
          <br />
          Ziethenstraße 10
          <br />
          33330 Gütersloh
          <br />
          E:
          <a class="mailForm" href="mailto:info@collet-cc.de">info@collet-cc.de</a>
          <br />
          T:
          <a class="callForm" href="tel:052415056664">05241 50 56 664</a>
          <br />
          M:
          <a class="callForm" href="tel:017641448502">0176 41 44 85 02</a>
        </p>

        <!-- {#if kontakt.Adresse}     {@html kontakt.Adresse} {/if} -->

      </div>
      <Kontaktform />
    </div>
  </div>

  {#if !isMobile() && !isIe()}
    <div class="newmap">
      <img
        src="/images/kontakt_map.jpg"
        alt="Kontakt Map Collet"
        style="width:100%" />
      <div class="markercontainer">
        <div class="marker" />
        <div class="pulse" />
      </div>
    </div>
  {:else}
    <div
      class="newmap"
      style="width:100vw; height: 100vh; display: flex; overflow: hidden;
      background-image: url(/images/kontakt_map.jpg); background-size:cover;
      background-position: center center;" />
  {/if}

</section>

{#if ww >= 1024}
  <div class="svgclippath">
    <svg viewBox="0 0 1200 900" width="0" height="0">
      <clipPath
        id="sliderblob"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0008, 0.002)">
        <path
          style=" transform: translate(-180px, 0px);"
          class="bblob"
          d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
          143.61c-4.55,95.55,170.07,212.41,10.95,462.77
          C1005.43,799.35,1195.72,900.48,1200,900z" />
      </clipPath>
    </svg>
  </div>
{/if}

export default function isMobile() {
  if (window.innerWidth >= 1024) {
    return false;
  }

  // var Android = navigator.userAgent.match(/Android/i);
  // var BlackBerry = navigator.userAgent.match(/BlackBerry/i);
  // var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  // var Opera = navigator.userAgent.match(/Opera Mini/i);
  // var Windows = navigator.userAgent.match(/IEMobile/i);

  // if (Android || BlackBerry || iOS || Opera || Windows) {
  //   return true;
  // }
  return true;
}

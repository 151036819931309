<script>
  export let url;
  export let onc;

  export function goBack() {
    window.history.back();
  }
</script>

<a href={onc === 'true' ? 'javascript:history.back()' : url} target="_self">
  <div class="backbtn">
    <svg class="svg-icon" viewBox="0 0 100 42">
      <circle
        cx="21"
        cy="21"
        r="20"
        stroke="#4a4a4a"
        stroke-width="1"
        fill="none" />
      <path
        fill="none"
        class="arrow"
        d="M75.7,19.4H26.4l4.4-4.4c0.6-0.6,0.6-1.6,0-2.2s-1.6-0.6-2.2,0l-7,7c0,0-0.1,0-0.1,0.1
        c-0.4,0.4-0.5,0.9-0.4,1.3c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.4,0.3,0.5c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0,0.1,0.1l7,7
        c0.6,0.6,1.6,0.6,2.2,0c0.6-0.6,0.6-1.6,0-2.2l-4.4-4.4h49.3c0.9,0,1.6-0.7,1.6-1.6S76.6,19.4,75.7,19.4z" />
    </svg>
    <p>zurück</p>
  </div>
</a>

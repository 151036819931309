<script>
  import SimpleLightbox from "simple-lightbox";
  import { onMount } from "svelte";

  export let videoId = "";
  export let altText = "";

  export let shadowSize = "25px";

  let cls;
  export { cls as class };
  export let style;

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function customContentLink() {
    SimpleLightbox.open({
      content:
        '<div class="contentInPopup">' +
        '<h3 class="attireTitleType3">Mit dem Laden des Videos<br> akzeptierst Du unsere <a  href="/impressum/" target="_blank">Datenschutzerklärung.</a></h3><button  onclick="setJaVideo()">Gib mir mehr Cookies!</button>' +
        "</div>",
      elementClass: "slbContentEl"
    });
  }

  onMount(() => {
    if (getCookie("ch") === "1" || getCookie("ch") === "3") {
      setTimeout(() => {
        new SimpleLightbox({ elements: ".lightBoxVideoLink" });
      }, 1000);
    }
  });
</script>

<style>
  .lightBoxVideoLink {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 480px;
    width: 100%;
    position: relative;
    overflow: hidden;

    --shadow-size: 25px;

    border-radius: 48% 52% 75% 25%/59% 56% 44% 41%;
    box-shadow: var(--shadow-size) var(--shadow-size) 0 #f9c6dd;
    border: 1px solid #f9c6dd;
  }

  .lightBoxVideoLink > img {
    width: 110%;
    height: 110%;
    max-width: none;
    flex: none;
    border: none;
  }

  .lightBoxVideoLink:hover {
    box-shadow: var(--shadow-size) var(--shadow-size) 0 #adcdd1;
    border: 1px solid #adcdd1;
  }

  @media (max-width: 960px) {
    .lightBoxVideoLink {
      width: 100%;
      height: 350px;
    }
  }

  @media (max-width: 476px) {
    .lightBoxVideoLink {
      width: 100%;
      height: 200px;
    }

    .lightBoxVideoLink > img {
      width: 130%;
      height: 130%;
    }
  }
</style>

<svelte:head>
  <script>
    function setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    function setJaVideo() {
      setCookie("ch", "1", 99999);
      // document.getElementById("cookieHinweis").style.opacity = 0;
      // setTimeout(() => {
      //   document.getElementById("cookieHinweis").style.display = "none";
      //   window.location.href = "/";
      // }, 500);
      location.reload();
    }
  </script>
</svelte:head>
{#if videoId}
  {#if getCookie('ch') === '1' || getCookie('ch') === '3'}
    <a
      class={'lightBoxVideoLink' + (cls ? ' ' + cls : '')}
      style={'position: relative; --shadow-size: ' + shadowSize + '; ' + (style ? style : '')}
      frameborder="0"
      allowfullscreen
      allow="autoplay"
      href="https://www.youtube.com/embed/{videoId}?rel=0&mute=0&showinfo=0&controls=0&autoplay=1">
      <img
        alt={altText}
        src="https://img.youtube.com/vi/{videoId}/sddefault.jpg"
        style="object-fit: cover; object-position: center;" />
      <svg
        viewBox="0 0 48 48"
        width="48px"
        height="48px"
        style=" position: absolute; top: 0; left: 0; right: 0; bottom: 0;
        margin: auto;">
        <path
          fill="#FF3D00"
          d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z" />
        <path fill="#FFF" d="M20 31L20 17 32 24z" />
      </svg>
    </a>
  {:else}
    <div
      style={'position: relative; --shadow-size: ' + shadowSize + '; ' + (style ? style : '')}
      on:click={customContentLink}
      class={'slbContentEl lightBoxVideoLink' + (cls ? ' ' + cls : '')}>
      <img
        alt={altText}
        src="https://img.youtube.com/vi/{videoId}/sddefault.jpg"
        style="object-fit: cover; object-position: center;" />
      <svg
        viewBox="0 0 48 48"
        width="48px"
        height="48px"
        style=" position: absolute; top: 0; left: 0; right: 0; bottom: 0;
        margin: auto;">
        <path
          fill="#FF3D00"
          d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z" />
        <path fill="#FFF" d="M20 31L20 17 32 24z" />
      </svg>
    </div>
  {/if}
{/if}

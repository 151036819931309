<script>
  import LavaImage from "../components/LavaImage.svelte";
  import Prodhost from "../components/host.js";
  import { getWheelDeltaX, getWheelDeltaY } from "../utils";
  import { onMount } from "svelte";
  import SimpleLightbox from "simple-lightbox";

  import Mwelle from "../components/svg/mwelle.svelte";

  let gtInfo;

  let ww = window.innerWidth;
  window.addEventListener("resize", () => {
    ww = window.innerWidth;
    setScrollActive();
  });

  let scrollActive = false;

  function setScrollActive() {
    scrollActive = ww >= 960;
  }

  window.addEventListener("wheel", function(e) {
    if (!scrollActive) {
      return;
    }

    let x = getWheelDeltaX(e, true);
    if (x !== 0) {
      return;
    }

    var scroller = document.querySelector(".scrollcontentbox");
    if (scroller) {
      scroller.scrollLeft += getWheelDeltaY(e, true);
    }
  });

  let umfrage;
  let zahl;

  let code = "";

  function getcode() {
    if (code === "0815") {
      SimpleLightbox.open({
        content:
          '<div class="contentInPopup"><iframe class="slbIframe" autoplay="true"  allow="autoplay" frameborder="0" allowfullscreen="" src="https://www.youtube.com/embed/wq8UevtC1hw?rel=0&amp;mute=0&amp;showinfo=0&amp;controls=0&amp;autoplay=1"></iframe></div>',
        elementClass: "slbContentEl"
      });
    } else {
      SimpleLightbox.open({
        content:
          '<div class="contentInPopup"><h3>Autorisierung fehlgeschlagen, Zugriff verweigert.</h3></div>',
        elementClass: "slbContentEl"
      });
    }
  }

  onMount(async () => {
    setScrollActive();

    fetch(Prodhost + "/colletadmin/api/singletons/get/GTInfo")
      .then(response => response.json())
      .then(json => {
        gtInfo = json;
        window.requestAnimationFrame(() => {
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        });
      });
  });
</script>

<style>
  .gtInfo_introTextContainer {
    position: absolute;
    right: 5vw;
    top: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 36px;

    transition-timing-function: ease;
    transition: 1s;
  }

  .gtInfo_introTextContainerInner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .gtInfo_introText {
    width: 18em;
    font-size: 0.75em;
    text-align: left;

    font-weight: 600;
  }

  @media (min-width: 961px) {
    .gtInfo_introText {
      width: 18em;
      font-size: 0.75em;
      text-align: left;

      position: relative;
      left: -4em;
      top: -1.4em;
      color: #e61974;

      line-height: 1em;
    }
  }

  @media (max-width: 960px) {
    .gtInfo_introText {
      width: 18em;
      font-size: 0.75em;
      text-align: center;

      color: #fff;
    }
  }

  @media (min-width: 961px) {
    #gtInfo_mainImage :global(.Wave_svg) {
      display: none;
    }
  }

  @media (min-width: 961px) and (max-width: 1023px) {
    .gtInfo_introTextContent {
      padding: 2vw;
      background: rgba(249, 249, 249, 0.5);
      border-radius: 30px;
    }
  }

  @media (max-width: 960px) {
    .gtInfo_introTextContent {
      /* padding: 1em 1.5em 2em 2.5em; */
      padding: 0;

      z-index: 1;
    }
    .gtInfo_introTextContainerInner {
      color: #fff;
      border-radius: 50px;
      width: 100%;
    }

    .gtInfo_introTextContainer {
      max-width: 100%;
      width: 14em;
      right: 0;
      left: 0;
      /* top: 30vh;
      bottom: auto; */
      top: auto;
      bottom: 10vh;
      margin: auto;
      font-size: 24px;
      z-index: 100;
    }

    .gtInfo_introText {
      width: 100%;
      text-align: center;
    }
  }

  .hoblob2,
  .hoblob3 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    margin: auto;
    z-index: 0;

    display: block;
    border-radius: 26% 74% 56% 44% / 63% 45% 55% 37%;
  }

  .hoblob2 {
    opacity: 0.2;
    transform: scale(1.1) rotate(20deg);
    background: #156f7b;
  }

  .hoblob3 {
    transform: rotate(-15deg) scaleX(-1) scaleY(-1);
    background: #ec3a82;
  }
  .gtInfo_introText {
    width: 18em;
    font-size: 0.75em;
    text-align: center;

    font-weight: 600;
  }

  @media (min-width: 961px) {
    .gtInfo_introText {
      width: 18em;
      font-size: 0.75em;
      text-align: left;

      position: relative;
      left: -4em;
      top: -1.4em;
      color: #e61974;

      line-height: 1em;
    }
  }

  @media (max-width: 960px) {
    .gtInfo_introText {
      width: 18em;
      font-size: 0.75em;
      text-align: center;

      color: #fff;
    }
  }

  @media (max-width: 960px) {
    #gtInfo_mainImage {
      min-height: 30em;
    }
  }

  #gtInfo_mainImage {
    margin-right: 0 !important;
  }
</style>

<svelte:head>

  {#if code === '0815'}
    <style>
      .contentInPopup {
        width: 80vw;
        height: 80vh;
      }
    </style>
  {/if}
  <style>
    .contentmain {
      background: #1f5f6d;
    }

    .contentInPopup {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 960px) {
      .contentInPopup {
        width: 90vw;
        height: 20vh;
      }

      #page,
      .contentmain {
        position: relative;
        background: #fff;
      }

      .mwelle {
        top: -90px;
      }

      .Wave_svg {
        display: none !important;
      }
    }
  </style>
</svelte:head>

{#if gtInfo}
  <section
    id="gtInfo_mainImage"
    class="section mainslider is-vertical-center"
    style="width: 100vw;">
    <div class="mwelletop">
      <Mwelle />
    </div>
    <LavaImage
      imgSrc="{Prodhost}/{gtInfo.Bild.path}"
      class="gtInfo_mainImage"
      waveColor="#fff">
      <div class="gtInfo_introTextContainer" id="mainIntroText">
        {#if ww <= 960}
          <div class="hoblob2" />
          <div class="hoblob3" />
        {/if}
        <div class="gtInfo_introTextContent">
          <div class="gtInfo_introTextContainerInner">
            <p class="gtInfo_introText">
              {gtInfo.IntroText}
              <br />
              <input type="text" bind:value={code} placeholder="Code" />
              <button on:click={getcode}>OK</button>
            </p>
          </div>
        </div>
      </div>
    </LavaImage>
    <Mwelle />
  </section>
{/if}

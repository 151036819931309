<script>
  import Prodhost from "../components/host.js";
  import { onMount } from "svelte";
  import anime from "animejs";
  import BlobSlider from "../components/BlobSlider.svelte";
  import Mwelle from "../components/svg/mwelle.svelte";
  import isMobile from "../components/isMobile.js";

  function isFirefox() {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      return true;
    }
    return false;
  }

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function isSafari() {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      return true;
    }
    return false;
  }

  var impressum = [];
  var ww = window.innerWidth;
  var wh = window.innerHeight;

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/singletons/get/teilnahmebedingungen")
      .then(response => response.json())
      .then(json => {
        impressum = json;
      });

    setTimeout(() => {
      if (ww >= 1024) {
        anime({
          targets: "#sliderblob .bblob",
          duration: 20000,
          easing: "linear",
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }
    }, 300);

    const el = document.querySelector(".contentmain");
    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }
  });
</script>

<style>
  #msvg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  .contenttext {
    column-count: 5;
    text-align: left;
    font-size: 12px;
    /* min-width: 5000px; */
    width: 2700px;

    margin-left: 450px;
  }

  /* @media (max-width: 960px) {
    .sliderTextBox {
      top: 70px;
      left: 100px;
    }
  } */

  @media (max-width: 1510px) and (min-width: 961px) {
    #blslider {
      left: 20vw;
    }
  }

  @media (max-width: 961px) {
    .contenttext {
      column-width: 100%;
      width: 100%;
    }
  }
</style>

{#if isIe()}
  <style>
    #blslider {
      left: 30vw;
    }
    .contentmain > section > .columns {
      height: 100vh;
      width: max-content;
    }

    .contenttext {
      column-width: 300px;
      height: 80%;
      width: 100%;
    }

    .contentmain > section .contenttextblock {
      width: 100%;
      display: block;
      margin-top: 20vh;
      float: left;
    }
  </style>
{/if}

{#if isFirefox()}
  <style>
    .contentmain > section > .columns {
      height: 100vh;
      width: max-content;
    }

    .contenttext {
      column-width: 300px;
    }

    #blslider {
      left: 30vw;
    }
  </style>
{/if}

<section class="section mainslider ins">
  <div class="mwelletop">
    <Mwelle />
  </div>
  <div>
    {#if impressum.bild}
      {#if isIe()}
        <svg id="mainbild">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="{Prodhost}/colletadmin/{impressum.bild.styles[0].path}"
            clip-path="url(#sliderblob)" />
        </svg>
      {:else}
        <div id="mainbild">
          {#if ww >= 1024}
            <style>
              #msvg {
                clip-path: url("#sliderblob");
                -webkit-clip-path: url("#sliderblob");
              }
            </style>
          {/if}
          <div
            id="msvg"
            style="background-image: url({Prodhost}/colletadmin/{impressum.bild.styles[0].path});" />
        </div>
      {/if}
    {/if}
  </div>
  <div id="blslider">
    <BlobSlider />
    <div class="sliderTextBox is-vertical-center">
      <h1>
        {@html impressum.title}
      </h1>
    </div>
  </div>

  <Mwelle />
</section>

<section class="section">

  <div class="columns is-vcentered contenttextblock">
    <div
      class="column contenttext"
      style={ww > 960 ? '    column-count: 7!important;' : ''}>
      {@html impressum.text}
    </div>
  </div>
</section>

<div class="svgclippath">
  <svg viewBox="0 0 1200 900" width="0" height="0">
    <clipPath
      id="sliderblob"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0008, 0.002)">
      <path
        style=" transform: translate(-180px, 0px);"
        transform="translate(-180, 0)"
        class="bblob"
        d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
        143.61c-4.55,95.55,170.07,212.41,10.95,462.77
        C1005.43,799.35,1195.72,900.48,1200,900z" />
    </clipPath>
  </svg>
</div>

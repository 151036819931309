<script>
  import Prodhost from "../../components/host.js";
  import { onMount } from "svelte";
  import { url } from "@sveltech/routify";
  import ScrollMagic from "scrollmagic";
  import anime from "animejs";

  import ProjekteSlider from "../../components/ProjektSlider.svelte";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import ColletButton from "../../components/Button.svelte";
  import Kunden from "../../components/Kunden.svelte";
  import Canim from "../../components/svg/canim.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";
  import Mwelle2 from "../../components/svg/mwelle2.svelte";

  import isMobile from "../../components/isMobile.js";

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function ifSafari() {
    var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (iOS) {
      return true;
    }
  }

  var projekte = [];
  var projekteseite = [];
  let cx = 0;
  let cp = 0;

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  function countup(el, target) {
    let data = { count: 0 };
    anime({
      targets: data,
      count: [0, target],
      duration: 1000,
      round: 1,
      delay: 200,
      easing: "easeOutCubic",
      update() {
        el.innerText = data.count.toLocaleString();
      }
    });
  }

  onMount(() => {
    if (ww > 960 && !isMobile()) {
      var controller = new ScrollMagic.Controller({ vertical: false });
    } else {
      var controller = new ScrollMagic.Controller({ vertical: true });
    }
    const el = document.querySelector(".contentmain");

    if (ww > 960 && !isMobile()) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }

    fetch(Prodhost + "/colletadmin/api/singletons/get/projekte")
      .then(response => response.json())
      .then(json => {
        projekteseite = json;
      });

    fetch(Prodhost + "/colletadmin/api/collections/get/projekte", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        projekte = json.entries;

        projekte.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });

        var scene = new ScrollMagic.Scene({
          triggerElement: "#faktcount",
          duration: 500
        })
          .on("enter", function() {
            if (cx == 0) {
              countup(document.querySelector(".fakt1"), projekteseite.Fact1);
              countup(document.querySelector(".fakt2"), projekteseite.Fact2);
              countup(document.querySelector(".fakt3"), projekteseite.Fact3);
              countup(document.querySelector(".fakt4"), projekteseite.Fact4);
              cx = 1;
            }
          })
          .addTo(controller);

        var scene2 = new ScrollMagic.Scene({
          triggerElement: "#moreProjekt",
          duration: 1000,
          offset: -100
        })
          .on("enter", function() {
            if (cp == 0) {
              anime({
                targets: "#moreProjekt",
                scale: [0, 1.2],
                opacity: [0, 1],
                duration: 1000
              });

              anime({
                targets: "#moreProjekt h3",
                scale: [0, 1],
                duration: 1200
              });
              cp = 1;
            }
          })

          .addTo(controller);
      });

    if (ww > 1440) {
      setTimeout(() => {
        anime({
          targets: "#sliderblob .bblob",
          duration: 40000,
          easing: "linear",
          d: [
            {
              value:
                "M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
            },
            {
              value:
                "M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
            },

            {
              value:
                "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }, 1000);
    } else {
      if (!ifSafari()) {
        anime({
          targets: "#sliderblob .bblob",
          easing: "linear",
          duration: 20000,
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true
        });
      }
    }
  });
</script>

<style>
  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  #moreProjekt {
    opacity: 0;
    transform-origin: center;
  }

  .moreProjekt {
    position: absolute;
    top: 250px;
    left: 0;
    /* cursor: pointer; */
    transform: scale(1.5);
    z-index: 1000;
  }

  .moreProjekt h3 {
    color: #e61974;
    position: relative;
    z-index: 100;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    width: 250px;
    left: -30px;
    top: -10px;
    filter: drop-shadow(2px 2px 2px #999);
  }

  .moreProjekt svg {
    width: 300px;
    height: 250px;
    position: relative;
    top: -160px;
    font-weight: 400;
  }
  .moreProjekt svg path {
    fill: #156f7b;
    opacity: 0.25;
  }

  .moreProjekt svg path.pblob {
    transform: scaleY(0.9) scaleX(1.1) translateY(170px) translateX(240px);
    transform-origin: center;
  }

  .moreProjekt svg path.arrow {
    transform: rotateY(0deg) translateX(-20px) translateY(-1px) scale(0.8);
    opacity: 1;
    transition-duration: 0.5s;
    transform-origin: center;
  }

  .moreProjekt svg:hover path.arrow {
    transform: rotateY(0deg) translateX(0px) translateY(-1px) scale(0.8);
    opacity: 1;
    transition-duration: 0.5s;
  }

  #pSlider {
    margin-left: 200px;
    margin-right: -200px;
  }

  @media screen and (max-width: 1024px) {
    #msvg {
      object-fit: cover;
      height: calc(100vh - 2px);
      max-height: calc(100vh - 2px);
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
    }

    #mainbild,
    .mainslider {
      overflow: hidden;
    }
  }

  
  @media screen and (max-width: 920px) {
 
    .projekteBox .columns{
      flex-direction: column;
    }

   
  }

 
</style>

<svelte:head>
  <style>
    #projektS {
      width: 100vw;
    }
  </style>
</svelte:head>

<section class="section mainslider">
  <div id="blslider">
    {#if projekteseite.Title}
      <div class="sliderTextBox">
        <div>
          <h1>
            {@html projekteseite.Title}
          </h1>
          <p>
            {@html projekteseite.Text}
          </p>
        </div>
      </div>
      <BlobSlider />
    {/if}
  </div>
  <div class="mwelletop">
    <Mwelle />
  </div>
  <div>

    {#if isIe()}
      {#if projekteseite.Bild}
        <svg id="mainbild">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="{Prodhost}/colletadmin/{projekteseite.Bild.styles[0].path}"
            clip-path="url(#sliderblob)" />
        </svg>
      {/if}
    {:else}
      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
            }
          </style>
        {/if}
        <!-- <div
            id="msvg"
            style="background-image: url({Prodhost}/colletadmin/{projekteseite.Bild.styles[0].path});" /> -->

        <div id="msvg" />
      </div>
    {/if}

  </div>
  <Mwelle />
</section>

{#each projekte as projekt, i}
  {#if i <= 3}
    <section class="section projekteBox">
      <div class="prjanim columns {i % 2 ? 'projektBoxD' : 'projektBoxU'} ">
        <div class="column projektImage">
          <a
            class="linkToProjekt"
            href={$url('../' + projekt.slug + '/')}
            target="_self"
            title="to {projekt.Title}">
            <figure class="image is-square imageBtn is-rounded">
              <div class="imgblend is-rounded" />
              {#if projekt.Image.styles}
                <img
                  alt={projekt.Image.description !== '' ? projekt.Image.description : projekt.Title}
                  class="is-rounded"
                  src="{Prodhost}/colletadmin/{projekt.Image.styles[0].path}" />
              {:else}
                <img
                  alt={projekt.Image.description !== '' ? projekt.Image.description : projekt.Title}
                  class="is-rounded"
                  src="{Prodhost}/colletadmin/storage/uploads/{projekt.Image.path}" />
              {/if}
            </figure>
          </a>
        </div>
        <div class="column ">
          <div>
            <h2 class="title">
              {@html projekt.Title}
            </h2>
            <p class="subtitle">
              {projekt.Text.substring(0, 270)
                .split(' ')
                .reverse()
                .slice(1)
                .reverse()
                .join(' ') + ' ...'}
            </p>
            <div class="cbtn">
              <ColletButton
                href={$url('../' + projekt.slug + '/')}
                link="mehr lesen"
                title={projekt.Title} />
            </div>
          </div>
        </div>
      </div>

    </section>
  {/if}
{/each}

<section class="section" id="pSlider">
  <div class="moreProjekt" id="moreProjekt">
    <h3>Mehr Projekte</h3>
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 600 304"
      style="enable-background:new 0 0 700 400;"
      xml:space="preserve">
      <path
        class="pblob"
        d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z" />
      <path
        class="arrow"
        d="M108.6,236.8h442.7l-20.8,20.8c-2.8,2.8-2.8,7.6,0,10.4s7.6,2.8,10.4,0l33-33c0,0,0.5,0,0.5-0.5
        c1.9-1.9,2.4-4.2,1.9-6.1c0-0.5,0-0.5,0-0.9c-0.5-0.9-0.9-1.9-1.4-2.4c0-0.5,0-0.5-0.5-0.9c0,0-0.5,0-0.5-0.5l-33-33
        c-2.8-2.8-7.6-2.8-10.4,0c-2.8,2.8-2.8,7.6,0,10.4l20.8,20.8H108.6c-4.2,0-7.6,3.3-7.6,7.6S104.3,236.8,108.6,236.8z" />
    </svg>
  </div>
  <ProjekteSlider />
</section>

<section class="section infoDaten" id="faktcount">
  <div class="columns has-text-centered is-vcentered">
    <div class="column">
      <div class="columns ">
        <div class="column is-half">
          <h2 class="fakt1">0</h2>
          <h3>{projekteseite.Fact1Text}</h3>
        </div>
        <div class="column is-half">
          <h2 class="fakt2">0</h2>
          <h3>{projekteseite.Fact2Text}</h3>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <h2 class="fakt3">0</h2>
          <h3>{projekteseite.Fact3Text}</h3>
        </div>
        <div class="column is-half">
          <h2 class="fakt4">0</h2>
          <h3>{projekteseite.Fact4Text}</h3>
        </div>
      </div>
    </div>
  </div>
</section>
<Kunden
  kundenTitle={projekteseite.kundenTitle}
  kundenText={projekteseite.kundenText} />

{#if ww > 1440}
  <div class="svgclippath">
    <svg viewBox="0 0 398.6 435.4" width="0" height="0">
      <defs>
        <clipPath
          id="sliderblob"
          clipPathUnits="objectBoundingBox"
          transform="scale(0.0027, 0.0037)">
          <path
            style=" transform: translate(200px, 125px);"
            transform=" translate(200, 125)"
            class="bblob"
            d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z" />
        </clipPath>
      </defs>
    </svg>
  </div>
{:else}
  <div class="svgclippath">
    <svg viewBox="0 0 1200 900" width="0" height="0">
      <clipPath
        id="sliderblob"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0008, 0.002)">
        <path
          transform="translate(-180, 0)"
          class="bblob"
          d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
          143.61c-4.55,95.55,170.07,212.41,10.95,462.77
          C1005.43,799.35,1195.72,900.48,1200,900z" />
      </clipPath>
    </svg>
  </div>
{/if}

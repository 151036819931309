<script>
  import Prodhost from "./host.js";
  import { onMount } from "svelte";
  let footerimg;
  let region = [];
  var ww = window.innerWidth;
  let rbtoggle = false;

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/singletons/get/seite")
      .then(response => response.json())
      .then(response => {
        footerimg = response.footerimage.styles[0].path;
      });

    fetch(Prodhost + "/colletadmin/api/collections/get/region", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(response => {
        region = response.entries;
      });
  });

  let currentTime = new Date();
  let year = currentTime.getFullYear();
</script>

<footer class="footer">
  <div class="container is-fluid">
    <div class="columns">
      <div class="column has-text-right" style=" z-index: 10;">
        <a href="/" target="_self">
          <img
            class="footerLogo"
            src="/images/collet-logo.svg"
            alt="Collet Logo Weiss"
          />
        </a>
        <p>COLLET Concepts I Communication</p>
        <p>Ziethenstraße 10</p>
        <p>33330 Gütersloh</p>
        <p>
          <a href="mailto:info@collet-cc.de" class="footerlink mailForm">
            E: info@collet-cc.de
          </a>
        </p>
        <p>
          <a href="tel:052415056664" class="footerlink callForm">
            T: 05241 50 56 664
          </a>
        </p>
        <p>
          <a href="tel:017641448502" class="footerlink callForm">
            M: 0176 41 44 85 02
          </a>
        </p>
        <p>
          <a href="https://wa.me/4917641448502" class="footerlink callForm">
            WhatsApp: 0176 41 44 85 02
          </a>
        </p>
        {#if region}
          <div id="footerregion">
            <p
              class="regionbtn"
              on:click={() => {
                rbtoggle = !rbtoggle;
              }}
            >
              Regionen
            </p>
            <div id="regbox" class={rbtoggle === true ? 'frbactive' : ''}>
              {#each region as r}
                <a
                  target="_blank"
                  href="https://www.collet-cc.de/werbeagentur-{r.Url}/"
                  title="Collet Werbeagentur für {r.region}"
                >
                  Werbeagentur {r.region}
                </a>
                {#if ww > 1024}|{/if}
              {/each}
              <span
                on:click={() => {
                  rbtoggle = !rbtoggle;
                }}
              >
                x
              </span>
            </div>
          </div>
        {/if}
        <div class="footerLinksLeft">
          <a href="/impressum/" target="_self" style=" margin-top: 20px;">
            Impressum & Datenschutz
          </a>
        </div>

        <div class="socialLinks">
          <a
            href="https://www.xing.com/companies/colletconcepts%7Ccommunication"
            target="_blank"
            rel="noopener"
            title="Collet Xing"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <title>Collet Xing</title>
              <path
                d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8
                12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1
                0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18
                12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2
                14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8
                51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3
                6.7 8.5 14.1z"
                fill="#4a4a4a"
              />
            </svg>
          </a>


          <a
            href="https://www.instagram.com/colletconcepts/"
            target="_blank"
            rel="noopener"
            title="Collet Instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 17"
              class="w-6 h-6 mr-2 "
            >
              <title>Collet Instagram</title>
              <path
                d="M13.6683 4.78509C13.6683 4.90669 13.6443 5.02711 13.5978
                5.13945C13.5513 5.2518 13.483 5.35388 13.3971 5.43987C13.3111
                5.52586 13.209 5.59406 13.0966 5.6406C12.9843 5.68714 12.8639
                5.71109 12.7423 5.71109C12.6207 5.71109 12.5003 5.68714 12.3879
                5.6406C12.2756 5.59406 12.1735 5.52586 12.0875 5.43987C12.0015
                5.35388 11.9333 5.2518 11.8868 5.13945C11.8402 5.02711 11.8163
                4.90669 11.8163 4.78509C11.8163 4.5395 11.9138 4.30397 12.0875
                4.13031C12.2612 3.95665 12.4967 3.85909 12.7423 3.85909C12.9879
                3.85909 13.2234 3.95665 13.3971 4.13031C13.5707 4.30397 13.6683
                4.5395 13.6683 4.78509ZM16.6083 8.96509V8.97709L16.5553
                12.3331C16.5418 13.5434 16.055 14.7004 15.1992 15.5563C14.3435
                16.4123 13.1866 16.8993 11.9763 16.9131L8.60828
                16.9651H8.59628L5.24028 16.9121C4.02995 16.8986 2.87299 16.4118
                2.01702 15.5561C1.16104 14.7003 0.674051 13.5434 0.660276
                12.3331L0.608276 8.96509V8.95309L0.661276 5.59709C0.674786
                4.38676 1.16152 3.2298 2.01731 2.37383C2.8731 1.51785 4.02995
                1.03086 5.24028 1.01709L8.60828 0.965088H8.62028L11.9763
                1.01809C13.1866 1.0316 14.3436 1.51833 15.1995 2.37412C16.0555
                3.22991 16.5425 4.38676 16.5563 5.59709L16.6083 8.96509ZM15.1183
                8.96509L15.0663 5.62009C15.0569 4.79737 14.7259 4.01099 14.1442
                3.4292C13.5624 2.84742 12.776 2.51644 11.9533 2.50709L8.60828
                2.45509L5.26328 2.50709C4.44056 2.51644 3.65418 2.84742 3.07239
                3.4292C2.49061 4.01099 2.15963 4.79737 2.15028 5.62009L2.09828
                8.96509L2.15028 12.3101C2.15963 13.1328 2.49061 13.9192 3.07239
                14.501C3.65418 15.0828 4.44056 15.4137 5.26328 15.4231L8.60828
                15.4751L11.9533 15.4231C12.776 15.4137 13.5624 15.0828 14.1442
                14.501C14.7259 13.9192 15.0569 13.1328 15.0663 12.3101L15.1183
                8.96509ZM12.7163 8.96509C12.7163 10.0546 12.2835 11.0995 11.5131
                11.8699C10.7427 12.6403 9.69779 13.0731 8.60828 13.0731C7.51877
                13.0731 6.47388 12.6403 5.70348 11.8699C4.93308 11.0995 4.50028
                10.0546 4.50028 8.96509C4.50028 7.87558 4.93308 6.83069 5.70348
                6.06029C6.47388 5.28989 7.51877 4.85709 8.60828 4.85709C9.69779
                4.85709 10.7427 5.28989 11.5131 6.06029C12.2835 6.83069 12.7163
                7.87558 12.7163 8.96509ZM11.2263 8.96509C11.2263 8.27075 10.9505
                7.60485 10.4595 7.11388C9.96851 6.62291 9.30261 6.34709 8.60828
                6.34709C7.91394 6.34709 7.24804 6.62291 6.75707 7.11388C6.2661
                7.60485 5.99028 8.27075 5.99028 8.96509C5.99028 9.65942 6.2661
                10.3253 6.75707 10.8163C7.24804 11.3073 7.91394 11.5831 8.60828
                11.5831C9.30261 11.5831 9.96851 11.3073 10.4595 10.8163C10.9505
                10.3253 11.2263 9.65942 11.2263 8.96509Z"
                fill="#4a4a4a"
              />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/collet-concepts-communication
            "
            target="_blank"
            rel="noopener"
            title="Collet LinkedIn"
          >

            <svg
              width="100%"
              height="100%"
              viewBox="0 0 658 663"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xml:space="preserve"
              xmlns:serif="http://www.serif.com/"
              style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
            >
              <title>Collet LinkedIn</title>
              <rect
                id="ArtBoard1"
                x="0"
                y="0"
                width="657.677"
                height="662.067"
                style="fill:none;"
              />
              <clipPath id="_clip1LinkedIn">
                <rect x="0" y="0" width="657.677" height="662.067" />
              </clipPath>
              <g clip-path="url(#_clip1LinkedIn)">
                <path
                  d="M0.019,47.643c-0,-26.177 21.775,-47.422
                  48.62,-47.422l560.592,-0c26.854,-0 48.62,21.245
                  48.62,47.422l0,567.164c0,26.186 -21.766,47.414
                  -48.62,47.414l-560.592,-0c-26.845,-0 -48.62,-21.228
                  -48.62,-47.405l-0,-567.182l-0,0.009Z"
                  fill="#4a4a4a"
                />
                <path
                  d="M199.431,554.379l0,-298.928l-99.359,0l0,298.928l99.368,0l-0.009,0Zm-49.662,-339.734c34.642,-0
                  56.209,-22.956 56.209,-51.642c-0.652,-29.337 -21.567,-51.65
                  -55.549,-51.65c-34.008,-0 -56.217,22.313
                  -56.217,51.65c-0,28.686 21.558,51.642
                  54.906,51.642l0.651,-0Zm104.655,339.734l99.351,0l-0,-166.915c-0,-8.925
                  0.651,-17.868 3.273,-24.241c7.18,-17.859 23.529,-36.343
                  50.982,-36.343c35.944,-0 50.33,27.409
                  50.33,67.599l0,159.9l99.351,0l-0,-171.395c-0,-91.814
                  -49.011,-134.539 -114.38,-134.539c-53.595,-0 -77.132,29.953
                  -90.208,50.356l0.66,0l0,-43.341l-99.35,-0c1.294,28.043
                  -0.009,298.928 -0.009,298.928l0,-0.009Z"
                  style="fill:#fff;fill-rule:nonzero;"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>

  {#if footerimg}
    <div
      class="fblob"
      style=" background-image:url({Prodhost}/colletadmin/{footerimg})"
    />
  {/if}


</footer>

<style>
  footer.footer {
    background-color: transparent;
    color: #383737;
    padding: 0;
    margin-right: -40px;
    margin-left: 220px;
    position: relative;
    overflow: hidden;
    height: 100vh;
  }

  footer .footerLogo {
    max-width: 170px;
    margin-top: 20vh;
    margin-bottom: 30px;
    width: 170px;
    height: 48px;
  }

  footer .footerLinksLeft {
    margin-top: 50px;
  }

  footer .footerLinksLeft a,
  .footerlink {
    color: #383737;
  }

  @media screen and (max-width: 1024px) {
    .footerlink {
      display: block;
      width: 100%;
      height: 48px;
    }
  }

  footer .socialLinks {
    margin-top: 50px;
    float: right;
    width: 100%;
  }

  footer .socialLinks a {
    height: auto;
    width: 100%;
    max-width: 20px;
    margin-left: 10px;
    float: right;
  }

  .fblob {
    width: 350px;
    height: 350px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -1;
    display: block;
    animation: footerblobmorph 8s ease-in-out infinite both alternate;
    border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    background-size: cover;
  }

  #footerregion #regbox.frbactive {
    bottom: 0px;
  }

  #footerregion #regbox.frbactive span {
    color: #fff;
    position: absolute;
    right: 10px;
    bottom: 12px;
    cursor: pointer;
  }

  #footerregion .regionbtn {
    background-color: transparent;
    border: 1px solid #4a4a4a;
    color: #4a4a4a;
    border-radius: 50px;
    padding: 1px 10px;
    width: 100px;
    text-align: center;
    right: 0;
    float: right;
    margin-top: 8px;
    cursor: pointer;
  }

  #footerregion .regionbtn:hover {
    background-color: #4a4a4a;
    border: 1px solid #4a4a4a;
    color: #ffffff;
  }

  #footerregion #regbox {
    position: fixed;
    text-align: center;
    bottom: -500px;
    right: 0px;
    color: #fff;
    background: #e83082;
    padding: 10px 20%;
    width: 100%;
    transition-duration: 1s;
    z-index: 100;
  }

  #footerregion #regbox a {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    cursor: pointer;
  }

  #footerregion #regbox a:hover {
    color: #383737;
  }

  @media (min-width: 1025px) and (max-height: 800px) {
    .fblob {
      bottom: -150px;
    }
  }

  @media (max-width: 1024px) {
    .fblob {
      bottom: -150px;
    }
  }

  @media (max-width: 960px) {
    .fblob {
      width: 350px;
      height: 350px;
      bottom: -50px;
      right: calc(50vw - 175px);
    }

    #footerregion #regbox {
      bottom: -1500px;
      padding: 10px 30px;
    }

    #footerregion #regbox a {
      width: 100%;
      float: left;
    }
  }

  @keyframes footerblobmorph {
    0% {
      border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    }
    50% {
      border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
    }
    100% {
      border-radius: 28% 72% 49% 51% / 25% 51% 49% 75%;
    }
  }

  @media (max-width: 960px) {
    footer.footer {
      padding: 0;
      margin: 0;
      position: relative;
      height: 100%;
      width: 100%;
    }

    footer.footer .container {
      padding: 0;
    }

    footer .footerLogo {
      max-width: 170px;
      margin-top: 100px;
      margin-bottom: 30px;
    }

    footer .socialLinks {
      margin-bottom: 50px;
      margin-top: 30px;
      float: right;
      width: 100%;
    }
  }
</style>

<script>
  import Prodhost from "../components/host.js";

  import { onMount } from "svelte";
  import { url } from "@sveltech/routify";
  import anime from "animejs";
  import BuroSlider from "../components/Buro.svelte";
  import BlobSlider from "../components/BlobSlider.svelte";
  import ColletButton from "../components/Button.svelte";
  import Canim from "../components/svg/canim.svelte";
  import Mwelle from "../components/svg/mwelle.svelte";
  import SimpleLightbox from "simple-lightbox";

  let about = [];
  let team = [];

  let uactive = 1000;

  function ifSafari() {
    var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (iOS) {
      return true;
    }
  }

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function customContentLink() {
    SimpleLightbox.open({
      content:
        '<div class="contentInPopup">' +
        '<h3 class="attireTitleType3">Mit dem Laden des Videos<br> akzeptierst Du unsere <a  href="/impressum/" target="_blank">Datenschutzerklärung.</a></h3><button  onclick="setJaVideo()">Gib mir mehr Cookies!</button>' +
        "</div>",
      elementClass: "slbContentEl"
    });
  }

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  async function hashchange() {
    await fetch(Prodhost + "/colletadmin/api/singletons/get/about")
      .then(response => response.json())
      .then(json => {
        about = json;
      });

    await fetch(Prodhost + "/colletadmin/api/collections/get/team")
      .then(response => response.json())
      .then(json => {
        team = json.entries;
      });
  }

  onMount(() => {
    hashchange();
    if (ww > 1440) {
      setTimeout(() => {
        anime({
          targets: "#sliderblob .bblob",
          duration: 40000,
          easing: "linear",
          d: [
            {
              value:
                "M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
            },
            {
              value:
                "M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
            },

            {
              value:
                "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }, 1000);
    } else {
      if (!ifSafari()) {
        anime({
          targets: "#sliderblob .bblob",
          easing: "linear",
          duration: 20000,
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true
        });
      }
    }

    if (getCookie("ch") === "1") {
      setTimeout(() => {
        new SimpleLightbox({ elements: ".lightBoxVideoLink" });
      }, 1000);
    }

    const el = document.querySelector(".contentmain");
    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      // window.addEventListener("wheel", function(e) {
      //   if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += 100;
      //   else el.scrollLeft -= 100;
      // });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;

        anime({
          targets: "#bgElement1",
          duration: 1500,
          opacity: 0.1,
          easing: "linear",
          loop: false
        });

        anime({
          targets: ".prjanim",
          duration: 2000,
          opacity: 1,
          scale: 1,
          translateX: x / -70,
          easing: "linear",
          loop: false
        });
      };
    }
  });
</script>

<svelte:head>
  <script>
    function setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    function setJaVideo() {
      setCookie("ch", "1", 99999);
      // document.getElementById("cookieHinweis").style.opacity = 0;
      // setTimeout(() => {
      //   document.getElementById("cookieHinweis").style.display = "none";
      //   window.location.href = "/ueberuns/";
      // }, 500);

      location.reload();
    }
  </script>
</svelte:head>

<section class="section mainslider is-vertical-center">

  <div class="mwelletop">
    <Mwelle />
  </div>
  <div>

    {#if isIe()}
      {#if about.Bild}
        <svg id="mainbild">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="{Prodhost}/colletadmin/{about.Bild.styles[0].path}"
            clip-path="url(#sliderblob)"
          />
        </svg>
      {/if}
    {:else}
      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
            }
          </style>
        {/if}

        <div id="msvg" />
        <!-- <div
            id="msvg"
            style="background-image: url({Prodhost}/colletadmin/{about.Bild.styles[0].path});" /> -->
      </div>
    {/if}

  </div>

  <div id="blslider">

    <BlobSlider />
    <div class="sliderTextBox">
      <div>
        <h1>
          {#if about.Title}
            {@html about.Title}
          {/if}
        </h1>
        <p>
          {#if about.Beschreibung}
            {@html about.Beschreibung}
          {/if}
        </p>
      </div>
    </div>
  </div>
  <div id="bgElement1">
    <Canim />
  </div>

  <Mwelle />

</section>
{#if about.Wir01}
  <section class="section about" id="projektInfo">

    <div class="columns is-hidden-touch ">
      <div class="column ">
        <div class="wir1">
          <span>01</span>
          <p>
            {@html about.Wir01}
          </p>
        </div>
        <div class="wir3">
          <h2>WIR</h2>
          <span>03</span>
          <p>
            {@html about.Wir03}
          </p>
        </div>
      </div>
      <div class="column">
        <div class="wir2">
          <span>02</span>
          <p>
            {@html about.Wir02}
          </p>
        </div>
      </div>
    </div>

    <div class="columns is-hidden-desktop">

      <div class="column">
        <h2>WIR</h2>
      </div>

      <div class="column">
        <div class="wir1">
          <span>01</span>
          <p>
            {@html about.Wir01}
          </p>
        </div>
      </div>
      <div class="column">
        <div class="wir2">
          <span>02</span>
          <p>
            {@html about.Wir02}
          </p>
        </div>
      </div>
      <div class="column">
        <div class="wir3">
          <span>03</span>
          <p>
            {@html about.Wir03}
          </p>
        </div>
      </div>
    </div>

  </section>

  <section class="section" id="projektInfoMobil">
    <div class="wir1">
      <span>01</span>
      <p>
        {@html about.Wir01}
      </p>
    </div>
    <div class="wir2">
      <span>02</span>
      <p>
        {@html about.Wir02}
      </p>
    </div>
    <div class=" wir3">
      <span>03</span>
      <p>
        {@html about.Wir03}
      </p>
    </div>
  </section>
{/if}

<section class="section " id="infoAboutSection">
  <div class="infoAbout has-text-centered is-vcentered">

    <div style="display: block">

      {#if team.length > 0}
        {#if ww < 960}
          <div
            class="auboxtext {uactive == 1000 ? '' : 'uactivefalse'}
            teamblockuser teamblockusertext"
            style=""
          >
            <h2>
              1, 2 oder 3?
              <!-- {#if about.TeamTitle}{about.TeamTitle}{/if} -->
            </h2>
            <p>
              Du suchst Kontakt?
              <br />
              Bei uns hast Du drei Anlaufstellen.
              <!-- {#if about.TeamText}{about.TeamText}{/if} -->
            </p>
          </div>
        {/if}

        <div class="teamblockuserblock" style="display: flex">
          <div class="teamblockuserblock1">

            <div class="teamblockuser">
              <div class="clip-svg csvg csvg0">
                <div class="csbg csbg0" />
                <div
                  class="csimage"
                  style="background-image: url('{Prodhost}/colletadmin/{team[0].Foto.styles[1].path}')"
                />
                <div class="uactivetext">
                  <p>
                    <strong>{team[0].Name}</strong>
                  </p>
                  <p>{team[0].Position}</p>
                  {#if team[0].email !== ''}
                    
                  
                  <a href="mailto:{team[0].email}" alt={team[0].Name}>
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="csbgpath"
                        d="M16.778 0.333252H1.22244C0.927754 0.333252 0.645139
                        0.450315 0.436765 0.658689C0.228391 0.867063 0.111328
                        1.14968 0.111328 1.44436V12.5555C0.111328 12.8502
                        0.228391 13.1328 0.436765 13.3411C0.645139 13.5495
                        0.927754 13.6666 1.22244 13.6666H16.778C17.0727 13.6666
                        17.3553 13.5495 17.5637 13.3411C17.772 13.1328 17.8891
                        12.8502 17.8891 12.5555V1.44436C17.8891 1.14968 17.772
                        0.867063 17.5637 0.658689C17.3553 0.450315 17.0727
                        0.333252 16.778 0.333252ZM15.9224
                        12.5555H2.14466L6.03355 8.53325L5.23355 7.76103L1.22244
                        11.911V2.28881L8.12799 9.16103C8.33617 9.36797 8.61779
                        9.48413 8.91133 9.48413C9.20487 9.48413 9.48648 9.36797
                        9.69466 9.16103L16.778 2.11659V11.8388L12.6891
                        7.74992L11.9058 8.53325L15.9224 12.5555ZM1.95022
                        1.44436H15.878L8.91133 8.37214L1.95022 1.44436Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  {/if}
                </div>
              </div>
            </div>
          </div>

          <div class="teamblockuserblock2">

            <div class="teamblockuser">
              <div class="clip-svg csvg csvg1">
                <div class="csbg csbg1" />
                <div
                  class="csimage"
                  style="background-image: url('{Prodhost}/colletadmin/{team[1].Foto.styles[1].path}')"
                />
                <!--     <figure
                  class="image"
                  on:mouseover={() => (uactive = 1)}
                  on:mouseout={() => (uactive = 1000)}
                >

                   <img
                    src="{Prodhost}/colletadmin/{team[1].Foto.styles[1].path}"
                    alt={team[1].Foto.description !== '' ? team[1].Foto.description : team[1].Name}
                  /> 
                </figure> -->
                <div class="uactivetext">
                  <p>
                    <strong>{team[1].Name}</strong>
                  </p>
                  <p>{team[1].Position}</p>
                   {#if team[1].email !== ''}
                  <a href="mailto:{team[1].email}" alt={team[1].Name}>
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="csbgpath"
                        d="M16.778 0.333252H1.22244C0.927754 0.333252 0.645139
                        0.450315 0.436765 0.658689C0.228391 0.867063 0.111328
                        1.14968 0.111328 1.44436V12.5555C0.111328 12.8502
                        0.228391 13.1328 0.436765 13.3411C0.645139 13.5495
                        0.927754 13.6666 1.22244 13.6666H16.778C17.0727 13.6666
                        17.3553 13.5495 17.5637 13.3411C17.772 13.1328 17.8891
                        12.8502 17.8891 12.5555V1.44436C17.8891 1.14968 17.772
                        0.867063 17.5637 0.658689C17.3553 0.450315 17.0727
                        0.333252 16.778 0.333252ZM15.9224
                        12.5555H2.14466L6.03355 8.53325L5.23355 7.76103L1.22244
                        11.911V2.28881L8.12799 9.16103C8.33617 9.36797 8.61779
                        9.48413 8.91133 9.48413C9.20487 9.48413 9.48648 9.36797
                        9.69466 9.16103L16.778 2.11659V11.8388L12.6891
                        7.74992L11.9058 8.53325L15.9224 12.5555ZM1.95022
                        1.44436H15.878L8.91133 8.37214L1.95022 1.44436Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  {/if}
                </div>
              </div>
            </div>

            {#if ww > 960}
              <div
                class="auboxtext {uactive == 1000 ? '' : 'uactivefalse'}
                teamblockuser teamblockusertext"
                style=""
              >
                <h2>
                  1, 2 oder 3?
                  <!-- {#if about.TeamTitle}{about.TeamTitle}{/if} -->
                </h2>
                <p>
                  Du suchst Kontakt?
                  <br />
                  Bei uns hast Du drei Anlaufstellen.
                  <!-- {#if about.TeamText}{about.TeamText}{/if} -->
                </p>
              </div>
            {/if}

            <div class="teamblockuser">
              <div class="clip-svg csvg csvg4">
                <div class="csbg csbg4" />
                <div
                  class="csimage"
                  style="background-image: url('{Prodhost}/colletadmin/{team[2].Foto.styles[1].path}')"
                />
                <div class="uactivetext">
                  <p>
                    <strong>{team[2].Name}</strong>
                  </p>
                  <p>{team[2].Position}</p>
                   {#if team[2].email !== ''}
                  <a href="mailto:{team[2].email}" alt={team[2].Name}>
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="csbgpath"
                        d="M16.778 0.333252H1.22244C0.927754 0.333252 0.645139
                        0.450315 0.436765 0.658689C0.228391 0.867063 0.111328
                        1.14968 0.111328 1.44436V12.5555C0.111328 12.8502
                        0.228391 13.1328 0.436765 13.3411C0.645139 13.5495
                        0.927754 13.6666 1.22244 13.6666H16.778C17.0727 13.6666
                        17.3553 13.5495 17.5637 13.3411C17.772 13.1328 17.8891
                        12.8502 17.8891 12.5555V1.44436C17.8891 1.14968 17.772
                        0.867063 17.5637 0.658689C17.3553 0.450315 17.0727
                        0.333252 16.778 0.333252ZM15.9224
                        12.5555H2.14466L6.03355 8.53325L5.23355 7.76103L1.22244
                        11.911V2.28881L8.12799 9.16103C8.33617 9.36797 8.61779
                        9.48413 8.91133 9.48413C9.20487 9.48413 9.48648 9.36797
                        9.69466 9.16103L16.778 2.11659V11.8388L12.6891
                        7.74992L11.9058 8.53325L15.9224 12.5555ZM1.95022
                        1.44436H15.878L8.91133 8.37214L1.95022 1.44436Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  {/if}
                </div>
              </div>
            </div>

          </div>

        </div>
      {/if}

    </div>

  </div>
</section>

<!-- 

<section class="section ">
  <div class="infoAbout has-text-centered is-vcentered">

    <div style="display: block">

      <div
        class="auboxtext {uactive == 1000 ? '' : 'uactivefalse'} teamblockuser
        teamblockusertext"
        style="">
        <h2>
          {#if about.TeamTitle}{about.TeamTitle}{/if}
        </h2>
        <p>
          {#if about.TeamText}{about.TeamText}{/if}
        </p>
      </div>
      {#if team}
        {#each team as t, i}
          <div class="teamblockuser">
            <div class="clip-svg csvg csvg{i}">
              <figure
                class="image"
                on:mouseover={() => (uactive = i)}
                on:mouseout={() => (uactive = 1000)}>
                <img
                  src="{Prodhost}/colletadmin/{t.Foto.styles[1].path}"
                  alt={t.Foto.description !== '' ? t.Foto.description : t.Name} />
              </figure>
              <div class="uactivetext">
                <p>
                  <strong>{t.Name}</strong>
                </p>
                <p>{t.Position}</p>
              </div>
            </div>
          </div>
        {/each}
      {/if}

    </div>

  </div>
</section> -->

{#if about.Video}
  <section class="section is-vertical-center">

    <div class="aboutVideo has-text-centered is-vcentered columns">
      <div class="column">

        {#if getCookie('ch') === '1' || getCookie('ch') === '3'}
          <a
            class="lightBoxVideoLink"
            style=" position: relative; "
            frameborder="0"
            allowfullscreen
            allow="autoplay"
            href="https://www.youtube.com/embed/{about.Video}??rel=0&mute=0&showinfo=0&controls=0&autoplay=1"
          >
            <div
              style="background-image:url(https://img.youtube.com/vi/{about.Video}/sddefault.jpg);
              background-size: 110%;"
            >
              <svg
                viewBox="0 0 48 48"
                width="48px"
                height="48px"
                style=" position: absolute; top: 40%; left: 45%;"
              >
                <path
                  fill="#FF3D00"
                  d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                />
                <path fill="#FFF" d="M20 31L20 17 32 24z" />
              </svg>
            </div>
          </a>
        {:else}
          <div
            class="lightBoxVideoLink slbContentEl"
            style=" position: relative; "
            on:click={customContentLink}
          >
            <div
              style="background-image:url(https://img.youtube.com/vi/{about.Video}/sddefault.jpg);
              background-size: 110%;"
            >
              <svg
                viewBox="0 0 48 48"
                width="48px"
                height="48px"
                style=" position: absolute; top: 40%; left: 45%;"
              >
                <path
                  fill="#FF3D00"
                  d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                />
                <path fill="#FFF" d="M20 31L20 17 32 24z" />
              </svg>
            </div>
          </div>
        {/if}

      </div>
    </div>
  </section>
{/if}

<section class="section" id="bSlider">
  {#if about.TourTitle}
    <BuroSlider TourTitle={about.TourTitle} TourText={about.TourText} />
  {/if}
</section>

<div class="svgclippath">
  <svg viewBox="0 0 300 300" style=" width: 300px; height: 300px;">
    <clipPath
      id="blob1"
      clipPathUnits="objectBoundingBox"
      style="transform: scale(0.5) translate(20px, -10px);"
    >
      <path
        style=" transform: translate(1px, 1px) scale(0.0055);"
        d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
        fill="#f00"
      />
    </clipPath>
  </svg>
  <svg viewBox="0 0 300 300" style=" width: 300px; height: 300px;">
    <clipPath
      id="blob2"
      clipPathUnits="objectBoundingBox"
      style="transform: scale(0.4) translate(220px, 60px);"
    >
      <path
        style=" transform: translate(1px, 1px) scale(0.0055);"
        d="M93.6,-83.8C121.6,-39.6,144.6,-0.7,145.2,47.7C145.8,96.1,123.9,153.9,81.9,176.7C39.9,199.4,-22.1,187.1,-85.6,162.4C-149.1,137.7,-213.9,100.7,-234.6,43.9C-255.2,-12.9,-231.6,-89.4,-185.1,-137.8C-138.6,-186.2,-69.3,-206.6,-18.2,-192.1C32.8,-177.5,65.7,-128,93.6,-83.8Z"
        fill="#f00"
      />
    </clipPath>
  </svg>
  <svg viewBox="0 0 300 300" style=" width: 300px; height: 300px;">
    <clipPath
      id="blob3"
      clipPathUnits="objectBoundingBox"
      style="transform: scale(0.45) translate(110px, 20px);"
    >
      <path
        style=" transform: translate(1px, 1px) scale(0.0055);"
        d="M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
        fill="#f00"
      />
    </clipPath>
  </svg>
  <svg viewBox="0 0 300 300" style=" width: 300px; height: 300px;">
    <clipPath
      id="blob4"
      clipPathUnits="objectBoundingBox"
      style="transform: scale(0.45) translate(150px, 20px);"
    >
      <path
        style=" transform: translate(1px, 1px) scale(0.0055);"
        d="M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
        fill="#f00"
      />
    </clipPath>
  </svg>
  <svg viewBox="0 0 300 300" style=" width: 300px; height: 300px;">
    <clipPath
      id="blob5"
      clipPathUnits="objectBoundingBox"
      style="transform: scale(0.37) translate(130px, 100px);"
    >
      <path
        style=" transform: translate(1px, 1px) scale(0.0055);"
        d="M167.6,-128.5C210.7,-79.7,234.5,-10.4,223.5,56.2C212.4,122.8,166.5,186.6,105,215.2C43.4,243.9,-33.8,237.3,-98.1,205.6C-162.4,173.9,-213.8,117.2,-219,59.9C-224.2,2.5,-183.3,-55.5,-139.1,-104.6C-94.9,-153.7,-47.4,-193.8,7.4,-199.7C62.3,-205.7,124.6,-177.3,167.6,-128.5Z"
        fill="#f00"
      />
    </clipPath>
  </svg>

  <svg viewBox="0 0 391.8 413.1" width="0" height="0">
    <clipPath
      id="blobc"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0031, 0.0031)"
    >
      <path
        class="st0"
        style="transform: translateX(145px);"
        d="M6.4,17.2c100.8,0,146.8,86.6,146.8,146.7c0,71.8-57.3,145-146.8,146.7c-62.2,1.2-146.8-50.6-146.8-146.7
        C-140.4,78.6-72.8,17.2,6.4,17.2z"
      />
    </clipPath>
  </svg>

  {#if ww > 1440}
    <svg viewBox="0 0 398.6 435.4" width="0" height="0">
      <defs>
        <clipPath
          id="sliderblob"
          clipPathUnits="objectBoundingBox"
          transform="scale(0.0027, 0.0037)"
        >
          <path
            style=" transform: translate(200px, 125px);"
            transform=" translate(200, 125)"
            class="bblob"
            d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
          />
        </clipPath>
      </defs>
    </svg>
  {:else}
    <svg viewBox="0 0 1200 900" width="0" height="0">
      <clipPath
        id="sliderblob"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0008, 0.002)"
      >
        <path
          transform="translate(-180, 0)"
          class="bblob"
          d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
          143.61c-4.55,95.55,170.07,212.41,10.95,462.77
          C1005.43,799.35,1195.72,900.48,1200,900z"
        />
      </clipPath>
    </svg>
  {/if}

</div>

<style>
  .svgclippath {
    position: absolute;
    top: -1000000px;
  }
  #projektInfo {
    text-align: left;
    padding: 20vh 0px 20vh 250px;
    min-width: 1800px;
    width: auto;
    margin-right: -250px;
  }

  #projektInfo .column {
    position: relative;
    z-index: 1;
  }

  #projektInfo .column span {
    position: absolute;
    z-index: 1;
    font-size: 7rem;
    font-weight: 900;
  }

  .wir1,
  .wir2,
  .wir3 {
    max-width: 450px;
    position: relative;
  }

  .wir3 {
    left: 150px;
  }

  .wir2 {
    left: -200px;
  }

  .wir1 p,
  .wir2 p,
  .wir3 p {
    font-size: 18px;
  }

  #projektInfo .column h2 {
    position: relative;
    z-index: 10;
    font-size: 32px;
    text-transform: uppercase;
  }

  #projektInfo .wir1 span {
    color: #e61974;
    opacity: 0.25;
    left: 150px;
    top: -100px;
  }

  #projektInfo .wir2 span {
    color: #156f7b;
    opacity: 0.25;
    left: -100px;
    top: 130px;
  }

  #projektInfo .wir3 h2 {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 80px;
    font-size: 3rem;
  }

  #projektInfo .wir3 span {
    color: #000000;
    opacity: 0.1;
    left: 20px;
    top: 50px;
  }

  .blobbgslider {
    width: 70vw;
    float: left;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    display: none;
  }

  .infoAbout {
    position: relative;
    width: 1400px;
    height: 100vh;
    z-index: 10000;
  }

  .aubox0 {
    position: absolute;
    left: 0;
    top: 20%;
    width: 400px;
  }
  .aubox1 {
    position: absolute;
    left: 400px;
    top: 0%;
    width: 400px;
  }
  .aubox2 {
    position: absolute;
    left: 800px;
    top: 15%;
    width: 400px;
  }
  .aubox3 {
    position: absolute;
    left: 300px;
    bottom: 0%;
    width: 400px;
  }
  .aubox4 {
    position: absolute;
    left: 700px;
    bottom: 0%;
    width: 400px;
  }

  .auboxtext {
    position: absolute;
    left: 450px;
    top: 42%;
    width: 400px;
  }

  .clip-svg img {
    width: 400px;
    /* margin: 40px; */
    opacity: 1;
    clip-path: url("#blob1");
  }

  .clip-svg:hover img {
    /* clip-path: url("#blobc") !important; */
    transition-duration: 1s;
  }

  .clip-svg.csvg0 img,
  .csbg.csbg0,
  .clip-svg.csvg0 .csimage {
    /* clip-path: url("#blob1"); */
    transition-duration: 1s;
    border-radius: 34% 66% 51% 49% / 41% 33% 67% 59%;
  }

  .clip-svg.csvg1 img,
  .csbg.csbg1,
  .clip-svg.csvg1 .csimage {
    /* clip-path: url("#blob2"); */
    border-radius: 36% 64% 66% 34% / 39% 47% 53% 61%;
  }

  .clip-svg.csvg2 img,
  .csbg.csbg2,
  .clip-svg.csvg2 .csimage {
    clip-path: url("#blob3");
  }

  .clip-svg.csvg3 img,
  .csbg.csbg3,
  .clip-svg.csvg3 .csimage {
    /* clip-path: url("#blob4"); */
    border-radius: 34% 66% 51% 49% / 41% 33% 67% 59%;
  }

  .clip-svg.csvg4 img,
  .csbg.csbg4,
  .clip-svg.csvg4 .csimage {
    /* clip-path: url("#blob5"); */
    border-radius: 34% 66% 51% 49% / 41% 33% 67% 59%;
  }

  .clip-svg.csvg5 img,
  .csbg.csbg5,
  .clip-svg.csvg5 .csimage {
    clip-path: url("#blob3");
  }

  .clip-svg.csvg6 img,
  .csbg.csbg6,
  .clip-svg.csvg6 .csimage {
    clip-path: url("#blob5");
  }

  .clip-svg.csvg {
    transition-duration: 1s;
    z-index: 10;
  }

  .clip-svg.csvg:hover {
    z-index: 10;
  }

  .clip-svg.csvg:hover .csbg {
    width: 350px;
    height: 350px;
    background-color: #156f7b;
    opacity: 0.5;
    position: absolute;
    /* top: 0; */
    left: 0;
    z-index: 1;
    transition-duration: 1s;
  }

  .teamblockuser {
    z-index: 1;
    position: relative;
  }

  .teamblockuser:hover {
    z-index: 100;
  }

  .uactive {
    opacity: 1 !important;
    z-index: 100;
    transition-duration: 1s;
  }
  .uactivefalse {
    opacity: 0.1 !important;
    z-index: 1;
    transition-duration: 1s;
  }

  .uactivetext {
    opacity: 0;
    display: none;
    transition-duration: 1s;
    position: absolute;
    top: 40%;
    right: 0%;
    /* width: 250px; */
    text-align: center;
    width: 100%;
    color: #fff;
  }

  .uactivetext strong {
    /* color: #e61974; */
    color: #fff;

    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .clip-svg.csvg:hover .uactivetext {
    opacity: 1;
    display: block;
    transition-duration: 1s;
    /* background: #ffffff90; */
    padding: 20px;
    z-index: 10;
  }

  #bSlider {
    min-width: 1200px;
    position: relative;
    z-index: 1000;
  }

  .teamblockuserblock {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: start;
    align-items: center;
  }

  .teamblockuserblock .csimage {
    /* filter: drop-shadow(2px 4px 10px #dadada); */
    box-shadow: 0 0 10px #dadada;
  }

  .teamblockuserblock .teamblockuserblock1,
  .teamblockuserblock .teamblockuserblock2 {
    display: flex;
    width: 100%;

    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: start;
  }

  .teamblockuserblock .teamblockuserblock2 {
    flex-direction: column;
  }
  .teamblockuserblock .teamblockuserblock2 .csvg1 {
    left: -100px;
    position: relative;
  }
  .teamblockuserblock .teamblockuserblock {
    justify-content: flex-end;
  }

  .csimage {
    width: 350px;
    height: 350px;
    background-size: cover;
    background-position: center;
    margin: 30px auto;
  }

  @media (min-width: 1024px) {
    /* .clip-svg:hover {
      width: 500px;
      height: 500px;
    } */

    .uactivetext {
      right: 0%;
    }

    .infoAbout {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .infoAbout > div {
      width: 100%;
    }
  }

  @media (max-height: 850px) {
    .aubox0 {
      top: 10%;
    }

    .aubox1 {
      top: -10%;
    }

    .aubox2 {
      top: 0%;
    }

    .aubox3,
    .aubox4 {
      bottom: -10%;
    }
  }

  @media (max-width: 960px) {
    .teamblockuserblock .teamblockuserblock2 .csvg1 {
      left: 0px;
    }
    .teamblockuserblock2 {
      margin-bottom: 150px;
    }
    .wir1 span,
    .wir2 span,
    .wir3 span {
      color: #e61974;
      opacity: 0.25;
      left: 0px;
      width: 100%;
      top: 70px;
      position: relative;
      font-size: 90px;
      font-weight: 800;
    }

    .wir2 span {
      color: #156f7b;
    }

    .wir3 span {
      color: #000000;
    }

    .wir3,
    .wir2,
    .wir1 {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 30px;
      text-align: left;
      position: static;
    }

    .clip-svg {
      width: auto;

      margin: 0 auto;
      position: relative !important;
    }

    .clip-svg img {
      margin: 0 auto;
      position: static !important;
    }

    .clip-svg:hover {
      position: static !important;

      /* transition-duration: 0s; */
    }

    .clip-svg:hover .csbg {
      /* background-color: transparent!important;
      height: 0px!important;
      display: none; */
    }

    .uactivetext strong {
      color: #e61974;

      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .clip-svg.csvg:hover .uactivetext {
      opacity: 1;
      display: block;
      transition-duration: 1s;
      /* background: #ffffff90; */

      z-index: 10;
    }

    .uactivetext {
      opacity: 1;
      display: block;
      position: static;
      width: 100%;
      text-align: center;
      transition-duration: 0s;
      color: #4a4a4a;
      padding: 20px;
    }

    path.csbgpath {
      fill: #e61974 !important;
    }

    .teamblockuserblock {
      flex-direction: column;
    }

    .csbg {
      display: none !important;
    }

    #projektInfo {
      text-align: left;
      padding: 0 32px;
      min-width: 100%;
      width: auto;
    }

    .infoAbout {
      position: static;
      width: 100%;
      height: auto;
    }

    .aubox0,
    .aubox1,
    .aubox2,
    .aubox3,
    .aubox4 {
      position: static !important;
      width: 100%;
      margin: 0;
      padding: 0;
      height: 500px;
      float: left;
    }

    .auboxtext {
      position: static;
      width: 100%;
      margin-top: 50px;
    }

    #projektInfo .wir1 span,
    #projektInfo .wir2 span,
    #projektInfo .wir3 span {
      opacity: 0.25;
      left: 0;
      top: 0;
    }
  }

  .aboutVideo {
    min-width: 853px;
    margin: 0 150px 0 100px;
  }

  .aboutVideo .lightBoxVideoLink > div {
    width: 100%;
    height: 480px;
    position: relative;
    background-position: center;
    border-radius: 48% 52% 75% 25%/59% 56% 44% 41%;
    box-shadow: 25px 25px 0 #f9c6dd;
    border: 1px solid #f9c6dd;
  }

  .aboutVideo .lightBoxVideoLink:hover > div {
    box-shadow: 25px 25px 0 #adcdd1;
    border: 1px solid #adcdd1;
  }

  .teamblockuser {
    /* width: 20%; */
    float: left;
    max-height: 40vh;
  }

  .teamblockuser.teamblockusertext {
    position: relative;
    left: -100px;
    text-align: left;
    /* top: 120px; */
  }

  .teamblockuser.teamblockusertext h2 {
    margin-bottom: 10px;
  }

  @media (max-width: 960px) {
    .aboutVideo {
      min-width: none;
      margin: 0;
    }

    .teamblockuser {
      width: 100%;
      float: left;
      max-height: none;
    }

    .teamblockuser.teamblockusertext {
      position: relative;
      left: 0;
      top: 0px;
    }

    .aboutVideo .lightBoxVideoLink > div {
      width: 100%;
      height: 350px;
    }

    .clip-svg a path {
      fill: #e61974 !important;
    }

    #projektInfo .columns.is-hidden-desktop {
      flex-direction: column;
      display: flex;
    }

    .infoAbout .auboxtext {
      text-align: center;
    }
  }

  @media (max-width: 476px) {
    .aboutVideo .lightBoxVideoLink > div {
      width: 100%;
      height: 200px;
      background-size: 130% !important;
    }
  }

  @media screen and (max-width: 1024px) {
    #msvg {
      object-fit: cover;
      height: calc(100vh - 2px);
      max-height: calc(100vh - 2px);
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
    }

    #mainbild,
    .mainslider {
      overflow: hidden;
    }
  }
  @media screen and (max-width: 1200px) {
    .clip-svg {
      display: flex;
      flex-direction: column;
    }
    .csimage {
      width: 250px;
      height: 250px;
      margin: 20px 0px;
      align-self: center;
    }
  }

  @media screen and (min-width: 1024px) and (max-height: 940px) {
    .csimage {
      width: 250px;
      height: 250px;
    }

    .clip-svg.csvg:hover .csbg {
      width: 250px;
      height: 250px;
    }

    .uactivetext{
      top:35%
    }
  }
</style>

<script>
  import { onMount, onDestroy } from "svelte";
  import { isActive, url } from "@sveltech/routify";
  import anime from "animejs";
  import Prodhost from "../components/host.js";

  const links = [
    ["./index", "Home"],
    ["./projekte", "Projekte"],
    ["./leistungen", "Leistungen"],
    ["./blog", "News & Blog"],
    ["./ueberuns", "Über uns"],
    ["./blog/jobs-in-der-agentur", "Jobs"],
    ["./kontakt", "Kontakt"]
  ];

  var awards = [];

  let bactive = false;
  let lactive = false;
  var ww = window.innerWidth;

  var ltoggletext = "PUSH IT!";

  onMount(() => {
    if (!bactive) {
      const elmOverlay = document.querySelector(".shape-overlays");
      const overlay = new ShapeOverlays(elmOverlay);

      overlay.isAnimating = true;
      for (var i = 0; i < overlay.numPoints; i++) {
        overlay.delayPointsArray[i] = 0;
      }

      if (ww < 1024) {
        elmOverlay.style.display = "none";
        elmOverlay.style.background = "none";
      }

      setTimeout(() => {
        elmOverlay.style.background = "none";
        overlay.close();
      }, 300);
    }

    window.addEventListener("orientationchange", reportWindowSize);
    function reportWindowSize() {
      location.reload();
    }

    if (ww >= 1024) {
      window.addEventListener("wheel", function(e) {
        if (document.querySelector(".scrollcontentbox").scrollLeft > 700) {
          document.querySelector("#backBtn").style.display = "block";
          document.querySelector("#backBtn").style.opacity = 1;
          document
            .querySelector("#backBtn")
            .addEventListener("click", function() {
              if (document.querySelector(".scrollcontentbox")) {
                if (
                  /MSIE 9/i.test(navigator.userAgent) ||
                  /rv:11.0/i.test(navigator.userAgent) ||
                  /Edge\/\d./i.test(navigator.userAgent)
                ) {
                  document.querySelector(".scrollcontentbox").scrollLeft = 0;
                } else {
                  document.querySelector(".scrollcontentbox").scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                  });
                }
                document.querySelector("#backBtn").style.opacity = 0;
                document.querySelector("#backBtn").style.display = "none";
              }
            });
        } else {
          document.querySelector("#backBtn").style.opacity = 0;
          document.querySelector("#backBtn").style.display = "none";
        }
      });
    }
  });

  let actl = 1;

  function act(lid, dl) {
    actl = lid;
    anime({
      targets: ".lsvgb",
      zIndex: {
        value: [10, 0],
        round: true
      },
      opacity: [1, 0],

      duration: 0
    });
    anime({
      targets: ".lbp11",
      opacity: [1, 0],
      duration: 0
    });

    anime({
      targets: ".lbp12",
      opacity: [1, 0],
      duration: 0
    });
    anime({
      targets: ".lbp13",
      opacity: [1, 0],
      duration: 0
    });

    anime({
      targets: ".lbp14",
      opacity: [1, 0],
      duration: 0
    });
    anime({
      targets: "svg#lsvgb" + lid + "",
      opacity: [0, 1],
      zIndex: {
        value: [0, 10],
        round: true
      },
      duration: 0,
      dalay: 50
    });

    var jcor = [
      { x11: 0, x12: 47, y11: 115, y12: 6, x21: 47, x22: 57 },
      { x11: 0, x12: 47, y11: 190, y12: 6, x21: 47, x22: 37 },
      { x11: 0, x12: 117, y11: 240, y12: 6, x21: 117, x22: 57 },
      { x11: -180, x12: 47, y11: 290, y12: 6, x21: 47, x22: 57 }
    ];

    var a1 = anime({
      targets: ".nav-menu-block-desktop-right svg#lsvgb" + lid + " .ll1",
      x2: [jcor[lid - 1].x11, jcor[lid - 1].x12],
      duration: 300,
      easing: "linear",
      delay: dl + 200,
      complete: function() {
        anime({
          targets: "svg#lsvgb" + lid + " .lbp14",
          opacity: [0, 1],
          duration: 1000
        });
        anime({
          targets: "svg#lsvgb" + lid + " .lbp11",
          opacity: [0, 1],
          duration: 1000,
          delay: 500
        });
      }
    });
    var a2 = anime({
      targets: ".nav-menu-block-desktop-right svg#lsvgb" + lid + " .ll2",
      y2: [jcor[lid - 1].y11, jcor[lid - 1].y12],
      duration: 300,
      easing: "linear",
      delay: dl + 500,
      complete: function() {
        anime({
          targets: "svg#lsvgb" + lid + " .lbp12",
          opacity: [0, 1],
          duration: 1000
        });
      }
    });
    var a3 = anime({
      targets: ".nav-menu-block-desktop-right svg#lsvgb" + lid + " .ll3",
      x2: [jcor[lid - 1].x21, jcor[lid - 1].x22],
      duration: 300,
      easing: "linear",
      delay: dl + 800,
      complete: function() {
        anime({
          targets: "svg#lsvgb" + lid + " .lbp13",
          opacity: [0, 1],
          duration: 1000
        });
      }
    });
  }

  function ltoggle() {
    lactive = !lactive;

    if (ww > 1024) {
      if (lactive === true) {
        ltoggletext = "REAL GOOD!";
      } else {
        ltoggletext = "PUSH IT!";
      }

      if (lactive) {
        if (ww < 1200) {
          anime({
            targets: "#navMenu",
            left: ["50%", "18%"],
            duration: 1000,
            easing: "easeInQuad"
          });
        } else {
          anime({
            targets: "#navMenu",
            left: ["50%", "30%"],
            duration: 1000,
            easing: "easeInQuad"
          });
        }

        anime({
          targets: ".nav-menu-block-desktop-left-llinie-l",
          x2: [0, 100],
          duration: 700,
          delay: 900
        });

        anime({
          targets: ".nav-menu-block-desktop-right",
          width: ["360px", "560px"],
          opacity: 1,
          duration: 1000,
          delay: 1000,
          begin: function() {}
        });

        act(1, 1000);
      }
      if (!lactive) {
        anime({
          targets: ".lbp11",
          opacity: [1, 0],
          duration: 1000
        });

        anime({
          targets: ".lbp12",
          opacity: [1, 0],
          duration: 1000
        });

        anime({
          targets: ".lbp13",
          opacity: [1, 0],
          duration: 1000
        });

        anime({
          targets: "#navMenu",
          left: ["30%", "50%"],
          duration: 1000,
          delay: 1000,
          easing: "easeInQuad"
        });

        anime({
          targets: ".nav-menu-block-desktop-left-llinie-l",
          x2: [100, 0],
          duration: 700,
          delay: 800
        });

        anime({
          targets: ".nav-menu-block-desktop-right",
          opacity: 0,
          delay: 500,
          width: ["560px", "360px"],
          duration: 1000
        });
      }
    }
  }

  hashchange();

   async function hashchange() {

    await fetch(Prodhost + "/colletadmin/api/collections/get/awards", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { publish: true },
        sort: { _o: 1 }
      })
    })
      .then(response => response.json())
      .then(json => {
        awards = json.entries;
      });

   }

  function btoggle() {
    const elmOverlay = document.querySelector(".shape-overlays");
    const overlay = new ShapeOverlays(elmOverlay);
    bactive = !bactive;
    overlay.isAnimating = true;
    for (var i = 0; i < overlay.numPoints; i++) {
      overlay.delayPointsArray[i] = 0;
    }

    if (ww < 1024) {
      elmOverlay.style.display = "block";
    }

    if (bactive) {
      overlay.open();
      anime({
        targets: "#navMenu",
        opacity: 1,
        duration: 700,
        delay: 700
      });
    }
    if (!bactive) {
      overlay.close();
      anime({
        targets: "#navMenu",
        opacity: 0,
        duration: 500
      });
    }
  }

  const ease = {
    exponentialInOut: t => {
      return t == 0.0 || t == 1.0
        ? t
        : t < 0.5
        ? +0.5 * Math.pow(2.0, 20.0 * t - 10.0)
        : -0.5 * Math.pow(2.0, 10.0 - t * 20.0) + 1.0;
    },
    sineOut: t => {
      const HALF_PI = 1.5707963267948966;
      return Math.sin(t * HALF_PI);
    }
  };

  class ShapeOverlays {
    constructor(elm) {
      this.elm = elm;
      this.path = elm.querySelectorAll("path");
      this.isOpened = false;
      this.isAnimating = false;
      this.numPoints = 2;
      this.duration = 700;
      this.delayPointsArray = [];
      this.delayPointsMax = 0;
      this.delayPerPath = 100;
      this.timeStart = Date.now();
    }
    toggle() {
      this.isAnimating = true;
      for (var i = 0; i < this.numPoints; i++) {
        this.delayPointsArray[i] = 0;
      }
      if (this.isOpened === false) {
        this.open();
      } else {
        this.close();
      }
    }
    open() {
      this.isOpened = true;
      this.elm.classList.add("is-opened");
      this.timeStart = Date.now();
      this.renderLoop();
    }
    close() {
      this.isOpened = false;
      this.elm.classList.remove("is-opened");
      this.timeStart = Date.now();
      this.renderLoop();
    }
    updatePath(time) {
      const points = [];
      for (var i = 0; i < this.numPoints; i++) {
        const thisEase = i % 2 === 1 ? ease.sineOut : ease.exponentialInOut;
        points[i] =
          (1 -
            thisEase(
              Math.min(
                Math.max(time - this.delayPointsArray[i], 0) / this.duration,
                1
              )
            )) *
          100;
      }
      let str = "";
      str += this.isOpened ? `M 0 0 H ${points[0]}` : `M ${points[0]} 0`;
      for (var i = 0; i < this.numPoints - 1; i++) {
        const p = ((i + 1) / (this.numPoints - 1)) * 100;
        const cp = p - ((1 / (this.numPoints - 1)) * 100) / 2;
        str += `C ${points[i]} ${cp} ${points[i + 1]} ${cp} ${
          points[i + 1]
        } ${p} `;
      }
      str += this.isOpened ? `H 100 V 0` : `H 0 V 0`;
      return str;
    }
    render() {
      if (this.isOpened) {
        for (var i = 0; i < this.path.length; i++) {
          this.path[i].setAttribute(
            "d",
            this.updatePath(
              Date.now() - (this.timeStart + this.delayPerPath * i)
            )
          );
        }
      } else {
        for (var i = 0; i < this.path.length; i++) {
          this.path[i].setAttribute(
            "d",
            this.updatePath(
              Date.now() -
                (this.timeStart +
                  this.delayPerPath * (this.path.length - i - 1))
            )
          );
        }
      }
    }
    renderLoop() {
      this.render();
      if (
        Date.now() - this.timeStart <
        this.duration +
          this.delayPerPath * (this.path.length - 1) +
          this.delayPointsMax
      ) {
        requestAnimationFrame(() => {
          this.renderLoop();
        });
      } else {
        this.isAnimating = false;
      }
    }
  }
</script>

<style>
  #navleistungen path {
    fill: #be145f;
    transition-duration: 0.5s;
  }

  #navleistungen:hover path {
    fill: #fff;
    transition-duration: 0.5s;
  }
  .hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  .shape-overlays {
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 700;
    background: #e61974;
  }



  .shape-overlays__path:nth-of-type(1) {
    fill: #fff;
  }

  .shape-overlays__path:nth-of-type(2) {
    fill: #ff7db7;
  }

  .shape-overlays__path:nth-of-type(3) {
    fill: #e61974;
  }

  .shape-overlays__path:nth-of-type(4) {
    fill: #e61974;
  }
  .navbartop {
    z-index: 1000;
  }

  #navinfo {
    position: fixed;
    bottom: 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    width: 100%;
    left: 0;
    background: transparent;
    line-height: 30px;
    z-index: 1000;
  }

  #navinfo .mobilnavinfo  {
    width: 100%;
  }

  #navinfo .mobilnavinfo a {
    font-size: 14px;
    width: 100%!important;
    font-weight: 400!important;
    height: 20px!important;
  }

  #navinfo span a.infolink {
    color: #ffffff;
    text-decoration: none;
    position: static;
  }

  #navinfo span a,
  #navinfo span a svg {
    width: 17px;
    height: 17px;
    fill: #fff;
    position: relative;
    top: 1px;
    left: 5px;
  }

  .navtop-active #backBtn {
    opacity: 0 !important;
  }

  #backBtn {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 220px;
    top: 38px;
    opacity: 0;
    cursor: pointer;
    display: none;
  }

  #backBtn svg {
    width: 25px;
    height: 25px;
  }

  #backBtn:hover path {
    fill: #e61974;
  }

  .burgerkontakt,
  .burgerjobs {
    display: none;
  }

  .lb-text,
  .lbp {
    opacity: 0;
    cursor: pointer;
  }

  #navMenu
    > div.nav-menu-block-desktop
    > div.nav-menu-block-desktop-right
    svg
    .lb-text:hover {
    fill: #4a4a4a;
  }

  div.nav-menu-block-desktop-right svg a {
    fill: #fff;
  }

  div.nav-menu-block-desktop-right svg a:hover {
    fill: #4a4a4a;
  }

  #navMenu .nav-menu-block-desktop-right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .ll_link {
    color: #fff;
  }

  .ll_link:hover {
    color: #4a4a4a;
  }

  .ll_link_active {
    color: #4a4a4a;
  }

  .lsvgb {
    /* margin-left: 50px;
    margin-bottom: -44px; */
    position: absolute;
    z-index: 1;
  }

  #lsvgb1 {
    left: 40px;
    /* bottom: -45px; */
    padding: 50px 0 200px 0;
    height: 366px;
    bottom: -245px;

  }

  #lsvgb2 {
    left: 70px;
    /* bottom: -98px; */


    padding: 50px 0 200px 0;
    height: 440px;
    bottom: -298px;

    
  }

  #lsvgb3 {
    left: 120px;
    /* bottom: -150px; */



    padding: 50px 0 100px 0;
    height: 390px;
    bottom: -250px;
  
  }

  #lsvgb4 {
    left: 100px;
    /* bottom: -205px; */

    
    padding: 50px 0 100px 0;
    height: 440px;
    bottom: -305px;

  }

  #headerawards  {
    display: flex;
    position: fixed;
    left: 10px;
    bottom: 50px;
    z-index: 1000000;
    max-width: 700px;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1700px){
    #headerawards  {
       max-width: 400px;

    }

  }

  #headerawards > * {
    max-width: 100px!important;
    transition-duration: 0.5s;
    margin-right: 10px

  }

    #headerawards > *:hover {
    filter: invert(1) grayscale(1);
    transition-duration: 0.5s
  }


  @media screen and (max-width: 1024px) {
    #navinfo .infolink-mobil {
      width: 100%;
      padding-left: 9vw;
    }

    #navinfo .infolink-mobil a {
      font-size: 12px !important;
      font-weight: 400 !important;
      float: left !important;
      width: 100%;
      height: 20px !important;
    }

    #navinfo span a {
      display: block;
    }

    #navinfo span {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    #navinfo span a svg,
    #navinfo span a {
      display: block;
      width: 25px !important;
      height: 25px !important;
      margin: 0 5px;
    }
  }

  @media handheld,
    only screen and (max-width: 960px) and (orientation: landscape) {
    #navMenu
      > div.nav-menu-block-desktop
      > div.nav-menu-block-desktop-left
      > a {
      font-size: 2rem;
      height: 40px !important;
    }

    #navinfo {
      position: fixed;
      bottom: 35px;
      font-size: 14px;
      color: #fff;
      text-align: right;
      width: 50%;
      right: 40px;
      left: auto;
      background: transparent;
      line-height: 30px;
    }

    #navinfo span {
      width: 100%;
      height: 50px;
      display: flex;
      padding-right: 10px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media screen and (min-width: 1024px) {
    .burgerkontakt {
      position: absolute;
      text-transform: uppercase;
      font-weight: 500;
      color: #fff;
      background: #e61974;
      font-size: 14px;
      z-index: 30;
      opacity: 1;
      -webkit-transition: 1s;
      transition: 1s;
      right: 70px;
      top: 36px;
      padding: 3px 10px;
      border-radius: 30px;
      border: 1px #fff solid;
      display: block;
    }

    .navbar .burgerkontakt:hover {
      color: #e61974 !important;
      background: #fff !important;
      border: 1px #e61974 solid;
    }

    .burgerjobs {
      position: absolute;
      text-transform: uppercase;
      font-weight: 500;
      color: #fff;
      background: #156f7b;
      font-size: 14px;
      z-index: 30;
      opacity: 1;
      -webkit-transition: 1s;
      transition: 1s;
      right: 160px;
      top: 36px;
      padding: 3px 10px;
      border-radius: 30px;
      border: 1px #fff solid;
      display: block;
    }

    .navbar .burgerjobs:hover {
      color: #156f7b !important;
      background: #fff !important;
      border: 1px #156f7b solid;
    }
  }
</style>

<svg class="hidden">
  <symbol id="icon-arrow" viewBox="0 0 24 24">
    <title>arrow</title>
    <polygon
      points="6.3,12.8 20.9,12.8 20.9,11.2 6.3,11.2 10.2,7.2 9,6 3.1,12 9,18
      10.2,16.8 " />
  </symbol>
</svg>

<nav class="navbar navbartop {bactive ? 'navtop-active' : ''}">
  <a
    class="navbar-item collet-logo"
    href="/"
    target="_self"
    title="Collet Logo">
    <svg
      viewBox="0 0 312.773 89"
      width="150px"
      height="50px"
      class="logotop {bactive ? 'logo-active' : 'logo-normal'}">
      <path
        d=" M 41.892 17.705 C 39.3 14.609 35.538 12.852 30.688 12.852 C 20.821
        12.852 13.547 20.886 13.547 31.934 C 13.547 42.982 20.821 51.017 30.271
        51.017 C 35.538 51.017 39.802 48.674 42.477 44.656 L 53.348 52.774 C
        48.414 59.807 40.053 63.07 31.941 63.07 C 13.465 63.07 0.002 50.934
        0.002 31.934 C 0.002 12.935 13.465 0.799 31.941 0.799 C 38.547 0.799
        46.908 3.059 51.926 9.504 L 41.892 17.705 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 87.723 50.221 C 98.843 50.221 106.118 42.186 106.118 31.138 C
        106.118 20.09 98.843 12.055 87.723 12.055 C 76.603 12.055 69.329 20.09
        69.329 31.138 C 69.329 42.186 76.603 50.221 87.723 50.221 Z M 87.723
        0.005 C 106.202 0.005 119.664 12.139 119.664 31.138 C 119.664 50.138
        106.202 62.274 87.723 62.274 C 69.245 62.274 55.782 50.138 55.782 31.138
        C 55.782 12.139 69.245 0.005 87.723 0.005 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 128.33 2.305 L 141.373 2.305 L 141.373 49.51 L 165.538 49.51 L
        165.538 61.562 L 128.33 61.562 L 128.33 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 172.633 2.305 L 185.678 2.305 L 185.678 49.51 L 209.841 49.51 L
        209.841 61.562 L 172.633 61.562 L 172.633 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 218.983 2.305 L 259.201 2.305 L 259.201 14.359 L 232.025 14.359 L
        232.025 25.407 L 257.696 25.407 L 257.696 37.458 L 232.025 37.458 L
        232.025 49.51 L 260.705 49.51 L 260.705 61.562 L 218.983 61.562 L
        218.983 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 294.242 2.305 L 284.577 2.305 L 266.031 2.305 L 266.031 13.856 L
        282.854 13.856 L 282.854 61.562 L 282.919 61.562 L 295.898 61.562 L
        295.965 61.562 L 295.965 13.856 L 312.787 13.856 L 312.787 2.305 L
        294.242 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 79.359 31.138 C 79.359 26.515 83.103 22.767 87.723 22.767 C 92.343
        22.767 96.088 26.515 96.088 31.138 C 96.088 35.763 92.343 39.511 87.723
        39.511 C 83.103 39.511 79.359 35.763 79.359 31.138 Z "
        fill="rgb(236,58,130)" />
      <path
        d=" M 8.48 77.688 C 7.832 76.806 6.796 76.357 5.745 76.357 C 3.304
        76.357 1.573 78.507 1.573 80.905 C 1.573 83.442 3.288 85.453 5.745
        85.453 C 7.074 85.453 8.14 84.91 8.913 83.92 L 9.886 84.741 C 8.913
        86.056 7.491 86.658 5.745 86.658 C 2.608 86.658 0.182 84.23 0.182 80.905
        C 0.182 77.688 2.5 75.151 5.745 75.151 C 7.228 75.151 8.681 75.662 9.608
        76.868 L 8.48 77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 24.659 80.905 C 24.659 78.383 22.897 76.357 20.333 76.357 C 17.768
        76.357 16.006 78.383 16.006 80.905 C 16.006 83.426 17.768 85.453 20.333
        85.453 C 22.897 85.453 24.659 83.426 24.659 80.905 Z M 14.615 80.905 C
        14.615 77.595 17.01 75.151 20.333 75.151 C 23.655 75.151 26.05 77.595
        26.05 80.905 C 26.05 84.216 23.655 86.658 20.333 86.658 C 17.01 86.658
        14.615 84.216 14.615 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 31.69 75.429 L 33.329 75.429 L 39.664 84.525 L 39.694 84.525 L
        39.694 75.429 L 40.992 75.429 L 40.992 86.379 L 39.355 86.379 L 33.02
        77.286 L 32.988 77.286 L 32.988 86.379 L 31.69 86.379 L 31.69 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 54.93 77.688 C 54.282 76.806 53.247 76.357 52.196 76.357 C 49.754
        76.357 48.024 78.507 48.024 80.905 C 48.024 83.442 49.739 85.453 52.196
        85.453 C 53.525 85.453 54.591 84.91 55.364 83.92 L 56.337 84.741 C
        55.364 86.056 53.941 86.658 52.196 86.658 C 49.059 86.658 46.633 84.23
        46.633 80.905 C 46.633 77.688 48.95 75.151 52.196 75.151 C 53.679 75.151
        55.133 75.662 56.058 76.868 L 54.93 77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 61.699 75.429 L 68.576 75.429 L 68.576 76.636 L 62.997 76.636 L
        62.997 80.07 L 68.205 80.07 L 68.205 81.276 L 62.997 81.276 L 62.997
        85.174 L 68.854 85.174 L 68.854 86.379 L 61.699 86.379 L 61.699 75.429 Z
        "
        fill="rgb(133,132,135)" />
      <path
        d=" M 75.715 80.256 L 77.908 80.256 C 79.207 80.256 80.103 79.668 80.103
        78.446 C 80.103 77.223 79.207 76.636 77.908 76.636 L 75.715 76.636 L
        75.715 80.256 Z M 74.417 75.429 L 77.663 75.429 C 79.671 75.429 81.494
        76.141 81.494 78.43 C 81.494 80.828 79.454 81.462 77.908 81.462 L 75.715
        81.462 L 75.715 86.379 L 74.417 86.379 L 74.417 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 94.474 76.636 L 90.858 76.636 L 90.858 86.379 L 89.56 86.379 L
        89.56 76.636 L 85.944 76.636 L 85.944 75.429 L 94.474 75.429 L 94.474
        76.636 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 104.75 77.363 C 104.318 76.666 103.544 76.357 102.756 76.357 C
        101.613 76.357 100.424 76.898 100.424 78.197 C 100.424 79.203 100.98
        79.652 102.725 80.209 C 104.426 80.751 106.002 81.307 106.002 83.456 C
        106.002 85.638 104.132 86.658 102.154 86.658 C 100.887 86.658 99.527
        86.24 98.786 85.143 L 99.898 84.23 C 100.362 85.034 101.288 85.453
        102.231 85.453 C 103.344 85.453 104.61 84.802 104.61 83.549 C 104.61
        82.204 103.699 81.971 101.753 81.322 C 100.207 80.811 99.033 80.131
        99.033 78.307 C 99.033 76.203 100.841 75.151 102.756 75.151 C 103.978
        75.151 105.013 75.49 105.817 76.433 L 104.75 77.363 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 120.419 73.139 L 120.419 88.606 L 119.399 88.606 L 119.399 73.139
        L 120.419 73.139 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 142.052 77.688 C 141.403 76.806 140.369 76.357 139.317 76.357 C
        136.877 76.357 135.145 78.507 135.145 80.905 C 135.145 83.442 136.861
        85.453 139.317 85.453 C 140.647 85.453 141.712 84.91 142.486 83.92 L
        143.458 84.741 C 142.486 86.056 141.063 86.658 139.317 86.658 C 136.18
        86.658 133.754 84.23 133.754 80.905 C 133.754 77.688 136.073 75.151
        139.317 75.151 C 140.8 75.151 142.254 75.662 143.181 76.868 L 142.052
        77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 158.232 80.905 C 158.232 78.383 156.47 76.357 153.905 76.357 C
        151.339 76.357 149.578 78.383 149.578 80.905 C 149.578 83.426 151.339
        85.453 153.905 85.453 C 156.47 85.453 158.232 83.426 158.232 80.905 Z M
        148.188 80.905 C 148.188 77.595 150.583 75.151 153.905 75.151 C 157.227
        75.151 159.623 77.595 159.623 80.905 C 159.623 84.216 157.227 86.658
        153.905 86.658 C 150.583 86.658 148.188 84.216 148.188 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 165.263 75.429 L 167.194 75.429 L 170.903 84.014 L 170.933 84.014
        L 174.673 75.429 L 176.559 75.429 L 176.559 86.379 L 175.261 86.379 L
        175.261 77.193 L 175.23 77.193 L 171.305 86.379 L 170.517 86.379 L
        166.592 77.193 L 166.561 77.193 L 166.561 86.379 L 165.263 86.379 L
        165.263 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 182.833 75.429 L 184.764 75.429 L 188.472 84.014 L 188.504 84.014
        L 192.243 75.429 L 194.128 75.429 L 194.128 86.379 L 192.83 86.379 L
        192.83 77.193 L 192.799 77.193 L 188.874 86.379 L 188.087 86.379 L
        184.161 77.193 L 184.131 77.193 L 184.131 86.379 L 182.833 86.379 L
        182.833 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 208.608 82.204 C 208.608 84.586 207.495 86.658 204.481 86.658 C
        201.468 86.658 200.356 84.586 200.356 82.204 L 200.356 75.429 L 201.654
        75.429 L 201.654 82.018 C 201.654 83.781 202.304 85.453 204.481 85.453 C
        206.661 85.453 207.309 83.781 207.309 82.018 L 207.309 75.429 L 208.608
        75.429 L 208.608 82.204 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 214.835 75.429 L 216.473 75.429 L 222.808 84.525 L 222.839 84.525
        L 222.839 75.429 L 224.137 75.429 L 224.137 86.379 L 222.499 86.379 L
        216.164 77.286 L 216.132 77.286 L 216.132 86.379 L 214.835 86.379 L
        214.835 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 230.411 75.429 L 231.71 75.429 L 231.71 86.379 L 230.411 86.379 L
        230.411 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 245.647 77.688 C 244.998 76.806 243.963 76.357 242.912 76.357 C
        240.471 76.357 238.74 78.507 238.74 80.905 C 238.74 83.442 240.456
        85.453 242.912 85.453 C 244.241 85.453 245.308 84.91 246.08 83.92 L
        247.054 84.741 C 246.08 86.056 244.658 86.658 242.912 86.658 C 239.776
        86.658 237.35 84.23 237.35 80.905 C 237.35 77.688 239.668 75.151 242.912
        75.151 C 244.396 75.151 245.849 75.662 246.775 76.868 L 245.647 77.688 Z
        "
        fill="rgb(133,132,135)" />
      <path
        d=" M 256.557 77.1 L 256.526 77.1 L 254.255 82.482 L 258.721 82.482 L
        256.557 77.1 Z M 255.97 75.429 L 257.191 75.429 L 261.842 86.379 L
        260.328 86.379 L 259.215 83.688 L 253.745 83.688 L 252.617 86.379 L
        251.149 86.379 L 255.97 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 272.767 76.636 L 269.151 76.636 L 269.151 86.379 L 267.853 86.379
        L 267.853 76.636 L 264.236 76.636 L 264.236 75.429 L 272.767 75.429 L
        272.767 76.636 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 277.851 75.429 L 279.15 75.429 L 279.15 86.379 L 277.851 86.379 L
        277.851 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 294.833 80.905 C 294.833 78.383 293.072 76.357 290.507 76.357 C
        287.941 76.357 286.18 78.383 286.18 80.905 C 286.18 83.426 287.941
        85.453 290.507 85.453 C 293.072 85.453 294.833 83.426 294.833 80.905 Z M
        284.789 80.905 C 284.789 77.595 287.184 75.151 290.507 75.151 C 293.83
        75.151 296.224 77.595 296.224 80.905 C 296.224 84.216 293.83 86.658
        290.507 86.658 C 287.184 86.658 284.789 84.216 284.789 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 301.864 75.429 L 303.503 75.429 L 309.839 84.525 L 309.869 84.525
        L 309.869 75.429 L 311.168 75.429 L 311.168 86.379 L 309.53 86.379 L
        303.193 77.286 L 303.163 77.286 L 303.163 86.379 L 301.864 86.379 L
        301.864 75.429 Z "
        fill="rgb(133,132,135)" />
    </svg>
  </a>

  <div id="backBtn">
    <svg viewBox="0 0 20 20" style="width:25px;height:25px;">
      <path
        d="M0 10c0-.9.1-1.8.4-2.6.2-.8.6-1.6 1-2.4.4-.7 1-1.4 1.6-2 .6-.6
        1.3-1.1 2-1.6.7-.4 1.5-.8 2.4-1C8.2.1 9.1 0 10 0c.9 0 1.8.1 2.6.4.8.2
        1.6.6 2.4 1 .7.4 1.4 1 2 1.6.6.6 1.1 1.3 1.6 2 .4.7.8 1.5 1 2.4.3.8.4
        1.7.4 2.6 0 .9-.1 1.8-.4 2.6-.2.8-.6 1.6-1 2.4-.4.7-1 1.4-1.6 2-.6.6-1.3
        1.1-2 1.6-.7.4-1.5.8-2.4 1-.8.3-1.7.4-2.6.4-.9
        0-1.8-.1-2.6-.4-.8-.2-1.6-.6-2.4-1-.7-.4-1.4-1-2-1.6-.6-.6-1.1-1.3-1.6-2-.4-.7-.8-1.5-1-2.4-.3-.8-.4-1.7-.4-2.6zm1.7
        0c0 .8.1 1.5.3 2.2.2.7.5 1.4.8 2 .4.6.8 1.2 1.3 1.7s1.1.9 1.7 1.3c.6.4
        1.3.6 2 .8.7.2 1.4.3 2.2.3.8 0 1.5-.1 2.2-.3.7-.2 1.4-.5 2-.8.6-.4
        1.2-.8 1.7-1.3s.9-1.1 1.3-1.7c.4-.6.6-1.3.8-2 .2-.7.3-1.4.3-2.2
        0-.8-.1-1.5-.3-2.2-.2-.7-.5-1.4-.8-2-.4-.6-.8-1.2-1.3-1.7s-1.1-.9-1.7-1.3c-.6-.4-1.3-.6-2-.8-.7-.2-1.4-.3-2.2-.3-.8
        0-1.5.1-2.2.3-.7.2-1.4.5-2 .8-.6.4-1.1.9-1.6 1.4-.5.5-1 1-1.3
        1.6-.4.7-.7 1.3-.9 2-.2.7-.3 1.4-.3 2.2zm4.2 0l4-4.2h2.9L9.7
        8.9h6.7v2.2H9.7l3.1 3.1H9.9l-4-4.2zm-.3 4.2H4V5.8h1.6v8.4z"
        fill="#4a4a4a" />
    </svg>
  </div>

  {#if window.location.pathname !== '/blog/jobs-in-der-agentur/' && window.location.pathname !== '/kontakt/'}
    <a href="/blog/jobs-in-der-agentur/" target="_self" class="burgerjobs">
      Jobs
    </a>
  {/if}

  {#if window.location.pathname !== '/kontakt/'}
    <a href="/kontakt/" target="_self" class="burgerkontakt">Kontakt</a>
  {/if}

  <div
    class="{bactive ? 'is-active' : ''} navbar-burger burger"
    on:click={btoggle}
    data-target="navMenu">
    <span />
    <span />
    <span />
  </div>
  <div class="navbar-menu {bactive ? 'nav-active' : ''} " id="navMenu">

    <div class="nav-menu-block-desktop">
      <div class="nav-menu-block-desktop-left">
        {#each links as [path, name]}
          {#if name === 'Leistungen'}
            <a
              href={ww > 1024 ? '#' : '/leistungen/'}
              class="{lactive ? 'activel' : ''}
              "
              on:click={ltoggle}
              target="_self"
              title={name}>
              {name}
              <span>{ltoggletext}</span>
              <svg
                class="nav-menu-block-desktop-left-llinie"
                viewBox="0 0 100 5"
                xmlns="http://www.w3.org/2000/svg">
                <line
                  class="nav-menu-block-desktop-left-llinie-l"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="0"
                  stroke-width="5"
                  stroke="#BE145F" />
              </svg>
            </a>
          {:else}
            <a
              href={name === 'Home' ? 'https://www.collet-cc.de/' : $url(path) + '/'}
              class:active={$isActive(path)}
              target="_self"
              title={name}>
              {name}
            </a>
          {/if}
        {/each}
      </div>
      <div class="nav-menu-block-desktop-right">

        <div style="position: relative; height: 90px">
          <svg
            id="lsvgb1"
            class="lsvgb"
            width="550"
            height="116"
            viewBox="-70 0 340 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
              class="ll1"
              x1="0"
              x2="0"
              y1="115"
              y2="115"
              stroke="white"
              stroke-width="2" />
            <line
              class="ll2"
              x1="47"
              y1="115"
              x2="47"
              y2="115"
              stroke="#ffffff"
              stroke-width="2" />
            <line
              class="ll3"
              x1="47"
              y1="6"
              x2="47"
              y2="6"
              stroke="#ffffff"
              stroke-width="2" />
            <text x="70" y="14" class="lb-text lbp13 ">
              <a href="/leistungen/markenagentur/" target="_self">
                Brand Management
              </a>
            </text>

             <text x="-175" y="42" class="lb-text lbp12 ">
              <a href="/leistungen/kampagnen-agentur/" target="_self">
                 Out Of Home-Kampagnen
              </a>
            </text>
            
            <!-- <text x="-120" y="42" class="lb-text lbp12">
              <a href="/leistungen/employer-branding-agentur/" target="_self">
                Employer Branding
              </a>
            </text> -->
            <g class="lb-text lbp11">
              <a href="/leistungen/corporate-identity-agentur/" target="_self">
                <text x="60" y="75">Corporate</text>
                <text x="60" y="95">Identity Entwicklung</text>  
              </a>
            </g>

         <g class="lbp12 lbp">
              <path
                d="M52 37C52 39.7614 49.7614 42 47 42C44.2386 42 42 39.7614 42
                37C42 34.2386 44.2386 32 47 32C49.7614 32 52 34.2386 52 37Z"
                fill="#E61974" />
              <path
                d="M51.5 37C51.5 39.4853 49.4853 41.5 47 41.5C44.5147 41.5 42.5
                39.4853 42.5 37C42.5 34.5147 44.5147 32.5 47 32.5C49.4853 32.5
                51.5 34.5147 51.5 37Z"
                stroke="white" />

            </g> 
            <g class="lbp13 lbp">
              <path
                d="M63 5C63 7.76142 60.7614 10 58 10C55.2386 10 53 7.76142 53
                5C53 2.23858 55.2386 0 58 0C60.7614 0 63 2.23858 63 5Z"
                fill="#E61974" />
              <path
                d="M62.5 5C62.5 7.48528 60.4853 9.5 58 9.5C55.5147 9.5 53.5
                7.48528 53.5 5C53.5 2.51472 55.5147 0.5 58 0.5C60.4853 0.5 62.5
                2.51472 62.5 5Z"
                stroke="white" />
            </g>
            <g class="lbp11 lbp">
              <path
                d="M52 84C52 86.7614 49.7614 89 47 89C44.2386 89 42 86.7614 42
                84C42 81.2386 44.2386 79 47 79C49.7614 79 52 81.2386 52 84Z"
                fill="#E61974" />
              <path
                d="M51.5 84C51.5 86.4853 49.4853 88.5 47 88.5C44.5147 88.5 42.5
                86.4853 42.5 84C42.5 81.5147 44.5147 79.5 47 79.5C49.4853 79.5
                51.5 81.5147 51.5 84Z"
                stroke="white" />
            </g>

          </svg>

          <svg
            id="lsvgb2"
            class="lsvgb"
            width="550"
            height="190"
            viewBox="-70 0 340 190"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
              class="ll1"
              x1="0"
              x2="0"
              y1="190"
              y2="190"
              stroke="white"
              stroke-width="2" />
            <line
              class="ll2"
              x1="47"
              y1="190"
              x2="47"
              y2="190"
              stroke="#ffffff"
              stroke-width="2" />
            <line
              class="ll3"
              x1="47"
              y1="6"
              x2="47"
              y2="6"
              stroke="#ffffff"
              stroke-width="2" />
            <text x="-90" y="14" class="lb-text lbp13 ">
              <a href="/leistungen/filmagentur/" target="_self">
                Video & Radio
              </a>
            </text>
            <text x="60" y="65" class="lb-text lbp12">
              <a href="/leistungen/social-media-marketing-agentur/" target="_self">
                Social Media Marketing
              </a>
            </text>
            <g class="lb-text lbp11">
              <text x="60" y="120">
                <a href="/leistungen/content-marketing-agentur/" target="_self">
                  Content Creation
                </a>
              </text>
            </g>

            <g class="lbp12 lbp" style="transform: translateY(20px);">
              <path
                d="M52 37C52 39.7614 49.7614 42 47 42C44.2386 42 42 39.7614 42
                37C42 34.2386 44.2386 32 47 32C49.7614 32 52 34.2386 52 37Z"
                fill="#E61974" />
              <path
                d="M51.5 37C51.5 39.4853 49.4853 41.5 47 41.5C44.5147 41.5 42.5
                39.4853 42.5 37C42.5 34.5147 44.5147 32.5 47 32.5C49.4853 32.5
                51.5 34.5147 51.5 37Z"
                stroke="white" />

            </g>
            <g class="lbp13 lbp" style="transform: translateX(-20px);">
              <path
                d="M63 5C63 7.76142 60.7614 10 58 10C55.2386 10 53 7.76142 53
                5C53 2.23858 55.2386 0 58 0C60.7614 0 63 2.23858 63 5Z"
                fill="#E61974" />
              <path
                d="M62.5 5C62.5 7.48528 60.4853 9.5 58 9.5C55.5147 9.5 53.5
                7.48528 53.5 5C53.5 2.51472 55.5147 0.5 58 0.5C60.4853 0.5 62.5
                2.51472 62.5 5Z"
                stroke="white" />
            </g>
            <g class="lbp11 lbp" style="transform: translateY(30px);">
              <path
                d="M52 84C52 86.7614 49.7614 89 47 89C44.2386 89 42 86.7614 42
                84C42 81.2386 44.2386 79 47 79C49.7614 79 52 81.2386 52 84Z"
                fill="#E61974" />
              <path
                d="M51.5 84C51.5 86.4853 49.4853 88.5 47 88.5C44.5147 88.5 42.5
                86.4853 42.5 84C42.5 81.5147 44.5147 79.5 47 79.5C49.4853 79.5
                51.5 81.5147 51.5 84Z"
                stroke="white" />
            </g>

          </svg>

          <svg
            id="lsvgb3"
            class="lsvgb"
            width="550"
            height="240"
            viewBox="-70 0 340 240"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
              class="ll1"
              x1="0"
              x2="0"
              y1="240"
              y2="240"
              stroke="white"
              stroke-width="2" />
            <line
              class="ll2"
              x1="117"
              y1="240"
              x2="117"
              y2="240"
              stroke="#ffffff"
              stroke-width="2" />
            <line
              class="ll3"
              x1="117"
              y1="6"
              x2="77"
              y2="6"
              stroke="#ffffff"
              stroke-width="2" />
            <text
              x="70"
              y="14"
              class="lb-text lbp13"
              style="transform: translateX(-155px);">
              <a href="/leistungen/webentwicklung-agentur/" target="_self">
                Webentwicklung
              </a>
            </text>

             

              <text
              x="52"
              y="42"
              class="lb-text lbp12"
              style="transform: translate(5px, 25px);">
              <a href="/leistungen/ui-ux/" target="_self">
                UI/UX
              </a>
            </text>
            <!-- <text
              x="-95"
              y="42"
              class="lb-text lbp12"
              style="transform: translate(5px, 25px);">
              <a href="/leistungen/sea-agentur/" target="_self">
                Suchmaschinenwerbung
              </a>
            </text>
            <g class="lb-text lbp11" style="transform: translate(-82px, 50px);">

              <a href="/leistungen/seo-werbeagentur/" target="_self">
                <text x="55" y="75">Suchmaschinen-</text>
                <text x="80" y="95">optimierung</text>

              </a>

            </g> -->

            <g class="lbp12 lbp" style="transform: translate(70px, 25px);">
              <path
                d="M52 37C52 39.7614 49.7614 42 47 42C44.2386 42 42 39.7614 42
                37C42 34.2386 44.2386 32 47 32C49.7614 32 52 34.2386 52 37Z"
                fill="#E61974" />
              <path
                d="M51.5 37C51.5 39.4853 49.4853 41.5 47 41.5C44.5147 41.5 42.5
                39.4853 42.5 37C42.5 34.5147 44.5147 32.5 47 32.5C49.4853 32.5
                51.5 34.5147 51.5 37Z"
                stroke="white" />

            </g>
            <g class="lbp13 lbp">
              <path
                d="M63 5C63 7.76142 60.7614 10 58 10C55.2386 10 53 7.76142 53
                5C53 2.23858 55.2386 0 58 0C60.7614 0 63 2.23858 63 5Z"
                fill="#E61974" />
              <path
                d="M62.5 5C62.5 7.48528 60.4853 9.5 58 9.5C55.5147 9.5 53.5
                7.48528 53.5 5C53.5 2.51472 55.5147 0.5 58 0.5C60.4853 0.5 62.5
                2.51472 62.5 5Z"
                stroke="white" />
            </g>
           <!--  <g class="lbp11 lbp" style="transform: translate(70px, 35px);">
              <path
                d="M52 84C52 86.7614 49.7614 89 47 89C44.2386 89 42 86.7614 42
                84C42 81.2386 44.2386 79 47 79C49.7614 79 52 81.2386 52 84Z"
                fill="#E61974" />
              <path
                d="M51.5 84C51.5 86.4853 49.4853 88.5 47 88.5C44.5147 88.5 42.5
                86.4853 42.5 84C42.5 81.5147 44.5147 79.5 47 79.5C49.4853 79.5
                51.5 81.5147 51.5 84Z"
                stroke="white" />
            </g> -->

          </svg>

          <!-- <svg
            id="lsvgb4"
            class="lsvgb"
            width="550"
            height="290"
            viewBox="-70 0 340 290"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <line
              class="ll1"
              x1="-180"
              x2="-180"
              y1="290"
              y2="290"
              stroke="white"
              stroke-width="2" />
            <line
              class="ll2"
              x1="47"
              y1="290"
              x2="47"
              y2="290"
              stroke="#ffffff"
              stroke-width="2" />
            <line
              class="ll3"
              x1="47"
              y1="6"
              x2="47"
              y2="6"
              stroke="#ffffff"
              stroke-width="2" />
            <text x="70" y="14" class="lb-text lbp13 ">
              <a href="/leistungen/webdesign-guetersloh/" target="_self">
                Webdesign
              </a>
            </text>
            <text
              x="-135"
              y="42"
              class="lb-text lbp12"
              style="transform: translate(30px);">
              <a href="/leistungen/corporate-design-agentur/" target="_self">
                Corporate Design
              </a>
            </text>
            <g class="lb-text lbp11" style="transform: translateY(50px);">
              <a
                href="/leistungen/corporate-publishing-agentur/"
                target="_self">
                <text x="60" y="75">Corporate Publishing</text>
                <text x="60" y="95">& Editorial Design</text>
              </a>
            </g>

            <g class="lb-text lbp14" style="transform: translateY(50px);">
              <a href="/leistungen/kampagnen-agentur/" target="_self">
                <text x="60" y="155">Anzeigen-</text>
                <text x="60" y="175">und Plakatkampagnen</text>
              </a>
            </g>

            <g class="lbp12 lbp" style="transform: translateY(40px, 30px);">
              <path
                d="M52 37C52 39.7614 49.7614 42 47 42C44.2386 42 42 39.7614 42
                37C42 34.2386 44.2386 32 47 32C49.7614 32 52 34.2386 52 37Z"
                fill="#E61974" />
              <path
                d="M51.5 37C51.5 39.4853 49.4853 41.5 47 41.5C44.5147 41.5 42.5
                39.4853 42.5 37C42.5 34.5147 44.5147 32.5 47 32.5C49.4853 32.5
                51.5 34.5147 51.5 37Z"
                stroke="white" />

            </g>
            <g class="lbp13 lbp">
              <path
                d="M63 5C63 7.76142 60.7614 10 58 10C55.2386 10 53 7.76142 53
                5C53 2.23858 55.2386 0 58 0C60.7614 0 63 2.23858 63 5Z"
                fill="#E61974" />
              <path
                d="M62.5 5C62.5 7.48528 60.4853 9.5 58 9.5C55.5147 9.5 53.5
                7.48528 53.5 5C53.5 2.51472 55.5147 0.5 58 0.5C60.4853 0.5 62.5
                2.51472 62.5 5Z"
                stroke="white" />
            </g>
            <g class="lbp11 lbp" style="transform: translateY(50px);">
              <path
                d="M52 84C52 86.7614 49.7614 89 47 89C44.2386 89 42 86.7614 42
                84C42 81.2386 44.2386 79 47 79C49.7614 79 52 81.2386 52 84Z"
                fill="#E61974" />
              <path
                d="M51.5 84C51.5 86.4853 49.4853 88.5 47 88.5C44.5147 88.5 42.5
                86.4853 42.5 84C42.5 81.5147 44.5147 79.5 47 79.5C49.4853 79.5
                51.5 81.5147 51.5 84Z"
                stroke="white" />
            </g>

            <g class="lbp11 lbp" style="transform: translateY(130px);">
              <path
                d="M52 84C52 86.7614 49.7614 89 47 89C44.2386 89 42 86.7614 42
                84C42 81.2386 44.2386 79 47 79C49.7614 79 52 81.2386 52 84Z"
                fill="#E61974" />
              <path
                d="M51.5 84C51.5 86.4853 49.4853 88.5 47 88.5C44.5147 88.5 42.5
                86.4853 42.5 84C42.5 81.5147 44.5147 79.5 47 79.5C49.4853 79.5
                51.5 81.5147 51.5 84Z"
                stroke="white" />
            </g>

          </svg> -->

        </div> 

        <p
          class="ll_link {actl === 1 ? 'll_link_active' : ''}"
          on:click={() => act(1, 0)}>
          MARKENFÜHRUNG
        </p>
        <p
          class="ll_link {actl === 2 ? 'll_link_active' : ''}"
          on:click={() => act(2, 0)}>
          CONTENT MARKETING
        </p>
        <p
          class="ll_link {actl === 3 ? 'll_link_active' : ''}"
          on:click={() => act(3, 0)}>
          DIGITALE KOMMUNIKATION
        </p>
        <!-- <p
          class="ll_link {actl === 4 ? 'll_link_active' : ''}"
          on:click={() => act(4, 0)}>
          DESIGN
        </p> -->

        <!-- <p
          class="ll_link"
          on:click={() => {
            location.href = 'https://www.collet-cc.de/leistungen/kommunikationsagentur/';
          }}>
          KOMMUNIKATION
        </p> -->

        <p style="margin-top: 50px">
          <a href="/leistungen/" id="navleistungen" target="_self" aria-label="leistungen">
            <svg
              width="287"
              height="16"
              viewBox="0 0 287 16"
              title="leistungen"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_iaaa)">
                <path
                  d="M10.3691 12.3438H5.82812L4.56836 15H0.886719L8.41602
                  0.78125H11.5605L14.1875 15H10.7109L10.3691 12.3438ZM7.07812
                  9.69727H10.0273L9.40234 4.79492L7.07812 9.69727ZM19.1387
                  12.3633H24.9297L24.4707 15H15.3398L17.8008
                  0.78125H21.1504L19.1387 12.3633ZM29.7637
                  12.3633H35.5547L35.0957 15H25.9648L28.4258
                  0.78125H31.7754L29.7637 12.3633ZM46.209
                  8.97461H40.9844L40.3887 12.3633H46.5703L46.1113
                  15H36.5898L39.0508 0.78125H48.5918L48.1328
                  3.42773H41.9414L41.4141 6.42578H46.6484L46.209
                  8.97461ZM64.0312 15H60.7109L61.7363 9.0625H56.8633L55.8281
                  15H52.4883L54.9492 0.78125H58.2988L57.3125
                  6.42578H62.1953L63.1719 0.78125H66.502L64.0312 15ZM74.7441
                  12.3438H70.2031L68.9434 15H65.2617L72.791
                  0.78125H75.9355L78.5625 15H75.0859L74.7441 12.3438ZM71.4531
                  9.69727H74.4023L73.7773 4.79492L71.4531 9.69727ZM83.5137
                  12.3633H89.3047L88.8457 15H79.7148L82.1758
                  0.78125H85.5254L83.5137 12.3633ZM101.18
                  3.42773H97.0391L95.0371 15H91.6875L93.6992
                  3.42773H89.627L90.0957 0.78125H101.639L101.18 3.42773ZM110.271
                  8.97461H105.047L104.451 12.3633H110.633L110.174
                  15H100.652L103.113 0.78125H112.654L112.195
                  3.42773H106.004L105.477 6.42578H110.711L110.271
                  8.97461ZM119.422 11.1523C119.565 10.4167 119.168 9.84701
                  118.23 9.44336L116.629 8.7793C114.383 7.77018 113.315 6.39648
                  113.426 4.6582C113.471 3.86393 113.745 3.15755 114.246
                  2.53906C114.747 1.91406 115.441 1.42904 116.326
                  1.08398C117.212 0.738932 118.178 0.572917 119.227
                  0.585938C120.633 0.611979 121.762 1.02214 122.615
                  1.81641C123.475 2.61068 123.898 3.67513 123.885
                  5.00977H120.564C120.584 4.46289 120.467 4.02669 120.213
                  3.70117C119.965 3.36914 119.555 3.19661 118.982
                  3.18359C118.423 3.17057 117.928 3.30078 117.498
                  3.57422C117.068 3.84766 116.814 4.21549 116.736
                  4.67773C116.613 5.32878 117.094 5.86263 118.182 6.2793C119.275
                  6.69596 120.07 7.05404 120.564 7.35352C122.12 8.28451 122.85
                  9.54102 122.752 11.123C122.7 11.9434 122.436 12.6628 121.961
                  13.2812C121.486 13.8997 120.825 14.3783 119.979
                  14.7168C119.139 15.0553 118.195 15.2181 117.146
                  15.2051C116.378 15.1921 115.665 15.0716 115.008 14.8438C114.35
                  14.6159 113.787 14.2839 113.318 13.8477C112.368 12.9688
                  111.912 11.8132 111.951 10.3809L115.301 10.3906C115.262
                  11.1198 115.402 11.6732 115.721 12.0508C116.04 12.4219 116.577
                  12.6074 117.332 12.6074C117.892 12.6074 118.357 12.4805
                  118.729 12.2266C119.106 11.9661 119.337 11.6081 119.422
                  11.1523ZM136.707 3.42773H132.566L130.564 15H127.215L129.227
                  3.42773H125.154L125.623 0.78125H137.166L136.707
                  3.42773ZM145.799 8.97461H140.574L139.979
                  12.3633H146.16L145.701 15H136.18L138.641
                  0.78125H148.182L147.723 3.42773H141.531L141.004
                  6.42578H146.238L145.799 8.97461ZM150.936
                  12.3633H156.727L156.268 15H147.137L149.598
                  0.78125H152.947L150.936 12.3633ZM161.561
                  12.3633H167.352L166.893 15H157.762L160.223
                  0.78125H163.572L161.561 12.3633ZM178.006
                  8.97461H172.781L172.186 12.3633H178.367L177.908
                  15H168.387L170.848 0.78125H180.389L179.93
                  3.42773H173.738L173.211 6.42578H178.445L178.006
                  8.97461ZM190.877 15H187.645L184.246 6.02539L182.684
                  15H179.344L181.805 0.78125H185.066L188.455 9.75586L190.008
                  0.78125H193.348L190.877 15ZM206.365 12.3438H201.824L200.564
                  15H196.883L204.412 0.78125H207.557L210.184 15H206.707L206.365
                  12.3438ZM203.074 9.69727H206.023L205.398 4.79492L203.074
                  9.69727ZM222.869 15H219.637L216.238 6.02539L214.676
                  15H211.336L213.797 0.78125H217.059L220.447 9.75586L222
                  0.78125H225.34L222.869 15ZM232.879 11.1523C233.022 10.4167
                  232.625 9.84701 231.688 9.44336L230.086 8.7793C227.84 7.77018
                  226.772 6.39648 226.883 4.6582C226.928 3.86393 227.202 3.15755
                  227.703 2.53906C228.204 1.91406 228.898 1.42904 229.783
                  1.08398C230.669 0.738932 231.635 0.572917 232.684
                  0.585938C234.09 0.611979 235.219 1.02214 236.072
                  1.81641C236.932 2.61068 237.355 3.67513 237.342
                  5.00977H234.021C234.041 4.46289 233.924 4.02669 233.67
                  3.70117C233.423 3.36914 233.012 3.19661 232.439 3.18359C231.88
                  3.17057 231.385 3.30078 230.955 3.57422C230.525 3.84766
                  230.271 4.21549 230.193 4.67773C230.07 5.32878 230.551 5.86263
                  231.639 6.2793C232.732 6.69596 233.527 7.05404 234.021
                  7.35352C235.577 8.28451 236.307 9.54102 236.209 11.123C236.157
                  11.9434 235.893 12.6628 235.418 13.2812C234.943 13.8997
                  234.282 14.3783 233.436 14.7168C232.596 15.0553 231.652
                  15.2181 230.604 15.2051C229.835 15.1921 229.122 15.0716
                  228.465 14.8438C227.807 14.6159 227.244 14.2839 226.775
                  13.8477C225.825 12.9688 225.369 11.8132 225.408
                  10.3809L228.758 10.3906C228.719 11.1198 228.859 11.6732
                  229.178 12.0508C229.497 12.4219 230.034 12.6074 230.789
                  12.6074C231.349 12.6074 231.814 12.4805 232.186
                  12.2266C232.563 11.9661 232.794 11.6081 232.879
                  11.1523ZM246.932 8.97461H241.707L241.111
                  12.3633H247.293L246.834 15H237.312L239.773
                  0.78125H249.314L248.855 3.42773H242.664L242.137
                  6.42578H247.371L246.932 8.97461ZM259.812 15H256.492L257.518
                  9.0625H252.645L251.609 15H248.27L250.73 0.78125H254.08L253.094
                  6.42578H257.977L258.953 0.78125H262.283L259.812 15ZM271.619
                  8.97461H266.395L265.799 12.3633H271.98L271.521 15H262L264.461
                  0.78125H274.002L273.543 3.42773H267.352L266.824
                  6.42578H272.059L271.619 8.97461ZM284.49 15H281.258L277.859
                  6.02539L276.297 15H272.957L275.418 0.78125H278.68L282.068
                  9.75586L283.621 0.78125H286.961L284.49 15Z"
                  fill="#BE145F" />
              </g>
              <defs>
                <filter
                  id="filter0_iaaa"
                  x="0.886719"
                  y="0.585205"
                  width="286.074"
                  height="18.6206"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow" />
                </filter>
              </defs>
            </svg>
          </a>
        </p>

      </div>
    </div>
    {#if ww >= 1024}

    <div id="headerawards">

     {#each awards as aw}
              {#if aw.link !== ''}
                <a href={aw.link} target="_self">

                  <img
                    class="awardsImage"
                    alt={aw.image.description !== '' ? aw.image.description : aw.title}
                    src="{Prodhost}/colletadmin/storage/uploads/{aw.image.path}"
                  />
                </a>
              {:else}
                <div>
                  <img
                    class="awardsImage"
                    alt={aw.image.description !== '' ? aw.image.description : aw.title}
                    src="{Prodhost}/colletadmin/storage/uploads/{aw.image.path}"
                  />
                </div>
              {/if}
            {/each}

    </div>

      <!-- <div id="awww-nominee">
      <a    target="self"
          href="/"
          title="awwwards" >&nbsp;</a>
      
      </div>

      <div id="gba-nominee">
      <a    target="self"
          href="/"
          title="german-brand-award" >&nbsp;</a>
      
      </div>
      <div id="da2021-nominee">
        <a
          target="self"
          href="/blog/collet-deutscher-agenturpreis-2021-gewonnen/"
          title="deutscher-agenturpreis-2021">
          &nbsp;
        </a>
      </div>
      <div id="cssda-nominee">
        <a
          target="self"
          href="/blog/collet-gewinnt-css-design-award/"
          title="css-design-award">
          &nbsp;
        </a>
      </div>
      <div id="da2020-nominee">
        <a
          target="self"
          href="/blog/collet-deutscher-agenturpreis-2020-zwei-mal-gewonnen/"
          title="deutscher-agenturpreis-2020">
          &nbsp;
        </a>
      </div> -->
    {/if}
    <div id="navinfo">
  
      <span>
        {#if ww >= 1024}
          <a href="/impressum/" target="_self" class="infolink">
            Impressum & Datenschutz
          </a>
          |
          <a href="tel:05241 50 56 664" class="infolink callForm">
            05241 50 56 664
          </a>
          |
          <a href="mailto:info@collet-cc.de" class="infolink mailForm">
            info@collet-cc.de
          </a>
          |
       
        {/if}
        <a
          href="https://www.linkedin.com/company/collet-concepts-communication "
          target="_blank"
          rel="noopener"
          title="Collet LinkedIn">

          <svg
            width="100%"
            height="100%"
            viewBox="0 0 658 663"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;
            width: 17px; height: 17px;">
            <title>Collet LinkedIn</title>
            <rect
              id="ArtBoard1"
              x="0"
              y="0"
              width="657.677"
              height="662.067"
              style="fill:none;" />
            <clipPath id="_clip1LinkedIn">
              <rect x="0" y="0" width="657.677" height="662.067" />
            </clipPath>
            <g clip-path="url(#_clip1LinkedIn)">
              <path
                d="M0.019,47.643c-0,-26.177 21.775,-47.422
                48.62,-47.422l560.592,-0c26.854,-0 48.62,21.245
                48.62,47.422l0,567.164c0,26.186 -21.766,47.414
                -48.62,47.414l-560.592,-0c-26.845,-0 -48.62,-21.228
                -48.62,-47.405l-0,-567.182l-0,0.009Z"
                fill="#fff" />
              <path
                d="M199.431,554.379l0,-298.928l-99.359,0l0,298.928l99.368,0l-0.009,0Zm-49.662,-339.734c34.642,-0
                56.209,-22.956 56.209,-51.642c-0.652,-29.337 -21.567,-51.65
                -55.549,-51.65c-34.008,-0 -56.217,22.313 -56.217,51.65c-0,28.686
                21.558,51.642
                54.906,51.642l0.651,-0Zm104.655,339.734l99.351,0l-0,-166.915c-0,-8.925
                0.651,-17.868 3.273,-24.241c7.18,-17.859 23.529,-36.343
                50.982,-36.343c35.944,-0 50.33,27.409
                50.33,67.599l0,159.9l99.351,0l-0,-171.395c-0,-91.814
                -49.011,-134.539 -114.38,-134.539c-53.595,-0 -77.132,29.953
                -90.208,50.356l0.66,0l0,-43.341l-99.35,-0c1.294,28.043
                -0.009,298.928 -0.009,298.928l0,-0.009Z"
                style="fill: rgb(230, 25, 116);fill-rule:nonzero;" />
            </g>
          </svg>
        </a>
        <a
          href="https://www.xing.com/companies/colletconcepts%7Ccommunication"
          target="_blank"
          rel="noopener"
          title="Collet Xing">
          <svg
            aria-hidden="true"
            viewBox="0 0 384 512"
            style=" width: 17px; height: 17px;">
            <path
              d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7
              12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1
              0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18
              12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2
              14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8
              51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3
              6.7 8.5 14.1z" />
          </svg>
        </a>

   

        <a
          href="https://www.instagram.com/colletconcepts/"
          target="_blank"
          rel="noopener"
          title="Collet Instagram">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 17"
            class="w-6 h-6 mr-2 ">
            <title>Collet Instagram</title>
            <path
              d="M13.6683 4.78509C13.6683 4.90669 13.6443 5.02711 13.5978
              5.13945C13.5513 5.2518 13.483 5.35388 13.3971 5.43987C13.3111
              5.52586 13.209 5.59406 13.0966 5.6406C12.9843 5.68714 12.8639
              5.71109 12.7423 5.71109C12.6207 5.71109 12.5003 5.68714 12.3879
              5.6406C12.2756 5.59406 12.1735 5.52586 12.0875 5.43987C12.0015
              5.35388 11.9333 5.2518 11.8868 5.13945C11.8402 5.02711 11.8163
              4.90669 11.8163 4.78509C11.8163 4.5395 11.9138 4.30397 12.0875
              4.13031C12.2612 3.95665 12.4967 3.85909 12.7423 3.85909C12.9879
              3.85909 13.2234 3.95665 13.3971 4.13031C13.5707 4.30397 13.6683
              4.5395 13.6683 4.78509ZM16.6083 8.96509V8.97709L16.5553
              12.3331C16.5418 13.5434 16.055 14.7004 15.1992 15.5563C14.3435
              16.4123 13.1866 16.8993 11.9763 16.9131L8.60828
              16.9651H8.59628L5.24028 16.9121C4.02995 16.8986 2.87299 16.4118
              2.01702 15.5561C1.16104 14.7003 0.674051 13.5434 0.660276
              12.3331L0.608276 8.96509V8.95309L0.661276 5.59709C0.674786 4.38676
              1.16152 3.2298 2.01731 2.37383C2.8731 1.51785 4.02995 1.03086
              5.24028 1.01709L8.60828 0.965088H8.62028L11.9763 1.01809C13.1866
              1.0316 14.3436 1.51833 15.1995 2.37412C16.0555 3.22991 16.5425
              4.38676 16.5563 5.59709L16.6083 8.96509ZM15.1183 8.96509L15.0663
              5.62009C15.0569 4.79737 14.7259 4.01099 14.1442 3.4292C13.5624
              2.84742 12.776 2.51644 11.9533 2.50709L8.60828 2.45509L5.26328
              2.50709C4.44056 2.51644 3.65418 2.84742 3.07239 3.4292C2.49061
              4.01099 2.15963 4.79737 2.15028 5.62009L2.09828 8.96509L2.15028
              12.3101C2.15963 13.1328 2.49061 13.9192 3.07239 14.501C3.65418
              15.0828 4.44056 15.4137 5.26328 15.4231L8.60828 15.4751L11.9533
              15.4231C12.776 15.4137 13.5624 15.0828 14.1442 14.501C14.7259
              13.9192 15.0569 13.1328 15.0663 12.3101L15.1183 8.96509ZM12.7163
              8.96509C12.7163 10.0546 12.2835 11.0995 11.5131 11.8699C10.7427
              12.6403 9.69779 13.0731 8.60828 13.0731C7.51877 13.0731 6.47388
              12.6403 5.70348 11.8699C4.93308 11.0995 4.50028 10.0546 4.50028
              8.96509C4.50028 7.87558 4.93308 6.83069 5.70348 6.06029C6.47388
              5.28989 7.51877 4.85709 8.60828 4.85709C9.69779 4.85709 10.7427
              5.28989 11.5131 6.06029C12.2835 6.83069 12.7163 7.87558 12.7163
              8.96509ZM11.2263 8.96509C11.2263 8.27075 10.9505 7.60485 10.4595
              7.11388C9.96851 6.62291 9.30261 6.34709 8.60828 6.34709C7.91394
              6.34709 7.24804 6.62291 6.75707 7.11388C6.2661 7.60485 5.99028
              8.27075 5.99028 8.96509C5.99028 9.65942 6.2661 10.3253 6.75707
              10.8163C7.24804 11.3073 7.91394 11.5831 8.60828 11.5831C9.30261
              11.5831 9.96851 11.3073 10.4595 10.8163C10.9505 10.3253 11.2263
              9.65942 11.2263 8.96509Z"
              fill="#fff" />
          </svg>
        </a>

      </span>

        {#if ww < 1024}
  

             <div class="mobilnavinfo">
          <a href="/impressum/" target="_self" class="infolink">
            Impressum & Datenschutz
          </a>
          <a href="tel:05241 50 56 664" class="infolink callForm">
           Tel: 05241 50 56 664
          </a>
          <a href="mailto:info@collet-cc.de" class="infolink mailForm">
           eMail: info@collet-cc.de
          </a>
     
     </div>
        {/if}
    </div>

  </div>

</nav>

<svg class="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
  <path class="shape-overlays__path" />
  <path class="shape-overlays__path" />
  <path class="shape-overlays__path" />
</svg>

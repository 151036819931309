<script>
  import Prodhost from "./host.js";
  import Switch from "./Switch.svelte";

  let checked = false;

  let acceptTerms1 = false;
  let acceptTerms2 = false;
  let acceptTerms3 = false;
  let acceptTerms4 = false;

  let help = "";

  let sub = 0;

  let form_name = "";
  let form_email = "";
  let form_message = "";
  let form_tel = "";

  let ds = "Nein";

  let form_name_error = false;
  let form_message_error = false;
  let form_email_error = false;
  let form_tel_error = false;

  function ValidateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  function validate() {
    if (
      ValidateEmail(form_email) === true &&
      form_name !== "" &&
      form_message !== ""
    ) {
      if (checked !== true) {
        return false;
      } else {
        ds = "Ja";
      }

      window.lintrk('track', { conversion_id: 7418572 });

      if (acceptTerms1 === true) {
        help += "DigitaleKommunikation | ";
      }
      if (acceptTerms2 === true) {
        help += "Markenführung | ";
      }
      if (acceptTerms3 === true) {
        help += "Content Marketing | ";
      }
      if (acceptTerms4 === true) {
        help += "Design";
      }

      fetch(
        Prodhost +
          "/colletadmin/api/forms/submit/kontakt?token=858a578118582b1e68c0b557022355",
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            form: {
              Name: form_name,
              Email: form_email,
              Message: form_message,
              Telefon: form_tel,
              "Wobei können wir Dir helfen? ": help,
              "Datenschutzerklärung akzeptiert ": ds
            }
          })
        }
      )
        .then(entry => entry.json())
        .then(entry => {
          window.location.href='https://collet-cc.de/kontakt-vielen-dank/'
          sub = 1;
        });
    } else {
      if (ValidateEmail(form_email) === false) {
        form_email_error = true;
      } else {
        form_email_error = false;
      }

      if (form_name === "") {
        form_name_error = true;
      } else {
        form_name_error = false;
      }


      if (form_message === "") {
        form_message_error = true;
      } else {
        form_message_error = false;
      }
    }
  }
</script>

<style>
  #kontaktForm .form_error {
    /* border: 1px solid #f00 !important; */
    background: #ffdbdb !important;
  }

  #kontaktForm input,
  #kontaktForm textarea {
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.7);
    padding: 8px 15px;
    border: none;
    margin: 0 0 8px 0;
    color: #000;
    font-size: 16px;
  }

  #kontaktForm ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #2a292d !important;
    opacity: 1; /* Firefox */
    font-size: 16px;
  }

  #kontaktForm :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #2a292d !important;
    font-size: 16px;
  }

  #kontaktForm ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #2a292d !important;
    font-size: 16px;
  }

  #kontaktForm button {
    border: none;
    background: #fff;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 16px;
    color: #156f7b;
    font-weight: 700;
    cursor: pointer;
    margin-top: 10px;
  }

  #kontaktForm button:hover {
    background: #e61974;
    color: #fff;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-left: calc(2rem / -2);
    margin-right: calc(2rem / -2);
  }

  .col-4 {
    flex: 0 0 calc((100% / (12 / 4)) - 2rem);
    max-width: calc((100% / (12 / 4)) - 2rem);
  }

  .col {
    flex: 1;
  }
</style>

<div style="z-index: 100000; position: relative;">
  {#if sub === 1}
    <h3 style="color: #fff; margin: 20px; font-weight: 700; font-size: 18px;">
      Post für uns: Cool, dass Du uns geschrieben hast.
      <br />
      Wir melden uns in Kürze bei Dir.
    </h3>
  {:else}
    <div>
      <p style="margin: 20px 0 10px 0; font-weight: 700">
        Schreib uns Deinen Liebesbrief oder warum Du mit uns arbeiten möchtest.
        <br />
        Wir freuen uns - so oder so.
      </p>
    </div>
    <form id="kontaktForm" name="termin" on:submit|preventDefault={validate}>
      <p>
        <input
          class={form_name_error === true ? 'form_error' : ''}
          id="name"
          name="form[name]"
          placeholder="Name*"
          type="text"
          bind:value={form_name}
          required />
      </p>
      <p>

        <input
          class={form_email_error === true ? 'form_error' : ''}
          id="email"
          name="form[email]"
          type="email"
          placeholder="Email*"
          bind:value={form_email}
          required />
      </p>

      <p>
        <input
          class={form_tel_error === true ? 'form_error' : ''}
          id="tel"
          name="form[tel]"
          type="tel"
          placeholder="Telefonnummer"
          bind:value={form_tel}
           />
      </p>

      <p style="margin: 10px 0; font-weight: 700">
        Wobei können wir Dir helfen?
      </p>

      <div class="row">
        <div class="col col-4">
          <Switch
            bind:checked={acceptTerms1}
            id={'cb_dk'}
            text={'Digitale Kommunikation'} />

        </div>
        <div class="col col-4">
          <Switch
            bind:checked={acceptTerms2}
            id={'cb_mf'}
            text={'Markenführung'} />

        </div>
        <div class="col col-4">
          <Switch
            bind:checked={acceptTerms3}
            id={'cb_cm'}
            text={'Content Marketing'} />

        </div>
        <div class="col col-4">
          <Switch bind:checked={acceptTerms4} id={'cb_ds'} text={'Design'} />

        </div>

      </div>

      <p style="margin-top: 80px;">
        <textarea
          class={form_message_error === true ? 'form_error' : ''}
          id="message"
          name="form[message]"
          placeholder="Nachricht"
          bind:value={form_message} />

      </p>
      <p style=" font-size: 12px;">
        <input
          id="ihd"
          type="checkbox"
          style="width: 50px; position: relative; top: 2px; font-size: 12px;"
          bind:checked />
        Ich habe die
        <a
          href="https://www.collet-cc.de/impressum/"
          style="color:#fff; font-size: 12px; font-weight: 700">
          Datenschutzerklärung
        </a>
        <label for="ihd">gelesen und akzeptiere sie.</label>

      </p>
      <p>
        <button class="sendForm" type="submit" disable>Nachricht senden</button>
      </p>
    </form>
  {/if}
</div>

<script>
  import Prodhost from "../../components/host.js";

  import {  onDestroy  } from "svelte";

  import { slide } from "svelte/transition";
  import { params } from "@sveltech/routify";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import Lpunkt from "../../components/Lpunkt.svelte";

  import Mwelle from "../../components/svg/mwelle.svelte";
  import Mwelle2 from "../../components/svg/mwelle2.svelte";
  import anime from "animejs";
  import ScrollMagic from "scrollmagic";
  import isMobile from "../../components/isMobile.js";

  import BackButton from "../../components/BackButton.svelte";


  import { Swiper, Navigation } from "swiper/js/swiper.esm.js";

  import SimpleLightbox from "simple-lightbox";

  import { noindex } from './../../global.js';
  import { get } from 'svelte/store';
  
  function ifSafari() {
    var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (iOS) {
      return true;
    }
  }

  function faqstylenorm(i) {}

  // Install modules
  Swiper.use([Navigation]);

  import { onMount } from "svelte";

  let leistungen = [];

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  // let isOpen = [];
  // isOpen[0] = false;
  // isOpen[1001] = false;

  var isOpen = 0;
  var isOpenFaq = 0;

  if (ww < 1024) {
    isOpenFaq = 1000;
  }

  function toggle(x) {
    isOpen[x] = !isOpen[x];
  }

  var ljson = [];
  var ljson_length = 0;

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function openTab(tabName) {
    var i;
    var x = document.getElementsByClassName("tabtext");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }

    document.getElementById(tabName).style.display = "block";
  }

  async function hashchange() {
    await fetch(
      Prodhost + "/colletadmin/api/collections/get/onepageleistungen",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: { slug: $params.slug, published: true }
        })
      }
    )
      .then(res => res.json())
      .then(res => {
        leistungen = res.entries[0];

        if (leistungen.noindex){
           noindex.set(true);       
        }
      });
  }

  var anim1 = "";
  var anim2 = "";

  function handleClick(x) {
    anime({
      targets: "#llineanim .lpunkt2 .lpunkthover2",
      opacity: 0,
      duration: 300,
      easing: "linear",
      loop: false
    });

    anime({
      targets: ".lpunkt  .pltext",
      opacity: 0,
      duration: 1000,
      easing: "linear",
      loop: false
    });

    anime({
      targets: ".lpunkt" + x + " .pltext",
      opacity: 1,
      duration: 1000,
      easing: "linear",
      loop: false
    });

    anime({
      targets: ".lpunkt .pltitle  text",
      fill: "#E61974",
      duration: 0,
      easing: "linear",
      loop: false
    });

    anime({
      targets: ".lpunkt .pltitle rect",
      fill: "#ffffff",
      duration: 0,
      easing: "linear",
      loop: false
    });

    anime({
      targets: ".lpunkt" + x + " .pltitle  text",
      fill: "#ffffff",
      duration: 1000,
      easing: "linear",
      delay: 50,
      loop: false
    });

    anime({
      targets: ".lpunkt" + x + " .pltitle rect",
      fill: "#E61974",
      duration: 1000,
      easing: "linear",
      delay: 50,
      loop: false
    });
  }

  onMount(() => {
    hashchange();

    anime({
      targets: ".lplus-f svg path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 3000,
      delay: function(el, i) {
        return i * 250;
      },
      direction: "alternate",
      loop: true
    });




  	
    const el = document.querySelector(".contentmain");
    if (ww > 960 && !isMobile()) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          // if (e.deltaY < 5) {
          //   sp = sp * 20;
          // } else {
          //   sp = sp * 12;
          // }
          sp = sp * 2;
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }

    setTimeout(() => {
      var swiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        slidesPerView: 1,
        speed: 700,
        loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }, 700);

    var controller;

    // ljson_length = leistungen.leistungen.length;
    // ljson_length = 3;

    if (ww >= 1024) {
      setTimeout(() => {
        ljson_length = leistungen.leistungen.length;

        document.getElementById("llineanimblock").style.maxWidth =
          550 * ljson_length + "px";

        document.getElementById("llineanimblock").style.width =
          550 * ljson_length + "px";
        controller = new ScrollMagic.Controller({
          globalSceneOptions: {
            reverse: false
          },
          vertical: false
        });

        var scene = new ScrollMagic.Scene({
          triggerElement: "#llineanim",
          duration: ww,
          triggerHook: 0.7
        })
          .on("enter", function() {
            document
              .getElementById("llineanim")
              .classList.add("llineanimactive");
            anime({
              targets: "#llineanim .collet-c",
              rotate: ["180deg", "0deg"],
              duration: 500,
              easing: "linear",
              loop: false,
              complete: function(anim) {
                anime({
                  targets: "#llineanim .lline",
                  x2: [280, 420 * ljson_length],
                  duration: 1000,
                  easing: "linear",
                  loop: false,
                  complete: function() {
                    if (!ifSafari()) {
                      anime({
                        targets: "#llineanim .llineend",
                        opacity: 1,
                        duration: 1000,
                        easing: "linear",
                        loop: false
                      });
                    }
                  }
                });

                for (let index = 0; index < ljson_length; index++) {
                  var ll = 300 * (index + 1);
                  anime({
                    targets: "#llineanim .lpunkt" + (index + 1),
                    translateX: [0, ll],
                    duration: 700 * index,
                    easing: "linear",
                    loop: false,
                    complete: function(anim) {
                      var ly2 = 640;
                      if ((index + 1) % 2 === 0) {
                        ly2 = 560;
                      }
                      anime({
                        targets:
                          "#llineanim .lpunkt" + (index + 1) + " .lineud",
                        y2: [600, ly2],
                        x2: [250, 270],
                        duration: 500,
                        easing: "linear",
                        loop: false,
                        complete: function(anim) {
                          if (index + 1 === 1) {
                            anime({
                              targets: ".lpunkt1 .pltext",
                              opacity: 1,
                              duration: 1000,
                              easing: "linear",
                              loop: false
                            });
                          }

                          if (index + 1 === 2) {
                            anime({
                              targets: "#llineanim .lpunkt2 .lpunkthover2",
                              r: [20, 29],
                              duration: 1500,
                              easing: "linear",
                              direction: "alternate",
                              loop: true
                            });
                          }

                          anime({
                            targets:
                              "#llineanim .lpunkt" + (index + 1) + " .pltitle",
                            opacity: 1,
                            duration: 1000,
                            easing: "linear",
                            loop: false
                          });
                        }
                      });
                    }
                  });
                }
              }
            });
          })
          .addTo(controller);
      }, 1500);

      setTimeout(() => {
        anime({
          targets: "#sliderblob .bblob",
          duration: 40000,
          easing: "linear",
          d: [
            {
              value:
                "M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
            },
            {
              value:
                "M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
            },

            {
              value:
                "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }, 1000);
    }

    setTimeout(() => {
      new SimpleLightbox({ elements: ".lightBoxVideoLink" });
    }, 1000);
    if (getCookie("ch") === "1" || getCookie("ch") === "3") {
    }

    // document.getElementById("defaultOpen").click();
  }, 500);
</script>

<svelte:head>
  <style>
    .leistungenBlockTitle h2 {
      margin-bottom: 20px;
    }
    .section.footersection {
      max-width: 1000px;
      margin: auto 0px auto 0px;
      display: inline-flex;
      align-items: center;
    }

    .section > div.ltextblock h2 {
      font-size: 32px;
    }

    .section > div.ltextblock2 p strong {
      color: #e61974;
      float: left;
      margin-top: 15px;
      width: 100%;
    }

    .llineanimactive .lpunkt {
      cursor: pointer;
    }

    .pltitle rect {
      transition-duration: 1s;
    }

    .llineanimactive .lpunkt:hover .lpunkthover {
      r: 29;
      transition-duration: 1s;
    }

    div.tablinksblock {
      border: none;
      width: 50%;
      position: relative;
    }

    div.tabrightblock {
      width: 50%;
      margin-bottom: -50px;
      padding: 15px 0 15px 15px;
      /* border: 1px solid #4a4a4a; */
      border-right: none;
      position: relative;
    }

    div.tabrightblock::after {
      content: "";
      border: 1px solid #4a4a4a;
      border-right: none;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 10%;
    }

    div.tablinksblock button {
      background-color: inherit;
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 10px 16px;
      min-height: 50px;
      transition: 0.3s;
      width: 90%;
      text-align: left;
      margin: 10px 0;
      border-radius: 0 30px 30px 0;
      color: #fff;
      position: relative;
      z-index: 2;
      font-size: 18px;
      transition-duration: 0.5s;
    }

    div.tablinksblock button.tablinks.faqactive::after {
      content: "";
      border: none;
      border-bottom: 1px solid #555;
      position: absolute;
      width: calc(10% + 5px);
      right: calc(-10% - 5px);
      top: 25px;
      float: right;
      left: auto;
      z-index: -1;
      display: block;
    }

    .tabcontent {
      display: none;
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-top: none;
    }

    .sliderTextBox {
      width: 600px;
    }

    .sliderTextBox h1 {
      width: 600px;
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
    }

    #blslider > svg.blob1 {
      transform: scaleX(1.5) translateX(55px);
    }

    #blslider > svg.blob2 {
      transform: rotate(17deg) scaleX(1.4) translate(85px, -40px);
    }

    #blslider > svg.blob3 {
      transform: rotate(-21deg) scaleX(-1.2) translate(-35px, 90px);
    }

    .kcbb a {
      background-color: #e61974;
      color: #fff;
      border-color: #e61974;
      border-radius: 50px;
      cursor: pointer;
      justify-content: center;
      padding: calc(0.5em - 1px) 1em;
      text-align: center;
      white-space: nowrap;
      border-width: 1px;
    }

    .kcbb a:hover {
      background-color: #4a4a4a;
      color: #fff;
      border-color: #4a4a4a;
    }

    body #page div.contentmain.scrollcontentbox section.section.mainslider {
      margin-right: 10vw !important;
    }

    #lvideoblock {
      max-width: 400px;
      min-width: auto;
      margin: 0 100px 0 -100px;
    }

    #lvideoblock .lightBoxVideoLink > div {
      width: 315px;
      height: 315px;
      border-radius: 100%;
      box-shadow: 0px 0px 10px #dadada;
      border: 1px solid #f9c6dd;
      filter: brightness(0.8);
      transition-duration: 1s;
    }
    #lvideoblock .lightBoxVideoLink:hover > div {
      border: 1px solid #adcdd1;
      filter: brightness(1);
      transition-duration: 1s;
    }
    @media screen and (max-width: 1024px) {
      #lvideoblock {
        margin: 0 auto;
      }

      #lvideoblock .lightBoxVideoLink {
        margin: 0 auto;
      }

      #lvideoblock .lightBoxVideoLink > div {
        width: 250px;
        height: 250px;
        border-radius: 100%;
      }

      body #page div.contentmain.scrollcontentbox section.section.mainslider {
        margin-right: auto;
      }

      section.section > div.ltextblock h2,
      section.section > div.ltextblock h2 strong {
        font-size: 20px !important;
      }

      div.tablinksblock,
      div.tabrightblock {
        width: 100%;
      }

      .mwelle {
        position: absolute;
        bottom: 0px;
        top: auto;
        z-index: 100;
      }

      #page .mainslider .mwelletop {
        bottom: auto;
        top: 0px !important;
        width: 100%;
        height: 100px;
      }

      #page .mainslider .mwelletop .mwelle {
        position: absolute;
        bottom: auto;
        top: -5px !important;
        left: -20vw;
      }

      .section.footersection {
        margin: auto auto;
      }

      .wwhnblock {
        margin-top: 50px;
      }

      .wwhnblock > svg {
        position: static !important;
      }

      .wwhnblock > svg text {
        font-size: 14px;
      }

      div.tablinksblock button {
        width: 100%;
      }

      div.tablinksblock button.tablinks.active::after {
        display: none;
      }

      .tablinks > svg {
        top: 0px !important;
        position: relative;
        transform: rotate(90deg);
      }

      div.tablinksblock button {
        padding: 12px 16px;
        min-height: 70px;
      }

      body #page div.contentmain.scrollcontentbox section.section.mainslider {
        margin-bottom: 150px !important;
      }
    }

    @media (min-width: 1280px) {
      #blslider {
        left: 30vw !important;
      }
    }

    @media (orientation: landscape) and (max-width: 960px) {
      body #page div.contentmain.scrollcontentbox section.section.mainslider {
        height: 150vh !important;
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1440px) and (max-height: 1200px) {
      body #page div.contentmain.scrollcontentbox section.section.mainslider {
        margin-right: 15vw !important;
      }

      #ltextblock2 {
        align-items: center !important;
      }

      #mainbild {
        height: 100vh;
        float: left;
        width: 70vw;
        min-width: 1200px;
      }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min--moz-device-pixel-ratio: 2),
      only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1100px) and (max-width: 1440px) and (max-height: 1200px) {
      body #page div.contentmain.scrollcontentbox section.section.mainslider {
        margin-right: 300px !important;
      }
      #leistungenBlockMobil .leistungenBlockTitle {
        top: 63% !important;
      }
    }
  </style>
</svelte:head>

{#if leistungen}
  <section class="section mainslider">
    <div id="blslider2">
      {#if leistungen.title}
        <style>
          .sliderTextBox {
            text-transform: uppercase;
            width: auto;
            z-index: 100;
            position: relative;
            z-index: 10;
            padding: 50px 60px 80px 90px;
            top: auto;
            transform: rotate(-6deg);
            left: auto;
            color: #fff;
            text-align: left;
            height: auto !important;
            align-items: center;
            display: flex;
            border-radius: 25% 25% 74% 26% / 54% 37% 63% 46%;
            background: rgb(230, 25, 116);
          }

          @media (max-width: 960px) {
            .sliderTextBox {
              border-radius: 35% 65% 74% 26%/54% 37% 63% 46%;
            }
          }

          .blob11 {
            background: #ff5ba2;
            position: absolute;
            z-index: 1;
            width: 110%;
            border-radius: 25% 25% 74% 26% / 54% 37% 63% 46%;
            opacity: 0.35;
            background: #ff5ba2;
            left: -6%;
            top: -20px;
            transform: rotate(-17deg);
            height: 110%;
          }

          .blob22 {
            background: #156f7b;
            position: absolute;
            z-index: 2;
            transform: rotate(8deg);
            top: -5%;
            left: -60px;
            width: 110%;
            height: 110%;
            opacity: 0.35;
            border-radius: 25% 25% 74% 26% / 54% 37% 63% 46%;
          }
        </style>
        <div id="sliderText">

          <div class="sliderTextBox">
            <div>
              <h1>
                {@html leistungen.title}
              </h1>
              <p>
                {@html leistungen.desc}
              </p>
            </div>

          </div>
          <div class="blob11" />
          <div class="blob22" />

           {#if leistungen.noindex === true}     

                <div class="contactbtn">
                <a href="/kontakt/" target="_self">Kontakt</a>
                </div>
            {/if}
        </div>
        <!-- <BlobSlider /> -->
      {/if}
    </div>
    <div class="mwelletop">
      <Mwelle />
    </div>
    <div>

      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
              width: 100%;
              float: left;
            }
          </style>
          {#if leistungen.bild}
            <div
              id="msvg"
              style="background-image: url(https://www.collet-cc.de/colletadmin/{leistungen.bild.styles[0].path});"
            />
          {/if}
        {:else if leistungen.bild}
          <div
            id="msvg"
            style="background-image: url(https://www.collet-cc.de/colletadmin/{leistungen.bild.styles[2].path});"
          />
        {/if}
        {#if ww >= 1024}
          <div class="svgclippath">
            <svg viewBox="0 0 398.6 435.4" width="0" height="0">
              <defs>
                <clipPath
                  id="sliderblob"
                  clipPathUnits="objectBoundingBox"
                  transform="scale(0.0027, 0.0037)"
                >
                  <path
                    style=" transform: translate(200px, 125px);"
                    transform=" translate(200, 125)"
                    class="bblob"
                    d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        {:else}
          <div class="svgclippath">
            <svg viewBox="0 0 1200 900" width="0" height="0">
              <clipPath
                id="sliderblob"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.0008, 0.002)"
              >
                <path
                  transform="translate(-180, 0)"
                  class="bblob"
                  d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
                  143.61c-4.55,95.55,170.07,212.41,10.95,462.77
                  C1005.43,799.35,1195.72,900.48,1200,900z"
                />
              </clipPath>
            </svg>
          </div>
        {/if}
      </div>

    </div>
    <Mwelle />
  

    {#if leistungen.noindex === false}  
      <div class="backb">
        <BackButton url="/leistungen/" />
      </div>

      {:else}

    
      <style>
        body #spiel, 
        body #wwhnblock, 
        body .burgerjobs, 
        body .burgerkontakt {
          display: none!important;
        }
      </style>


    {/if}
  </section>

  <section class="section ">
    <div class="text-left ltextblock ltextblock1">
      {@html leistungen.text1}

      <div
        class="godcall"
        style={!leistungen.calltoaction_text || leistungen.calltoaction_text == '' ? 'justify-content: flex-end;' : ''}
      >

        {#if leistungen.calltoaction_text}
          {#if leistungen.calltoaction_text !== ''}
            <a
              href={leistungen.calltoaction_link}
              alt={leistungen.calltoaction_text}
              target="_self"
              id="calltoaction_link"
            >
              {leistungen.calltoaction_text}
            </a>
          {/if}
        {/if}

        <!-- {#if leistungen.godigital}
          <a
            href="https://www.collet-cc.de/blog/go-digital/"
            id="godigital"
            target="_self"
          >
            <svg
              width="30"
              height="36"
              viewBox="0 0 30 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_daa)">
                <path
                  d="M15.9623 16.1273L14.9995 16.3973L15.9623 16.1273ZM17.7214
                  25.0172C18.2028 25.2878 18.8125 25.1168 19.083 24.6353L23.4919
                  16.7892C23.7625 16.3077 23.5915 15.6981 23.11 15.4275C22.6285
                  15.157 22.0189 15.328 21.7483 15.8094L17.8293 22.7838L10.855
                  18.8648C10.3735 18.5942 9.76387 18.7652 9.49332
                  19.2467C9.22277 19.7281 9.39376 20.3378 9.87523
                  20.6083L17.7214 25.0172ZM11.0372 2.27005L14.9995
                  16.3973L16.9252 15.8572L12.9628 1.72995L11.0372
                  2.27005ZM14.9995 16.3973L17.2484 24.4155L19.1741
                  23.8754L16.9252 15.8572L14.9995 16.3973Z"
                  fill="#E61974"
                />
              </g>
              <defs>
                <filter
                  id="filter0_daa"
                  x="0.909668"
                  y="0.0113373"
                  width="28.662"
                  height="35.0856"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>

            <img
              src="https://www.collet-cc.de/images/go-digital.svg"
              alt="Go-Digital"
              width="100%"
              height="100%"
            />
          </a>
        {/if} -->

      </div>
    </div>
  </section>

  {#if leistungen.youtubeImage && leistungen.youtubeId !== ''}
    <section class="section " id="lvideoblock">

      <a
        class="lightBoxVideoLink"
        frameborder="0"
        allowfullscreen
        allow="autoplay"
        href="https://www.youtube.com/embed/{leistungen.youtubeId}?rel=0&mute=0&showinfo=0&controls=0&autoplay=1"
        style="position: relative; z-index: 1;"
      >
        <div
          style="object-fit: cover; object-position: center;background-image:
          url({Prodhost}/colletadmin/{leistungen.youtubeImage.styles[0].path});
          background-size: cover; background-position: center;
          background-repeat: no-repeat;"
        />
        <svg
          width="57"
          height="50"
          viewBox="0 0 57 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style=" position: absolute; top: 0; left: 0; right: 0; bottom: 0;
          margin: auto;"
        >
          <path
            d="M54 25L3 3M3 3V47V3ZM54 25L3 47L54 25Z"
            stroke="#E61974"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <!-- 
        <svg
          viewBox="0 0 48 48"
          width="48px"
          height="48px"
          style=" position: absolute; top: 0; left: 0; right: 0; bottom: 0;
          margin: auto;"
        >
          <path
            fill="#FF3D00"
            d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
          />
          <path fill="#FFF" d="M20 31L20 17 32 24z" />
        </svg> -->
      </a>

    </section>
  {/if}

  <section class="section " id="ltextblock2">
    <div class="text-left ltextblock ltextblock2">
      <span class="lplus">+</span>
      {@html leistungen.text2}

      {#if leistungen.calltoaction2_text}
        {#if leistungen.calltoaction2_text !== ''}
          <a
            href={leistungen.calltoaction2_link}
            alt={leistungen.calltoaction2_text}
            target="_blank"
            id="calltoaction2_link"
          >
            {leistungen.calltoaction2_text}
          </a>
        {/if}
      {/if}
    </div>
  </section>

  <section class="section" id="llineanimblock">
    <div id="leistungenBlockMobil" class="text-left ltextblock ltextblock3">

      {#if ww >= 1024}
        {#if leistungen.leistungen}
          {#each leistungen.leistungen as lj, i}
            <p class="ltextseo">{lj.settings.text}</p>
          {/each}
        {/if}

        <div class="leistungenBlockTitle">
          {@html leistungen.leistungentext}
        </div>

        <svg
          width={550 * ljson_length}
          height="1200"
          viewBox="0 0 {550 * ljson_length} 1200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          id="llineanim"
        >

          <line
            x1="280"
            y1="600"
            x2="280"
            y2="600"
            stroke="#E61974"
            stroke-width="3"
            class="lline"
          />

          {#if !ifSafari()}
            <line
              x1="
              {420 * ljson_length}
              "
              y1="610"
              x2="
              {420 * ljson_length}
              "
              y2="590"
              stroke="#E61974"
              stroke-width="3"
              class="llineend"
            />
          {/if}

          <g style="transform: translateY(360px)">
            <path
              d="M287 239C287 262.196 268.196 281 245 281C221.804 281 203
              262.196 203 239C203 215.804 221.804 197 245 197C268.196 197 287
              215.804 287 239Z"
              fill="#E61974"
            />

            <path
              d="M242.712 373.908C215.852 373.908 191.502 368.107 169.662
              356.505C148.073 344.651 131.003 328.383 118.451 307.701C106.15
              286.767 100 263.184 100 236.954C100 210.723 106.15 187.267 118.451
              166.585C131.003 145.651 148.073 129.383 169.662 117.781C191.502
              105.927 215.977 100 243.089 100C265.933 100 286.518 104.035
              304.843 112.106C323.42 120.177 338.984 131.779 351.535
              146.912L312.374 183.232C294.551 162.55 272.46 152.209 246.101
              152.209C229.784 152.209 215.224 155.866 202.422 163.18C189.619
              170.242 179.578 180.205 172.298 193.068C165.269 205.931 161.754
              220.56 161.754 236.954C161.754 253.348 165.269 267.977 172.298
              280.84C179.578 293.703 189.619 303.791 202.422 311.106C215.224
              318.168 229.784 321.699 246.101 321.699C272.46 321.699 294.551
              311.232 312.374 290.298L351.535 326.617C338.984 342.002 323.42
              353.73 304.843 361.801C286.267 369.872 265.556 373.908 242.712
              373.908Z"
              fill="#231F20"
              class="collet-c"
              style=" transform-origin: 56% 50%; transform-box: fill-box;
              transform: rotate(180deg); -webkit-filter: drop-shadow( 3px 3px
              10px rgba(0, 0, 0, .5)); filter: drop-shadow( 3px 3px 10px rgba(0,
              0, 0, .5)); "
            />
          </g>
          {#if leistungen.leistungen}
            {#each leistungen.leistungen as lj, i}
              <g
                class="lpunkt lpunkt{i + 1}"
                on:click={() => handleClick(i + 1)}
              >
                <Lpunkt
                  n={i + 1}
                  title={lj.settings.title}
                  text={lj.settings.text}
                />
              </g>
            {/each}
          {/if}
        </svg>
      {:else}
        <svg
          width="400"
          height="400"
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          class="leistungenBlockLogo"
          id="llineanim"
        >

          <path
            d="M287 239C287 262.196 268.196 281 245 281C221.804 281 203 262.196
            203 239C203 215.804 221.804 197 245 197C268.196 197 287 215.804 287
            239Z"
            fill="#E61974"
          />

          <path
            d="M242.712 373.908C215.852 373.908 191.502 368.107 169.662
            356.505C148.073 344.651 131.003 328.383 118.451 307.701C106.15
            286.767 100 263.184 100 236.954C100 210.723 106.15 187.267 118.451
            166.585C131.003 145.651 148.073 129.383 169.662 117.781C191.502
            105.927 215.977 100 243.089 100C265.933 100 286.518 104.035 304.843
            112.106C323.42 120.177 338.984 131.779 351.535 146.912L312.374
            183.232C294.551 162.55 272.46 152.209 246.101 152.209C229.784
            152.209 215.224 155.866 202.422 163.18C189.619 170.242 179.578
            180.205 172.298 193.068C165.269 205.931 161.754 220.56 161.754
            236.954C161.754 253.348 165.269 267.977 172.298 280.84C179.578
            293.703 189.619 303.791 202.422 311.106C215.224 318.168 229.784
            321.699 246.101 321.699C272.46 321.699 294.551 311.232 312.374
            290.298L351.535 326.617C338.984 342.002 323.42 353.73 304.843
            361.801C286.267 369.872 265.556 373.908 242.712 373.908Z"
            fill="#231F20"
            class="collet-c"
            style="
            "
          />

        </svg>

        <div class="leistungenBlockTitle">
          {#if leistungen.leistungentext}
            {@html leistungen.leistungentext}
          {/if}
        </div>

        {#if leistungen.leistungen}
          {#each leistungen.leistungen as lj, i}
            <div style="margin-bottom: 0px">
              <button
                class="isOpen {isOpen === i ? 'isOpenActive' : ''}"
                on:click={() => {
                  isOpen = i;
                }}
              >
                {lj.settings.title}
              </button>
              <div
                class="leistungenBlockText faqactiveText {isOpen === i ? 'faqactiveTextactiv' : ''}"
                transition:slide={{ duration: 300 }}
              >
                {@html lj.settings.text}
              </div>
            </div>
          {/each}
        {/if}
      {/if}

    </div>
  </section>
  {#if leistungen.testimonials && leistungen.testimonials.length > 0}
    <section class="section " style="margin: auto 0">
      <div class="text-left ltextblock ltextblock2 testimonialsblock">

        <div class="kcbt">
          {#if leistungen.testimonialstext}
            {@html leistungen.testimonialstext}
          {/if}
        </div>
        <div class="position:relative">

          <style>
            .swiper-slide p .img {
              border-radius: 50%;
              cursor: pointer;
              height: 250px;
              width: 250px;
              background-size: cover;
              background-position: center;
              margin: 0 auto;
            }
          </style>
          <div class="swiper-container">

            <div class="swiper-wrapper">
              {#each leistungen.testimonials as lj, i}
                <div class="swiper-slide">
                  {#if !lj.settings.img}
                    <span class="lplus-c">
                      <svg
                        width="84"
                        height="66"
                        viewBox="0 0 84 66"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.4102 0.617188L40.4766 10.3828C33.8034 22.4271
                          30.3447 33.6982 30.1006
                          44.1963V65.8027H0.0712891V45.6611C0.152669 38.2555
                          2.34993 30.321 6.66309 21.8574C10.9762 13.3125 16.2253
                          6.23242 22.4102 0.617188ZM65.2568 0.617188L83.3232
                          10.3828C76.6501 22.4271 73.1914 33.6982 72.9473
                          44.1963V65.8027H42.918V45.6611C42.9993 38.2555 45.1966
                          30.321 49.5098 21.8574C53.8229 13.3125 59.0719 6.23242
                          65.2568 0.617188Z"
                          fill="#156F7B"
                          fill-opacity="0.25"
                        />
                      </svg>
                    </span>
                  {/if}
                  <div>
                    <p>
                      {#if lj.settings.img}
                        <a href={lj.settings.link} target="_self">
                          <div
                            class="img"
                            style="background-image:url(https://www.collet-cc.de{lj.settings.img.path})"
                            alt={lj.settings.title}
                          />
                        </a>
                      {:else}
                        {@html lj.settings.text}
                      {/if}
                    </p>
                    <h4>{lj.settings.title}</h4>
                  </div>
                </div>
              {/each}

            </div>
            {#if leistungen.testimonials.length > 1}
              <div class="swiper-button-next">
                {#if navigator.userAgent.indexOf('Trident') > -1}next{/if}
              </div>
              <div class="swiper-button-prev">
                {#if navigator.userAgent.indexOf('Trident') > -1}prev{/if}
              </div>
            {/if}
          </div>

        </div>

        <div class="kcbb">
          {@html leistungen.testimonialstext2}
        </div>

      </div>
    </section>
  {/if}

  <section class="section " id="faqsection">
    <span class="lplus-f">

      <svg
        width="330"
        height="517"
        viewBox="0 0 330 517"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.06343 150.691H120.563C120.563 131.358 130.163 93.1914 168.563
          95.1914C216.564 97.6914 207.563 148.191 203.563 169.691C199.563
          191.191 150.563 223.691 128.563 254.191C110.963 278.591 107.897
          330.691 108.563 353.691H210.063C210.397 348.858 211.763 334.491
          214.563 315.691C218.063 292.191 237.563 277.691 286.563
          234.191C325.763 199.391 329.897 150.358 327.063 130.191C330.063
          89.6914 302.163 7.39143 166.563 2.19143C30.9634 -3.00857 0.39676
          99.0247 2.06343 150.691Z"
          stroke="#D4D4D4"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.063 395.191C107.063 395.191 94.2301 436.858 94.5635
          457.691C96.0635 476.358 111.463 513.991 161.063 515.191C210.663
          516.391 225.397 477.358 226.563 457.691C227.23 436.858 215.063 395.191
          161.063 395.191Z"
          stroke="#D4D4D4"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

    </span>
    <div class="text-left ltextblock ltextblock2">

      <div id="faqblock">

        <div class="tablinksblock">

          <h2>
            FAQs - Was Du schon immer
            <br />
            wissen wolltest
          </h2>

          {#if leistungen && leistungen.length !== 0}
            {#each leistungen.faqblock as lf, i}
              <button
                class="tablinks {isOpenFaq === i ? 'faqactive' : ''}"
                on:click={function() {
                  if (isOpenFaq === i && ww < 1024) {
                    isOpenFaq = 1000;
                  } else {
                    isOpenFaq = i;
                  }
                }}
              >
                <span style="max-width:90%;">{lf.settings.title}</span>
                <svg
                  width="29"
                  height="24"
                  viewBox="0 0 29 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d{i})">
                    <path
                      d="M24.7071 8.7071C25.0976 8.31658 25.0976 7.68342 24.7071
                      7.29289L18.3431 0.928931C17.9526 0.538407 17.3195 0.538407
                      16.9289 0.928931C16.5384 1.31946 16.5384 1.95262 16.9289
                      2.34314L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384
                      14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616
                      18.3431 15.0711L24.7071 8.7071ZM4 9L16.7586 9L16.7586 7L4
                      7L4 9ZM16.7586 9L24 9L24 7L16.7586 7L16.7586 9Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d{i}"
                      x="0"
                      y="0.635986"
                      width="29"
                      height="22.7279"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
              {#if ww < 1024}
                <div
                  transition:slide={{ duration: 300 }}
                  class="faqactiveText {isOpenFaq === i ? 'faqactiveTextactiv' : ''}"
                >
                  {@html lf.settings.text}
                </div>
              {/if}
            {/each}
          {/if}

          {#if leistungen.calltoaction3_text}
            {#if leistungen.calltoaction3_text !== ''}
              <a
                href={leistungen.calltoaction3_link}
                alt={leistungen.calltoaction3_text}
                target="_self"
                id="calltoaction_link"
              >
                {leistungen.calltoaction3_text}
              </a>
            {/if}
          {/if}

        </div>
        {#if ww >= 1024}
          <div class="tabrightblock">
            {#if leistungen && leistungen.length !== 0}
              {#each leistungen.faqblock as lj, i}
                <div
                  transition:slide={{ duration: 300 }}
                  class="tabtext faqactiveText {isOpenFaq === i ? 'faqactiveTextactiv' : ''}"
                >
                  {@html lj.settings.text}
                </div>
              {/each}
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </section>

  <section class="section" id="wwhnblock">
    <div class="text-left ltextblock ltextblock2 wwhnblock">
      <h2>
        {@html leistungen.whnmtext}
      </h2>

      <div class="whnm" style="position:relative; left: 100px">
        <svg
          width="170"
          height="148"
          viewBox="0 0 170 148"
          fill="none"
          class=""
          onclick="location.href='{leistungen.whnm_l_1}'"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="whnmb"
            d="M9.1192 109.288L9.11894 109.287C-0.721103 89.5086 -2.42763
            64.7161 7.01341 43.8828C16.4931 23.0458 37.1303 6.18145 59.7599
            2.21553L59.7602 2.21547C82.4234 -1.773 107.145 7.15062 125.457
            23.2064C143.791 39.2803 155.589 62.354 152.283 81.4627C150.628
            91.0225 145.205 99.6597 137.808 107.455C130.412 115.249 121.07
            122.173 111.629 128.305C92.7259 140.584 73.6642 149.507 55.2758
            146.643L55.275 146.643C36.8414 143.801 18.9307 129.066 9.1192
            109.288Z"
            stroke="#E61974"
          />
          <path
            d="M75.7066 63L75.7066 64L75.7066 63ZM84.7413 63.7071C85.1318
            63.3166 85.1318 62.6834 84.7413 62.2929L78.3773 55.9289C77.9868
            55.5384 77.3536 55.5384 76.9631 55.9289C76.5726 56.3195 76.5726
            56.9526 76.9631 57.3431L82.62 63L76.9631 68.6569C76.5726 69.0474
            76.5726 69.6805 76.9631 70.0711C77.3536 70.4616 77.9868 70.4616
            78.3773 70.0711L84.7413 63.7071ZM61.0342 64L75.7066 64L75.7066
            62L61.0342 62L61.0342 64ZM75.7066 64L84.0342 64L84.0342 62L75.7066
            62L75.7066 64Z"
            fill="#E61974"
          />

        </svg>
        <h3>
          {@html leistungen.whnm_t_1}
        </h3>
      </div>
      <div class="whnm whnm2" style="position:relative; left: 200px">
        <svg
          width="170"
          height="148"
          viewBox="0 0 170 148"
          fill="none"
          class=""
          onclick="location.href='{leistungen.whnm_l_2}'"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="whnmb"
            d="M9.1192 109.288L9.11894 109.287C-0.721103 89.5086 -2.42763
            64.7161 7.01341 43.8828C16.4931 23.0458 37.1303 6.18145 59.7599
            2.21553L59.7602 2.21547C82.4234 -1.773 107.145 7.15062 125.457
            23.2064C143.791 39.2803 155.589 62.354 152.283 81.4627C150.628
            91.0225 145.205 99.6597 137.808 107.455C130.412 115.249 121.07
            122.173 111.629 128.305C92.7259 140.584 73.6642 149.507 55.2758
            146.643L55.275 146.643C36.8414 143.801 18.9307 129.066 9.1192
            109.288Z"
            stroke="#E61974"
          />
          <path
            d="M75.7066 63L75.7066 64L75.7066 63ZM84.7413 63.7071C85.1318
            63.3166 85.1318 62.6834 84.7413 62.2929L78.3773 55.9289C77.9868
            55.5384 77.3536 55.5384 76.9631 55.9289C76.5726 56.3195 76.5726
            56.9526 76.9631 57.3431L82.62 63L76.9631 68.6569C76.5726 69.0474
            76.5726 69.6805 76.9631 70.0711C77.3536 70.4616 77.9868 70.4616
            78.3773 70.0711L84.7413 63.7071ZM61.0342 64L75.7066 64L75.7066
            62L61.0342 62L61.0342 64ZM75.7066 64L84.0342 64L84.0342 62L75.7066
            62L75.7066 64Z"
            fill="#E61974"
          />

        </svg>
        <h3>
          {@html leistungen.whnm_t_2}
        </h3>
      </div>

      <div class="whnm" style="position:relative; left: 100px">
        <svg
          width="170"
          height="148"
          viewBox="0 0 170 148"
          fill="none"
          class=""
          onclick="location.href='{leistungen.whnm_l_3}'"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="whnmb"
            d="M9.1192 109.288L9.11894 109.287C-0.721103 89.5086 -2.42763
            64.7161 7.01341 43.8828C16.4931 23.0458 37.1303 6.18145 59.7599
            2.21553L59.7602 2.21547C82.4234 -1.773 107.145 7.15062 125.457
            23.2064C143.791 39.2803 155.589 62.354 152.283 81.4627C150.628
            91.0225 145.205 99.6597 137.808 107.455C130.412 115.249 121.07
            122.173 111.629 128.305C92.7259 140.584 73.6642 149.507 55.2758
            146.643L55.275 146.643C36.8414 143.801 18.9307 129.066 9.1192
            109.288Z"
            stroke="#E61974"
          />
          <path
            d="M75.7066 63L75.7066 64L75.7066 63ZM84.7413 63.7071C85.1318
            63.3166 85.1318 62.6834 84.7413 62.2929L78.3773 55.9289C77.9868
            55.5384 77.3536 55.5384 76.9631 55.9289C76.5726 56.3195 76.5726
            56.9526 76.9631 57.3431L82.62 63L76.9631 68.6569C76.5726 69.0474
            76.5726 69.6805 76.9631 70.0711C77.3536 70.4616 77.9868 70.4616
            78.3773 70.0711L84.7413 63.7071ZM61.0342 64L75.7066 64L75.7066
            62L61.0342 62L61.0342 64ZM75.7066 64L84.0342 64L84.0342 62L75.7066
            62L75.7066 64Z"
            fill="#E61974"
          />

        </svg>
        <h3>
          {@html leistungen.whnm_t_3}
        </h3>
      </div>
    </div>
  </section>
{/if}

<style>
  .contentmain > section {
    width: 100%;
  }

  .godcall {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  #godigital {
    max-width: 250px;
    width: 100%;
    float: right;
    margin-top: 30px;
    transform: rotate(0);
    margin-right: 150px;
  }
  #godigital svg {
    display: none;
  }

  #godigital img {
    width: 100%;
  }

  #calltoaction_link,
  #calltoaction2_link,
  #calltoaction3_link {
    margin-top: 30px;
    float: left;
    margin-left: 150px;
    background-color: #e61974;
    color: #fff;
    border-color: #e61974;
    border-radius: 50px;
    cursor: pointer;
    justify-content: center;
    padding: calc(0.5em - 1px) 1em;
    text-align: center;
    white-space: nowrap;
    border-width: 1px;
  }

  #calltoaction_link:hover,
  #calltoaction2_link:hover,
  #calltoaction3_link:hover {
    background-color: #4a4a4a;
    color: #fff;
    border-color: #4a4a4a;
  }

  @media screen and (max-width: 1024px) {
    .godcall {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
    #calltoaction_link,
    #calltoaction2_link,
    #calltoaction3_link {
      margin: 20px 0;
      width: 100%;
      white-space: break-spaces;
      position: relative;
      z-index: 10;
    }
    #godigital {
      max-width: 200px;
      float: right;
      margin-top: 0px;
      transform: rotate(-20deg);
      margin-right: -20px;
      text-align: center;
      margin-bottom: 30px;
    }

    #godigital svg {
      display: inline;
      position: relative;
      left: -30px;
      transform: rotate(20deg);
    }
  }

  .section {
    max-width: 1000px;
    min-width: 1000px;
    margin: auto 200px auto 200px;
    display: inline-flex;
    align-items: center;
    color: #231f20;
  }

  #llineanimblock {
    margin-right: 0;
  }

  .section > div.ltextblock {
    position: relative;
    z-index: 0;
  }

  .lplus {
    position: absolute;
    top: 70px;
    left: -170px;
    z-index: -1;
    font-size: 30vw;
    line-height: 0;
    font-weight: 900;
    opacity: 0.2;
    color: #156f7b;
  }

  .text-left {
    text-align: left;
  }

  .swiper-container {
    width: 500px;
    height: 100%;
    overflow: hidden;
  }

  .llineend {
    opacity: 0;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .llineend {
        display: none !important;
        opacity: 0 !important;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    font-style: italic;
    width: 440px;
    height: 350px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: transparent !important;
    background: transparent !important;
  }

  .swiper-slide > div {
    padding: 0 50px;
  }

  .swiper-slide > div > h4 {
    font-size: 18px;
    font-weight: bold;
    color: #e61974;
    margin-top: 30px;
  }

  .swiper-container .swiper-button-next {
    transform: rotate(0deg) !important;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
    background-color: transparent !important;
  }

  .swiper-container .swiper-button-prev {
    transform: rotate(0deg) !important;
    left: 0;
    right: auto;
    bottom: auto;
    top: 50%;
    background-color: transparent !important;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    padding: 5px 10px;
    color: rgba(116, 116, 116, 0.5);
    background-color: transparent !important;
  }

  .lplus-c {
    position: absolute;
    top: 0;
    left: 40px;
    z-index: -1;
    line-height: 0;
    font-weight: 900;
    color: #156f7b;
  }

  .lplus-f {
    position: absolute;
    left: -240px;
    z-index: -1;
    bottom: -50px;
  }

  .kcbt {
    margin-bottom: 60px;
  }

  .kcbb {
    margin-left: 50px;
  }

  .kcbb a {
    margin-left: 100px;
    margin-top: 30px;
  }

  .whnm {
    cursor: pointer;
    fill: #fff;
    transition-duration: 0.5s;
    position: relative;
  }

  .whnm:hover .whnmb {
    fill: #156f7b;
    transition-duration: 0.5s;
  }

  .whnm h3 {
    font-weight: bold;
    color: #231f20;
    position: absolute;
    top: 50px;
    left: 100px;
    max-width: 300px;
    /* text-shadow: 0px 0px 10px #b0b0b0; */
  }

  .whnm path {
    -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
  }

  .whnm path.whnmb {
    -webkit-filter: none;
    filter: none;
  }

  #faqblock {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .leistungenBlockTitle {
    position: absolute;
    left: -90px;
    bottom: 20%;
    width: 500px;
    line-height: 30px;
  }

  #leistungenBlockMobil::after {
    display: none;
  }

  .backb {
    right: -400px;
    z-index: 9;
  }

  .tablinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tablinks > svg {
    top: 3px;
    position: relative;
  }

  #ltextblock2 {
    align-items: start;
    padding-top: 15vh;
  }

  #wwhnblock {
    margin-right: -250px;
  }

  .testimonialsblock {
    max-width: 700px;
  }

  #sliderText {
    position: relative;
  }

  #blslider2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 100;
    left: auto;
    right: -10%;
    top: auto;
    bottom: 25vh;
    position: absolute;
    max-height: 500px;
    max-width: 700px;
  }
  .faqactiveText {
    opacity: 0;
    height: 0;
  }

  .faqactiveTextactiv {
    opacity: 1;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    .tabtext.faqactiveText {
      transition-duration: 1s;
    }

    .tabtext.faqactiveTextactiv {
      transition-duration: 1s;
    }

    #faqsection {
      margin-top: 80px !important;
    }

    .whnm {
      left: 0 !important;
    }

    .whnm.whnm2 {
      left: 50px !important;
    }

    .whnm h3 {
      max-width: 170px !important;
    }

    .testimonialsblock {
      max-width: none;
    }

    #ltextblock2 {
      align-items: center;
      padding-top: auto;
    }

    #wwhnblock {
      margin-right: 0px;
    }

    .backb {
      position: absolute;
      bottom: -90px;
      right: 0 !important;
      left: auto;
      text-align: -webkit-left;
    }

    section.section {
      margin: 20px 0 !important;
      padding: 0 20px;
      min-width: 0;
    }
    #faqblock {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .kcbt {
      margin-left: 0px;
      margin-bottom: 60px;
    }

    .kcbb {
      margin-left: 0px;
    }

    .kcbb a {
      margin-left: 0px;
      margin-top: 30px;
    }

    .swiper-slide {
      width: 100% !important;
      height: auto;
    }

    .swiper-wrapper {
      position: relative;
      width: 100%;
      margin: 0 auto;
    }

    .swiper-container {
      width: 90vw !important;
      height: auto !important;
      margin: 30px auto !important;
    }

    .ltextblock3 {
      width: 100%;
    }

    .ltextblock3 > div > .isOpen {
      text-align: center;
      color: #e61974;
      width: 100%;
      padding: 10px 20px;
      border-radius: 20px;
      border: 1px solid #e61974;
      margin: 0 auto;
      cursor: pointer;
      font-size: 16px;
      transition-duration: 0.5s;
      background: #fff;
    }

    .ltextblock3 > div > .isOpen.isOpenActive {
      background: #e61974;
      color: #fff;
      transition-duration: 0.5s;
    }

    #leistungenBlockMobil {
      position: relative;
      z-index: 2;
    }

    #leistungenBlockMobil .isOpen,
    #leistungenBlockMobil svg {
      position: relative;
      z-index: 2;
    }

    #leistungenBlockMobil::after {
      display: block;
      content: "";
      border-right: 2px solid #e6197414;
      border-top: 2px solid #e6197414;
      height: calc(95% - 30vw);
      top: 30vw;
      width: 20%;
      position: absolute;
      left: 31%;
      z-index: 0;
    }

    .leistungenBlockLogo {
      width: 50vw;
      height: auto;
      margin: 0px 0 30px 0;
      display: block;
      position: relative;
    }

    .leistungenBlockTitle {
      margin: 0px 0 50px 0;
      position: relative;
      display: block;
      left: 0;
      z-index: 2;
      width: 90vw;
    }

    .leistungenBlockTitle h2 {
      font-size: 30px !important;
      font-weight: 700;
      font-family: "Roboto";
    }

    .leistungenBlockText {
      background: #fff;
      padding: 30px 15px 15px 15px;
      border: 1px solid #e61974;
      border-radius: 15px;
      z-index: 1;
      position: relative;
      top: -15px;
    }
  }

  @media screen and (max-width: 694px) {
    #sliderText {
      max-width: 100vw;
    }

    #leistungenBlockMobil h3 {
      max-width: 90vw;
    }
  }

  @media screen and (max-width: 1024px) {
    #sliderText {
      max-width: none;
      width: 500px;
    }

    .sliderTextBox p {
      max-width: none;
    }

    .sliderTextBox {
      max-width: none;
      /* width: 100vw; */
      padding: 50px 60px 80px 60px;
    }

    #blslider2 {
      inset: auto auto !important;
      align-items: flex-end !important;
      height: 100vh !important;
      width: 100vw !important;
    }

    #blslider2 .blob11,
    #blslider2 .blob22 {
      height: 90%;
      top: auto;
    }

    #blslider2 .blob22 {
      bottom: -5%;
    }
    #blslider2 .blob11 {
      bottom: -20px;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    div.tablinksblock {
      padding-right: 20px;
    }

    div.tablinksblock button {
      padding: 10px 16px 10px 16px;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    div.tablinksblock button.tablinks > svg {
      top: auto !important;
      float: none !important;
      width: 20px;
      height: 20px;
    }

    section.section {
      margin: 0px !important;
      padding: 0px 300px 0 0;
      min-width: 0;
    }

    #mainbild {
      margin-right: 300px;
      width: 90vw;
    }
    #llineanim {
      top: -10vh;
    }

    #leistungenBlockMobil::after {
      display: none;
    }

    .swiper-container {
      width: 70% !important;
    }
    .wwhnblock {
      margin-right: -200px;
    }

    #faqblock {
      flex-direction: row;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) and (max-height: 1200px) {
    section.section {
      margin: 0 250px 0 0 !important;
      padding: 0 0 0 0;
      min-width: 0;
    }

    #wwhnblock {
      margin-right: -250px !important;
    }

    #llineanimblock {
      margin: 0 !important;
    }

    #leistungenBlockMobil {
      position: relative;
      top: 0;
      z-index: 2;
    }
    #leistungenBlockMobil .leistungenBlockTitle {
      margin: 0 0 50px;
      position: absolute;
      display: block;
      left: 0;
      z-index: 2;
      width: 450px;
      top: 85vh;
    }

    .backb {
      right: 80px !important;
      bottom: 40px;
    }
    .leistungenBlockTitle h2 {
      margin-bottom: 0px;
    }

    #faqsection {
      max-width: 1300px;
      width: 1300px;
    }

    #ltextblock2 {
      padding: 0;

      max-width: 90vw;
      width: 90vw;
    }

    #sliderText {
      width: auto;
    }

    #mainbild {
      margin-right: 0px !important;
    }
  }

  @media screen and (orientation: portrait) and (min-width: 760px) and (max-width: 1024px) and (min-height: 1000px) {
    #ltextblock2 {
      padding-top: 0;
    }
    section.section,
    #faqsection {
      margin: 0 0 150px 0 !important;
    }

    #wwhnblock {
      margin: 0 !important;
    }

    #llineanimblock {
      margin-top: -100px !important;
      margin-bottom: 100px !important;
    }

    #faqsection {
      margin-top: 30px !important;
    }

    .wwhnblock {
      margin: 0 auto;
    }
  }

  .ltextseo {
    opacity: 0;
    z-index: -10;
    position: absolute;
  }

  div.tablinksblock button {
    background-color: #e61974;
  }

  div.tablinksblock button.tablinks.faqactive {
    background-color: #4a4a4a;
    transition-duration: 0.5s;
  }

  @media screen and (min-width: 1024) {
    div.tablinksblock button:hover {
      background-color: #4a4a4a;
      transition-duration: 0.5s;
    }
  }

   @media screen and (min-width: 1024px) and (max-width: 1440px) and (max-height: 850px) {
  
    #leistungenBlockMobil .leistungenBlockTitle {
      margin: 0 0 50px;
      position: absolute;
      display: block;
      left: 0;
      z-index: 2;
      width: 450px;
      top: auto;
      bottom: 22vh;
    }

  
  
   }
</style>

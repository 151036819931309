<script>
  export let showBlob1 = true;
  export let showBlob2 = true;
  export let showBlob3 = true;
</script>

{#if showBlob1}
<svg class="blob1" viewBox="0 0 700 400" width="700pt" height="400pt">
  <path
    d=" M 66.2 68.2 C 153.161 12.447 500.2 -12.8 576.482 21.985 C 652.763 56.77
    776.816 213.483 554.056 272.872 C 331.297 332.26 197.741 472.854 110.531
    327.412 C 23.321 181.971 -20.761 123.953 66.2 68.2 Z "
    fill="rgb(230,25,116)" />
</svg>
{/if}

{#if showBlob2}
<svg class="blob2" viewBox="0 0 700 400" width="700pt" height="400pt">
  <path
    d=" M 66.2 68.2 C 153.161 12.447 500.2 -12.8 576.482 21.985 C 652.763 56.77
    776.816 213.483 554.056 272.872 C 331.297 332.26 197.741 472.854 110.531
    327.412 C 23.321 181.971 -20.761 123.953 66.2 68.2 Z "
    fill="#156F7B" />
</svg>
{/if}

{#if showBlob3}
<svg class="blob3" viewBox="0 0 700 400" width="700pt" height="400pt">
  <path
    d=" M 66.2 68.2 C 153.161 12.447 500.2 -12.8 576.482 21.985 C 652.763 56.77
    776.816 213.483 554.056 272.872 C 331.297 332.26 197.741 472.854 110.531
    327.412 C 23.321 181.971 -20.761 123.953 66.2 68.2 Z "
    fill="#e61974" />
</svg>
{/if}

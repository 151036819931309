<script>
  import Prodhost from "./host.js";
  import { onMount } from "svelte";

  export let qid;

  let umfragen = [];
  let z1 = 0;
  let z2 = 0;
  let z1t = 0;
  let z2t = 0;

  $: z1;
  $: z2;

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function handleClick(uid, zn, z) {
    z++;
    if (zn === 1) {
      z1 = z;
      z1t = 1;
    }
    if (zn === 2) {
      z2 = z;
      z2t = 1;
    }
    fetch(Prodhost + "/colletadmin/api/collections/save/umfragen", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: { _id: [uid], ["Zahl" + zn]: z }
      })
    })
      .then(res => res.json())
      .then(entry => console.log(entry));
  }

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/collections/get/umfragen", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { _id: {$in: [qid]} }
      })
    })
      .then(res => res.json())
      .then(res => {
        umfragen = res.entries;
        z1 = umfragen[0].Zahl1;
        z2 = umfragen[0].Zahl2;

      });
  });
</script>

<style>
  .qumfrage {
    text-align: center;
    margin: 100px 0 200px 0;
    /* min-width: 700px;
    max-width: 1000px; */
  }
  .qumfrage button {
    padding: 0;
    margin: 20px;
    border: none;
    cursor: pointer;
    height: 300px;
    width: 100%;
    background: transparent;
  }

  .qumfrage .column {
    position: relative;
    z-index: 10;
  }

  .qumfrage .qzahl {
    font-weight: 900;
    font-size: 3rem;
  }

  .qumfrage h2{
    font-size: 2rem;
  }

  .qumfrage .qdisable {
    opacity: 0.4;
    transform: scale(0.7);
    cursor: auto;
  }

  .qumfrage .qactive .blob {
    /* border: 10px solid rgba(230, 25, 116, 0.1); */
    transform: scale(1.1);
    cursor: auto;
  }

  .qumfrage .qactive .blob2 {
    /* border: 10px solid rgba(21, 111, 123, 0.1); */
    transform: scale(1.1);
    cursor: auto;
  }

  .qumfrage .blob,
  .qumfrage .blob2 {
    transform: scale(1);
    width: 100%;
    height: 100%;
    background: transparent;
    background-color: transparent;
    background-size: cover !important;
    background-position: 50% 50% !important;
    box-shadow: 5px 5px 5px #eee;
  }

  .qumfrage .blob:hover,
  .qumfrage .blob2:hover {
    transform: scale(1.05);
  }

  .qumfrage .qactive .blob:hover,
  .qumfrage .qactive .blob2:hover {
    transform: scale(1.1);
  }

  .qumfrage .qdisable .blob:hover,
  .qumfrage .qdisable .blob2:hover {
    transform: scale(1);
  }

  .qumfrage .blob {
    border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
  }

  .qumfrage .blob2 {
    border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
  }

  @media (max-width: 500px) {
    .qumfrage button {
      height: 150px;
    }
  }

  @media (max-width: 960px) {
    .qumfrage {
      text-align: center;
      margin: 20px 0px;
      min-width: 100%;
      max-width: 100%;
    }

    .qumfrage .column {
      width: 50%;
      float: left;
    }

    .qumfrage button {
      margin: 20px 0;
    }
  }
</style>

<svelte:head>

  {#if isIe()}
    <style>
      .nextprevBox {
        margin-left: 700px !important;
      }
    </style>
  {/if}
</svelte:head>

{#if umfragen}
  {#each umfragen as u}
    <div class="qumfrage">
      <h2>{u.Title}</h2>
      <div>
        {@html u.Text}
      </div>
      <div class="columns is-vcentered ">
        <div class="column">
          <button
            class="z1 {z2t === 1 ? 'qdisable' : ''}
            {z1t === 1 ? 'qactive' : ''}"
            on:click|once={z2t === 0 ? handleClick(u._id, 1, u.Zahl1) : ''}>
            <div
              class="blob"
              style="background-image:url({Prodhost}/colletadmin/{u.Bild1.styles[0].path})" />
          </button>
          <p class="qzahl" style="color: #e61974">{z1}</p>
        </div>
        <div class="column">
          <button
            class="z2 {z1t === 1 ? 'qdisable' : ''}
            {z2t === 1 ? 'qactive' : ''}"
            on:click|once={z1t === 0 ? handleClick(u._id, 2, u.Zahl2) : ''}>
            <div
              class="blob2"
              style="background-image:url({Prodhost}/colletadmin/{u.Bild2.styles[0].path})" />
          </button>
          <p class="qzahl" style="color: #156f7b">{z2}</p>
        </div>
      </div>
    </div>
  {/each}
{/if}

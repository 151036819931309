<script>
  import Prodhost from "./host.js";
  import { onMount } from "svelte";
  import Mwelle from "../components/svg/mwelle.svelte";
  import isMobile from "./isMobile.js";

  var ww = window.innerWidth;

  export let kundenTitle;
  export let kundenText;

  function isTablet() {
    if (navigator.userAgent.toLowerCase().match(/tablet/i) || ww <= 1280) {
      return true;
    }
    return false;
  }

  let kunden = [];
  let kundenimg = [];
  let rn = 0;
  let rn2 = 6;
  let l;
  let rkunde;

  function randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/collections/get/Kunden", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sort: { _o: 1 },
        limit: 11
      })
    })
      .then(response => response.json())
      .then(json => {
        kunden = json.entries;
      });

    fetch(Prodhost + "/colletadmin/api/collections/get/Kunden", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sort: { _o: 1 }
      })
    })
      .then(response => response.json())
      .then(json => {
        json.entries.forEach(kImg);
        function kImg(item, index, arr) {
          item.Link.length ? (l = item.Link) : (l = "javascript:void(0);");
          kundenimg.push({
            image: Prodhost + "" + item.Image.path,
            name: item.Name,
            link: l
          });
        }
      });

    var mi = 0;

    if (ww <= 1280 || navigator.userAgent.indexOf("Trident") > -1) {
      setInterval(function() {
        if (mi >= kundenimg.length) {
          mi = 0;
        }

        document.querySelector(".mi img").src = kundenimg[mi].image;
        document.querySelector(".mi img").alt = kundenimg[mi].name;
        setTimeout(() => {
          document.querySelector(".mi img").style.opacity = 1;
        }, 100);
        setTimeout(() => {
          document.querySelector(".mi img").style.opacity = 0;
        }, 900);
        mi++;
      }, 1600);
    }

    if (ww > 1280) {
      var i = 0;
      var orn, orn2;
      setInterval(function() {
        if (i >= kundenimg.length) {
          i = 0;
        }

        rn = randomNumber(0, 4);
        if (rn === orn) {
          if (rn === 0) {
            rn = rn + 1;
          } else {
            rn = rn - 1;
          }
        }

        document.querySelector(".ibox" + rn + " img").src = kundenimg[i].image;
        document.querySelector(".ibox" + rn + " img").alt = kundenimg[i].name;
        document.querySelector(".ibox" + rn + " a").href = kundenimg[i].link;
        i++;

        orn = rn;
      }, 2000);

      setInterval(function() {
        setTimeout(() => {
          if (i >= kundenimg.length) {
            i = 0;
          }

          rn2 = randomNumber(6, 10);
          if (rn2 === orn2) {
            if (rn2 === 6) {
              rn2 = rn2 + 1;
            } else {
              rn2 = rn2 - 1;
            }
          }

          document.querySelector(".ibox" + rn2 + " img").src =
            kundenimg[i].image;
          document.querySelector(".ibox" + rn2 + " img").alt =
            kundenimg[i].name;
          document.querySelector(".ibox" + rn2 + " a").href = kundenimg[i].link;

          i++;

          orn2 = rn2;
        }, 1000);
      }, 2000);
    }
  });
</script>

{#if isTablet()}
  <style>
    #kundensction {
      width: auto;
    }
    #kundensction > .columns {
      width: 600px;
      background: #e61974;
      padding: 0 50px;
      color: #fff;
    }

    #kundensction > .columns h2 {
      color: #fff;
    }

    #kundensction .mibox {
      width: 100%;
      text-align: center;
      float: left;
      display: block;
      position: static;
      height: 200px;
      line-height: 200px;
    }

    #kundensction .mibox .mi {
      width: 100%;
    }

    #kundensction img {
      max-width: 300px;
      max-height: 120px;
    }
  </style>
{/if}
<section class="section" id="kundensction">

  {#if isMobile() || isTablet()}
    <div class="columns is-gapless is-multiline has-text-centered is-vcentered">
      <div class="mwelletop">
        <Mwelle />
      </div>
      <div class={navigator.userAgent.indexOf('Trident') > -1 ? '' : 'column'}>
        <h2>{kundenTitle}</h2>
        <p>{kundenText}</p>
      </div>
      <div class="mibox">

        {#if kunden.length}
          <div class="column is-half mi">
            <img
              src="{Prodhost}{kunden[0].Image.path}"
              alt={kunden[0].Name} />
          </div>
        {/if}
      </div>
      <div class="mwellebottom">
        <Mwelle />
      </div>
    </div>
  {/if}

  {#if !isMobile() && !isTablet()}
    <div class="columns is-gapless is-multiline has-text-centered is-vcentered">
      {#each kunden as kunde, i}
        {#if i == 5}
          <div class="column is-half">
            <h2>{kundenTitle}</h2>
            <p>{kundenText}</p>
          </div>
        {:else}
          <div class="column is-one-quarter ">
            <div class="ibox{i} {rn == i || rn2 == i ? 'anim' : ''}">
              {#if kunde.Link.length}
                <a
                  href={kunde.Link}
                  target="_blank"
                  rel="noopener"
                  class="kundenlink">
                  <img
                    src="{Prodhost}{kunde.Image.path}"
                    alt={kunde.Name} />
                </a>
              {:else}
                <a href="javascript:void(0);" class="kundenlink">
                  <img
                    src="{Prodhost}{kunde.Image.path}"
                    alt={kunde.Name} />
                </a>
              {/if}

              <svg
                viewBox="0 0 500 500"
                class="kundesvg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                  <filter
                    id="dropShoadow{i}"
                    x="-200%"
                    y="-200%"
                    width="400%"
                    height="400%"
                    filterUnits="objectBoundingBox"
                    color-interpolation-filters="sRGB">
                    <feOffset
                      xmlns="http://www.w3.org/2000/svg"
                      in="SourceGraphic"
                      dx="2"
                      dy="10" />
                    <feGaussianBlur
                      xmlns="http://www.w3.org/2000/svg"
                      stdDeviation="2.146804531419514"
                      result="pf_100_offsetBlur" />
                    <feComposite
                      xmlns="http://www.w3.org/2000/svg"
                      in="SourceGraphic"
                      in2="pf_100_offsetBlur"
                      result="pf_100_inverse"
                      operator="out" />
                    <feFlood
                      xmlns="http://www.w3.org/2000/svg"
                      flood-color="#000000"
                      flood-opacity="0.5"
                      result="pf_100_color" />
                    <feComposite
                      xmlns="http://www.w3.org/2000/svg"
                      in="pf_100_color"
                      in2="pf_100_inverse"
                      operator="in"
                      result="pf_100_shadow" />
                    <feComposite
                      xmlns="http://www.w3.org/2000/svg"
                      in="pf_100_shadow"
                      in2="SourceGraphic"
                      operator="over" />
                  </filter>
                </defs>

                {#if i === 1 || i === 7}
                  <g
                    filter="url(#dropShoadow{i})"
                    transform="translate(300,250)">
                    <path
                      d="M93.6,-83.8C121.6,-39.6,144.6,-0.7,145.2,47.7C145.8,96.1,123.9,153.9,81.9,176.7C39.9,199.4,-22.1,187.1,-85.6,162.4C-149.1,137.7,-213.9,100.7,-234.6,43.9C-255.2,-12.9,-231.6,-89.4,-185.1,-137.8C-138.6,-186.2,-69.3,-206.6,-18.2,-192.1C32.8,-177.5,65.7,-128,93.6,-83.8Z"
                      fill="#fff"
                      stroke-width="5"
                      stroke=""
                      stroke-linejoin="miter"
                      stroke-linecap="butt"
                      stroke-miterlimit="4" />
                  </g>
                {/if}
                {#if i === 2 || i === 8}
                  <g
                    filter="url(#dropShoadow{i})"
                    transform="translate(250,250)">
                    <path
                      class="kundensvgblock"
                      d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
                      fill="#fff"
                      stroke-width="5"
                      stroke=""
                      stroke-linejoin="miter"
                      stroke-linecap="butt"
                      stroke-miterlimit="4" />
                  </g>
                {/if}

                {#if i === 3 || i === 9}
                  <g
                    filter="url(#dropShoadow{i})"
                    transform="translate(250,250)">
                    <path
                      class="kundensvgblock"
                      d="M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
                      fill="#fff"
                      stroke-width="5"
                      stroke=""
                      stroke-linejoin="miter"
                      stroke-linecap="butt"
                      stroke-miterlimit="4" />
                  </g>
                {/if}

                {#if i === 0 || i === 4 || i === 10}
                  <g
                    filter="url(#dropShoadow{i})"
                    transform="translate(300,200)">
                    <path
                      class="kundensvgblock"
                      d="M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
                      fill="#fff"
                      stroke-width="5"
                      stroke=""
                      stroke-linejoin="miter"
                      stroke-linecap="butt"
                      stroke-miterlimit="4" />
                  </g>
                {/if}

                {#if i === 6 || i === 11}
                  <g
                    filter="url(#dropShoadow{i})"
                    transform="translate(250,300)">
                    <path
                      class="kundensvgblock"
                      d="M178.7,-150.5C210.4,-102.8,200.2,-29.7,176.1,25.9C152.1,81.5,114,119.6,67.9,142.2C21.8,164.9,-32.3,172.1,-73.5,152.3C-114.8,132.6,-143.2,85.9,-159.1,31.8C-175.1,-22.3,-178.6,-83.8,-150,-130.8C-121.4,-177.8,-60.7,-210.4,6.4,-215.5C73.5,-220.6,147,-198.2,178.7,-150.5Z"
                      fill="#fff"
                      stroke-width="5"
                      stroke=""
                      stroke-linejoin="miter"
                      stroke-linecap="butt"
                      stroke-miterlimit="4" />
                  </g>
                {/if}

              </svg>
            </div>
          </div>
        {/if}
      {/each}
    </div>
  {/if}
</section>

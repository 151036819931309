<script>
  import Prodhost from "../components/host.js";
  import { onMount, onDestroy  } from "svelte";
  import { isActive } from "@sveltech/routify";
  import Header from "../components/Header.svelte";
  import HeaderIe from "../components/HeaderIe.svelte";
  import Footer from "../components/Footer.svelte";
  import isMobile from "../components/isMobile.js";
  import { noindex } from './../global.js';

  import { get } from 'svelte/store';

   
  let page = [];
  let chdetails = false;

  let essenziell = true;
  let statistiken = false;
  let externemedien = false;

  export function chdetailsshow() {
    chdetails = !chdetails;
     
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function eraseCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }

  function setCa() {
    setCookie("ch", "1", 99999);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'cookie_consent',
      'cookie_consent': 'granted'
    });
    location.reload();
  }


  function setC() {

    if( essenziell == true && statistiken == true && externemedien == true ){
      setCookie("ch", "1", 99999);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'cookie_consent',
        'cookie_consent': 'granted'
      });
      location.reload();      
    }

    if( essenziell == false && statistiken == false && externemedien == false ){
        setCookie("ch", "2", 99999);

        location.reload();
    }

    if( essenziell == true && statistiken == false && externemedien == true ){
      setCookie("ch", "3", 99999);
      location.reload();     
    }

    if( essenziell == true && statistiken == false && externemedien == false ){
      setCookie("ch", "4", 99999);
      location.reload();     
    }
    
    if( essenziell == true && statistiken == true && externemedien == false ){
      setCookie("ch", "5", 99999);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'cookie_consent',
        'cookie_consent': 'granted'
      });
      location.reload();     
    }   
   
  }


 

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/singletons/get/seite")
      .then(response => response.json())
      .then(response => {
        page = response;
      });

 

  });

  var sUsrAg = navigator.userAgent;
</script>

<style lang="scss" global>
  @import "../style/global.scss";
  #cookieHinweis {
    opacity: 1;
    transition: opacity 1s;
  }

  @media (max-width: 960px) {
    .contentmain {
      height: auto;
    }

    #cookieHinweis {
      opacity: 0;
      animation: opacity;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
    }

    #projekte_block {
      min-height: 1600px;
    }

    #projekte_block h2 {
      min-height: 100px;
    }

    #projekte_block,
    #projekte_block h2 {
      opacity: 0;
      animation: opacity;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  #spiel {
    position: fixed;
    z-index: 150;
    bottom: 30px;
    left: 30px;
  }

  #spiel p {
    font-size: 14px;
    line-height: 18px;
  }

  #spiel a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 10px;
    border-radius: 200px;
    color: #fff;
    background: #e61974;
   
  }

  #spiel a:hover {
    background: #4a4a4a;

  }

  #spiel .pacman {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    position: relative;
    margin-right: 10px;
  }

  .pacman__eye {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 6px;
    right: 14px;
    background: #e61974;
  }

  .pacman__mouth {
    background: #e61974;
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    animation-name: eat;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    position: relative;
    right: -2px;
  }

  #spiel a:hover .pacman__mouth,
  #spiel a:hover .pacman__eye {
    background: #4a4a4a;
  }

  @keyframes eat {
    0% {
      clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }
    25% {
      clip-path: polygon(100% 60%, 44% 48%, 100% 40%);
    }
    50% {
      clip-path: polygon(100% 50%, 44% 48%, 100% 50%);
    }
    75% {
      clip-path: polygon(100% 59%, 44% 48%, 100% 35%);
    }
    100% {
      clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }
  }
</style>

<svelte:head>
  {#if page.GoogleTagManagerHead && getCookie('ch') === '1'}
    {@html page.GoogleTagManagerHead}
  {/if}
</svelte:head>

{#if page.GoogleTagManagerBody && getCookie('ch') === '1'}
  {@html page.GoogleTagManagerBody}
{/if}

{#if $isActive('/testscroll')}
  <slot />
{:else}
  {#if sUsrAg.indexOf('Trident') > -1}
    <HeaderIe />
  {:else}
    <Header />
  {/if}
  <div id="page">


    {#if !isMobile()  }

   
      <div id="spiel">
        <a href="/perfectmatch/" rel="nofollow" target="_blank">
          <div class="pacman">
            <div class="pacman__eye" />
            <div class="pacman__mouth" />
          </div>

          
          <p>
            Spiel mit uns:
            <br />
            a perfect match?!
          </p>
        </a>
      </div>
   
    {/if}
    {#if $isActive('/index')}
      <div id="index">
        <slot />
      </div>
    {:else if $isActive('/kontakt')}
      <div
        class=" is-fluid contentmain scrollcontentbox"
        style="background:#12151a; padding: 0px; overflow-x: hidden; overflow-y:
        hidden;">
        <slot />
      </div>
    {:else if $isActive('/blog/:slug')}
      <div class="is-fluid">
        <slot />
        <section class="section footersection">
          <Footer />
        </section>
      </div>
    {:else}
      <div class=" is-fluid contentmain scrollcontentbox">
        <slot />
        <section class="section footersection">
          <Footer />
        </section>
      </div>
    {/if}

      <script type="text/javascript">
    _linkedin_partner_id = "3856684";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  </script>

  <script type="text/javascript">
    (function(l) {
      if (!l) {
        window.lintrk = function(a, b) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
  </script>

    <img
      height="1"
      width="1"
      style="display:none;"
      alt=""
      src="https://px.ads.linkedin.com/collect/?pid=3856684&conversionId=7418572&fmt=gif"
    />
 


  </div>
  



  {#if page.CookieHinweisText && getCookie('ch') !== '1'   && getCookie('ch') !== '3'  && getCookie('ch') !== '4'  && getCookie('ch') !== '5'}
  <div id="cookieHinweisBlock">
    <div id="cookieHinweis">
    <h3>  Cookie-Einstellung</h3> 
      <p>
        Um Dir ein optimales Website-Erlebnis zu bieten, verwenden wir Cookies. Einige von ihnen sind essenziell, während andere uns helfen, unsere Website immer weiter zu verbessern. 
         Weitere Infos findest Du in unseren <a href="https://www.collet-cc.de/impressum/">Datenschutzrichtlinien</a>. 
      </p> 
      <div class="chcheck" style="display: flex; flex-direction: row">
        <span><input type="checkbox" name="essenziell" id=""  bind:checked={essenziell}> Essenziell</span>
        <span><input type="checkbox" name="statistiken" id=""  bind:checked={statistiken}> Statistiken</span>
        <span><input type="checkbox" name="externemedien" id=""  bind:checked={externemedien}> Externe Medien</span>
      </div>
  	  <span class="chdetailsclick"   on:click={chdetailsshow}>Details anzeigen</span>
      {#if chdetails}
      <div id="chdetails">
        <span  on:click={chdetailsshow}>x</span>
      <strong>Essenzielle Cookies:</strong> Essenzielle Cookies ermöglichen eine einwandfreie Nutzung unserer Webseite, indem sie z.B. Grundfunktionen wie Seitennavigation ermöglichen. Ohne den Einsatz essenzieller Cookies ist unsere Webseite nicht funktionsfähig. <br> <br> <strong>Statistische Cookies:</strong> Statistische Cookies ermöglichen uns nachzuvollziehen, wie Du mit unserer Webseite interagierst und welche Inhalte Dich am meisten interessiert. So können wir die Performance unserer Webseite ständig verbessern. <br><br><strong>Externe Medien Cookies: </strong>Externe Medien Cookies setzen wir auf unserer Webseite ein, um die Dienste von Drittanbietern, deren Angebote und Inhalte mit einbinden zu können. <br><br> Weitere Infos findest Du in unseren <a href="https://www.collet-cc.de/impressum/">Datenschutzrichtlinien</a>.</div>
      {/if}
        <button on:click={setCa}>Alle akzeptieren</button>
        <button on:click={setC} style="background-color: #ff81b0">Auswahl akzeptieren</button>         
      </div>
     </div>
  {/if}
{/if}



<script>
  import { isActive, url } from "@sveltech/routify";

  const links = [
    ["./index", "Home"],
    ["./projekte", "Projekte"],
    ["./leistungen", "Leistungen"],
    ["./blog", "News & Blog"],
    ["./ueberuns", "Über uns"],
    ["./blog/jobs-in-der-agentur", "Jobs"],
    ["./kontakt", "Kontakt"]
  ];

  let bactive = false;

  function btoggle() {
    bactive = !bactive;
  }
</script>

<style>
  .hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  .navbartop {
    z-index: 1000;
  }

  .logotop.logo-active {
    z-index: 10000000;
  }

  #navMenu.nav-active {
    background-color: #e61974;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    padding: 150px 0px 0 32px;
    opacity: 1;
  }

  #navinfo {
    position: fixed;
    bottom: 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    width: 100%;
    left: 0;
    background: transparent;
    line-height: 30px;
  }

  #navinfo span a.infolink {
    color: #ffffff;
    text-decoration: none;
    position: static;
  }

  #navinfo span a,
  #navinfo span a svg {
    width: 17px;
    height: 17px;
    fill: #fff;
    position: relative;
    top: 1px;
    left: 5px;
  }
</style>

<svg class="hidden">
  <symbol id="icon-arrow" viewBox="0 0 24 24">
    <title>arrow</title>
    <polygon
      points="6.3,12.8 20.9,12.8 20.9,11.2 6.3,11.2 10.2,7.2 9,6 3.1,12 9,18
      10.2,16.8 " />
  </symbol>
</svg>

<nav class="navbar navbartop {bactive ? 'navtop-active' : ''}">
  <a class="navbar-item " href="/" target="_self" id="collet-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="isolation:isolate"
      viewBox="0 0 312.773 89"
      width="150px"
      height="50px"
      class="logotop {bactive ? 'logo-active' : 'logo-normal'}">

      <path
        d=" M 41.892 17.705 C 39.3 14.609 35.538 12.852 30.688 12.852 C 20.821
        12.852 13.547 20.886 13.547 31.934 C 13.547 42.982 20.821 51.017 30.271
        51.017 C 35.538 51.017 39.802 48.674 42.477 44.656 L 53.348 52.774 C
        48.414 59.807 40.053 63.07 31.941 63.07 C 13.465 63.07 0.002 50.934
        0.002 31.934 C 0.002 12.935 13.465 0.799 31.941 0.799 C 38.547 0.799
        46.908 3.059 51.926 9.504 L 41.892 17.705 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 87.723 50.221 C 98.843 50.221 106.118 42.186 106.118 31.138 C
        106.118 20.09 98.843 12.055 87.723 12.055 C 76.603 12.055 69.329 20.09
        69.329 31.138 C 69.329 42.186 76.603 50.221 87.723 50.221 Z M 87.723
        0.005 C 106.202 0.005 119.664 12.139 119.664 31.138 C 119.664 50.138
        106.202 62.274 87.723 62.274 C 69.245 62.274 55.782 50.138 55.782 31.138
        C 55.782 12.139 69.245 0.005 87.723 0.005 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 128.33 2.305 L 141.373 2.305 L 141.373 49.51 L 165.538 49.51 L
        165.538 61.562 L 128.33 61.562 L 128.33 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 172.633 2.305 L 185.678 2.305 L 185.678 49.51 L 209.841 49.51 L
        209.841 61.562 L 172.633 61.562 L 172.633 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 218.983 2.305 L 259.201 2.305 L 259.201 14.359 L 232.025 14.359 L
        232.025 25.407 L 257.696 25.407 L 257.696 37.458 L 232.025 37.458 L
        232.025 49.51 L 260.705 49.51 L 260.705 61.562 L 218.983 61.562 L
        218.983 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 294.242 2.305 L 284.577 2.305 L 266.031 2.305 L 266.031 13.856 L
        282.854 13.856 L 282.854 61.562 L 282.919 61.562 L 295.898 61.562 L
        295.965 61.562 L 295.965 13.856 L 312.787 13.856 L 312.787 2.305 L
        294.242 2.305 Z "
        fill="rgb(55,53,53)" />
      <path
        d=" M 79.359 31.138 C 79.359 26.515 83.103 22.767 87.723 22.767 C 92.343
        22.767 96.088 26.515 96.088 31.138 C 96.088 35.763 92.343 39.511 87.723
        39.511 C 83.103 39.511 79.359 35.763 79.359 31.138 Z "
        fill="rgb(236,58,130)" />
      <path
        d=" M 8.48 77.688 C 7.832 76.806 6.796 76.357 5.745 76.357 C 3.304
        76.357 1.573 78.507 1.573 80.905 C 1.573 83.442 3.288 85.453 5.745
        85.453 C 7.074 85.453 8.14 84.91 8.913 83.92 L 9.886 84.741 C 8.913
        86.056 7.491 86.658 5.745 86.658 C 2.608 86.658 0.182 84.23 0.182 80.905
        C 0.182 77.688 2.5 75.151 5.745 75.151 C 7.228 75.151 8.681 75.662 9.608
        76.868 L 8.48 77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 24.659 80.905 C 24.659 78.383 22.897 76.357 20.333 76.357 C 17.768
        76.357 16.006 78.383 16.006 80.905 C 16.006 83.426 17.768 85.453 20.333
        85.453 C 22.897 85.453 24.659 83.426 24.659 80.905 Z M 14.615 80.905 C
        14.615 77.595 17.01 75.151 20.333 75.151 C 23.655 75.151 26.05 77.595
        26.05 80.905 C 26.05 84.216 23.655 86.658 20.333 86.658 C 17.01 86.658
        14.615 84.216 14.615 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 31.69 75.429 L 33.329 75.429 L 39.664 84.525 L 39.694 84.525 L
        39.694 75.429 L 40.992 75.429 L 40.992 86.379 L 39.355 86.379 L 33.02
        77.286 L 32.988 77.286 L 32.988 86.379 L 31.69 86.379 L 31.69 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 54.93 77.688 C 54.282 76.806 53.247 76.357 52.196 76.357 C 49.754
        76.357 48.024 78.507 48.024 80.905 C 48.024 83.442 49.739 85.453 52.196
        85.453 C 53.525 85.453 54.591 84.91 55.364 83.92 L 56.337 84.741 C
        55.364 86.056 53.941 86.658 52.196 86.658 C 49.059 86.658 46.633 84.23
        46.633 80.905 C 46.633 77.688 48.95 75.151 52.196 75.151 C 53.679 75.151
        55.133 75.662 56.058 76.868 L 54.93 77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 61.699 75.429 L 68.576 75.429 L 68.576 76.636 L 62.997 76.636 L
        62.997 80.07 L 68.205 80.07 L 68.205 81.276 L 62.997 81.276 L 62.997
        85.174 L 68.854 85.174 L 68.854 86.379 L 61.699 86.379 L 61.699 75.429 Z
        "
        fill="rgb(133,132,135)" />
      <path
        d=" M 75.715 80.256 L 77.908 80.256 C 79.207 80.256 80.103 79.668 80.103
        78.446 C 80.103 77.223 79.207 76.636 77.908 76.636 L 75.715 76.636 L
        75.715 80.256 Z M 74.417 75.429 L 77.663 75.429 C 79.671 75.429 81.494
        76.141 81.494 78.43 C 81.494 80.828 79.454 81.462 77.908 81.462 L 75.715
        81.462 L 75.715 86.379 L 74.417 86.379 L 74.417 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 94.474 76.636 L 90.858 76.636 L 90.858 86.379 L 89.56 86.379 L
        89.56 76.636 L 85.944 76.636 L 85.944 75.429 L 94.474 75.429 L 94.474
        76.636 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 104.75 77.363 C 104.318 76.666 103.544 76.357 102.756 76.357 C
        101.613 76.357 100.424 76.898 100.424 78.197 C 100.424 79.203 100.98
        79.652 102.725 80.209 C 104.426 80.751 106.002 81.307 106.002 83.456 C
        106.002 85.638 104.132 86.658 102.154 86.658 C 100.887 86.658 99.527
        86.24 98.786 85.143 L 99.898 84.23 C 100.362 85.034 101.288 85.453
        102.231 85.453 C 103.344 85.453 104.61 84.802 104.61 83.549 C 104.61
        82.204 103.699 81.971 101.753 81.322 C 100.207 80.811 99.033 80.131
        99.033 78.307 C 99.033 76.203 100.841 75.151 102.756 75.151 C 103.978
        75.151 105.013 75.49 105.817 76.433 L 104.75 77.363 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 120.419 73.139 L 120.419 88.606 L 119.399 88.606 L 119.399 73.139
        L 120.419 73.139 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 142.052 77.688 C 141.403 76.806 140.369 76.357 139.317 76.357 C
        136.877 76.357 135.145 78.507 135.145 80.905 C 135.145 83.442 136.861
        85.453 139.317 85.453 C 140.647 85.453 141.712 84.91 142.486 83.92 L
        143.458 84.741 C 142.486 86.056 141.063 86.658 139.317 86.658 C 136.18
        86.658 133.754 84.23 133.754 80.905 C 133.754 77.688 136.073 75.151
        139.317 75.151 C 140.8 75.151 142.254 75.662 143.181 76.868 L 142.052
        77.688 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 158.232 80.905 C 158.232 78.383 156.47 76.357 153.905 76.357 C
        151.339 76.357 149.578 78.383 149.578 80.905 C 149.578 83.426 151.339
        85.453 153.905 85.453 C 156.47 85.453 158.232 83.426 158.232 80.905 Z M
        148.188 80.905 C 148.188 77.595 150.583 75.151 153.905 75.151 C 157.227
        75.151 159.623 77.595 159.623 80.905 C 159.623 84.216 157.227 86.658
        153.905 86.658 C 150.583 86.658 148.188 84.216 148.188 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 165.263 75.429 L 167.194 75.429 L 170.903 84.014 L 170.933 84.014
        L 174.673 75.429 L 176.559 75.429 L 176.559 86.379 L 175.261 86.379 L
        175.261 77.193 L 175.23 77.193 L 171.305 86.379 L 170.517 86.379 L
        166.592 77.193 L 166.561 77.193 L 166.561 86.379 L 165.263 86.379 L
        165.263 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 182.833 75.429 L 184.764 75.429 L 188.472 84.014 L 188.504 84.014
        L 192.243 75.429 L 194.128 75.429 L 194.128 86.379 L 192.83 86.379 L
        192.83 77.193 L 192.799 77.193 L 188.874 86.379 L 188.087 86.379 L
        184.161 77.193 L 184.131 77.193 L 184.131 86.379 L 182.833 86.379 L
        182.833 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 208.608 82.204 C 208.608 84.586 207.495 86.658 204.481 86.658 C
        201.468 86.658 200.356 84.586 200.356 82.204 L 200.356 75.429 L 201.654
        75.429 L 201.654 82.018 C 201.654 83.781 202.304 85.453 204.481 85.453 C
        206.661 85.453 207.309 83.781 207.309 82.018 L 207.309 75.429 L 208.608
        75.429 L 208.608 82.204 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 214.835 75.429 L 216.473 75.429 L 222.808 84.525 L 222.839 84.525
        L 222.839 75.429 L 224.137 75.429 L 224.137 86.379 L 222.499 86.379 L
        216.164 77.286 L 216.132 77.286 L 216.132 86.379 L 214.835 86.379 L
        214.835 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 230.411 75.429 L 231.71 75.429 L 231.71 86.379 L 230.411 86.379 L
        230.411 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 245.647 77.688 C 244.998 76.806 243.963 76.357 242.912 76.357 C
        240.471 76.357 238.74 78.507 238.74 80.905 C 238.74 83.442 240.456
        85.453 242.912 85.453 C 244.241 85.453 245.308 84.91 246.08 83.92 L
        247.054 84.741 C 246.08 86.056 244.658 86.658 242.912 86.658 C 239.776
        86.658 237.35 84.23 237.35 80.905 C 237.35 77.688 239.668 75.151 242.912
        75.151 C 244.396 75.151 245.849 75.662 246.775 76.868 L 245.647 77.688 Z
        "
        fill="rgb(133,132,135)" />
      <path
        d=" M 256.557 77.1 L 256.526 77.1 L 254.255 82.482 L 258.721 82.482 L
        256.557 77.1 Z M 255.97 75.429 L 257.191 75.429 L 261.842 86.379 L
        260.328 86.379 L 259.215 83.688 L 253.745 83.688 L 252.617 86.379 L
        251.149 86.379 L 255.97 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 272.767 76.636 L 269.151 76.636 L 269.151 86.379 L 267.853 86.379
        L 267.853 76.636 L 264.236 76.636 L 264.236 75.429 L 272.767 75.429 L
        272.767 76.636 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 277.851 75.429 L 279.15 75.429 L 279.15 86.379 L 277.851 86.379 L
        277.851 75.429 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 294.833 80.905 C 294.833 78.383 293.072 76.357 290.507 76.357 C
        287.941 76.357 286.18 78.383 286.18 80.905 C 286.18 83.426 287.941
        85.453 290.507 85.453 C 293.072 85.453 294.833 83.426 294.833 80.905 Z M
        284.789 80.905 C 284.789 77.595 287.184 75.151 290.507 75.151 C 293.83
        75.151 296.224 77.595 296.224 80.905 C 296.224 84.216 293.83 86.658
        290.507 86.658 C 287.184 86.658 284.789 84.216 284.789 80.905 Z "
        fill="rgb(133,132,135)" />
      <path
        d=" M 301.864 75.429 L 303.503 75.429 L 309.839 84.525 L 309.869 84.525
        L 309.869 75.429 L 311.168 75.429 L 311.168 86.379 L 309.53 86.379 L
        303.193 77.286 L 303.163 77.286 L 303.163 86.379 L 301.864 86.379 L
        301.864 75.429 Z "
        fill="rgb(133,132,135)" />

    </svg>

  </a>
  <div
    class="{bactive ? 'is-active' : ''} navbar-burger burger"
    on:click={btoggle}
    data-target="navMenu">
    <span />
    <span />
    <span />
  </div>
  <div class="navbar-menu {bactive ? 'nav-active' : ''} " id="navMenu">
    {#each links as [path, name]}
      <a
        href={$url(path)}
        class:active={$isActive(path)}
        on:click={btoggle}
        target="_self">
        {name}
      </a>
    {/each}

    <div id="navinfo" class="is-hidden-mobile">
      <span>
        <a href="/impressum/" target="_self" class="infolink">
          Impressum & Datenschutz
        </a>
        |
        <a href="tel:05241 50 56 664" class="infolink callForm">05241 50 56 664</a>
        |
        <a href="mailto:info@collet-cc.de" class="infolink mailForm">
          info@collet-cc.de
        </a>
        |
        <a
          href="https://www.xing.com/companies/colletconcepts%7Ccommunication"
          target="_blank"
          rel="noopener"
          title="Collet Xing">
          <svg aria-hidden="true" viewBox="0 0 384 512">
            <path
              d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7
              12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1
              0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18
              12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2
              14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8
              51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3
              6.7 8.5 14.1z" />
          </svg>
        </a>
      </span>
    </div>

  </div>
</nav>

<script>
  export let size = 0;
  export let text = "";
  export let delay = 200;

  export let animation = "";

  export let id;
  let cls;
  export { cls as class };
  export let style;

  export let dotColor = 'rgb(236,58,130)';

  const TEXT_DELAY = 1000;
</script>

<style>
    .ColletRotationC_wrapper {
        color: #e61974;
        line-height: 3.7em;
    }

    .ColletRotationC_wrapper {
        display: flex;
        align-items: center;
    }

    .ColletRotationC_svg {
        width: 3.75em;
        height: 3.75em;
        margin-right: -0.875em;
        flex: none;
    }

    .ColletRotationC_text {
        flex: none;
        animation-duration: 1s;
        animation-direction: normal;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .ColletRotationC_text[animate] {
        animation-name: ColletRotationC_opacity;
    }

    .ColletRotationC_cPath {
        animation-duration: 1s;
        animation-direction: normal;
        animation-timing-function: linear;

        transform-origin: center;
    }

    .ColletRotationC_cPath[animate] {
        animation-name: ColletRotationC_spin;
    }

    .ColletRotationC_cPath[animate='loop'] {
        animation-iteration-count: infinite;
    }

    @keyframes ColletRotationC_spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes ColletRotationC_opacity {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>

<div class={'colletBtn ColletRotationC_wrapper' + (cls ? ' ' + cls : '')}
  style={(style ? style : '') + (size ? 'font-size: ' + size + 'px;' : '')} id={id ? id : undefined}>
  <svg class="ColletRotationC_svg" viewBox="0 0 50 50">
    <defs>
      <clipPath>
        <rect width="50" height="50" />
      </clipPath>
    </defs>
    <path
      d=" M 16.166 24.913 C 16.166 20.295 19.91 16.552 24.531 16.552 C 29.15
          16.552 32.895 20.295 32.895 24.913 C 32.895 29.534 29.15 33.278 24.531
          33.278 C 19.91 33.278 16.166 29.534 16.166 24.913 Z "
      fill="{dotColor}" />
    <path class="ColletRotationC_cPath" animate={animation ? animation : undefined}
      style={(delay ? 'animation-delay: ' + delay + 'ms;' : '')}
      d=" M 40.868 5.724 C 36.55 2.172 31.022 0.038 25 0.038 C 11.202 0.038
          0 11.24 0 25.038 L 0 25.038 C 0 38.835 11.202 50.037 25 50.037 C
          31.747 50.037 37.874 47.359 42.373 43.008 L 36.142 36.776 C 33.239
          39.533 29.315 41.225 25 41.225 C 16.066 41.225 8.813 33.972 8.813
          25.038 L 8.813 25.038 C 8.813 16.103 16.066 8.85 25 8.85 C 28.589 8.85
          31.907 10.021 34.592 12 L 40.868 5.724 Z "
      fill="rgb(55,53,53)" />
  </svg>
  {#if text}

    <div class="omein ColletRotationC_text" animate={animation ? animation : undefined}
      style={(animation ? 'opacity: 0;' : '') + (delay ? 'animation-delay: ' + (parseFloat(delay) + TEXT_DELAY) + 'ms;' : '')}>{text}</div>
  {/if}
</div>
<script>
  import Prodhost from "../../components/host.js";
  import { onMount } from "svelte";
  import anime from "animejs";
  import { params, url } from "@sveltech/routify";
  import ScrollMagic from "scrollmagic";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import ColletButton from "../../components/Button.svelte";
  import Kunden from "../../components/Kunden.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";
  import isMobile from "../../components/isMobile.js";

  // import "lazysizes";

  function ifSafari() {
    var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (iOS) {
      return true;
    }
  }

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  let news = [];
  let newsSeite = [];
  let newsCount;
  let mehrDisable = 0;
  let nn = 0;
  let cp = 0;

  $: mehrDisable;

  function updateShow(nn) {
    fetch(Prodhost + "/colletadmin/api/collections/get/News", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        news = json.entries;
        news.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });
        mehrDisable = 1;
      });
  }

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  async function hashchange() {
    await fetch(Prodhost + "/colletadmin/api/collections/get/News", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        news = json.entries;
        news.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });
        news.length = 6;
      });

    await fetch(Prodhost + "/colletadmin/api/singletons/get/news")
      .then(response => response.json())
      .then(json => {
        newsSeite = json;
      });

    await fetch(Prodhost + "/colletadmin/api/collections/get/News", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        newsCount = json.entries.length;
      });
  }

  onMount(() => {
    hashchange();
    // document.addEventListener("lazybeforeunveil", function(e) {
    //   var bg = e.target.getAttribute("data-bg");
    //   if (bg) {
    //     e.target.style.backgroundImage = "url(" + bg + ")";
    //   }
    // });

    if (ww > 1440) {
      setTimeout(() => {
        anime({
          targets: "#sliderblob .bblob",
          duration: 40000,
          easing: "linear",
          d: [
            {
              value:
                "M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z"
            },
            {
              value:
                "M137.1,-110.4C159.1,-81.8,145.3,-25.9,137.9,41.5C130.6,108.9,129.5,187.8,95.9,207C62.3,226.1,-3.8,185.6,-58,148.6C-112.2,111.7,-154.6,78.3,-167.8,34.3C-181.1,-9.7,-165.2,-64.4,-132.4,-95.4C-99.6,-126.4,-49.8,-133.7,3.9,-136.8C57.6,-139.9,115.2,-138.9,137.1,-110.4Z"
            },

            {
              value:
                "M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }, 1000);
    } else {
      if (!ifSafari()) {
        anime({
          targets: "#sliderblob .bblob",
          easing: "linear",
          duration: 20000,
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true
        });
      }
    }

    setTimeout(() => {
      if (ww > 960 && !isMobile()) {
        var controller = new ScrollMagic.Controller({ vertical: false });
        var scene = new ScrollMagic.Scene({
          triggerElement: "#moreNewsBox",
          duration: ww,
          // offset: 50,
          triggerHook: 0.99
        })
          .on("enter", function() {
            if (cp === 0) {
              anime({
                targets: "#moreNewsBox",
                scale: [0, 1.2],
                opacity: [0, 1],
                duration: 500
              });
              anime({
                targets: "#moreNewsBox h3",
                scale: [0, 1],
                duration: 700
              });
              cp = 1;
            }
          })
          .addTo(controller);
      } else {
        anime({
          targets: "#moreNewsBox",
          scale: [0, 1.2],
          opacity: [0, 1],
          duration: 500
        });
        anime({
          targets: "#moreNewsBox h3",
          scale: [0, 1],
          duration: 700
        });
      }
    }, 700);

    const el = document.querySelector(".contentmain");
    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;

        anime({
          targets: "#bgElement1",
          duration: 1500,
          opacity: 0.1,
          scale: 2,
          translateX: x / 5,
          easing: "linear",
          loop: false
        });

        anime({
          targets: ".prjanim",
          duration: 2000,
          opacity: 1,
          scale: 1,
          translateX: x / -30,
          easing: "linear",
          loop: false
        });
      };
    }
  });
</script>

<style>
  .mainslider {
    margin-right: 350px !important;
  }
  .newsBox {
    width: auto;
    min-width: 400px;
    height: 100vh;
    margin: 0 70px;
    position: relative;
  }
  @keyframes move {
    0% {
      transform: scale(1) translate(0, 0);
    }
    50% {
      transform: scale(1) translate(-10vw, 0);
    }
    100% {
      transform: scale(1) translate(0, 0);
    }
  }

  h2.title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 40px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  p.subtitle {
    font-size: 18px;
  }

  .imageBtn,
  .imageBtn img {
    width: 50vh;
    height: 50vh;
    max-width: 400px;
    max-height: 400px;
    text-align: center;
    margin: 0 auto;
  }

  .newsBoxU {
    position: absolute;
    top: 5%;
  }

  .newsBoxD {
    position: absolute;
    bottom: 5%;
  }

  .cbtn {
    width: 100%;
    position: relative;
    left: 30%;
  }

  #moreNewsBox {
    opacity: 0;
    transform-origin: center;
  }

  .moreNewsBox {
    top: 40vh;
    position: absolute;
    left: 0;
    cursor: pointer;
    transform: scale(1.5);
    z-index: 10000;
  }

  .moreNewsBox h3 {
    color: #e61974;
    position: relative;
    z-index: 100;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    width: 250px;
    left: -20px;
    top: -10px;
    filter: drop-shadow(2px 2px 2px #999);
  }

  .moreNewsBox svg {
    width: 250px;
    height: 250px;
    position: relative;
    top: -160px;
    font-weight: 400;
  }
  .moreNewsBox svg path {
    fill: #156f7b;
    opacity: 0.25;
  }

  .moreNewsBox svg path.pblob {
    transform: scaleY(1) scaleX(1.2) translateY(180px) translateX(280px);
    transform-origin: center;
  }

  .moreNewsBox svg path.arrow {
    transform: rotateY(0deg) translateX(0px) translateY(0px) scale(1);
    opacity: 1;
    transition-duration: 0.5s;
    transform-origin: center;
  }

  .moreNewsBox:hover svg path.arrow {
    transform: rotateY(0deg) translateX(20px) translateY(0px) scale(1);
    opacity: 1;
    transition-duration: 0.5s;
  }

  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  .moreNews {
    margin-left: 200px;
  }

  @media screen and (max-width: 1024px) {
 
    #msvg {
      object-fit: cover;
      height: calc(100vh - 2px);
      max-height: calc(100vh - 2px);
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
    }

    #mainbild,
    .mainslider {
      overflow: hidden;
    }
  }
</style>

<section class="section mainslider">

  <div class="mwelletop">
    <Mwelle />
  </div>

  <div>

    {#if isIe()}
      {#if newsSeite.Bild}
        <svg id="mainbild">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            xlink:href="{Prodhost}/colletadmin/{newsSeite.Bild.styles[0].path}"
            clip-path="url(#sliderblob)" />
        </svg>
      {/if}
    {:else}
      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
            }
          </style>
        {/if}
        <div id="msvg" />
      </div>
    {/if}
  </div>
  <div id="blslider">
    <BlobSlider />
    <div class="sliderTextBox">
      <div>
        {#if newsSeite.Title}
          <h1>
            {@html newsSeite.Title}
          </h1>
        {/if}
        {#if newsSeite.Text}
          <p>
            {@html newsSeite.Text}
          </p>
        {/if}
      </div>
    </div>
  </div>

  <Mwelle />
</section>

{#each news as n, i}
  <section class=" newsBox">
    <div class=" {i % 2 ? 'newsBoxD' : 'newsBoxU'} ">
      <a href={$url('../' + n.slug + '/')} target="_self">
        {#if n.Image}
          <figure class="image imageBtn">
            <img
              alt={n.Image.description !== '' ? n.Image.description : n.Title.replace('&shy;', ' ')}
              class="is-rounded"
              src="{Prodhost}/colletadmin/{n.Image.styles[0].path}" />
          </figure>
        {/if}
      </a>
      <div>
        {#if n.Title}
          <h2 class="title">
            {@html n.Title}
          </h2>
        {/if}
        {#if n.Text}
          <p class="subtitle">
            {@html n.Text.substring(0, 160)
              .split(' ')
              .reverse()
              .slice(1)
              .reverse()
              .join(' ') + ' ...'}
          </p>
        {/if}
        <div class="cbtn">
          <ColletButton href={$url('../' + n.slug + '/')} link="mehr lesen" />
        </div>
      </div>
    </div>
  </section>
{/each}

{#if mehrDisable === 0}
  <section class="section moreNews">
    <div id="moreNewsBox" class="moreNewsBox" on:click={updateShow}>
      <h3>Mehr laden</h3>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 600 304"
        style="enable-background:new 0 0 600 304;"
        xml:space="preserve">
        <path
          class="pblob"
          d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z" />
        <path
          class="arrow"
          d="M108.6,236.8h442.7l-20.8,20.8c-2.8,2.8-2.8,7.6,0,10.4s7.6,2.8,10.4,0l33-33c0,0,0.5,0,0.5-0.5
          c1.9-1.9,2.4-4.2,1.9-6.1c0-0.5,0-0.5,0-0.9c-0.5-0.9-0.9-1.9-1.4-2.4c0-0.5,0-0.5-0.5-0.9c0,0-0.5,0-0.5-0.5l-33-33
          c-2.8-2.8-7.6-2.8-10.4,0c-2.8,2.8-2.8,7.6,0,10.4l20.8,20.8H108.6c-4.2,0-7.6,3.3-7.6,7.6S104.3,236.8,108.6,236.8z" />
      </svg>
    </div>
  </section>
{/if}

{#if ww > 1440}
  <div class="svgclippath">
    <svg viewBox="0 0 398.6 435.4" width="0" height="0">
      <defs>
        <clipPath
          id="sliderblob"
          clipPathUnits="objectBoundingBox"
          transform="scale(0.0027, 0.0037)">
          <path
            style=" transform: translate(200px, 125px);"
            transform=" translate(200, 125)"
            class="bblob"
            d="M118.9,-93.8C150.7,-54.9,170.9,-5.6,163.6,41.8C156.3,89.1,121.5,134.7,76.2,155.5C30.8,176.3,-25.2,172.4,-79.3,151.4C-133.4,130.4,-185.6,92.4,-188,51.6C-190.4,10.8,-142.9,-32.6,-103.2,-73.3C-63.6,-114,-31.8,-152,5.9,-156.7C43.5,-161.4,87,-132.7,118.9,-93.8Z" />
        </clipPath>
      </defs>
    </svg>
  </div>
{:else}
  <div class="svgclippath">
    <svg viewBox="0 0 1200 900" width="0" height="0">
      <clipPath
        id="sliderblob"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0008, 0.002)">
        <path
          transform="translate(-180, 0)"
          class="bblob"
          d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
          143.61c-4.55,95.55,170.07,212.41,10.95,462.77
          C1005.43,799.35,1195.72,900.48,1200,900z" />
      </clipPath>
    </svg>
  </div>
{/if}

import Prodhost from "./components/host";

export function ifSafari() {
  var iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  if (iOS) {
    return true;
  }
}

export function isIe() {
  if (
    /MSIE 9/i.test(navigator.userAgent) ||
    /rv:11.0/i.test(navigator.userAgent) ||
    /Edge\/\d./i.test(navigator.userAgent)
  ) {
    return true;
  }
  return false;
}

export function getRandomString(length) {
  let result = "";
  while (result.length < length) {
    result += Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "");
  }

  return result.substr(0, length);
}

export function nextFrame() {
  return new Promise(resolve => {
    window.requestAnimationFrame(() => {
      resolve();
    });
  });
}

export function loadUmfrage(id) {
  return fetch(Prodhost + "/colletadmin/api/collections/get/umfragen", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      filter: { _id: { $in: [id] } }
    })
  })
    .then(res => res.json())
    .then(res => {
      return res.entries[0];
    });
}

export function saveUmfrageValue(id, zahl, value) {
  return fetch(Prodhost + "/colletadmin/api/collections/save/umfragen", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      data: { _id: [id], ["Zahl" + zahl]: value }
    })
  }).then(res => res.json());
}

export async function increaseUmfrageValue(id, zahl) {
  let umfrage = await loadUmfrage(id);
  umfrage["Zahl" + zahl] = parseInt(umfrage["Zahl" + zahl]) + 1;
  await saveUmfrageValue(id, zahl, umfrage["Zahl" + zahl]);
  return umfrage;
}

var PIXEL_PER_LINE = 16;
export function getWheelDelta(e, prop, noFallback) {
  var delta;
  if ((e[prop] === 0 || e[prop] == null) && noFallback !== true) {
    delta = e.wheelDelta || 0;
  } else {
    delta = e[prop] || 0;
    if (e.deltaMode === 1) {
      delta *= PIXEL_PER_LINE;
    }
  }
  return delta;
}

export function getWheelDeltaY(e, noFallback) {
  return getWheelDelta(e, "deltaY", noFallback);
}

export function getWheelDeltaX(e, noFallback) {
  return getWheelDelta(e, "deltaX", noFallback);
}

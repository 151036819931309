<script>
  import anime from "animejs";
  import { onMount } from "svelte";

  onMount(() => {
    anime({
      targets: ".line-drawing-demo .lines path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 7000,
      delay: function(el, i) {
        return i * 250;
      },
      direction: "alternate",
      loop: true
    });
  });
</script>

<div class="demo-content align-center line-drawing-demo">
  <svg viewBox="0 0 280 100">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-width="0.5"
      class="lines">
      <path
        class="el"
        d=" M 60.376 8.995 C 54.572 4.219 47.141 1.35 39.045 1.35 C 20.497 1.35
        5.438 16.409 5.438 34.958 L 5.438 34.958 C 5.438 53.506 20.497 68.565
        39.045 68.565 C 48.115 68.565 56.351 64.964 62.4 59.115 L 54.023 50.738
        C 50.12 54.443 44.846 56.718 39.045 56.718 C 27.035 56.718 17.284 46.968
        17.284 34.958 L 17.284 34.958 C 17.284 22.947 27.035 13.197 39.045
        13.197 C 43.87 13.197 48.33 14.77 51.94 17.432 L 60.376 8.995 Z "
        style="stroke-dashoffset: 0px;"
        stroke-dasharray="316.85528564453125" />
      <path
        class="el"
        d=" M 27.17 34.791 C 27.17 28.583 32.203 23.55 38.414 23.55 C 44.625
        23.55 49.658 28.583 49.658 34.791 C 49.658 41.002 44.625 46.036 38.414
        46.036 C 32.203 46.036 27.17 41.002 27.17 34.791 Z "
        style="stroke-dashoffset: 0px;"
        stroke-dasharray="316.85528564453125" />
    </g>
  </svg>
</div>

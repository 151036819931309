<script>
  export let checked = false;
  export let id;
  export let text;
</script>

<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switchslider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255, 0.7);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .switchslider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #2c2d30;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .switchslider {
    background-color: #e61974;
  }

  input:checked + .switchslider {
    box-shadow: 0 0 1px #e61974;
  }

  input:checked + .switchslider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  @media screen and (max-width: 1024px) {
    .switch > span {
      font-size: 12px;
    }
  }
</style>

<div>
  <label class="switch" for={id}>
    {#if text !== ''}
      <span
        style=" margin-top: 35px; display: block; text-align: left; position:
        absolute;">
        {text}
      </span>
    {/if}
    <input type="checkbox" bind:checked {id} />
    <span class="switchslider" />
  </label>
</div>

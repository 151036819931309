<script>
  export let href = "/";
  export let link = "";
  export let title = "";
</script>

<div class="colletBtn">
  <a {href} {title} target="_self">
    <svg
      viewBox="0 0 50 50"
      width="50px"
      height="50px">
      <defs>
        <clipPath>
          <rect width="50" height="50" />
        </clipPath>
      </defs>
      <path
        d=" M 16.166 24.913 C 16.166 20.295 19.91 16.552 24.531 16.552 C 29.15
        16.552 32.895 20.295 32.895 24.913 C 32.895 29.534 29.15 33.278 24.531
        33.278 C 19.91 33.278 16.166 29.534 16.166 24.913 Z "
        fill="rgb(236,58,130)" />
      <path
        class="crotate"
        d=" M 40.868 5.724 C 36.55 2.172 31.022 0.038 25 0.038 C 11.202 0.038 0
        11.24 0 25.038 L 0 25.038 C 0 38.835 11.202 50.037 25 50.037 C 31.747
        50.037 37.874 47.359 42.373 43.008 L 36.142 36.776 C 33.239 39.533
        29.315 41.225 25 41.225 C 16.066 41.225 8.813 33.972 8.813 25.038 L
        8.813 25.038 C 8.813 16.103 16.066 8.85 25 8.85 C 28.589 8.85 31.907
        10.021 34.592 12 L 40.868 5.724 Z "
        fill="rgb(55,53,53)" />
    </svg>
    {link}
  </a>
</div>

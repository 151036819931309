export default [
    { name: "start", x: 0.35, y: 0.5, z: 0.2 },
    // { name: "design1", x: 4.86, y: 0.5, z: 3.2 },
    // { name: "design2", x: 3.75, y: 0.5, z: 4.45 },
    // { name: "design3", x: 2.623, y: 0.5, z: 5.74 },
    // { name: "design4", x: 1.35, y: 0.5, z: 7.5 },
    { name: "digital", x: 11.1, y: 0.5, z: 3.8 },
    { name: "digita2", x: 11.1, y: 0.5, z: 5.5 },
    // { name: "digita3", x: 11.1, y: 0.5, z: 7 },
    { name: "mf1", x: 11.1, y: 0.5, z: -2.1 },
    { name: "mf2", x: 13.6, y: 0.5, z: -0.4 },
    { name: "mf3", x: 14.4, y: 0.5, z: 1.2 },
    // { name: "mf4", x: 15.4, y: 0.5, z: 2.8 },
    // { name: "mf5", x: 15.4, y: 0.5, z: 4.6 },
    // { name: "komun1", x: 4.52, y: 0.5, z: -5.05 },
    // { name: "komun2", x: 6.416, y: 0.5, z: -6.6 },
    // { name: "komun3", x: 8.32, y: 0.5, z: -8.18 },
    // { name: "onlinemarketing0", x: -4.8, y: 0.5, z: -4.247 },
    // { name: "onlinemarketing11", x: -3, y: 0.5, z: -6 },
    // { name: "onlinemarketing12", x: -1.45, y: 0.5, z: -7.8 },
    // { name: "onlinemarketing13", x: 0.2, y: 0.5, z: -9.6 },
    // { name: "onlinemarketing21", x: -6, y: 0.5, z: -5.6 },
    // { name: "onlinemarketing22", x: -4.9, y: 0.5, z: -6.8 },
    // { name: "onlinemarketing23", x: -3.8, y: 0.5, z: -7.9 },
    // { name: "contentmarketing1", x: -10.9, y: 0.5, z: -0.32 },
    { name: "contentmarketing2", x: -11.95, y: 0.5, z: -1.8 },
    { name: "contentmarketing3", x: -11.95, y: 0.5, z: -3.6 },
    { name: "contentmarketing4", x: -11.95, y: 0.5, z: -5.7 },
    // { name: "contentmarketing5", x: -11.95, y: 0.5, z: -6.5 },
    // { name: "contentmarketing6", x: -11.95, y: 0.5, z: -8.1 },
    // { name: "branding1", x: -10.9, y: 0.5, z: 1.45 },
    // { name: "branding2", x: -11.95, y: 0.5, z: 3.5 },
    // { name: "branding3", x: -11.95, y: 0.5, z: 4.8 },
    // { name: "branding4", x: -11.95, y: 0.5, z: 6.2 },
    // { name: "event1", x: -5.6, y: 0.5, z: 5.4 },
    // { name: "event2", x: -6.75, y: 0.5, z: 6.65 },
    // { name: "event3", x: -8, y: 0.5, z: 8 }
];
<script>
  import Prodhost from "./host.js";

  import { onMount } from "svelte";
  import SimpleLightbox from "simple-lightbox";
  import isMobile from "./isMobile.js";
  import { Swiper, Navigation, EffectCoverflow } from "swiper/js/swiper.esm.js";

  Swiper.use([Navigation, EffectCoverflow]);

  export let TourTitle;
  export let TourText;

  let tour = [];
  let newsslider;

  let direct = "horizontal";
  let ww = window.innerWidth;
  if (!isMobile()) {
    direct = "vertical";
  }
  if (ww >= 960) {
    direct = "vertical";
  }

  onMount(() => {
    fetch(Prodhost+"/colletadmin/api/collections/get/tour")
      .then(response => response.json())
      .then(json => {
        tour = json.entries;

        setTimeout(() => {
          var swiper = new Swiper(".swiper-container", {
            direction: "horizontal",
            slidesPerView: 3,
            effect: "coverflow",
            speed: 700,
            simulateTouch: false,
            allowTouchMove: false,
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true
            },
            loop: true,
            pagination: false,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev"
            },
            breakpoints: {
              960: {
                direction: "vertical"
              }
            }
          });
          new SimpleLightbox({ elements: ".swiper-container a" });
        }, 500);
      });
  });
</script>

<div id="projektS">
  <div class="takeTour">
    <div>
      <h3>{TourTitle}</h3>
      <p>{TourText}</p>
      <span />
    </div>
  </div>
  <div class="projektSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        {#each tour as t}
          <div class="swiper-slide">
            <a
              href="{Prodhost}/colletadmin/{t.Bild.styles[1].path}/" aria-label="Büro Galerie Fotos" >
              <div
                style="background-image:url({Prodhost}/colletadmin/{t.Bild.styles[0].path})" />
            </a>
          </div>
        {/each}
      </div>
      <div class="swiper-button-next">
        {#if navigator.userAgent.indexOf('Trident') > -1}next{/if}
      </div>
      <div class="swiper-button-prev">
        {#if navigator.userAgent.indexOf('Trident') > -1}prev{/if}
      </div>
    </div>
  </div>
</div>

<script>
  import Prodhost from "../../components/host.js";
  import { onMount, afterUpdate } from "svelte";
  import { ready } from "@sveltech/routify";
  import anime from "animejs";
  import ScrollMagic from "scrollmagic";
  import Scroll from "../../components/Scroll.svelte";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import ColletButton from "../../components/Button.svelte";
  import Canim from "../../components/svg/canim.svelte";
  import AccordionItem from "../../components/AccordionItem.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";
  import isMobile from "../../components/isMobile.js";
  import sceneobj from "./sceneobj";

  function isIe() {
    if (navigator.userAgent.indexOf("Trident") > -1) {
      return true;
    }
    return false;
  }

  function isAllIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  var leistungen = [];
  var leistungenSeite = [];
  var lcat = [];
  var lmap = [];

  let scrollaction = "load";

  let stripeReady = false;
  let mounted = false;
  let firstclick = 0;

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  var tl = anime.timeline({
    duration: 1000,
    loop: true
  });

  onMount(() => {
    setTimeout(() => {
      if (ww >= 1024) {
        anime({
          targets: "#sliderblob .bblob",
          duration: 20000,
          easing: "linear",
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }
    }, 1000);

    fetch(Prodhost + "/colletadmin/api/collections/get/onepageleistungen")
      .then(response => response.json())
      .then(json => {
        leistungen = json.entries;
        var l = json.fields.kategorie.options.options;
        lcat = l.split(",").map(item => item.trim());
        lcat = [
          "Markenführung",
          "Content Marketing",
          "Digitale Kommunikation"         
        ];
      });
    // "Kommunikation"
    fetch(Prodhost + "/colletadmin/api/singletons/get/Leistungen")
      .then(response => response.json())
      .then(json => {
        leistungenSeite = json;
      });

    if (isIe()) {
      setTimeout(() => {
        const el = document.querySelector(".contentmain");
        window.addEventListener("wheel", function(e) {
          let sp = e.deltaY + e.deltaX;
          if (e.deltaY >= 100) {
            sp = sp / 2;
          }
          if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
            if (e.deltaY < 5) {
              sp = sp * 20;
            } else {
              sp = sp * 12;
            }
          }

          if (
            navigator.userAgent.search("Safari") >= 0 &&
            navigator.userAgent.search("Chrome") < 0 &&
            navigator.platform.indexOf("Mac") > -1
          ) {
            if (sp < 0) {
              sp = -80;
            } else {
              sp = 80;
            }
          }

          if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
          else el.scrollLeft -= sp * -1;
        });
        el.onscroll = function() {
          var elmnt = document.querySelector(".contentmain");
          var x = elmnt.scrollLeft;
        };
      }, 500);
    }

    if (isMobile()) {
      const items = document.querySelectorAll(".accordion button");

      function toggleAccordion(item) {
        item.classList.toggle("active");
        item.nextElementSibling.classList.toggle("active");
      }

      items.forEach(item =>
        item.addEventListener("click", toggleAccordion(item))
      );
    }
  });

  afterUpdate(() => {
    mounted = true;
    if (stripeReady && !isMobile() && !isIe()) {
      loadStripeElements();
    }

    if ((stripeReady && isMobile()) || (stripeReady && isIe())) {
      loadIcons();
    }
  });

  function stripeLoaded() {
    if (mounted) {
      setTimeout(() => {
        if (isMobile() || isIe()) {
          loadIcons();
        }
        if (!isMobile() && !isIe()) {
          loadStripeElements();
        }
      }, 1000);
    }
  }

  function loadIcons() {
    var controller = new ScrollMagic.Controller();

    var icons = [
      "mf",
      "contentmarketing",
      "digital",
      "design",
      "kommunikation",
      "event",
      "branding",
      "onlinemarketing"
    ];

    setTimeout(() => {
      if ((ww > 960 && !isMobile()) || (ww > 960 && isIe())) {
        var controller = new ScrollMagic.Controller({ vertical: false });
      } else {
        var controller = new ScrollMagic.Controller({ vertical: true });
      }

      for (let index = 0; index < lcat.length; index++) {
        let lanimicon = document.getElementById("lcat" + index);

        let an = lottie.loadAnimation({
          container: lanimicon,
          renderer: "svg",
          loop: true,
          autoplay: false,
          path: "/js/icons/" + icons[index] + ".json" // the path to the animation json
        });

        var scene = new ScrollMagic.Scene({
          triggerElement: "#lcat" + index,
          duration: 300,
          offset: 0
        })
          .on("enter", function(e) {
            var paths = lanimicon.querySelectorAll("path"),
              i;
            for (i = 0; i < paths.length; ++i) {
              paths[i].setAttribute("style", "stroke:#e61974");
            }
            an.play();
            lanimicon.nextElementSibling.style.color = "#e61974";
          })
          .on("leave", function(e) {
            var paths = lanimicon.querySelectorAll("path"),
              i;
            for (i = 0; i < paths.length; ++i) {
              paths[i].setAttribute("style", "stroke:#4a4a4a");
            }
            lanimicon.nextElementSibling.style.color = "#4a4a4a";
            an.stop();
          })
          .addTo(controller);
      }
    }, 200);
  }

  function loadStripeElements() {
    setTimeout(() => {
      const el = document.querySelector(".contentmain");

      if ((ww > 960 && !isMobile()) || (ww > 960 && isIe())) {
        var controller = new ScrollMagic.Controller({
          vertical: false
        });
      } else {
        var controller = new ScrollMagic.Controller({
          vertical: true
        });
      }

      var scene = new ScrollMagic.Scene({
        triggerElement: "#leistungenBox",
        duration: ww,
        triggerHook: 0.3,
        offset: 0
      })
        .on("enter", function() {
          anime({
            targets: [".canvasblande", "#colletscroll"],
            opacity: 0,
            duration: 1500,
            complete: function() {
              document.querySelector(".canvasblande").style.display = "none";
              document.getElementById("colletscroll").style.display = "none";
            }
          });
        })
        .on("leave", function(event) {
          anime({
            targets: [".canvasblande", "#colletscroll"],
            opacity: 1,
            duration: 1500,
            begin: function() {
              document.querySelector(".canvasblande").style.display = "block";
              document.getElementById("colletscroll").style.display = "block";
            }
          });

          anime({
            targets: ["#info"],
            opacity: 0,
            duration: 200
          });
        })
        .addTo(controller);

      scrollaction = "scroll";

      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }
        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }, 1500);

    setTimeout(() => {
      var drawingSurface = document.getElementById("canvas");
      var contentmain = document.querySelector(" .contentmain");

      var scene = new THREE.Scene();

      var camera = new THREE.PerspectiveCamera(75, ww / wh, 0.1, 1000);
      var renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true
      });

      var raycaster = new THREE.Raycaster();
      var mouse = new THREE.Vector2();
      var controls = new THREE.OrbitControls(camera);
      controls.enabled = false;
      renderer.setSize(contentmain.offsetWidth, contentmain.offsetHeight);
      renderer.shadowMap.enabled = false;
      renderer.shadowMapSoft = false;
      drawingSurface.appendChild(renderer.domElement);
      var material = new THREE.MeshLambertMaterial({
        color: 0xe61974
      });
      material.color instanceof THREE.Color === true;

      var materialselect = new THREE.MeshLambertMaterial({
        color: 0x555555
      });

      var geometry = new THREE.BoxGeometry(1, 1, 1);
      var materialtr = new THREE.MeshPhongMaterial({
        color: 0xe61974,
        opacity: 0,
        transparent: true
      });

      var materialtrsel = new THREE.MeshPhongMaterial({
        color: 0xe61974,
        opacity: 1,
        transparent: true
      });

      var materialtractiv = new THREE.MeshPhongMaterial({
        color: 0x156f7b,
        opacity: 1,
        transparent: true
      });

      var material1 = new THREE.MeshLambertMaterial({
        color: 0xe61974,
        opacity: 0.4,
        transparent: true
      });

      const radiusTop = 0.5;
      const radiusBottom = 0.5;
      const height = 0.3;
      const radialSegments = 50;
      const geometryCylinder = new THREE.CylinderBufferGeometry(
        radiusTop,
        radiusBottom,
        height,
        radialSegments
      );

      const radiusTopStart = 3.2;
      const radiusBottomStart = 3.2;
      const heightStart = 0.3;
      const radialSegmentsStart = 50;
      const geometryCylinderStart = new THREE.CylinderBufferGeometry(
        radiusTopStart,
        radiusBottomStart,
        heightStart,
        radialSegmentsStart
      );

      let obs = sceneobj;

      let objscale = 0.9;

      if (ww <= 1024) {
        objscale = 2;
      }

      let activeobj;

      var objgroup = new THREE.Group();

      for (var i = 0; i < obs.length; i++) {
        if (obs[i].name === "start") {
          window[obs[i].name] = new THREE.Mesh(
            geometryCylinderStart,
            materialtr
          );
        } else {
          window[obs[i].name] = new THREE.Mesh(geometryCylinder, materialtr);
        }
        window[obs[i].name].position.x = obs[i].x;
        window[obs[i].name].position.y = obs[i].y;
        window[obs[i].name].position.z = obs[i].z;

        window[obs[i].name].scale.x = objscale;
        window[obs[i].name].scale.y = objscale;
        window[obs[i].name].scale.z = objscale;
        window[obs[i].name].castShadow = false;
        window[obs[i].name].receiveShadow = false;

        objgroup.add(window[obs[i].name]);

        scene.add(objgroup);
      }

      camera.position.x = 0;
      camera.position.y = 20;
      camera.position.z = 0.1;
      var material1 = new THREE.MeshLambertMaterial({
        color: 0xe61974,
        opacity: 0.2,
        transparent: true
      });

      const c1 = new THREE.CircleGeometry(8, 50);
      const circle1 = new THREE.Mesh(c1, material1);
      circle1.rotation.x = -1.57;
      circle1.position.y = -1;
      circle1.position.x = 0.2;
      circle1.position.z = 0.2;
      scene.add(circle1);

      anime({
        targets: circle1.scale,
        duration: 12000,
        y: [1, 1.2],
        x: [1, 1.2],
        direction: "alternate",
        easing: "easeInOutSine",
        loop: true
      });

      var material2 = new THREE.MeshLambertMaterial({
        color: 0xe61974,
        opacity: 0.1,
        transparent: true
      });

      const c2 = new THREE.CircleGeometry(11, 50);
      const circle2 = new THREE.Mesh(c2, material2);
      circle2.rotation.x = -1.57;
      circle2.position.y = -1.5;
      circle2.position.x = 0.2;
      circle2.position.z = 0.2;
      scene.add(circle2);

      anime({
        targets: circle2.scale,
        duration: 15000,
        y: [1, 1.2],
        x: [1, 1.2],
        direction: "alternate",
        easing: "easeInOutSine",
        loop: true
      });

      var material3 = new THREE.MeshLambertMaterial({
        color: 0xe61974,
        opacity: 0.05,
        transparent: true
      });

      const c3 = new THREE.CircleGeometry(15, 50);
      const circle3 = new THREE.Mesh(c3, material3);
      circle3.rotation.x = -1.57;
      circle3.position.y = -3;
      circle3.position.x = 0.2;
      circle3.position.z = 0.2;
      scene.add(circle3);

      anime({
        targets: circle3.scale,
        duration: 17000,
        y: [1, 1.2],
        x: [1, 1.2],
        direction: "alternate",
        easing: "easeInOutSine",
        loop: true
      });

      var ambientLight = new THREE.AmbientLight(0x555555);
      scene.add(ambientLight);

      var spotLight = new THREE.SpotLight(0xaaaaaa);
      spotLight.position.set(2, 20, 3);
      spotLight.castShadow = false;

      scene.add(spotLight);

      const loadersvg = new THREE.SVGLoader();

      loadersvg.load("/js/leistungenNew.svg", function(svgData) {
        
        THREE.Cache.clear();

        var paths = svgData.paths;
        var group = new THREE.Group();

        group.scale.multiplyScalar(0.25);
        group.position.x = -18;
        group.position.y = 0.5;
        group.position.z = -12;
        group.rotation.x = -1.57;
        group.scale.y *= -0.2;
        group.scale.x *= 0.2;
        for (var i = 0; i < paths.length; i++) {
          var path = paths[i];
          var materialsvg = new THREE.MeshBasicMaterial({
            color: "#e61974",
            side: THREE.DoubleSide,
            depthTest: true,
            depthWrite: true
          });

          if (
            path.userData.node.id === "lkurv1" ||
            path.userData.node.id === "lkurv2" ||
            path.userData.node.id === "lkurv3" ||
            path.userData.node.id === "lkurv4" ||
            path.userData.node.id === "lkurv5"
          ) {
            materialsvg = new THREE.MeshBasicMaterial({
              color: "#e61974",
              opacity: 0.1,
              side: THREE.DoubleSide,
              depthTest: true,
              depthWrite: true,
              transparent: true
            });
          }

          var shapes = path.toShapes(true);
          for (var j = 0; j < shapes.length; j++) {
            var shape = shapes[j];
            var geometrysvg = new THREE.ShapeBufferGeometry(shape);
            var svgmesh = new THREE.Mesh(geometrysvg, materialsvg);
            group.add(svgmesh);
          }
        }
        scene.add(group);
      });

      function render() {
        requestAnimationFrame(render);
        controls.update();
        renderer.render(scene, camera);
      }
      window.addEventListener("resize", onWindowResize, false);
      window.addEventListener("mousedown", onDocumentMouseDown, false);
      window.addEventListener("mousemove", mousemove, false);

      render();

      let animtime = true;
      function animTo(obj, ox, oy, oz, od, title, text, link) {
        animtime = false;
        var intersects = raycaster.intersectObjects([obj]);
        if (intersects.length > 0) {
          obj.material = materialtractiv;
          activeobj = obj;
          infoBlock("out");
          var tween = anime({
            targets: camera.position,
            duration: 1500,
            easing: "easeInOutCubic",
            x: ox,
            y: oy,
            z: oz,
            complete: function(anim) {
              activeobj = obj;
              obj.material = materialtractiv;
              animtime = true;
              infoBlock("in");
              document.querySelector("#info h3").innerHTML = title;
              document.querySelector("#info p").innerHTML = text;
              document.querySelector("#info a").href =
                "/leistungen/" + link + "/";
            }
          });
        }
      }

      function infoBlock(inOut) {
        if (inOut === "in") {
          document.getElementById("info").style.display = "block";
          document.getElementById("info").style.opacity = 1;

          anime({
            targets: ["#info .infobox"],
            opacity: 1,
            duration: 1000,
            loop: false
          });
        } else {
          anime({
            targets: ["#info"],
            opacity: 0,
            duration: 500,
            loop: false,
            complete: function(anim) {
              document.getElementById("info").style.display = "none";
              document.querySelector(".infobox").style.opacity = 0;
            }
          });
        }
      }

      tl.add({
        targets: digital.scale,
        x: [0, 1],
        y: [0, 1],
        z: [0, 1]
      });

      if (firstclick === 0) {
        digital.material = materialtrsel;
        digital.scale.set(1, 1, 1);
        tl.play();
      }

      function mousemove(event) {
        if (animtime === true) {
          mouse.x = (event.clientX / ww) * 2 - 1;
          mouse.y = -(event.clientY / wh) * 2 + 1;
          raycaster.setFromCamera(mouse, camera);
          var intersects = raycaster.intersectObjects(objgroup.children);
          if (intersects.length > 0) {
            var resultsel = objgroup.children.find(obj => {
              if (activeobj !== intersects[0].object) {
                if (obj.uuid === intersects[0].object.uuid) {
                  if (obj !== digital || firstclick === 1) {
                    obj.material = materialtrsel;
                    anime({
                      targets: obj.scale,
                      x: 1,
                      y: 1,
                      z: 1,
                      duration: 500,
                      loop: false
                    });
                  }
                }
              }
            });
            document.querySelector("body").style.cursor = "pointer";
          } else {
            document.querySelector("body").style.cursor = "default";
            for (let index = 0; index < objgroup.children.length; index++) {
              if (activeobj !== objgroup.children[index]) {
                if (objgroup.children[index] !== digital || firstclick === 1) {
                  objgroup.children[index].material = materialtr;
                  anime({
                    targets: objgroup.children[index].scale,
                    x: objscale,
                    y: objscale,
                    z: objscale,
                    duration: 500,
                    loop: false
                  });
                }
              }
            }
          }
        }
      }

      function resetobjmaterial() {
        for (let index = 0; index < objgroup.children.length; index++) {
          if (activeobj !== objgroup.children[index]) {
            objgroup.children[index].material = materialtr;
          }
        }
      }

      function onDocumentMouseDown(event) {
        firstclick = 1;
        tl.pause();

        activeobj = 0;
        resetobjmaterial();
        mouse.x = (event.clientX / ww) * 2 - 1;
        mouse.y = -(event.clientY / wh) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        if (event.button === 2) {
          activeobj = 0;
          animtime = false;
          resetobjmaterial();
          infoBlock("out");
          var tweenTop = anime({
            targets: camera.position,
            duration: 1500,
            easing: "easeInOutCubic",
            x: 0,
            y: 20,
            z: 0.1,
            complete: function(anim) {
              activeobj = 0;
              resetobjmaterial();
              animtime = true;
            }
          });
        } else {
          for (var i = 0; i < obs.length; i++) {
            if (obs[i].name === "start") {
              activeobj = 0;
              resetobjmaterial();
              animtime = false;
              infoBlock("out");
              var tweenTop = anime({
                targets: camera.position,
                duration: 1500,
                easing: "easeInOutCubic",
                x: 0,
                y: 20,
                z: 0.1,
                complete: function(anim) {
                  activeobj = 0;
                  resetobjmaterial();
                  animtime = true;
                }
              });
            } else {
              let posx = window[obs[i].name].position.x + 4;
              if (window[obs[i].name].position.x <= 0) {
                posx = window[obs[i].name].position.x - 4;
              }

              let posz = window[obs[i].name].position.z + 2;
              if (window[obs[i].name].position.z <= 0) {
                posz = window[obs[i].name].position.z - 2;
              }

              let ltext = "";
              let ltitle = "";
              let llink = "";

              leistungen.forEach(element => {
                if (obs[i].name === element.lid) {
                  ltext = element.maptext;
                  ltitle = element.maptitle;
                  llink = element.slug;
                  if (llink === "interne-kommunikation" || llink === "pr") {
                    llink = "kommunikationsagentur";
                  }
                }
              });

              animTo(
                window[obs[i].name],
                posx,
                7,
                posz,
                1500,
                ltitle,
                ltext,
                llink
              );
            }
          }
        }
      }

      function animate(time) {
        requestAnimationFrame(animate);

        TWEEN.update(time);
      }

      function onWindowResize() {
        camera.aspect = ww / wh;
        camera.updateProjectionMatrix();
        renderer.setSize(ww, wh);
      }
    }, 500);
  }
</script>

<style>
  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  .leistungen {
    margin-right: 0px;
  }
  .leistungen {
    min-width: 100vw;
    z-index: -1;
  }

  .canvasblande {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    opacity: 1;
    animation-duration: 2s;
  }

  #colletscroll {
    position: absolute;
    top: 50%;
    left: 10%;
    margin-left: -50px;
    z-index: 10000000;
    opacity: 1;
    animation-duration: 2s;
    animation-delay: 1s;
  }

  .laicon {
    width: 50px;
    margin: 0 auto;
    margin-top: 100px;
  }

  @media (max-width: 960px) {
    #leistungenBox {
      height: 1000px !important;
    }
  }

  @media (min-width: 961px) and (max-width: 1439px) {
    #blslider {
      left: 15vw !important;
    }
  }

  @media screen and (max-width: 1024px) {
    #msvg {
      object-fit: cover;
      height: calc(100vh - 2px);
      max-height: calc(100vh - 2px);
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
    }

    #mainbild,
    .mainslider {
      overflow: hidden;
    }
  }
</style>

<svelte:head>

  {#if !isMobile() && !isIe()}
    <script defer src="/js/thsvg.js" on:load={stripeLoaded}>

    </script>
  {/if}

  {#if isMobile() || isIe()}
    <script defer src="/js/icons.min.js" on:load={stripeLoaded}>

    </script>
  {/if}

  {#if isIe()}
    <script defer src="/js/accordion.js">

    </script>
  {/if}

  <style>
    #canvas {
      z-index: 1;
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      height: 100vh;
    }

    #info {
      display: none;
      position: fixed;
      left: 30%;
      top: 10%;
      z-index: 200;
      width: auto;
      max-width: 400px;
      background: transparent;
      opacity: 1;
    }

    #info .infobox {
      width: 100%;
      height: 100%;
      text-align: left;
      z-index: 100;
      font-size: 14px;
      padding: 20px;
      color: #2a2a2a;
      font-family: sans-serif;
      background: #ffffff;
      opacity: 0;
      border: 2px solid #e61974;
      white-space: normal;
    }

    #info .infobox h3 {
      font-weight: 700;
      font-size: 18px;
    }

    #info .infobox a,
    .accordion-item .content a {
      background: #e61974;
      color: #fff;
      border-radius: 50px;
      padding: 5px 10px;
      padding: calc(0.5em - 1px) 1em;
      margin-top: 20px;
      display: block;
      width: 70px;
      text-align: center;
    }

    .accordion-item .content a {
      width: 100%;
    }

    #info .infobox a:hover {
      background: #4a4a4a;
      color: #fff;
    }
  </style>
  {#if isIe()}
    <style>
      #page .contentmain.scrollcontentbox {
        width: 100% !important;
      }
    </style>
  {/if}

</svelte:head>

<section class="section mainslider" id="leistungenTop">
  <div class="mwelletop">
    <Mwelle />
  </div>
  <div>
    {#if leistungenSeite.Slider}
      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
            }
          </style>
        {/if}
        <div
          id="msvg"
          style="background-image: url(https://www.collet-cc.de/colletadmin/{leistungenSeite.Slider.styles[0].path});" />
        <!-- <div id="msvg" /> -->
      </div>
    {/if}
  </div>
  <div id="blslider">
    <BlobSlider />
    <div class="sliderTextBox">
      <div>
        <h1>
          {@html leistungenSeite.Title}
        </h1>
        <p>
          {@html leistungenSeite.Text}
        </p>
      </div>
    </div>
  </div>
  <Mwelle />
</section>

{#if isMobile()}
  <section class="section leistungen">
    <div class="accordion">
      {#each lcat as lc, i}
        <div id="lcat{i}" class="laicon" />
        <h3>{lc}</h3>
        {#if leistungen}
          {#each leistungen as l, li}
            {#if l.kategorie === lc && l.noindex !== true}
              <AccordionItem
                title={l.maptitle + l.noindex}
                content={l.maptext}
                link={l.slug}
                li={'a' + i + 'la' + li} />
            {/if}
          {/each}
        {/if}
      {/each}
    </div>
  </section>
{/if}

{#if isIe()}
  <section class="section leistungen" id="leistungenacc">

    {#each lcat as lc, i}
      <div class="labox">
        <div id="lcat{i}" class="laicon" />
        <h3>{lc}</h3>
        <div class="tabs">
          {#each leistungen as l, li}
            {#if l.Kategorie === lc && l.noindex !== true}
              <div class="tab">
                <input type="checkbox" id="chck{li}" />
                <label class="tab-label" for="chck{li}">{l.Title}</label>
                <div class="tab-content">
                  {@html l.Text}
                </div>
              </div>
            {/if}
          {/each}
        </div>
      </div>
    {/each}

  </section>
{/if}

{#if !isMobile() && !isIe()}
  <section class="section leistungen" id="leistungenBox">
    <div id="colletscroll">
      <Scroll action={scrollaction} />
    </div>
    <div class="canvasblande" />
    <div id="canvas" />

  </section>
  <div id="info">
    <div class="infobox">
      <h3>...</h3>
      <p>...</p>
      <a href={'#'} target="_self">Mehr</a>
    </div>
  </div>
{/if}

<div class="svgclippath">
  <svg viewBox="0 0 1200 900" width="0" height="0">
    <clipPath
      id="sliderblob"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0008, 0.002)">
      <path
        style=" transform: translate(-180px, 0px);"
        class="bblob"
        d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
        143.61c-4.55,95.55,170.07,212.41,10.95,462.77
        C1005.43,799.35,1195.72,900.48,1200,900z" />
    </clipPath>
  </svg>
</div>

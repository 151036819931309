<script>
  export let flip = false;
  export let color = '#ffffff';

  let cls;
  export { cls as class };
  export let style;
</script>

<style>
    .Wave_svg {
        transform: scale(3.5, 2) translateX(20%);
        display: block;
        width: 100%;
        height: 50px;
    }
</style>

<svg class={"Wave_svg" + (cls ? ' ' + cls : '')} x="0px" y="0px" viewBox="0 0 900 142.2" preserveAspectRatio="none" style={style ? style : undefined}>
  <path
    style={flip ? 'transform: rotate(180deg); transform-origin: center;' : undefined}
    d="M0,112.3l0,31.4h900v-31.4c0,0-127.4-187.2-292.5-78.1C387,180,325.7,63.5,199.3,48.8
    C78.8,34.7-0.5,108.9,0,112.3z"
    fill={color} />
</svg>

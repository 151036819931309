<script>
  export let n;
  export let text;
  export let title;

  var ct = "#E61974";
  var cb = "#ffffff";
  var x = 250;
  var y = 600;
  var ry = 635;
  var ty = 655.5;
  var sy = 700;

  var der = "start";

  if (n == 1) {
    cb = "#E61974";
    ct = "#ffffff";
  }

  if (n % 2 === 0) {
    der = "end";
    ry = 520;
    ty = 540.5;
    sy = 310;
  }



</script>

<circle cx={x} cy={y} r="20" stroke="#E61974" class="lpunkthover" />
<circle cx={x} cy={y} r="20" fill="#E61974" />

{#if n == 2}
  <circle cx={x} cy={y} r="20" fill="#E6197430" class="lpunkthover2" />
{/if}

<line
  x1={x}
  x2={x}
  y1={y}
  y2={y}
  stroke="#E61974"
  stroke-width="3"
  class="lineud" />
<g class="pltitle" style="opacity: 0">
  <g>
    <rect
      x="200"
      y={ry}
      width={title.length * 10 + 20}
      height="41"
      rx="20.5"
      fill={cb}
      stroke="#E61974"
      stroke-linejoin="round" />
    <text
      x={(title.length * 10 + 20) / 2 + 200}
      y={ty}
      dominant-baseline="middle"
      text-anchor="middle"
      fill={ct}>
      {title}
    </text>
  </g>
  <switch>
    <foreignObject
      x="200"
      y={sy}
      width="500"
      height="190"
      requiredExtensions="http://www.w3.org/1999/xhtml"
      class="pltext"
      style="opacity: 0">
      <body
        xmlns="http://www.w3.org/1999/xhtml"
        style="display: flex; height: 190px; align-items: flex-{der};">
        <p style="font-size:14px">
          {@html text}
        </p>
      </body>
    </foreignObject>
  </switch>
</g>

<script>
  import Prodhost from "../../components/host.js";
  import { params } from "@sveltech/routify";
  import { onMount } from "svelte";
  import anime from "animejs";
  import ScrollMagic from "scrollmagic";
  import SimpleLightbox from "simple-lightbox";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import BackButton from "../../components/BackButton.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";

  function isIEorEDGE() {
    if (navigator.appName == "Microsoft Internet Explorer") {
      return true; // IE
    } else if (navigator.appName == "Netscape") {
      return navigator.appVersion.indexOf("Edge") > -1; // EDGE
    }

    return false;
  }

  function isIe() {
    if (navigator.userAgent.indexOf("Trident") > -1) {
      return true;
    }
    return false;
  }

  import { Swiper, Navigation, EffectCoverflow } from "swiper/js/swiper.esm.js";
  Swiper.use([Navigation, EffectCoverflow]);

  let projekt = jdata;
  let p = jdata;
  let sl = [];
  let pvideos = [];
  let pslider;
  let cx = 0;
  let gallerysection = 0;

  
  let url = '';

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  function countup(el, target) {
    let data = { count: 0 };
    anime({
      targets: data,
      count: [0, target],
      duration: 1000,
      round: 1,
      delay: 200,
      easing: "easeOutCubic",
      update() {
        el.innerText = data.count.toLocaleString();
      }
    });
  }

  async function hashchange() {
    await fetch(Prodhost + "/colletadmin/api/collections/get/videos")
      .then(res => res.json())
      .then(res => {
        pvideos = res.entries;
      });

    // await fetch(Prodhost + "/colletadmin/api/collections/get/projekte", {
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     filter: { slug: $params.slug, published: true }
    //   })
    // })
    //   .then(response => response.json())
    //   .then(json => {
    //     projekt = json.entries;
    //     // p = projekt[0];
    //   })
    //   .then(json => {

    //   });
  }

  onMount(() => {
    hashchange();

    url = window.location.href

    if (!projekt.Gallery === undefined || !projekt.Gallery.length == 0) {
      projekt.Gallery.forEach(sliderAnim);
      function sliderAnim(item, index, arr) {
        if (ww > 2500) {
          sl.push({
            image: Prodhost + "/colletadmin/" + item.styles[2].path
          });
        } else {
          sl.push({
            image: Prodhost + "/colletadmin/" + item.styles[0].path
          });
        }
      }
    } else {
      if (ww > 2500) {
        sl.push({
          image: Prodhost + "/colletadmin/" + projekt.Image.styles[4].path
        });
      } else {
        sl.push({
          image: Prodhost + "/colletadmin/" + projekt.Image.styles[3].path
        });
      }
    }

    let img2 = document.querySelector("#mainimage image.mainimage1");
    let img1 = document.querySelector("#mainimage image.mainimage2");

    let newimg = 2;
    let newimg2 = 3;

    var tl1 = anime.timeline({
      easing: "linear",
      autoplay: false,
      loop: true,
      update: function(anim) {
        let p = Math.round(anim.progress);
        if (p === 30) {
          if (newimg2 >= sl.length) {
            newimg2 = 1;
          }

          img2.setAttributeNS(
            "http://www.w3.org/1999/xlink",
            "href",
            sl[newimg2].image
          );
        }

        if (p === 70) {
          if (newimg >= sl.length) {
            newimg = 0;
          }
          img1.setAttributeNS(
            "http://www.w3.org/1999/xlink",
            "href",
            sl[newimg].image
          );
        }
      },
      loopBegin: function(anim) {
        newimg2++;
        newimg2++;
      },
      loopComplete: function(anim) {
        newimg++;
        newimg++;
      }
    });

    tl1
      .add({
        targets: img1,
        opacity: [1, 0],
        duration: 300,
        delay: 5000
      })
      .add({
        targets: img1,
        opacity: [0, 1],
        duration: 300,
        delay: 5000
      });

    img1.setAttributeNS("http://www.w3.org/1999/xlink", "href", sl[0].image);

    if (sl.length >= 2) {
      img2.setAttributeNS("http://www.w3.org/1999/xlink", "href", sl[1].image);

      tl1.play();
    }

    if (projekt.Videos !== null || projekt.Gallery.length > 0) {
      if (projekt.Gallery.length > 0 || projekt.Videos.length > 0) {
        gallerysection = 1;

        setTimeout(() => {
          var swiper = new Swiper(".swiper-container", {
            direction: "horizontal",
            slidesPerView: 1,
            effect: "coverflow",
            speed: 700,
            loop: true,
            lazy: true,
            preloadImages: false,
            lazy: {
              loadPrevNext: true
            },
            pagination: false,
            simulateTouch: false,
            allowTouchMove: false,
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev"
            },
            breakpoints: {
              960: {
                slidesPerView: 3,
                direction: "vertical"
              }
            }
          });

          if (ww > 1024) {
            new SimpleLightbox({ elements: ".swiper-container a" });
          }
        }, 200);
      }
    }
    anime({
      targets: "#sliderblob .bblob",
      easing: "linear",
      duration: 20000,
      d: [
        {
          value:
            "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
        },
        {
          value:
            "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
        }
      ],
      loop: true,
      update: () => {
        const fi = document.querySelector("#sliderblob");
        fi.style.display = "none";
        fi.offsetWidth;
        fi.style.display = "block";
      }
    });

    var step = 100;
    const el = document.querySelector(".contentmain");

    if (ww < 960) {
      step = 0;
    }

    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (e.deltaY < 5) {
            sp = sp * 20;
          } else {
            sp = sp * 12;
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          if (sp < 0) {
            sp = -80;
          } else {
            sp = 80;
          }
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp * -1;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }

    setTimeout(function() {
      var controller = new ScrollMagic.Controller({ vertical: false });
      var scene = new ScrollMagic.Scene({
        triggerElement: "#faktcount",
        duration: 500
      })
        .on("enter", function() {
          if (cx == 0) {
            countup(document.querySelector(".fakt1"), projekt.Fact1);
            countup(document.querySelector(".fakt2"), projekt.Fact2);
            countup(document.querySelector(".fakt3"), projekt.Fact3);
            countup(document.querySelector(".fakt4"), projekt.Fact4);
            cx = 1;
          }
        })
        .on("leave", function() {
          // cx = 0;
        })
        .addTo(controller);
    }, 1000);
  });
</script>

<style>
  .contentmain > section > .columns {
    height: auto;
  }

  .sliderTextBox {
    height: 250px;
  }
  .sliderTextBox h1 {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }

  .projektLeistungen {
    margin-top: 15px;
    color: #e61974;
  }

  .projektLeistungen strong {
    color: #e61974;
  }

  #projektInfo {
    text-align: left;
    padding: 10% 200px 10% 0px;
    min-width: 100vw;
    width: auto;
  }

  #projektInfo .column {
    position: relative;
    z-index: 1;
    width: 600px;
    padding-left: 4rem;
    padding-right: 4rem;
    height: max-content;
  }

  #projektInfo .column span {
    position: absolute;
    z-index: 1;
    font-size: 7rem;
    top: -50px;

    font-weight: 900;
    color: #ffcdcd;
  }

  #projektInfo .column h2 {
    position: relative;
    z-index: 10;
    font-size: 32px;
    text-transform: uppercase;
  }

  #projektInfo .kunde span {
    color: #e61974;
    opacity: 0.25;
    left: 120px;
  }

  #projektInfo .herausforderung span {
    color: #156f7b;
    opacity: 0.25;
    left: 350px;
  }

  #projektInfo .unserjob span {
    color: #000000;
    opacity: 0.1;
    left: 190px;
  }

  .contentmain > section.infoDaten > .columns {
    height: 100vh;
  }

  .contentmain > section.infoDaten {
    min-width: 1200px;
    margin-right: 0;
    padding-right: 0 !important;
  }

  .contentmain > section {
    background: #ffffff;
  }

  #mainimage {
    width: 70vw;
    height: 100vh;
    overflow: hidden;

    margin: 0;
    padding: 0;
    display: block;
    float: left;
  }

  #sliderblob .bblob {
    transform: translate(-180px, 0px);
    -ms-transform: translate(-180px, 0px);
    -sand-transform: translate(-180px, 0px);
    -webkit-transform: translate(-180px, 0px);
  }

  #faktcount .fsvg {
    position: relative;
    top: -3vh;
  }

  #faktcount .fsvg h3 {
    position: relative;
    z-index: 1;
    color: #fff;
    padding: 0 70px 0 40px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 800;
  }
  #faktcount .fsvg .factssvg {
    z-index: 0;
    isolation: isolate;
    position: absolute;
    top: -120px;
    left: 10px;
    width: 350px;
    height: 300px;
  }

  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  .swiper-container {
    width: 500px;
    height: 100%;
    overflow: visible;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    background-size: cover;
    background-position: center;
    width: 500px;
    height: 300px;
    position: relative;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-container .swiper-slide  div {
    background-size: cover;
    background-position: center;
    width: 500px;
    height: 300px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    padding: 5px 10px;
    color: #fff;
    background-color: #e61974;
  }

  .swiper-button-next {
    bottom: 100px;
    left: 50%;
    top: auto;
    transform: rotateZ(90deg);
  }

  .swiper-button-prev {
    top: 100px;
    left: 50%;
    bottom: auto;
    transform: rotateZ(90deg);
    color: #fff;
    background-color: #e61974;
  }

  .mainimage1,
  .mainimage2 {
    opacity: 1;
    position: relative;
  }
  .mainimage1 {
    z-index: 1;
  }
  .mainimage2 {
    z-index: 2;
  }

  .infoDaten {
    padding: 0px !important;
  }

  @media (max-width: 960px) {
    #projektInfo {
      text-align: left;
      padding: 10% 5%;
      width: 100%;
    }

    .contentmain > section.infoDaten > .columns {
      height: auto;
      margin: 50px 0;
    }

    #projektInfo .herausforderung span {
      left: 230px;
    }

    #projektInfo .columns {
      display: block !important;
    }
  }
</style>

<svelte:head>
  {#if isIEorEDGE() || isIe()}
    <style>
      #projektInfo .column > p {
        column-width: 450px !important;
      }
    </style>
  {/if}
</svelte:head>

<section class="section mainslider ins">

  <div class="mwelletop">
    <Mwelle />
  </div>

  <svg id="mainimage">
    <image
      class="mainimage1"
      x="0"
      y="0"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      xlink:href=""
      clip-path="url(#sliderblob)" />
    <image
      class="mainimage2"
      x="0"
      y="0"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid slice"
      xlink:href=""
      clip-path="url(#sliderblob)" />
  </svg>

  <div id="blslider">
    <BlobSlider />
    <div class="sliderTextBox is-vertical-center">
      <h1 class="title">
        {#if p.Title}
          {@html p.Title}
        {/if}
      </h1>
    </div>
  </div>
  <Mwelle />
  <div class="backb">
    <BackButton url="/projekte/" />
  </div>
</section>

{#if isIe()}
  <style>
    #projektInfo .column > p {
      column-width: 300px;
      width: 100%;
    }

    #projektInfo .column {
      width: auto !important;
    }
  </style>
{/if}

<section class="section" id="projektInfo">
  <div class="columns ">
    <div class="column {ww >= 1024 ? ' is-one-quarter' : ''} kunde">
      {#if p.Block1Title}
        <h2> {p.Block1Title}</h2>
      {:else}
        <h2>Kunde</h2>
      {/if}
      <span>01</span>
      <p>
        {#if p.Kunde}
          {@html p.Kunde}
        {/if}
      </p>
    </div>
    <div
      class="column {ww >= 1024 ? ' is-one-quarter' : ''} herausforderung"
      style="width: {ww >= 1024 && p.Herausforderung.length > 1500 ? '1000px' : ''};">
      {#if p.Block2Title}
        <h2> {p.Block2Title}</h2>
      {:else}
        <h2>Herausforderung</h2>
      {/if}
      <span>02</span>
      <p
        style=" column-count: {ww >= 1024 && p.Herausforderung.length > 1500 ? 2 : 1};
        ">
        {@html p.Herausforderung}
      </p>
    </div>
    <div
      class="column {ww >= 1024 ? ' is-one-quarter' : ''} unserjob"
      style="width: {ww >= 1024 && p.UnserJob.length > 1500 ? '1400px' : ww >= 1024 && p.UnserJob.length > 700 ? '1000px' : ''};">
      {#if p.Block3Title}
        <h2> {p.Block3Title}</h2>
      {:else}
        <h2>Unser Job</h2>
      {/if}      
      <span>03</span>
      <p
        style=" column-count: {ww >= 1024 && p.UnserJob.length > 1500 ? 3 : ww >= 1024 && p.UnserJob.length > 700 ? 2 : 1};
        width: {isIEorEDGE() && p.UnserJob.length > 1500 ? '1400px!important' : isIEorEDGE() && p.UnserJob.length > 700 ? '1000px!important' : ''};">
        {@html p.UnserJob}
      </p>
      <div class=" projektLeistungen">
        <div>
          <p>
            <strong>Leistungen:</strong>
            {@html p.Leistungen}
          </p>
        </div>
      </div>
    </div>

  </div>
</section>

{#if gallerysection === 1}
  <section class="section" id="projektGallery">
    <div class="swiper-container">
      <div class="swiper-wrapper">

        {#if p.Videos}
          {#each p.Videos as v}
            {#each pvideos as pv}
              {#if v._id === pv._id}
                <div class="swiper-slide">
                  <a
                    class="lightBoxVideoLink"
                    style=" position: relative; "
                    href="https://www.youtube.com/embed/{pv.youtube}?autoplay=1">
                    <div
                      class="swiper-lazy"
                      style="background-image:url(https://img.youtube.com/vi/{pv.youtube}/sddefault.jpg);
                      background-size: 110%;">
                      <svg
                        viewBox="0 0 48 48"
                        width="48px"
                        height="48px"
                        style=" position: absolute; top: 40%; left: 45%;">
                        <path
                          fill="#FF3D00"
                          d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z" />
                        <path fill="#FFF" d="M20 31L20 17 32 24z" />
                      </svg>

                    </div>
                  </a>
                </div>
              {/if}
            {/each}
          {/each}
        {/if}

        {#if p.Gallery}
          {#each p.Gallery as t}
            <div class="swiper-slide">
              {#if ww >= 1024}
              
              {#if t.styles[3]}
              {#if t.styles[4]}
                <a
                  href="{Prodhost}/colletadmin/{ ww >= 1024 ? t.styles[4].path :  t.styles[3].path}"
                  aria-label="Projekt Galerie Bild">
                  <div
                    style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
                </a>
                {:else}
                <a
                href="{Prodhost}/colletadmin/{t.styles[3].path}"
                aria-label="Projekt Galerie Bild">
                <div
                  style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
              </a>
                {/if}
              {:else}
                <a
                href="{Prodhost}/colletadmin/{t.styles[0].path}"
                aria-label="Projekt Galerie Bild">
                <div
                  style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
                </a>
              {/if}

              {:else}
              <div
              style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
              {/if}
            </div>
          {/each}

          {#each p.Gallery as t}
            <div class="swiper-slide">
              {#if ww >= 1024}
              {#if t.styles[3]}
              {#if t.styles[4]}
              <a
                href="{Prodhost}/colletadmin/{ ww >= 1024 ? t.styles[4].path :  t.styles[3].path}"
                aria-label="Projekt Galerie Bild">
                <div
                  style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
              </a>
              {:else}
              <a
              href="{Prodhost}/colletadmin/{t.styles[3].path}"
              aria-label="Projekt Galerie Bild">
              <div
                style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
            </a>
              {/if}
            {:else}
              <a
              href="{Prodhost}/colletadmin/{t.styles[0].path}"
              aria-label="Projekt Galerie Bild">
              <div
                style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
              </a>
            {/if}

            {:else}
            <div
            style="background-image:url({Prodhost}/colletadmin/{t.styles[1].path})" />
            {/if}
            </div>
          {/each}
        {/if}
      </div>
      <div class="swiper-button-next">
        {#if navigator.userAgent.indexOf('Trident') > -1}next{/if}
      </div>
      <div class="swiper-button-prev">
        {#if navigator.userAgent.indexOf('Trident') > -1}prev{/if}
      </div>
    </div>
  </section>
{/if}
{#if p.Fact1Text}
  <section class="section infoDaten projektInfoDaten" id="faktcount">
    <div class="columns has-text-centered is-multiline is-vertical-center">
      <div class="column">
        <div class="columns">
          <div class="column">
            <h2 class="fakt1">0</h2>
            <h3>{p.Fact1Text}</h3>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h2 class="fakt2">0</h2>
            <h3>{p.Fact2Text}</h3>
          </div>
        </div>
      </div>
      <div class="column has-text-centered fsvg">
        <h3>Facts</h3>
        <svg
          viewBox="0 0 600 600"
          width="600pt"
          height="600pt"
          class="factssvg">
          <path
            d=" M 448.45 213.806 C 492.819 255.182 541.933 297.741 542.595
            345.301 C 543.147 392.952 495.026 445.696 431.674 469.976 C 368.322
            494.257 289.629 490.165 217.889 465.157 C 146.149 440.149 81.362
            394.316 68.338 340.572 C 55.315 286.828 94.165 225.355 145.597
            182.614 C 197.139 139.873 261.264 115.957 313.027 124.141 C 364.79
            132.235 404.192 172.429 448.45 213.806 Z "
            fill="#e61974" />
        </svg>

      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <h2 class="fakt3">0</h2>
            <h3>{p.Fact3Text}</h3>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h2 class="fakt4">0</h2>
            <h3>{p.Fact4Text}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
{/if}
<div class="svgclippath">
  <svg viewBox="0 0 1200 900" width="0" height="0">
    <clipPath
      id="sliderblob"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0008, 0.002)">
      <path
        transform="translate(-180, 0)"
        class="bblob"
        d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
        143.61c-4.55,95.55,170.07,212.41,10.95,462.77
        C1005.43,799.35,1195.72,900.48,1200,900z" />
    </clipPath>
  </svg>
</div>

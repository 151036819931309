<script>
  import Prodhost from "../../components/host.js";
  import Umfrage from "../../components/umfrage.svelte";
  import { params, url } from "@sveltech/routify";
  import anime from "animejs";

  import SimpleLightbox from "simple-lightbox";

  import { onMount, afterUpdate } from "svelte";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import ColletButton from "../../components/Button.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";
  import BackButton from "../../components/BackButton.svelte";
  import WheelIndicator from "wheel-indicator";

  var d = 1;
  var d2 = 0;

  function isSafari() {
    if (
      navigator.userAgent.search("Safari") >= 0 &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      return true;
    }
    return false;
  }

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  function isIEorEDGE() {
    if (navigator.appName == "Microsoft Internet Explorer") {
      return true; // IE
    } else if (navigator.appName == "Netscape") {
      return navigator.appVersion.indexOf("Edge") > -1; // EDGE
    }

    return false;
  }

  // function countInstances(string, word) {
  //   return string.split(word).length - 1;
  // }

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  var sBrowser,
    sUsrAg = navigator.userAgent;

  let news = [];
  let n = jdata;

  // let n = [];

  // let nnews = [];
  let nextNews, prevNews, nextImg, prevImg;
  let nextAlt = "nächste Beitrag";
  let prevAlt = "vorherigen Beitrag";

  function replaceMonat(text) {
    let ntext = text
      .replace("January", "Januar")
      .replace("February", "Februar")
      .replace("March", "März")
      .replace("May", "Mai")
      .replace("June", "Juni")
      .replace("July", "Juli")
      .replace("October", "Oktober")
      .replace("December", "Dezember");
    return ntext;
  }

  async function hashchange() {
    // await fetch(Prodhost + "/colletadmin/api/collections/get/News", {
    //   method: "post",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     filter: { slug: $params.slug, published: true }
    //   })
    // })
    //   .then(response => response.json())
    //   .then(json => {
    //     news = json.entries;
    //     n = news[0];
    //   });

    await fetch(Prodhost + "/colletadmin/api/collections/get/News", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(res => res.json())
      .then(res => {
        res.entries.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });

        for (let i = 0; i < res.entries.length; i++) {
          let inext;
          let iprev;
          if (res.entries[i].slug == $params.slug) {
            inext = i + 1;
            iprev = i - 1;
            if (inext <= res.entries.length - 1) {
              nextNews = res.entries[inext].slug;
              nextImg = res.entries[inext].Image.styles[0].path;
            }
            if (iprev >= 0) {
              prevNews = res.entries[iprev].slug;
              prevImg = res.entries[iprev].Image.styles[0].path;
            }
          }
        }
      });
  }

  onMount(() => {
    hashchange();

    if (ww >= 1024) {
      setTimeout(function() {
        var elmnt = document.querySelector(".newsTextColumn");
        var di = 0;
        elmnt.onscroll = function(e) {
          d2 = e.srcElement.scrollTop;
        };
        elmnt.onwheel = function(e) {
          setTimeout(function() {
            if (d === 2 && d2 === 0) {
              di += 1;
            }
          }, 200);
          if (di > 2) {
            d = 1;
            di = 0;
          }
        };
        var indicator = new WheelIndicator({
          elem: document.querySelector(".mainslider"),
          callback: function(e) {
            if (e.direction === "up") {
              if (d2 === 0) {
                d = 1;
              }
            }
            if (e.direction === "down") {
              d = 2;
              anime({
                targets: [".newstotop"],
                opacity: [0, 1],
                duration: 500,
                easing: "linear",
                loop: false
              });

              anime({
                targets: ["#sharebtn"],
                opacity: [0, 1],
                duration: 500,
                easing: "linear",
                loop: false
              });
            }
          }
        });
      }, 1500);

      setTimeout(() => {
        new SimpleLightbox({ elements: ".newsTextColumn a.lightbox" });

        anime({
          targets: "#sliderblob .bblob",
          duration: 20000,
          easing: "linear",
          d: [
            {
              value:
                "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
            },
            {
              value:
                "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
            }
          ],
          loop: true,
          update: () => {
            if (
              navigator.userAgent.search("Safari") >= 0 &&
              navigator.userAgent.search("Chrome") < 0
            ) {
              const fimp = document.getElementById("msvg");
              fimp.style.display = "none";
              fimp.offsetWidth;
              fimp.style.display = "block";
            }
          }
        });
      }, 1000);
    }
  });
</script>

<style>
  .sliderTextBox {
    height: 250px;
  }
  .sliderTextBox h1 {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
  .contentmain > section {
    background: #ffffff;
  }

  #mainbild {
    width: 100vw;
    background-position: top center;
    transition-duration: 1s;
  }

  #blslider {
    left: 20vw;
  }

  .newsText {
    text-align: left;
    width: 100%;
    background: #fff;
    margin: 0;
    padding: 0px;
    transition-duration: 1s;
    z-index: 10;
  }

  .newsText.newstextactive {
    right: 0;
    transition-duration: 1s;
  }

  .newsText .newsTextColumn {
    padding: 0px 15px;
    margin-top: 80px;
    scroll-behavior: smooth;
  }

  .newsText .newsdatum {
    position: absolute;
    top: 30px;
    left: -220px;
    color: #c4c4c4;
    font-weight: 800;
    width: 180px;
    text-align: right;
    float: right;
    font-size: 18px;
    text-transform: uppercase;
  }

  @media screen and (min-width: 1024px) {
    .newsText {
      text-align: left;
      position: absolute;
      top: 0;
      right: -100vw;
      width: 50%;
      background: #fff;
      margin: 0;
      padding: 0px 80px 0px 50px;
      transition-duration: 1s;
      z-index: 10;
      height: 100vh;
      box-shadow: -5px 0 10px #f3f3f3;
    }

    .newsText .newsTextColumn {
      position: relative;
      overflow-y: auto;
      height: 90vh;
      padding: 0px 70px 30px 30px;
      margin-top: 80px;
      scroll-behavior: smooth;
    }
  }

  .nextprevBox {
    margin-left: 200px;
    margin-right: -10vw;
    background: transparent !important;
    z-index: 10000;
  }

  .newsText .newsdatum > span {
    font-size: 70px;
    width: 100%;
    float: right;
    text-transform: uppercase;
  }

  .cbtnNext h3,
  .cbtnPrev h3 {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .cbtnPrev h3 {
    color: #156f7b;
    margin-left: 25px;
  }
  .cbtnNext h3 {
    color: #e61974;
    margin-left: -25px;
  }

  .cbtnNext svg,
  .cbtnPrev svg {
    width: 150px;
    position: absolute;
    left: -14px;
    bottom: -9px;
  }

  .cbtnNext svg {
    bottom: -45px;
  }

  .cbtnNext {
    /* position: absolute; 
     top: auto;
    left: auto; 
     right: 70px;*/
    width: 0;
    height: 0;
    bottom: 95px !important;
    right: -150px;
    position: relative;

    padding: 10px;
    filter: drop-shadow(2px 2px 2px #555);
  }

  .cbtnPrev {
    position: absolute;
    left: 30px;
    top: 200px;
    padding: 10px;
    filter: drop-shadow(2px 2px 2px #555);
  }

  #blob1 path.bblob {
    transform-origin: center;
    transform: translate(220px, 170px) scale(1, 1);
  }

  #blob2 path.bblob {
    transform-origin: center;
    transform: translate(220px, 170px) scale(1, 1);
  }

  .nprev a svg path.arrow {
    transform-origin: center;
    transform: scale(-1, 1) translateX(-100px);
    transition-duration: 0.5s;
  }

  .nprev a:hover svg path.arrow {
    transform-origin: center;
    transform: scale(-1, 1) translateX(25px);
    transition-duration: 0.5s;
  }

  .nprev a:hover figure {
    transform: scale(1.1, 0.9) translateX(-10px);
    transition-duration: 0.5s;
  }

  .nnext a svg path.arrow {
    transform-origin: center;
    transform: scale(1, 1) translateX(-100px);
    transition-duration: 0.5s;
  }

  .nnext a:hover svg path.arrow {
    transform-origin: center;
    transform: translateX(25px);
    transition-duration: 0.5s;
  }

  .nnext a:hover figure {
    transform: scale(1.1, 0.9) translateX(10px);
    transition-duration: 0.5s;
  }

  .nprev a,
  .nnext a {
    background-color: #e61974;
  }

  .nprev a img,
  .nnext a img {
    opacity: 1;
  }

  .nprev a:hover img,
  .nnext a:hover img {
    opacity: 0.7;
  }

  .cbtnPrev svg {
    left: -44px;
  }

  /* .clip-svg {
     width: 300px; 
  } */

  .nprev a:hover .fcsvg2,
  .nnext a:hover .fcsvg1 {
    background: #e61974;
    /* width: 300px; */
  }

  .clip-svg.csvg1,
  .fcsvg2 {
    /* clip-path: url("#blob1"); */
    border-radius: 68% 32% 34% 66% / 58% 35% 65% 42%;
  }

  .clip-svg.csvg2,
  .fcsvg1 {
    /* clip-path: url("#blob2"); */
    border-radius: 48% 52% 29% 71% / 55% 68% 32% 45%;
  }

  .nprev figure,
  .nnext figure {
    padding: 20px;
  }

  .ieBlock {
    width: 100%;
  }

  .ieFigure {
    width: 350px;
    height: auto;
  }

  .ieFigure img {
    border-radius: 700px;
  }
  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  .backb {
    right: -400px;
    z-index: 9;
  }

  .mainslider {
    width: 100%;
    transition-duration: 1s;
    z-index: 2;
    margin: 0 !important;
  }

  .mainslider.newsimgactive {
    width: 30vw;
    transition-duration: 1s;
  }

  .mainslider.newsimgactive #mainbild {
    width: 50vw;
    transition-duration: 1s;
  }

  @media (min-width: 1024px) {
    .mainslider {
      width: 70vw;
      transition-duration: 1s;
      z-index: 2;
    }
  }

  @media (max-width: 960px) {
    .backb {
      position: relative;
      float: left;
      bottom: -70px;
      right: 0 !important;
      left: 20px;
    }

    .sliderTextBox h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
    .nextprevBox {
      margin-left: 0px;
    }

    .newsText .newsdatum {
      position: relative;
      top: -20px;
      right: 15px;
      width: 100%;
      min-height: 120px;
      left: auto;
    }

    .newsText {
      margin: 100px 0 50px 0;
      text-align: left;
    }

    .newsTextColumn {
      width: 100% !important;
      column-count: 1 !important;
    }

    .newsText .newsTextColumn {
      height: auto !important;
      width: 100% !important;
      column-width: auto !important;
    }

    .newsText .columns:not(.is-desktop) {
      display: block !important;
    }

    /* #mainbild {
      margin: 0 -32px;
    } */
  }

  @media (min-width: 961px) and (max-width: 1439px) {
    #blslider {
      left: 10vw;
    }
  }

  .newstotop {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: none;
    opacity: 0;
    transition-duration: 1s;
  }

  .newstextactive .newstotop {
    display: block;
    background: none;
    border: none;
    transform: rotate(90deg);
    cursor: pointer;
  }

  #sharebtn {
    position: fixed;
    top: 45%;
    height: 100px;
    right: 8px;
    width: 65px;
    bottom: 20px;
    display: none;
    text-align: center;
    opacity: 0;
    transition-duration: 1s;
  }

  #sharebtn svg {
    width: 20px;
    height: 20px;
  }

  #sharebtn h4 {
    font-size: 14px;
  }

  #sharebtn svg path {
    fill: rgb(230, 25, 116);
  }

  #sharebtn a {
    width: 100%;
    display: block;
    margin-top: 10px;
    cursor: pointer;
  }

  .newstextactive #sharebtn {
    display: block;
  }
</style>

<svelte:head>

  {#if isIEorEDGE()}
    <style>
      .nextprevBox {
        margin-left: 20vw !important;
      }

      .nextprevBox figure,
      .nextprevBox figure img {
        border-radius: 500px;
      }
    </style>
  {/if}

  {#if isIe()}
    <style>
      .ieBlock {
        width: 40% !important;
      }

      .ieBlock .column {
        flex-basis: auto;
      }

      .cbtnPrev svg {
        left: 0px !important;
      }

      .cbtnPrev {
        left: 0px !important;
        top: 200px;
      }

      .cbtnNext {
        left: 180px !important;
        bottom: 70px;
      }
    </style>
  {/if}

  <style>
    section.section {
      padding: 0px;
    }
    @media screen and (min-width: 1024px) {
      #page footer.footer {
        background-color: transparent;
        color: #383737;
        padding: 0;
        margin-right: 0;
        margin-left: 0;
        position: absolute;
        overflow: hidden;
        height: 100vh;
        width: auto;
        top: 0;
        right: 0;
        z-index: 1;
      }

      .newsTextColumn .columns:not(.is-desktop) {
        display: flex;
        align-items: flex-start;
      }

      #page footer.footer .fblob {
        display: none;
      }

      .newsTextColumn::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 10px;
      }

      .newsTextColumn::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
        border-radius: 10px;
      }

      .newsTextColumn::-webkit-scrollbar-thumb {
        background-color: #5b5b5b;
        border-radius: 10px;
      }

      .newstotop:hover svg path {
        fill: #156f7b !important;
      }

      #colletscroll {
        position: absolute;
        right: -240px;
        top: 260px;
        z-index: 1000;
        transition-duration: 0.5s;
        opacity: 1;
      }

      .newsimgactive #colletscroll {
        opacity: 0;
        transition-duration: 0.5s;
      }

      .colletLoad svg {
        float: left;
        width: 40px;
        height: 40px;
        margin-right: 5px;
      }

      .colletLoad {
        line-height: 40px;
      }
    }

    @media screen and (max-width: 1024px) {
      #msvg {
        object-fit: cover;
        height: calc(100vh - 2px);
        max-height: calc(100vh - 2px);
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
      }

      #mainbild,
      .mainslider {
        overflow: hidden;
      }
    }
  </style>
</svelte:head>

<section class="mainslider ins newsblog {d === 2 ? 'newsimgactive' : ''}">
  {#if ww < 1024}
    <div class="mwelletop">
      <Mwelle />
    </div>
  {/if}
  {#if isIe()}
    {#if n.Image}
      <svg id="mainbild">
        <image
          x="0"
          y="0"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          xlink:href="{Prodhost}/colletadmin/{n.Image.styles[1].path}"
          clip-path="url(#sliderblob)" />
      </svg>
    {/if}
  {:else}
    <div id="mainbild">
      {#if ww >= 1024}
        <style>
          #msvg {
            clip-path: url("#sliderblob");
            -webkit-clip-path: url("#sliderblob");
          }
        </style>
      {/if}
      {#if n.Image}
        <!-- <div
          id="msvg"
          style="background-image: url({Prodhost}/colletadmin/{n.Image.styles[1].path});" /> -->

        <div id="msvg" />
      {/if}
    </div>
  {/if}
  <div id="blslider">
    <div id="colletscroll">
      <div class="colletLoad">
        <svg viewBox="0 0 50 50" width="40px" height="40px">
          <path
            d=" M 16.166 24.913 C 16.166 20.295 19.91 16.552 24.531 16.552 C
            29.15 16.552 32.895 20.295 32.895 24.913 C 32.895 29.534 29.15
            33.278 24.531 33.278 C 19.91 33.278 16.166 29.534 16.166 24.913 Z "
            fill="#ffffff" />
          <path
            class="crotate"
            d=" M 40.868 5.724 C 36.55 2.172 31.022 0.038 25 0.038 C 11.202
            0.038 0 11.24 0 25.038 L 0 25.038 C 0 38.835 11.202 50.037 25 50.037
            C 31.747 50.037 37.874 47.359 42.373 43.008 L 36.142 36.776 C 33.239
            39.533 29.315 41.225 25 41.225 C 16.066 41.225 8.813 33.972 8.813
            25.038 L 8.813 25.038 C 8.813 16.103 16.066 8.85 25 8.85 C 28.589
            8.85 31.907 10.021 34.592 12 L 40.868 5.724 Z "
            fill="#ffffff" />
        </svg>
        <p style="color: #ffffff;">Scroll</p>
      </div>
    </div>
    <BlobSlider />
    <div class="sliderTextBox is-vertical-center">
      <h1 class="title">
        {#if n.Title}
          {@html n.Title}
        {/if}
      </h1>
    </div>
  </div>
  {#if ww < 1024}
    <Mwelle />
  {/if}
  <div class="backb">
    <BackButton url="/blog/" />
  </div>
</section>

<section class="newsText {d === 2 ? 'newstextactive' : ''}">
  <div class="">
    <div class="newsdatum">
      {#if n.Datum}
        {#if window.location.pathname !== '/blog/jobs-in-der-agentur/'}
          <span>{n.Datum.substring(0, 2)}</span>
          {replaceMonat(n.Datum.substring(3))}
        {/if}
      {/if}
    </div>
    <div class="newsTextColumn ">
      {#if n.Text}
        <div class="columns {n.Text.length > 2000 ? '' : 'is-vcentered'} ">
          <div class="column">
            {@html n.Text}
            {#if n.Umfrage && n.Umfrage._id}
              <div class="">
                <Umfrage qid={n.Umfrage._id} />
              </div>
            {/if}
            <div
              class="columns is-vcentered is-multiline"
              style=" width: 90%; margin: 0 auto;">

              <div class="column nprev" style="position: relative">
                {#if prevNews}
                  <a
                    href={$url('../' + prevNews + '/')}
                    target="_self"
                    style="position: relative">
                    <figure
                      class="image {sUsrAg.indexOf('Trident') > -1 ? 'ieFigure' : ww => (1024 ? 'fcsvg2' : '')}
                      "
                      style="position: relative">
                      <img
                        src="{Prodhost}/colletadmin/{prevImg}"
                        class="clip-svg csvg2"
                        alt={prevAlt} />
                      <div class="cbtnPrev">
                        <h3>Prev</h3>
                        <svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 600 304"
                          style="enable-background:new 0 0 600 304;"
                          xml:space="preserve">
                          <path
                            class="arrow"
                            fill="#156f7b"
                            d="M108.6,236.8h442.7l-20.8,20.8c-2.8,2.8-2.8,7.6,0,10.4s7.6,2.8,10.4,0l33-33c0,0,0.5,0,0.5-0.5
                            c1.9-1.9,2.4-4.2,1.9-6.1c0-0.5,0-0.5,0-0.9c-0.5-0.9-0.9-1.9-1.4-2.4c0-0.5,0-0.5-0.5-0.9c0,0-0.5,0-0.5-0.5l-33-33
                            c-2.8-2.8-7.6-2.8-10.4,0c-2.8,2.8-2.8,7.6,0,10.4l20.8,20.8H108.6c-4.2,0-7.6,3.3-7.6,7.6S104.3,236.8,108.6,236.8z" />
                        </svg>
                      </div>
                    </figure>
                  </a>
                {/if}
              </div>

              <div class="column nnext" style="position: relative">
                {#if nextNews}
                  <a
                    href={$url('../' + nextNews + '/')}
                    target="_self"
                    style="position: relative">
                    <figure
                      class="image {sUsrAg.indexOf('Trident') > -1 ? 'ieFigure' : ww => (1024 ? 'fcsvg1' : '')}
                      "
                      style="position: relative">
                      <img
                        src="{Prodhost}/colletadmin/{nextImg}"
                        class="clip-svg csvg1"
                        alt={nextAlt} />
                      <div class="cbtnNext">
                        <h3>Next</h3>
                        <svg
                          x="0px"
                          y="0px"
                          viewBox="0 0 600 304"
                          style="enable-background:new 0 0 600 304;"
                          xml:space="preserve">
                          <path
                            class="arrow"
                            fill="#e61974"
                            d="M108.6,236.8h442.7l-20.8,20.8c-2.8,2.8-2.8,7.6,0,10.4s7.6,2.8,10.4,0l33-33c0,0,0.5,0,0.5-0.5
                            c1.9-1.9,2.4-4.2,1.9-6.1c0-0.5,0-0.5,0-0.9c-0.5-0.9-0.9-1.9-1.4-2.4c0-0.5,0-0.5-0.5-0.9c0,0-0.5,0-0.5-0.5l-33-33
                            c-2.8-2.8-7.6-2.8-10.4,0c-2.8,2.8-2.8,7.6,0,10.4l20.8,20.8H108.6c-4.2,0-7.6,3.3-7.6,7.6S104.3,236.8,108.6,236.8z" />
                        </svg>
                      </div>
                    </figure>
                  </a>
                {/if}
              </div>
            </div>
          </div>
        </div>
        <div id="sharebtn">
          <h4>SHARE</h4>
          <a
            href="https://www.xing.com/spi/shares/new?url={window.location.href}"
            target="_blank"
            rel="noopener"
            title="Collet Xing">
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512">
              <path
                d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8
                12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1
                0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18
                12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2
                14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8
                51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3
                6.7 8.5 14.1z" />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/dialog/feed?&app_id=4868519553188151&link={encodeURIComponent(window.location.href)}&display=popup"
            target="_blank"
            rel="noopener"
            title="Collet Facebook">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 167.657 167.657"
              style="enable-background:new 0 0 167.657 167.657;"
              xml:space="preserve">
              <g>
                <path
                  d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626
                  v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006
                  c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154
                  C167.657,37.881,130.125,0.349,83.829,0.349z" />
              </g>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/sharing/share-offsite/?url={window.location.href}"
            target="_blank"
            rel="noopener"
            title="Collet Instagram">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 510 510"
              style="enable-background:new 0 0 510 510;"
              xml:space="preserve">
              <path
                d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                M153,433.5H76.5V204H153V433.5z
                M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                S140.25,160.65,114.75,160.65z
                M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z" />
            </svg>
          </a>
        </div>
        <div
          onclick="document.querySelector('.newsTextColumn').scrollTo(0, 0);"
          class="newstotop">
          <svg viewBox="0 0 20 20" style="width:40px;height:40px;">
            <path
              d="M0 10c0-.9.1-1.8.4-2.6.2-.8.6-1.6 1-2.4.4-.7 1-1.4 1.6-2 .6-.6
              1.3-1.1 2-1.6.7-.4 1.5-.8 2.4-1C8.2.1 9.1 0 10 0c.9 0 1.8.1
              2.6.4.8.2 1.6.6 2.4 1 .7.4 1.4 1 2 1.6.6.6 1.1 1.3 1.6 2 .4.7.8
              1.5 1 2.4.3.8.4 1.7.4 2.6 0 .9-.1 1.8-.4 2.6-.2.8-.6 1.6-1
              2.4-.4.7-1 1.4-1.6 2-.6.6-1.3 1.1-2 1.6-.7.4-1.5.8-2.4
              1-.8.3-1.7.4-2.6.4-.9
              0-1.8-.1-2.6-.4-.8-.2-1.6-.6-2.4-1-.7-.4-1.4-1-2-1.6-.6-.6-1.1-1.3-1.6-2-.4-.7-.8-1.5-1-2.4-.3-.8-.4-1.7-.4-2.6zm1.7
              0c0 .8.1 1.5.3 2.2.2.7.5 1.4.8 2 .4.6.8 1.2 1.3 1.7s1.1.9 1.7
              1.3c.6.4 1.3.6 2 .8.7.2 1.4.3 2.2.3.8 0 1.5-.1 2.2-.3.7-.2 1.4-.5
              2-.8.6-.4 1.2-.8 1.7-1.3s.9-1.1 1.3-1.7c.4-.6.6-1.3.8-2
              .2-.7.3-1.4.3-2.2
              0-.8-.1-1.5-.3-2.2-.2-.7-.5-1.4-.8-2-.4-.6-.8-1.2-1.3-1.7s-1.1-.9-1.7-1.3c-.6-.4-1.3-.6-2-.8-.7-.2-1.4-.3-2.2-.3-.8
              0-1.5.1-2.2.3-.7.2-1.4.5-2 .8-.6.4-1.1.9-1.6 1.4-.5.5-1 1-1.3
              1.6-.4.7-.7 1.3-.9 2-.2.7-.3 1.4-.3 2.2zm4.2 0l4-4.2h2.9L9.7
              8.9h6.7v2.2H9.7l3.1 3.1H9.9l-4-4.2zm-.3 4.2H4V5.8h1.6v8.4z"
              fill="#e4e4e4" />
          </svg>
        </div>
      {/if}
    </div>
  </div>
</section>

<section class=" nextprevBox" />

<svg viewBox="0 0 398.6 435.4" width="0" height="0">
  <clipPath
    id="blob1"
    clipPathUnits="objectBoundingBox"
    transform="scale(0.002508, 0.002296)">
    <path
      class="bblob"
      transform=" translate(220, 170) "
      d="M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z" />
  </clipPath>
</svg>

<svg viewBox="0 0 398.6 435.4" width="0" height="0">
  <clipPath
    id="blob2"
    clipPathUnits="objectBoundingBox"
    transform="scale(0.002508, 0.002296)">
    <path
      class="bblob"
      transform=" translate(220, 170) "
      d="M72.4,-57.4C96.2,-27.8,119.8,2.5,125.7,49.7C131.7,96.9,120.1,161.1,78.8,191.8C37.5,222.5,-33.6,219.8,-96,192.4C-158.4,165,-212.3,112.9,-216.9,59.1C-221.5,5.4,-176.8,-50,-132.4,-84.3C-88.1,-118.6,-44,-131.8,-9.9,-123.9C24.2,-116,48.5,-87,72.4,-57.4Z" />
  </clipPath>
</svg>

{#if ww => 1024}
  <div class="svgclippath">
    <svg viewBox="0 0 1200 900" width="0" height="0">
      <clipPath
        id="sliderblob"
        clipPathUnits="objectBoundingBox"
        transform="scale(0.0008, 0.002)">
        <path
          style=" transform: translate(-180px, 0px);"
          transform="translate(-180, 0)"
          class="bblob"
          d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
          143.61c-4.55,95.55,170.07,212.41,10.95,462.77
          C1005.43,799.35,1195.72,900.48,1200,900z" />
      </clipPath>
    </svg>
  </div>

  <div class="scrollcontentbox" />
{/if}

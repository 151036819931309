<script>
  import Prodhost from "../../components/host.js";
  import { params } from "@sveltech/routify";
  import { onMount } from "svelte";
  import anime from "animejs";
  import BlobSlider from "../../components/BlobSlider.svelte";
  import Mwelle from "../../components/svg/mwelle.svelte";
  import BackButton from "../../components/BackButton.svelte";

  function isIe() {
    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent) ||
      /Edge\/\d./i.test(navigator.userAgent)
    ) {
      return true;
    }
    return false;
  }

  var ww = window.innerWidth;
  var wh = window.innerHeight;

  let jobs = [];

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/collections/get/jobs", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { slug: $params.slug, published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        jobs = json.entries;
        if (ww => 1024) {
          setTimeout(() => {
            anime({
              targets: "#sliderblob .bblob",
              duration: 20000,
              easing: "linear",
              d: [
                {
                  value:
                    "M1200,900H0V0h1200c0,0-237.23,127.43-98.93,292.52c184.67,220.44,37.23,281.75,18.49,408.23 C1101.71,821.22,1195.72,900.48,1200,900z"
                },
                {
                  value:
                    "M1200,900H0V0h1200c0,0-79.29,68.25-82.88,143.61c-4.55,95.55,170.07,212.41,10.95,462.77	C1005.43,799.35,1195.72,900.48,1200,900z"
                }
              ],
              loop: true,
              update: () => {
                if (
                  navigator.userAgent.search("Safari") >= 0 &&
                  navigator.userAgent.search("Chrome") < 0
                ) {
                  const fimp = document.getElementById("msvg");
                  fimp.style.display = "none";
                  fimp.offsetWidth;
                  fimp.style.display = "block";
                }
              }
            });
          }, 500);
        }
      });

    const el = document.querySelector(".contentmain");
    if (ww > 960) {
      window.addEventListener("wheel", function(e) {
        let sp = e.deltaY + e.deltaX;
        if (sp < 0) sp = sp * -1;
        if (e.deltaY >= 100) {
          sp = sp / 2;
        }
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          if (navigator.platform.indexOf("Mac") >= 0) {
            sp = 50;
          } else {
            if (e.deltaY < 5) {
              sp = sp * 20;
            } else {
              sp = sp * 12;
            }
          }
        }

        if (
          navigator.userAgent.search("Safari") >= 0 &&
          navigator.userAgent.search("Chrome") < 0 &&
          navigator.platform.indexOf("Mac") > -1
        ) {
          sp = 80;
        }

        if (e.deltaY > 0 || e.deltaX > 0) el.scrollLeft += sp;
        else el.scrollLeft -= sp;
      });

      el.onscroll = function() {
        var elmnt = document.querySelector(".contentmain");
        var x = elmnt.scrollLeft;
      };
    }
  });
</script>

<style>
  .sliderTextBox {
    top: 10px;
    height: 250px;
  }
  .sliderTextBox h1 {
    font-size: 1.8rem;
    line-height: 2.3rem;
    word-break: normal;
  }

  #blslider {
    left: 10vw;
  }

  #projektInfo {
    text-align: left;
    padding: 10% 0px 10% 0px;
    min-width: 100vw;
    margin-right: -200px;
    width: auto;
  }

  #projektInfo .column {
    position: relative;
    z-index: 1;
    min-width: 600px;
    padding-left: 4rem;
    padding-right: 4rem;
    height: max-content;
  }

  #projektInfo .column span {
    position: absolute;
    z-index: 1;
    font-size: 7rem;
    top: -50px;

    font-weight: 900;
    color: #ffcdcd;
  }

  #projektInfo .column h2 {
    position: relative;
    z-index: 10;
    font-size: 32px;
    text-transform: uppercase;
  }

  #projektInfo .j1 span {
    color: #e61974;
    opacity: 0.25;
    left: 220px;
  }

  #projektInfo .j2 span {
    color: #156f7b;
    opacity: 0.25;
    left: 290px;
  }

  #projektInfo .j3 span {
    color: #000000;
    opacity: 0.1;
    left: 300px;
  }

  #projektInfo .j4 span {
    color: #e61974;
    opacity: 0.25;
    left: 170px;
  }

  #projektInfo .j5 span {
    color: #156f7b;
    opacity: 0.25;
    left: 230px;
  }

  #mainbild {
    width: 50vw;
    background-position: top center;
    margin-right: 100px;
  }

  .svgclippath {
    position: absolute;
    top: -1000000px;
  }

  @media (max-width: 960px) {
    #projektInfo {
      text-align: left;
      padding: 0 32px;
      min-width: 100vw;
      margin-right: 0px;
      width: auto;
    }

    .sliderTextBox h1 {
      word-break: break-word;
      max-width: 300px;
    }

    #projektInfo h2 {
      font-size: 26px;
    }

    #projektInfo .j1 span,
    #projektInfo .j2 span,
    #projektInfo .j3 span,
    #projektInfo .j4 span,
    #projektInfo .j5 span {
      left: 200px;
    }
  }
</style>

{#each jobs as j}
  <section class="section mainslider ins jobs">
    <div class="mwelletop">
      <Mwelle />
    </div>

    {#if isIe()}
      <svg id="mainbild">
        <image
          x="0"
          y="0"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          xlink:href="{Prodhost}/colletadmin/{j.Bild.styles[0].path}"
          clip-path="url(#sliderblob)" />
      </svg>
    {:else}
      <div id="mainbild">
        {#if ww >= 1024}
          <style>
            #msvg {
              clip-path: url("#sliderblob");
              -webkit-clip-path: url("#sliderblob");
            }
          </style>
        {/if}
        <div
          id="msvg"
          style="background-image: url({Prodhost}/colletadmin/{j.Bild.styles[0].path});" />
      </div>
    {/if}

    <div id="blslider">
      <BlobSlider />
      <div class="sliderTextBox is-vertical-center">
        <h1 class="title">
          {@html j.Title}
        </h1>
      </div>
    </div>

    <Mwelle />

    <div class="backb">
      <BackButton url="" onc="true" />
    </div>

  </section>

  <section class="section" id="projektInfo">
    <style>
      ul {
        list-style: disc;
      }
    </style>
    <div class="columns ">
      <div class="column j1">
        <h2>Dein Profil</h2>
        <span>01</span>
        <p>
          {@html j.profil}
        </p>
      </div>
      <div class="column j2">
        <h2>Unser Angebot</h2>
        <span>02</span>
        <p>
          {@html j.angebot}
        </p>
      </div>
      <div class="column j3">
        <h2>Deine Aufgaben</h2>
        <span>03</span>
        <p>
          {@html j.aufgaben}
        </p>
      </div>
      <div class="column j4">
        <h2>Über uns</h2>
        <span>04</span>
        <p>
          {@html j.about}
        </p>
      </div>
      <div class="column j5">
        <h2>Bewerbung</h2>
        <span>05</span>
        <p>
          {@html j.bewerbung}
        </p>
      </div>
    </div>
  </section>
{/each}

<div class="svgclippath">
  <svg viewBox="0 0 1200 900" width="0" height="0">
    <clipPath
      id="sliderblob"
      clipPathUnits="objectBoundingBox"
      transform="scale(0.0008, 0.002)">
      <path
        style=" transform: translate(-180px, 0px);"
        transform="translate(-180, 0)"
        class="bblob"
        d="M1200,900H0V0h1200c0,0-79.29, 68.25-82.88,
        143.61c-4.55,95.55,170.07,212.41,10.95,462.77
        C1005.43,799.35,1195.72,900.48,1200,900z" />
    </clipPath>
  </svg>

</div>

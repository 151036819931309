<script>
  import Prodhost from "./host.js";
  import { onMount } from "svelte";
  // import Swiper from "swiper";
  import { url } from "@sveltech/routify";
  import isMobile from "./isMobile.js";

  import { Swiper, Navigation, EffectCoverflow } from "swiper/js/swiper.esm.js";

  // Install modules
  Swiper.use([Navigation, EffectCoverflow]);

  let projekte = [];

  onMount(() => {
    fetch(Prodhost + "/colletadmin/api/collections/get/projekte", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: { published: true }
      })
    })
      .then(response => response.json())
      .then(json => {
        projekte = json.entries;
        projekte.sort(function(a, b) {
          a.Datum = Number(new Date(a.Datum));
          b.Datum = Number(new Date(b.Datum));
          return b.Datum - a.Datum;
        });
      });

    let direct = "horizontal";

    if (!isMobile()) {
      direct = "vertical";
    }

    let ww = window.innerWidth;

    if (ww >= 1000) {
      direct = "vertical";
    }

    setTimeout(() => {
      var swiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        slidesPerView: 3,
        effect: "coverflow",
        speed: 700,
        simulateTouch: false,
        allowTouchMove: false,
        initialSlide: -1,
        loop: true,
        lazy: true,
        preloadImages: false,
        lazy: {
          loadPrevNext: true
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        pagination: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          960: {
            direction: "vertical"
          }
        }
      });
    }, 700);
  });
</script>

<style>
  .swiper-container {
    width: 500px;
    height: 100%;
    margin-left: 400px;
    margin-right: 500px;
    overflow: visible;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    background-size: cover;
    background-position: center;
    width: 500px;
    height: 300px;
    position: relative;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide em {
    font-style: normal;
    margin-right: 10px;
    color: #036358;
    font-weight: 700;
  }

  .swiper-slide > a > div {
    background-size: cover;
    background-position: center;
    width: 500px;
    height: 300px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    padding: 5px 10px;
    color: #fff;
    background-color: #e61974;
  }

  .swiper-button-next {
    bottom: 100px;
    left: 50%;
    top: auto;
    transform: rotateZ(90deg);
  }

  .swiper-button-prev {
    top: 100px;
    left: 50%;
    bottom: auto;
    transform: rotateZ(90deg);
    color: #fff;
    background-color: #e61974;
  }

  .swiper-slide .pslidetext {
    position: absolute;
    right: -500px;
    width: 420px;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.5s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
  }

  .swiper-slide .pslidetext {
    text-align: left;
    border-top: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    padding: 30px 0 30px 20px;
    background-color: #ffffff;
    top: 20%;
    font-size: 18px;
    opacity: 0;
  }

  .swiper-slide span {
    position: absolute;
    width: 35px;
    top: 120px;
    border-top: 1px solid #4a4a4a;
    left: -35px;
    z-index: 100;
  }

  .swiper-slide .pslidetext > p {
    background-color: #ffffff;
    position: relative;
    z-index: 10;
    width: 420px;
    z-index: 10;
    padding: 40px 0;
    margin: -50px 0;
  }
</style>

<div class="swiper-container">
  <div class="swiper-wrapper">
    {#each projekte as projekt, i}
      {#if i >= 4}
        <div class="swiper-slide">
          <a
            href={$url('../' + projekt.slug + '/')}
            title="to {projekt.Title}"
            target="_self">
            <div
              class="swiper-lazy"
              style="background-image:url({Prodhost}/colletadmin/{projekt.Image.styles[2].path})">
          
            </div>
          </a>
          <div class="pslidetext">
            <p>
              <em>{i + 1} / {projekte.length}</em>
              <strong>
                {@html projekt.Title}
              </strong>
              <br />
              {#if isMobile()}
                {@html projekt.Text.substring(0, 160)
                  .split(' ')
                  .reverse()
                  .slice(1)
                  .reverse()
                  .join(' ') + ' ...'}
              {:else}
                {@html projekt.Text.substring(0, 250)
                  .split(' ')
                  .reverse()
                  .slice(1)
                  .reverse()
                  .join(' ') + ' ...'}
              {/if}
            </p>
            <span />
          </div>
        </div>
      {/if}
    {/each}
  </div>
  <div class="swiper-button-next">
    {#if navigator.userAgent.indexOf('Trident') > -1}next{/if}
  </div>
  <div class="swiper-button-prev">
    {#if navigator.userAgent.indexOf('Trident') > -1}prev{/if}
  </div>
</div>

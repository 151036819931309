<script>
  import { onMount } from "svelte";
  import anime from "animejs";
  import ColletButton from "./Button.svelte";

  export let openertext;

  var ww = window.innerWidth;

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  onMount(() => {

    
      
      anime({
        targets: "#opener",
        translateX: ww * -3,
        duration: 2000,
        opacity: [1, 0],
        width: "0px",
        autoplay: true,
        easing: "easeInOutQuad",
        delay: 8000,
        loop: false,
        complete: function() {       
          document.querySelector(".slider").style.overflowX = "visible";
        }
      });
    

    anime({
      targets: ["#lamp-anim"],
      opacity: [0, 1],
      duration: 3000,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 7000,
      loop: false
    });

    anime({
      targets: ["#index_mainImage"],
      opacity: [0, 1],
      duration: 2000,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 7000,
      loop: false
    });

    // anime({
    //   targets: ".hoblob2",
    //   opacity: [0, 0.2],
    //   scale: [1.2, 1],
    //   rotate: ["0deg", "20deg"],
    //   duration: 1000,
    //   autoplay: true,
    //   easing: "easeInOutQuad",
    //   delay: 500,
    //   loop: false
    // });

    // anime({
    //   targets: ".hoblob3",
    //   opacity: [0, 0.2],
    //   rotate: ["0deg", "-15deg"],
    //   duration: 1000,
    //   autoplay: true,
    //   easing: "easeInOutQuad",
    //   delay: 1000,
    //   loop: false
    // });

    anime({
      targets: ".hoblob",
      opacity: [0, 1],
      duration: 3000,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 1000,
      loop: false
    });

    anime({
      targets: ".openerinfo",
      opacity: [0, 1],
      duration: 1500,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 1500,
      loop: false
    });

    anime({
      targets: ".crotateo",
      rotate: ["0deg", "360deg"],
      duration: 1000,
      autoplay: true,
      easing: "linear",
      delay: 1500,
      loop: false
    });

    anime({
      targets: ".omein",
      opacity: [0, 1],
      duration: 1000,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 2500,
      loop: false
    });

    anime({
      targets: ".openerinfotext",
      opacity: [0, 1],
      duration: 50,
      autoplay: true,
      easing: "easeInOutQuad",
      delay: 2000,
      complete: function() {
        // anime({
        //   targets: ".openerinfotext .rowtext",
        //   opacity: [0, 1],
        //   duration: 1500,
        //   easing: "easeInOutQuad",
        // });

        setTimeout(function() {
          var str = document.querySelector(".openerinfotext .rowtext")
            .innerHTML;
          var stext = str.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "");
          stext = stext.replace(/&nbsp;/gi, " ");

          var spans =
            "<span style='opacity: 0;'>" +
            stext.split("").join("</span><span style='opacity: 0;'>") +
            "</span>";
          document.querySelector(".openerinfotext").innerHTML = spans;

          var basicTimeline = anime.timeline();
          basicTimeline.add({
            targets: ".openerinfotext span",
            opacity: {
              value: ["0", "1"],
              duration: 700,
              delay: function(el, i) {
                return 40 * (i + 1);
              }
            }
          });
        }, 0);
      },
      loop: false
    });
  });
</script>

<style>
  #openerbox {
    position: relative;
    top: 0;
    left: 0;
    width: 900px;
    height: 500px;
  }
  .openerinfo {
    opacity: 0;
    position: absolute;
    left: 260px;
    width: 350px;
    z-index: 20;
    top: 150px;
  }
  .hoblob {
    opacity: 0;
    position: absolute;
    width: 400px;
    height: 400px;
    left: -50px;
    top: 80px;
    z-index: 10;
    display: block;
    background: #e61974;
    background: linear-gradient(270deg, #e61974, #156f7b);
    background-size: 400% 400%;
    -webkit-animation: homeopenermorph 4s linear infinite alternate;
    -moz-animation: homeopenermorph 4s linear infinite alternate;
    animation: homeopenermorph 4s linear infinite alternate;
    border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
  }

  /* .hoblob2 {
    opacity: 0.2;
    transform: scale(1.1) rotate(20deg);
    width: 650px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: -110px;
    z-index: 1;
    display: block;
    background: #156f7b;
    border-radius: 26% 74% 56% 44% / 63% 45% 55% 37%;
  }

  .hoblob3 {
    opacity: 0.2;
    transform: rotate(-15deg) scaleX(-1) scaleY(-1);
    width: 650px;
    height: 400px;
    position: absolute;
    bottom: 0px;
    left: -110px;
    z-index: 1;
    display: block;
    background: #e61974;
    border-radius: 26% 74% 56% 44% / 63% 45% 55% 37%;
  } */

  #openerbox .colletBtn svg {
    float: left;
    width: 150px;
    height: 150px;
    margin-right: -35px;
  }

  #openerbox .colletBtn {
    line-height: 150px;
    font-size: 40px;
    font-weight: 800;
    color: #e61974;
  }

  .colletBtn .crotateo {
    transform-origin: center;
  }

  .openerinfotext {
    width: 100%;
    float: left;
    margin-top: 30px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .openerinfotext .rowtext {
    opacity: 0;
  }

  .omein {
    opacity: 0;
  }

  @-webkit-keyframes homeopenermorph {
    0% {
      background-position: 0% 51%;
      border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    }
    50% {
      background-position: 100% 50%;
      border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
    }
    100% {
      border-radius: 28% 72% 49% 51% / 25% 51% 49% 75%;

      background-position: 0% 51%;
    }
  }
  @-moz-keyframes homeopenermorph {
    0% {
      background-position: 0% 51%;
      border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    }
    50% {
      background-position: 100% 50%;
      border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
    }
    100% {
      background-position: 0% 51%;
      border-radius: 28% 72% 49% 51% / 25% 51% 49% 75%;
    }
  }
  @keyframes homeopenermorph {
    0% {
      background-position: 0% 51%;
      border-radius: 48% 52% 75% 25% / 59% 56% 44% 41%;
    }
    50% {
      background-position: 100% 50%;
      border-radius: 43% 57% 62% 38% / 45% 71% 29% 55%;
    }
    100% {
      background-position: 0% 51%;
      border-radius: 28% 72% 49% 51% / 25% 51% 49% 75%;
    }
  }
</style>

<svelte:head>
  <style>
    #lamp-anim,
    #index_mainImage {
      opacity: 0;
    }

    .slider {
      overflow-x: hidden;
    }
  </style>
</svelte:head>

<div id="openerbox">
  <div class="hoblob" />
  <!-- <div class="hoblob2" />
  <div class="hoblob3" /> -->
  <div class="openerinfo">

    <div class="colletBtn">
      <svg viewBox="0 0 50 50" width="150px" height="150px">
        <defs>
          <clipPath>
            <rect width="150" height="150" />
          </clipPath>
        </defs>
        <path
          d=" M 16.166 24.913 C 16.166 20.295 19.91 16.552 24.531 16.552 C 29.15
          16.552 32.895 20.295 32.895 24.913 C 32.895 29.534 29.15 33.278 24.531
          33.278 C 19.91 33.278 16.166 29.534 16.166 24.913 Z "
          fill="rgb(236,58,130)" />
        <path
          class="crotateo"
          d=" M 40.868 5.724 C 36.55 2.172 31.022 0.038 25 0.038 C 11.202 0.038
          0 11.24 0 25.038 L 0 25.038 C 0 38.835 11.202 50.037 25 50.037 C
          31.747 50.037 37.874 47.359 42.373 43.008 L 36.142 36.776 C 33.239
          39.533 29.315 41.225 25 41.225 C 16.066 41.225 8.813 33.972 8.813
          25.038 L 8.813 25.038 C 8.813 16.103 16.066 8.85 25 8.85 C 28.589 8.85
          31.907 10.021 34.592 12 L 40.868 5.724 Z "
          fill="rgb(55,53,53)" />
      </svg>
      <div class="omein">OME IN!</div>
    </div>

    <div class="openerinfotext">
      <span />
      <div class="rowtext">
        <h1>
          {@html openertext}
        </h1>
      </div>
    </div>
  </div>
</div>

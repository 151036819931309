<script>
  import Prodhost from "./host.js";
  import { onMount, afterUpdate, beforeUpdate } from "svelte";
  import { nextFrame } from "../utils";
  import ColletRotationC from "./ColletRotationC.svelte";

  let elements = [];
  let elementsContent = [];
  let currentNews = {};
  let currentYear = "";
  let currentMonth = "";

  export let news = [];
  let oldNews = news;

  // export let mobile = false;

  let mobile = false;

  if (window.innerWidth <= 961) {
    mobile = true;
  }

  let currentIndex = 14;

  function getIndex(index) {
    if (news.length === 0) {
      return 0;
    }

    while (index >= news.length) {
      index -= news.length;
    }

    while (index < 0) {
      index += news.length;
    }

    return index;
  }

  function getContent(index) {
    let obj = news[getIndex(index)];

    return {
      alt: obj ? obj.Image.title : "",
      src: obj ? obj.Image.styles[4].path : "",
      slug: obj ? obj.slug : "",

      index: getIndex(index)
    };
  }

  var gel = true;
  function grtEl() {
    if (gel === true) {
      elements = Array.prototype.slice.call(
        document.querySelectorAll("#nwslider .nlimage"),
        0
      );
    }
    gel = false;
  }

  async function stepUp() {
    currentIndex = getIndex(currentIndex + 1);

    grtEl();

    elements.unshift(elements.pop());

    elements[0].classList.remove("animated");
    await nextFrame();
    elements[0].classList.remove("placeholder_bottom");
    elements[0].classList.add("placeholder_top");
    elementsContent[elements[0].dataset.index] = getContent(currentIndex + 2);
    await nextFrame();
    elements[0].classList.add("animated");
    elements[0].classList.add("image_0");
    elements[0].classList.remove("placeholder_top");

    elements[1].classList.remove("image_0");
    elements[1].classList.add("image_1");
    elementsContent[elements[1].dataset.index] = getContent(currentIndex + 1);

    elements[2].classList.remove("image_1");
    elements[2].classList.add("image_2");
    elementsContent[elements[2].dataset.index] = getContent(currentIndex);

    elements[3].classList.remove("image_2");
    elements[3].classList.add("image_3");
    elementsContent[elements[3].dataset.index] = getContent(currentIndex - 1);

    elements[4].classList.remove("image_3");
    elements[4].classList.add("image_4");
    elementsContent[elements[4].dataset.index] = getContent(currentIndex - 2);

    elements[5].classList.remove("image_4");
    elements[5].classList.add("placeholder_bottom");
    elementsContent[elements[5].dataset.index] = getContent(currentIndex - 3);
  }

  async function stepDown() {
    currentIndex = getIndex(currentIndex - 1);

    grtEl();
    elements.push(elements.shift());

    elements[5].classList.remove("image_0");
    elements[5].classList.add("placeholder_top");
    elementsContent[elements[5].dataset.index] = getContent(currentIndex + 3);

    elements[0].classList.remove("image_1");
    elements[0].classList.add("image_0");
    elementsContent[elements[0].dataset.index] = getContent(currentIndex + 2);

    elements[1].classList.remove("image_2");
    elements[1].classList.add("image_1");
    elementsContent[elements[1].dataset.index] = getContent(currentIndex + 1);

    elements[2].classList.remove("image_3");
    elements[2].classList.add("image_2");
    elementsContent[elements[2].dataset.index] = getContent(currentIndex);

    elements[3].classList.remove("image_4");
    elements[3].classList.add("image_3");
    elementsContent[elements[3].dataset.index] = getContent(currentIndex - 1);

    elements[4].classList.remove("animated");
    await nextFrame();
    elements[4].classList.remove("placeholder_top");
    elements[4].classList.add("placeholder_bottom");
    await nextFrame();
    elementsContent[elements[4].dataset.index] = getContent(currentIndex - 2);
    elements[4].classList.add("animated");
    elements[4].classList.remove("placeholder_bottom");
    elements[4].classList.add("image_4");
  }

  $: {
    if (news.length > 0) {
      elementsContent =
        news === oldNews
          ? elementsContent
          : [
              getContent(currentIndex + 2),
              getContent(currentIndex + 1),
              getContent(currentIndex),
              getContent(currentIndex - 1),
              getContent(currentIndex - 2),
              getContent(currentIndex - 3)
            ];

      setTimeout(() => {
        currentNews = news[currentIndex];
        let date = new Date(currentNews.Datum);
        if (isNaN(date.getTime())) {
          currentYear = "";
          currentMonth = "";
        } else {
          currentYear = (date.getFullYear() + "").slice(-2);
          currentMonth = ("0" + (date.getMonth() + 1)).slice(-2);
        }
      }, 2000);
    } else {
      elementsContent = [];
      currentNews = {};
      currentYear = "";
      currentMonth = "";
    }
    oldNews = news;
  }

  onMount(() => {
    if (news.length > 0) {
      elements = Array.prototype.slice.call(
        document.querySelectorAll("#nwslider .nlimage"),
        0
      );
      console.log(elements);
    }
  });
</script>

<style>
  .nlimage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    width: 25vh;
    height: 25vh;
    border-radius: 500px !important;
    overflow: hidden;
  }

  .nlimage img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 500px !important;
    object-position: center;
    object-fit: cover;
  }

  .animated {
    transition: 1s ease-in-out;
  }

  .image_0 {
    transform: translate(30vh, -50vh);
  }

  .image_1 {
    transform: translate(10vh, -27.5vh);
  }

  .image_2 {
    transform: translate(0px, 0px);
  }

  .image_3 {
    transform: translate(10vh, 27.5vh);
  }

  .image_4 {
    transform: translate(30vh, 50vh);
  }

  .placeholder_top,
  .placeholder_bottom {
    opacity: 0;
  }

  .placeholder_top {
    transform: translate(50vh, -70vh);
  }

  .placeholder_bottom {
    transform: translate(50vh, 70vh);
  }

  .newsSlider_wheel {
    position: relative;
    height: 100%;
    margin-left: 2em;
  }

  .newsSlider_stepper div {
    display: flex;
    justify-content: center;
  }

  .newsSlider_stepper img {
    top: 0;
    margin: 0;
  }

  .newsSlider_stepper img:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .newsSlider_number {
    margin: 0.5em 0;
    font-size: 2rem;
    font-weight: 600;
    color: #156f7b;
    opacity: 0.7;
  }

  .newsSlider_text {
    position: relative;
    left: calc(25vh + 2em);
    text-align: left;
  }

  .newsSlider_text h3 {
    font-weight: 600;
  }

  .newsSlider_text a {
    margin-top: 1em;
    font-size: 14px;
    display: inline-block;
  }

  .newsSlider_wrapper {
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .newsSlider_wrapper.mobile {
    flex-direction: column;
    height: auto;
  }

  .newsSlider_wrapper.mobile .newsSlider_wheel {
    flex-direction: column;
    height: 50vw;
    margin-left: 0;
    width: 60vw;
    overflow: hidden;
  }

  .newsSlider_wrapper.mobile .newsSlider_text {
    position: relative;
    left: auto;
    text-align: inherit;
  }

  .newsSlider_wrapper.mobile .image_0 {
    transform: translate(-160vw, 0);
  }

  .newsSlider_wrapper.mobile .image_1 {
    transform: translate(-80vw, 0);
  }

  .newsSlider_wrapper.mobile .image_2 {
    transform: translate(0px, 0px);
  }

  .newsSlider_wrapper.mobile .image_3 {
    transform: translate(80vw, 0);
  }

  .newsSlider_wrapper.mobile .image_4 {
    transform: translate(160vw, 0);
  }

  .newsSlider_wrapper.mobile .placeholder_top {
    transform: translate(-240vw, 0);
  }

  .newsSlider_wrapper.mobile .placeholder_bottom {
    transform: translate(240vw, 0);
  }

  .newsSlider_wrapper.mobile .nlimage {
    right: 0;
    border-radius: 500px;
    width: 50vw;
    height: 50vw;
  }

  .newsSlider_wrapper.mobile {
    min-height: 640px;
  }

  .newsSlider_wrapper.mobile .newsSlider_wheelWrapper {
    display: flex;
    align-items: center;
  }

  .newsSlider_wrapper.mobile img {
    margin: 0;
  }

  .newsSlider_wrapper.mobile img.newsSlider_arrow {
    top: auto;
    margin: 2vw;
    width: 20px;
    height: 37px;
  }

  @media handheld, only screen and (max-width: 960px) {
    .newsSlider_text,
    body #index .newsSlider_text a {
      margin-top: 1em !important;
    }
  }
</style>

{#if news.length > 0}

  <div class={'newsSlider_wrapper ' + (mobile ? 'mobile' : '')}>
    <div class="newsSlider_stepper">
      {#if !mobile}
        <div on:click={stepUp}>
          <img alt="arrow_up" src="{Prodhost}/images/angle-top.png" />
        </div>
      {/if}
      <div class="newsSlider_number">{currentYear}/{currentMonth}</div>
      {#if !mobile}
        <div on:click={stepDown}>
          <img alt="arrow_down" src="{Prodhost}/images/angle-bottom.png" />
        </div>
      {/if}
    </div>
    <div class="newsSlider_wheelWrapper">
      {#if mobile}
        <div on:click={stepUp}>
          <img
            class="newsSlider_arrow"
            alt="arrow_left"
            width="20"
            height="37"
            src="{Prodhost}/images/angle-left.png" />
        </div>
      {/if}
      <div id="nwslider" class="newsSlider_wheel">
        <div data-index="0" class="nlimage animated image_0">
          <a href="{Prodhost}/blog/{elementsContent[0].slug}/" target="_self">
            <img
              alt={elementsContent.length > 0 ? elementsContent[0].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 0 ? elementsContent[0].src : ''}" />
          </a>
        </div>
        <div data-index="1" class="nlimage animated image_1">
          <a href="{Prodhost}/blog/{elementsContent[1].slug}/" target="_self">
            <img
              alt={elementsContent.length > 1 ? elementsContent[1].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 1 ? elementsContent[1].src : ''}" />
          </a>
        </div>
        <div data-index="2" class="nlimage animated image_2">
          <a href="{Prodhost}/blog/{elementsContent[2].slug}/" target="_self">
            <img
              alt={elementsContent.length > 2 ? elementsContent[2].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 2 ? elementsContent[2].src : ''}" />
          </a>
        </div>
        <div data-index="3" class="nlimage animated image_3">
          <a href="{Prodhost}/blog/{elementsContent[3].slug}/" target="_self">
            <img
              alt={elementsContent.length > 3 ? elementsContent[3].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 3 ? elementsContent[3].src : ''}" />
          </a>
        </div>
        <div data-index="4" class="nlimage animated image_4">
          <a href="{Prodhost}/blog/{elementsContent[4].slug}/" target="_self">
            <img
              alt={elementsContent.length > 4 ? elementsContent[4].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 4 ? elementsContent[4].src : ''}" />
          </a>
        </div>
        <div
          data-index="5"
          class="nlimage animated placeholder_bottom placeholder_top">
          <a href="{Prodhost}/blog/{elementsContent[5].slug}/" target="_self">
            <img
              alt={elementsContent.length > 5 ? elementsContent[5].alt : ''}
              src="{Prodhost}/colletadmin/{elementsContent.length > 5 ? elementsContent[5].src : ''}" />
          </a>
        </div>
      </div>
      {#if mobile}
        <div on:click={stepDown}>
          <img
            class="newsSlider_arrow" 
            alt="arrow_right"
            width="20"
            height="37"
            src="{Prodhost}/images/angle-right.png" />
        </div>
      {/if}
    </div>
    <div class="newsSlider_text">
      <h3>
        {@html currentNews.Title || ''}
      </h3>
      <p>{currentNews.Meta || ''}</p>
      <a href="{Prodhost}/blog/{currentNews.slug || ''}" target="_self">
        <ColletRotationC text="MEHR LESEN" id="" class="" style="" />
      </a>
    </div>
  </div>
{/if}
